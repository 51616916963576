// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category_header_top_gen_body {
  width: calc(75% - 20px);
  /* height: 220px; */
  position: relative;
  box-sizing: border-box;
}

.category_header_top_gen_body_textt {
  color: #0a0d14;
  font-size: 29px;
  font-weight: 700;
}

.category_header_top_gen_body_genn {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: 230px;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.category_header_top_gen_body_mainImage {
  width: 100%;
  height: 100%;
}

.category_header_top_gen_body_mainImage img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.category_header_top_gen_body_water_mark {
  position: absolute;
  right: 0;
  bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/supamallComp/categoryHeaderTop/categoryHeaderTop.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;AACX","sourcesContent":[".category_header_top_gen_body {\n  width: calc(75% - 20px);\n  /* height: 220px; */\n  position: relative;\n  box-sizing: border-box;\n}\n\n.category_header_top_gen_body_textt {\n  color: #0a0d14;\n  font-size: 29px;\n  font-weight: 700;\n}\n\n.category_header_top_gen_body_genn {\n  width: 100%;\n  box-sizing: border-box;\n  position: relative;\n  height: 230px;\n  border-radius: 20px;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n\n.category_header_top_gen_body_mainImage {\n  width: 100%;\n  height: 100%;\n}\n\n.category_header_top_gen_body_mainImage img {\n  width: 100%;\n  height: 100%;\n  border-radius: 20px;\n  object-fit: cover;\n}\n\n.category_header_top_gen_body_water_mark {\n  position: absolute;\n  right: 0;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
