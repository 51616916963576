// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner_accordion_border_body {
  border: 1px solid #e2e4e9;

  width: 80%;
  margin-right: auto;
  margin-left: auto;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px;
}

.inner_accordion_border_body_heading {
  font-size: 14px;
  font-weight: 600;
  color: #0a0d14;
}

.inner_accordion_border_body_heading_sub {
  font-size: 12px;
  font-weight: 400;
  color: #525866;
  margin-top: 2px;
  text-transform: lowercase;
}

.inner_accordion_border_body_heading_sub::first-letter {
  text-transform: uppercase; /* Capitalize the first letter */
}

@media screen and (max-width: 600px) {
  .inner_accordion_border_body {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/innerAccordionBorderBody/innerAccordionBorderBody.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;;EAEzB,UAAU;EACV,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB,EAAE,gCAAgC;AAC7D;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".inner_accordion_border_body {\n  border: 1px solid #e2e4e9;\n\n  width: 80%;\n  margin-right: auto;\n  margin-left: auto;\n  padding: 30px;\n  box-sizing: border-box;\n  border-radius: 20px;\n}\n\n.inner_accordion_border_body_heading {\n  font-size: 14px;\n  font-weight: 600;\n  color: #0a0d14;\n}\n\n.inner_accordion_border_body_heading_sub {\n  font-size: 12px;\n  font-weight: 400;\n  color: #525866;\n  margin-top: 2px;\n  text-transform: lowercase;\n}\n\n.inner_accordion_border_body_heading_sub::first-letter {\n  text-transform: uppercase; /* Capitalize the first letter */\n}\n\n@media screen and (max-width: 600px) {\n  .inner_accordion_border_body {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
