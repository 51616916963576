import React, { useState, useEffect } from "react";
import "./aboutTab.css";
import AboutTabSideBar from "./aboutTabSideBar/AboutTabSideBar";
import AboutTabOverview from "./aboutTabOverview/AboutTabOverview";
import AboutOverviewNoIcon from "./aboutOverviewNoIcon/AboutOverviewNoIcon";
import AboutOverviewLocation from "./aboutOverviewLocation/AboutOverviewLocation";

const AboutTab = ({ anotherUserProfile, userDataUpdated }) => {
  const [tab, setTab] = useState(0);

  const [ownerDetails, setOwnerDetails] = useState({});

  const getObjectFromLocalStorage = () => {
    const item = localStorage.getItem("userData");
    const itemized = JSON.parse(item);
    setOwnerDetails(anotherUserProfile ? userDataUpdated : itemized);
  };
  useEffect(() => {
    getObjectFromLocalStorage();
  }, []);
  const handleTabChange = (status) => {
    setTab(status);
  };
  return (
    <div className="profile_body_about_tab_body">
      <AboutTabSideBar handleTabChange={handleTabChange} tab={tab} />
      {tab === 0 && (
        <AboutTabOverview
          anotherUserProfile={anotherUserProfile}
          ownerDetails={ownerDetails}
        />
      )}
      {tab === 1 && (
        <AboutOverviewNoIcon
          anotherUserProfile={anotherUserProfile}
          ownerDetails={ownerDetails}
        />
      )}
      {tab === 2 && (
        <AboutOverviewLocation
          anotherUserProfile={anotherUserProfile}
          ownerDetails={ownerDetails}
        />
      )}
    </div>
  );
};

export default AboutTab;
