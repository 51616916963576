// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_right_inner_body {
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login_right_inner_body_headImg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_right_inner_body_circleImg {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_right_inner_body_headText {
  margin-top: 10px;

  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  color: #ffffff;
  text-align: center;
}

.login_right_inner_body_bodyText {
  margin-top: 6px;

  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #fbdbcb;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/loginRightCarousel/loginRightCarousel.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;;EAEhB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;;EAEf,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".login_right_inner_body {\n  max-width: 400px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.login_right_inner_body_headImg {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.login_right_inner_body_circleImg {\n  margin-top: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.login_right_inner_body_headText {\n  margin-top: 10px;\n\n  font-size: 22px;\n  font-weight: 600;\n  font-style: normal;\n  color: #ffffff;\n  text-align: center;\n}\n\n.login_right_inner_body_bodyText {\n  margin-top: 6px;\n\n  font-size: 16px;\n  font-weight: 400;\n  font-style: normal;\n  color: #fbdbcb;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
