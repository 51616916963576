import React from "react";
import "./receiverChatCard.css";
import {
  photo_sample_tab2,
  sample_follower_img,
} from "../../../components/images";

const ReceiverChatCard = ({ messageTime, message }) => {
  return (
    <div className="receiver_chatCard_genBody_genn">
      <div className="receiver_chatCard_genBody">
        <div className="receiver_chatCard_genBodyDate">{messageTime}</div>
        <div className="receiver_chatCard_messageCard">
          <div className="receiver_chatCard_messageCard_img">
            <img src={sample_follower_img} />
          </div>

          <div className="receiver_chatCard_messageCard_card">
            <div className="receiver_chatCard_messageCard_card_name">
              Boss lady:
            </div>
            <div className="receiver_chatCard_messageCard_cardMessage">
              {message}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiverChatCard;
