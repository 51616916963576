import React from "react";
import "./eachMatchesModal.css";
import InfoModal from "../../infoModal/InfoModal";
import { useDispatch, useSelector } from "react-redux";
import {
  handleEachMatchesModal,
  handleGetEachMatchModalData,
} from "../../../redux/userData";
import {
  love_matches_iconn,
  matches_info,
  matches_profile_pics,
  x_cancel,
} from "../../images";
import WhiteBtn from "../../button/whiteBtn/WhiteBtn";
import AuthBtn from "../../button/authBtn/AuthBtn";
import useProfileImage from "../../../utils/useProfileImage";

const EachMatchesModal = () => {
  const dispatch = useDispatch();
  const { getEachMatchModalData } = useSelector(
    (state) => state.getEachMatchModalDataBody.getEachMatchModalDataBody
  );
  const baseImageUrl =
    "https://talkstuff-store.fra1.cdn.digitaloceanspaces.com";

  const profileImageInitials = useProfileImage(
    getEachMatchModalData?.user?.firstName,
    getEachMatchModalData?.user?.lastName,
    "150px",
    "150px",
    "145px",
    "145px",
    "45px",
    "auto",
    "auto"
  );
  return (
    <InfoModal
      width="600px"
      handleCloseModal={() => dispatch(handleGetEachMatchModalData(null))}
    >
      <div
        onClick={() => {
          dispatch(handleGetEachMatchModalData(null));
        }}
        className="each_matches_modal_bodyyy"
      >
        <img src={x_cancel} />
      </div>
      {getEachMatchModalData?.user?.profileImage ? (
        <div className="each_matches_modal_bodyyy_profile_pics">
          <img
            src={
              `${baseImageUrl}${getEachMatchModalData?.user?.profileImage}` ||
              profileImageInitials
            }
          />
        </div>
      ) : (
        profileImageInitials
      )}

      <div className="each_matches_modal_subtext_body">
        <div className="each_matches_modal_subtext_icon">
          <img src={love_matches_iconn} />
        </div>

        <div className="each_matches_modal_subtext_rightBody">
          <div className="each_matches_modal_subtext_name">
            {getEachMatchModalData?.user?.userName}
          </div>

          <div className="each_matches_modal_subtext_name__para">
            It seems like you are a perfect match for{" "}
            {getEachMatchModalData?.user?.userName}, do you want to message
            directly?
          </div>
        </div>
      </div>

      <div className="each_matches_modal_subtext_caution_body">
        <div className="each_matches_modal_subtext_caution_icon">
          <img src={matches_info} />
        </div>

        <div className="each_matches_modal_subtext_caution_textt">
          Exercise discretion and be mindful of respectful communication.
        </div>
      </div>

      <div className="each_matches_modal_subtext_btn_conttt">
        <WhiteBtn
          handleBtn={() => {
            dispatch(handleGetEachMatchModalData(null));
          }}
          whiteBtnText="Close"
          width="50%"
        />

        <AuthBtn btnText="Message" width="50%" />
      </div>
    </InfoModal>
  );
};

export default EachMatchesModal;
