import logo from "./logo.svg";
import "./App.css";
import AppWrapper from "./routes";
import DefaultNotificationViewer from "./utils/defaultNotificationViewer/DefaultNotificationViewer";

function App() {
  return (
    <div>
      <AppWrapper />
    </div>
  );
}

export default App;
