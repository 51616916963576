// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supermarket_category_bodyGennn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  gap: 20px;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.supermarket_category_bodyGennn_product {
  width: calc(75%-20px);
}

.supermarket_category_bodyGennn_conta {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/screens/supaMall/supaMallCategories/supermarketCategory/supermarketCategory.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,gBAAgB;EAChB,SAAS;EACT,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".supermarket_category_bodyGennn {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  margin-top: 30px;\n  gap: 20px;\n  max-width: 1300px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.supermarket_category_bodyGennn_product {\n  width: calc(75%-20px);\n}\n\n.supermarket_category_bodyGennn_conta {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
