import React, { useState, useEffect } from "react";
import "./postTab.css";
import PostCard from "../../postCard/PostCard";
import PostCardComponent from "../../postCardComponent/PostCardComponent";
import {
  minigadget,
  oneMinutes,
  post_fourImage,
  post_oneImage,
  post_threeImage,
  post_twoImage,
  sampleVid,
} from "../../images";
import { axiosCalls } from "../../../_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AuthBtn from "../../button/authBtn/AuthBtn";
import { MutatingDots } from "react-loader-spinner";

const PostTab = ({
  userPost,
  isLoading,
  onErrorLoading,
  handleGetUserPost,
}) => {
  // const [userPost, setUserPost] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  // const [onErrorLoading, setOnErrorLoading] = useState(false);

  useEffect(() => {
    handleGetUserPost();
  }, []);

  // const handleGetUserPost = async (e) => {
  //   setIsLoading(true);
  //   setOnErrorLoading(false);
  //   const res = await axiosCalls(
  //     "accounts/account-profile-userposts?page=1&limit=50",
  //     "GET"
  //   );

  //   if (res) {
  //     console.log(res);
  //     setIsLoading(false);
  //     if (!res?.status) {
  //       console.log(res?.er.data?.message);
  //       setOnErrorLoading(true);
  //       toast.error(res?.er?.data?.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "dark",
  //       });
  //       return;
  //     } else if (res?.status) {
  //       setUserPost(res?.data);
  //       setOnErrorLoading(false);
  //       // toast.success(res?.message, {
  //       //   position: "top-right",
  //       //   autoClose: 5000,
  //       //   hideProgressBar: false,
  //       //   closeOnClick: true,
  //       //   pauseOnHover: true,
  //       //   draggable: true,
  //       //   progress: undefined,
  //       //   theme: "dark",
  //       // });
  //     }
  //   }
  // };

  return (
    <div className="profile_post_tab_bodyy">
      {isLoading ? (
        // Show skeleton while loading
        // <Skeleton count={5} height={500} />

        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#008dcb"
            secondaryColor="#008dcb"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        // Show user posts when loaded
        userPost?.map((data, index) => (
          <PostCardComponent
            userPost={data}
            index={index}
            postCaption={data?.caption || <Skeleton count={2} />}
            postUser={data?.createdBy}
            showFollowBtn={true}
            imageFiles={data?.attachments || <Skeleton />}
          />
        ))
      )}

      {onErrorLoading && (
        <div className="network_xerror_body">
          Network error{" "}
          <AuthBtn
            width="200px"
            btnText="Reload"
            handleClick={handleGetUserPost}
          />
        </div>
      )}
    </div>
  );
};

export default PostTab;
