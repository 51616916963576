// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.luvhub_location_modal_body_gen {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.luvhub_location_modal_body_country {
  color: #0a0d14;
  font-size: 14px;
  font-weight: 600;
}

.luvhub_location_modal_body_cancel_close {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.luvhub_location_modal_country__each {
  border: 0.99px solid #e2e4e9;

  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 0.99px 1.98px 0px #5258660d;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #0a0d14;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
}

.luvhub_location_modal_country__each:hover {
  background: #e2e4e9;
}

.luvhub_location_modal_body_gen_citiesss {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/luvHubComp/luvHubLocationModal/luvHubLocationModal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,4BAA4B;;EAE5B,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,2CAA2C;EAC3C,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,SAAS;EACT,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".luvhub_location_modal_body_gen {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: space-between;\n  box-sizing: border-box;\n}\n\n.luvhub_location_modal_body_country {\n  color: #0a0d14;\n  font-size: 14px;\n  font-weight: 600;\n}\n\n.luvhub_location_modal_body_cancel_close {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.luvhub_location_modal_country__each {\n  border: 0.99px solid #e2e4e9;\n\n  background: #ffffff;\n  border-radius: 8px;\n  width: 100%;\n  box-sizing: border-box;\n  box-shadow: 0px 0.99px 1.98px 0px #5258660d;\n  padding: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  color: #0a0d14;\n  font-size: 11px;\n  font-weight: 600;\n  cursor: pointer;\n}\n\n.luvhub_location_modal_country__each:hover {\n  background: #e2e4e9;\n}\n\n.luvhub_location_modal_body_gen_citiesss {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  gap: 10px;\n  width: 100%;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
