import { useState, useEffect } from "react";

const useCalculateAge = (dob) => {
  const [age, setAge] = useState("");

  useEffect(() => {
    const calculateAge = (dob) => {
      const [day, month, year] = dob.split("-");
      const birthDate = new Date(`${year}-${month}-${day}`);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    };

    if (dob) {
      const calculatedAge = calculateAge(dob);
      setAge(`${calculatedAge} Years`);
    }
  }, [dob]);

  return age;
};

export default useCalculateAge;
