// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legal_body_component_body {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.legal_body_component_heading {
  color: #525866;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  text-align: left;
}

.legal_body_component_paragraphBody {
  margin-top: 3px;

  color: #525866;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/legalBodyComponent/legalBodyComponent.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;;EAEf,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".legal_body_component_body {\n  width: 100%;\n  margin-top: 15px;\n  margin-bottom: 15px;\n}\n\n.legal_body_component_heading {\n  color: #525866;\n  font-size: 14px;\n  font-weight: 600;\n  font-style: normal;\n  text-align: left;\n}\n\n.legal_body_component_paragraphBody {\n  margin-top: 3px;\n\n  color: #525866;\n  font-size: 14px;\n  font-weight: 400;\n  font-style: normal;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
