import React from "react";

const BottomLuvhub = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2724_6716)">
        <path
          d="M12.574 9.11616C13.4977 8.21105 14.4338 7.12617 14.4338 5.70652C14.4338 4.80222 14.0746 3.93497 13.4352 3.29554C12.7958 2.6561 11.9285 2.29688 11.0242 2.29688C9.93312 2.29688 9.1644 2.60684 8.2345 3.53674C7.3046 2.60684 6.53588 2.29688 5.4448 2.29688C4.5405 2.29688 3.67325 2.6561 3.03382 3.29554C2.39439 3.93497 2.03516 4.80222 2.03516 5.70652C2.03516 7.13236 2.96506 8.21725 3.89496 9.11616L8.2345 13.4557L12.574 9.11616Z"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2724_6716">
          <rect
            width="14.8784"
            height="14.8784"
            fill="white"
            transform="translate(0.794922 0.437012)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BottomLuvhub;
