// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_account_info_card_body {
  border: 0.99px solid #e2e4e9;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
}

.home_account_info_card_body_userhea {
  font-size: 12px;
  font-weight: 400;
  color: #525866;
}

.home_account_info_card_body_usersub {
  font-size: 12px;
  font-weight: 600;
  color: #0a0d14;
  margin-top: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/homeAccountInfoCard/homeAccountInfoCard.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB","sourcesContent":[".home_account_info_card_body {\n  border: 0.99px solid #e2e4e9;\n  padding: 12px;\n  border-radius: 8px;\n  width: 100%;\n  box-sizing: border-box;\n  margin-top: 10px;\n}\n\n.home_account_info_card_body_userhea {\n  font-size: 12px;\n  font-weight: 400;\n  color: #525866;\n}\n\n.home_account_info_card_body_usersub {\n  font-size: 12px;\n  font-weight: 600;\n  color: #0a0d14;\n  margin-top: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
