import React, { useState, useEffect } from "react";
import "./profileBody.css";
import ProfileTopCard from "../profileTopCard/ProfileTopCard";
import PostTab from "../profileTabs/postTab/PostTab";
import AboutTab from "../profileTabs/aboutTab/AboutTab";
import FollowersTab from "../profileTabs/followersTab/FollowersTab";
import FollowingTab from "../profileTabs/followingTab/FollowingTab";
import PhotosTab from "../profileTabs/photosTab/PhotosTab";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosCalls } from "../../_api";
import { CallCustomToast } from "../../utils/CallCustomToast";
import { useDispatch } from "react-redux";

const ProfileBody = () => {
  const [tab, setTab] = useState(0);

  const dispatch = useDispatch();
  const handleSetTab = (status) => {
    setTab(status);
  };
  const [userPhotos, setUserPhotos] = useState([]);
  const [isPhotosLoading, setIsPhotosLoading] = useState(false);

  const [onErrorPhotosLoading, setOnErrorPhotosLoading] = useState(false);
  const baseImageUrl =
    "https://talkstuff-store.fra1.cdn.digitaloceanspaces.com";

  const handleGetUserPhotos = async (e) => {
    setIsPhotosLoading(true);
    setOnErrorPhotosLoading(false);
    const res = await axiosCalls(
      "accounts/account-profile-userphotos?page=1&limit=50&flatten=true",
      "GET"
    );

    if (res) {
      console.log(res);
      setIsPhotosLoading(false);
      if (!res?.status) {
        // console.log(res?.er.data?.message);
        setOnErrorPhotosLoading(true);
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        setUserPhotos(res?.data);
        setOnErrorPhotosLoading(false);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };

  const [userPost, setUserPost] = useState([]);
  const [isPostTabLoading, setIsPostTabLoading] = useState(false);
  const [onErrorPostTabLoading, setOnErrorPostTabLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorLoading, setOnErrorLoading] = useState(false);
  const [userRelation, setUserRelation] = useState(null);
  const [isLoadingOnProfile, setIsLoadingOnProfile] = useState(false);
  const [onErrorOnProfile, setErrorOnProfile] = useState(false);
  const [userDataUpdated, setUserDataUpdated] = useState(null);
  const [anotherUserProfile, setAnotherUserProfile] = useState(false);

  useEffect(() => {
    // handleGetUserPost();
    // handleGetUserPhotos();
    handleGetUserRelations();
    handleGetUserData();
  }, []);

  const handleGetUserPost = async (e) => {
    setIsPostTabLoading(true);
    setOnErrorPostTabLoading(false);
    const res = await axiosCalls(
      "accounts/account-profile-userposts?page=1&limit=50",
      "GET"
    );

    if (res) {
      console.log(res);
      setIsPostTabLoading(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        setOnErrorPostTabLoading(true);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        setOnErrorPostTabLoading(false);
        setUserPost(res?.data);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };

  const handleGetUserRelations = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosCalls(
      "accounts/account-profile-relations?page=1&limit=50",
      "GET"
    );

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        // console.log(res?.er.data?.message);
        setOnErrorLoading(true);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        setOnErrorLoading(false);
        setUserRelation(res?.data);
        console.log(userRelation, "user relation");
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };

  const handleGetUserData = async (e) => {
    setIsLoadingOnProfile(true);
    setErrorOnProfile(false);
    const res = await axiosCalls("accounts/account-info", "GET");

    if (res) {
      console.log(res, "userData updated");
      setIsLoadingOnProfile(false);
      if (!res?.status) {
        // console.log(res?.er.data?.message);
        setErrorOnProfile(true);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        setErrorOnProfile(false);
        setUserDataUpdated(res?.data);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };
  return (
    <div className="profile_body_general_container">
      <ProfileTopCard
        userDataUpdated={userDataUpdated}
        userData={userRelation}
        anotherUserProfile={anotherUserProfile}
      />
      <div className="profile_top_home_body_tab_">
        <div
          onClick={() => {
            handleSetTab(0);
          }}
          className={
            tab === 0
              ? "profile_top_home_body_tab_each_active"
              : "profile_top_home_body_tab_each"
          }
        >
          Post
        </div>
        <div
          onClick={() => {
            handleSetTab(1);
          }}
          className={
            tab === 1
              ? "profile_top_home_body_tab_each_active"
              : "profile_top_home_body_tab_each"
          }
        >
          About
        </div>
        <div
          onClick={() => {
            handleSetTab(2);
          }}
          className={
            tab === 2
              ? "profile_top_home_body_tab_each_active"
              : "profile_top_home_body_tab_each"
          }
        >
          Followers
        </div>
        <div
          onClick={() => {
            handleSetTab(3);
          }}
          className={
            tab === 3
              ? "profile_top_home_body_tab_each_active"
              : "profile_top_home_body_tab_each"
          }
        >
          Following
        </div>
        <div
          onClick={() => {
            handleSetTab(4);
          }}
          className={
            tab === 4
              ? "profile_top_home_body_tab_each_active"
              : "profile_top_home_body_tab_each"
          }
        >
          Photos
        </div>
      </div>

      {tab === 0 && (
        <PostTab
          userPost={userPost}
          isLoading={isPostTabLoading}
          onErrorLoading={onErrorPostTabLoading}
          handleGetUserPost={handleGetUserPost}
        />
      )}
      {tab === 1 && <AboutTab />}
      {tab === 2 && <FollowersTab userData={userRelation} />}
      {tab === 3 && <FollowingTab userData={userRelation} />}
      {tab === 4 && (
        <PhotosTab
          userPhotos={userPhotos}
          isLoading={isPhotosLoading}
          onErrorLoading={onErrorPhotosLoading}
          handleGetUserPhotos={handleGetUserPhotos}
        />
      )}
    </div>
  );
};

export default ProfileBody;
