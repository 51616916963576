import React, { useState, useEffect } from "react";
import "./mobileSettingsBody.css";
import InfoModal from "../../infoModal/InfoModal";
import {
  bell_sidebar,
  heart_sidebar,
  lock_keyhole,
  profile_option_logout,
  user_settings_round,
} from "../../images";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  handleLogoutModal,
  handleOpenSettingModal,
} from "../../../redux/userData";
import LogoutIcon from "../../images/files/LogoutIcon";

const MobileSettingsBody = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [hoveredItemIndex, setHoveredItemIndex] = useState(-1); // Track hovered item index
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleItemMouseEnter = (index) => {
    setHoveredItemIndex(index); // Set the index of the hovered item
  };

  const handleItemMouseLeave = () => {
    setHoveredItemIndex(-1); // Reset the index when leaving the item
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const content = [
    {
      section_name: "Your Account",
      section_route: "/account",
      icon: user_settings_round,
    },
    {
      section_name: "Security and Privacy",
      section_route: "/security-privacy",
      icon: lock_keyhole,
    },
    {
      section_name: "Notification Preferences",
      section_route: "/notification-preferences",
      icon: bell_sidebar,
    },
    {
      section_name: "Interests",
      section_route: "/interests",
      icon: heart_sidebar,
    },
  ];

  return (
    <InfoModal
      handleCloseModal={() => {
        dispatch(handleOpenSettingModal(false));
      }}
    >
      {content?.map((item, index) => (
        <div
          className={
            location.pathname === item?.section_route
              ? "accordion_item_body_active"
              : "accordion_item_body"
          }
          // className="accordion_item_body"
          onMouseEnter={() => handleItemMouseEnter(index)} // Pass index to track hover
          onMouseLeave={handleItemMouseLeave} // Reset hover state
          onClick={() => {
            navigate(item?.section_route);
            dispatch(handleOpenSettingModal(false));
          }}
        >
          <div className="accordion_item_secondary_body">
            <div className="accordion_bullet_body">
              <img src={item?.icon} />
            </div>
            <div className="accordion_item" key={index}>
              {item?.section_name}
            </div>
          </div>
        </div>
      ))}

      <div
        className="accordion_item_body"
        onClick={() => {
          dispatch(handleLogoutModal(true));
          dispatch(handleOpenSettingModal(false));
        }}
      >
        <div className="accordion_item_secondary_body">
          <div className="accordion_bullet_body">
            <LogoutIcon />
          </div>
          <div className="accordion_item_logout">Logout</div>
        </div>
      </div>
    </InfoModal>
  );
};

export default MobileSettingsBody;
