import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./sidebarOption.css";
import { chevron_right_icon, user_round_sidebar } from "../images";

const SidebarOption = ({
  path,
  icon,
  text,
  onClickFunc,
  noOfNotification,
  showChevronIcon,
}) => {
  const [isHovered, setHovered] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      className={
        location.pathname === path || isHovered
          ? "sidebar_body_option_container_active"
          : "sidebar_body_option_container"
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClickFunc}
    >
      <div className="sidebar_body_option_icon_mainFlex">
        <div className="sidebar_body_option_icon">
          <img src={icon} />
        </div>
        <div className="sidebar_body_option_text">{text}</div>
        {noOfNotification && (
          <div className="sidebar_notification_counter">{noOfNotification}</div>
        )}
      </div>
      {showChevronIcon && (
        <div className="sidebar_notification_chevron_right">
          <img src={chevron_right_icon} />
        </div>
      )}
    </div>
  );
};

export default SidebarOption;
