import React from "react";
import "./aboutTabSideBarOption.css";
import { useLocation, useNavigate } from "react-router-dom";
import { chevron_right_icon } from "../../../images";

const AboutTabSideBarOption = ({ text, onClickFunc, tab }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div
      className={
        tab === "Active"
          ? "about_sidebar_body_option_container_active"
          : "about_sidebar_body_option_container"
      }
      onClick={onClickFunc}
    >
      <div className="about_sidebar_body_option_text">{text}</div>

      <div className="about_sidebar_notification_chevron_right">
        <img src={chevron_right_icon} />
      </div>
    </div>
  );
};

export default AboutTabSideBarOption;
