// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash_screen_body {
  margin: 0;
  padding: 0;
  background: #ef6924;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.splash_screen_logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.splash_screen_body_text {
  margin-top: 35px;
}
.splash_screen_body_text span {
  font-weight: 600;
  font-size: 23px;
  font-style: normal;
  color: #fff;
  margin-left: 5px;
}

@media screen and (max-width: 850px) {
  .splash_screen_body_text span {
    font-size: 14px;
  }
  .splash_screen_logo img {
    width: 154px;
    height: 46px;
  }

  .splash_screen_body_text {
    margin-top: 15px;
  }
}

/* @media screen and (max-width: 600px) {
  .splash_screen_body_text span {
    font-size: 16px;
  }

  
} */
`, "",{"version":3,"sources":["webpack://./src/components/splashScreen/splashScreen.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB;AACF;;AAEA;;;;;;GAMG","sourcesContent":[".splash_screen_body {\n  margin: 0;\n  padding: 0;\n  background: #ef6924;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100vw;\n  height: 100vh;\n}\n\n.splash_screen_logo {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.splash_screen_body_text {\n  margin-top: 35px;\n}\n.splash_screen_body_text span {\n  font-weight: 600;\n  font-size: 23px;\n  font-style: normal;\n  color: #fff;\n  margin-left: 5px;\n}\n\n@media screen and (max-width: 850px) {\n  .splash_screen_body_text span {\n    font-size: 14px;\n  }\n  .splash_screen_logo img {\n    width: 154px;\n    height: 46px;\n  }\n\n  .splash_screen_body_text {\n    margin-top: 15px;\n  }\n}\n\n/* @media screen and (max-width: 600px) {\n  .splash_screen_body_text span {\n    font-size: 16px;\n  }\n\n  \n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
