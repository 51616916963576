import React from "react";
import "./successResetPasswordModal.css";
import NewModal from "../newModal/NewModal";
import AuthBodyMargin from "../authBodyMargin/AuthBodyMargin";
import AuthTopIcon from "../authTopIcon/AuthTopIcon";
import AuthBtn from "../button/authBtn/AuthBtn";
import { newSingleLogo, talk_stuff_authLogo } from "../images";

const SuccessResetPasswordModal = ({
  handleSuccessReset,
  successBody,
  successHeader,
  mailDummy,
}) => {
  return (
    <NewModal>
      <AuthBodyMargin>
        <div className="termsandcondition_heading_logo">
          <img src={newSingleLogo} />
        </div>
        {/* </motion.div> */}
        <div className="success_register_input_body">
          {mailDummy && <AuthTopIcon userDummyIcon={mailDummy} />}

          <div className="success_register_body_text">{successHeader}</div>
          <div className="success_register_body_texthh">{successBody}</div>

          <AuthBtn
            handleClick={handleSuccessReset}
            btnText="Close"
            width="100%"
          />
        </div>
      </AuthBodyMargin>
    </NewModal>
  );
};

export default SuccessResetPasswordModal;
