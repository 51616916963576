// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_card_essential_body {
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  box-sizing: border-box;
  border-right: 1px solid #d9d9d9;
  gap: 5px;
  flex-direction: column;
}

.footer_card_essential_body_para {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_card_essential_body_header {
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.footer_card_essential_body_para {
  color: #333333;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 850px) {
  .footer_card_essential_body {
    border-bottom: 1px solid #d9d9d9;
    border-right: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/supamallComp/footerCardEssential/footerCardEssential.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,QAAQ;EACR,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE;IACE,gCAAgC;IAChC,kBAAkB;EACpB;AACF","sourcesContent":[".footer_card_essential_body {\n  width: 240px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 200px;\n  box-sizing: border-box;\n  border-right: 1px solid #d9d9d9;\n  gap: 5px;\n  flex-direction: column;\n}\n\n.footer_card_essential_body_para {\n  width: 80%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.footer_card_essential_body_header {\n  color: #000000;\n  font-size: 12px;\n  font-weight: 600;\n}\n\n.footer_card_essential_body_para {\n  color: #333333;\n  font-size: 11px;\n  font-weight: 400;\n  text-align: center;\n}\n\n@media screen and (max-width: 850px) {\n  .footer_card_essential_body {\n    border-bottom: 1px solid #d9d9d9;\n    border-right: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
