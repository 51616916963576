// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user_account_home_body_head {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.user_account_home_heading_flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  flex-direction: column;
  width: calc(100% - 60px);
}

.user_account_home_heading_text {
  font-size: 16px;
  font-weight: 600;
  color: #0a0d14;
}

.user_account_home_heading_text_sub {
  font-size: 14px;
  font-weight: 400;
  color: #525866;
}

@media screen and (max-width: 600px) {
  .user_account_home_heading_text {
    font-size: 14px;
  }

  .user_account_home_heading_text_sub {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/innerAccordionHeader/innerAccordionHeader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,2BAA2B;EAC3B,QAAQ;EACR,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".user_account_home_body_head {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  width: 100%;\n}\n\n.user_account_home_heading_flex {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  gap: 2px;\n  flex-direction: column;\n  width: calc(100% - 60px);\n}\n\n.user_account_home_heading_text {\n  font-size: 16px;\n  font-weight: 600;\n  color: #0a0d14;\n}\n\n.user_account_home_heading_text_sub {\n  font-size: 14px;\n  font-weight: 400;\n  color: #525866;\n}\n\n@media screen and (max-width: 600px) {\n  .user_account_home_heading_text {\n    font-size: 14px;\n  }\n\n  .user_account_home_heading_text_sub {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
