import React, { useState } from "react";
import "./helpSupportSearchCard.css";
import AuthInputIcon from "../../defaultInput/authInputnIcon/AuthInputIcon";
import { searchHelp, talkStuff_support_card } from "../../images";
import AuthInput from "../../defaultInput/authInput/AuthInput";
import AuthBtn from "../../button/authBtn/AuthBtn";

const HelpSupportSearchCard = () => {
  const [formData, setFormData] = useState({ email: "", feedback: "" });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setFormData({ email: "", feedback: "" });
    }, 4000);
  };
  return (
    <div className="help_support_search_body_gen">
      <div className="help_support_search_body_inputbody">
        <div className="help_support_search_body_InputHead">
          How can we help you?
        </div>
        {/* <div className="help_support_search_body_input">
          <AuthInputIcon
            width="100%"
            inputPlaceHolder="Search"
            inputLeftIcon={searchHelp}
          />
        </div> */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          style={{ width: "100%" }}
        >
          <div className="help_support_search_body_input">
            <AuthInput
              inputPlaceHolder="Enter your email"
              inputLabel="Email"
              required={true}
              inputValue={formData?.email}
              inputOnchange={handleChange}
              inputName="email"
              width="100%"
              labelColor="#fff"
              inputType="email"
            />
          </div>
          <div className="help_support_search_body_input">
            <div className="feedback_label_supporttt">
              Feedback{" "}
              <span
                style={{
                  color: "#F41010",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                *{" "}
              </span>
            </div>
            <div className="post_upload_uploaded_add_captionText_input">
              <textarea
                onChange={handleChange}
                name="feedback"
                value={formData?.feedback}
                placeholder="Write to talkstuff"
                required={true}
              />
            </div>
          </div>
          <AuthBtn
            btnText="Submit"
            // handleClick={(e) => {
            //   e.preventDefault();
            //   handleSubmit();
            // }}
            width="120px"
            isLoading={loading}
          />
        </form>
      </div>
      <div className="help_support_search_body_left_logo">
        <img src={talkStuff_support_card} />
      </div>
    </div>
  );
};

export default HelpSupportSearchCard;
