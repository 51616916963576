import React from "react";
import Switch from "react-switch";
import "./profileoptionEach.css";
import { profile_pop_user } from "../../images";
import { useLocation } from "react-router-dom";

const ProfileOptionEach = ({
  handleProfileOptionSwitch,
  checked,
  showSwitch,
  handleOptionClick,
  profileOptionText,
  profileOptionIcon,
  path,
  color,
}) => {
  const location = useLocation();

  return (
    <div
      onClick={handleOptionClick}
      className={
        location.pathname === path
          ? "profile_option_each_body_active"
          : "profile_option_each_body"
      }
    >
      <div className="profile_option_each_body_sub">
        <div className="profile_option_each_body_sub_icon">
          <img src={profileOptionIcon} />
        </div>
        <div style={{ color }} className="profile_option_each_body_sub_textt">
          {profileOptionText}
        </div>
      </div>
      {showSwitch && (
        <div className="profile_option_each_body_sub_switch">
          <Switch
            name="isActive"
            className="react-switcher"
            onColor="#EF6924"
            offColor="#e2e8f0"
            // onHandleColor="#36a6a4"
            handleDiameter={12}
            uncheckedIcon={false}
            checkedIcon={false}
            activeBoxShadow="0 0 1px 2px #FAF2FF"
            height={24}
            width={51}
            borderRadius={30}
            checked={checked}
            onChange={handleProfileOptionSwitch}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileOptionEach;
