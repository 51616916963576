import React, { useState } from "react";
import "./eachChatUser.css";
import { check_check_message, photo_sample_tab2 } from "../../images";
import { useDispatch } from "react-redux";
import {
  handleServerChatData,
  handleShowChatBox,
  handleShowEachUserChat,
} from "../../../redux/userData";
import { axiosAgoraCall, axiosChatCall } from "../../../_api";
import { CallCustomToast } from "../../../utils/CallCustomToast";

const EachChatUser = ({ data }) => {
  const [chatData, setChatData] = useState(null);
  const dispatch = useDispatch();

  const getChatToken = async (e) => {
    const formData = {
      appId: "31795023e0804e92aca50eb05f9e8a9f",
      subscriber: data?._id,
      expires: 7200000,
      isRenewal: true,
    };

    const res = await axiosChatCall("get-chat-token", "POST", formData);

    if (res) {
      console.log(res);
      if (!res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });

        return;
      } else if (res?.status) {
        console.log("call token success");
        dispatch(handleServerChatData(res?.data));
      }
    }
  };

  return (
    <div
      onClick={() => {
        getChatToken();
        dispatch(handleShowEachUserChat(data));
        console.log(data, "ydbdhhdjd");
        dispatch(handleShowChatBox(true));
      }}
      className="each_chatUser_bodygen"
    >
      <div className="each_chatUser_bodygen_profileImg">
        <img src={data?.profileImage} />
      </div>
      <div className="each_chatUser_bodygen_textGen">
        <div className="each_chatUser_bodygen_textGenName">
          {/* {data?.firstName} {data?.lastName} */}
          {data?.userName ?? ""}
        </div>
        <div className="each_chatUser_bodygen_text_dyna">
          <div className="each_chatUser_bodygen_text_checkkFlex">
            <div className="each_chatUser_bodygen_text_checkk">
              <img src={check_check_message} />
            </div>
            <div className="each_chatUser_bodygen_text_message">
              I have told you times without numbers
            </div>
          </div>
          <div className="each_chatUser_bodygen_text_dynaDate">5mins</div>
        </div>
      </div>
    </div>
  );
};

export default EachChatUser;
