import React, { useEffect } from "react";
import "./defaultNotificationViewer.css";
import { useDispatch, useSelector } from "react-redux";
import {
  disconnectSocket,
  subscribeToEvent,
} from "./notificationSocketServices";
import { io } from "socket.io-client";

import {
  handleDefaultToastData,
  handleShowDefaultToast,
} from "../../redux/userData";
import { close_toast } from "../../components/images";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CallCustomToast } from "../CallCustomToast";
const token = localStorage.getItem("token");

const socket = io(`https://notifications.talkstuff.social?token=${token}`);

const DefaultNotificationViewer = () => {
  const dispatch = useDispatch();

  const { showDefaultToast } = useSelector(
    (state) => state.defaultToastBody.defaultToastBody
  );

  //   const { defaultToastData } = useSelector(
  //     (state) => state.defaultToastDataBody.defaultToastDataBody
  //   );

  useEffect(() => {
    // Subscribe to real-time updates
    // subscribeToEvent("newFeed", handleNewFeed);
    // subscribeToEvent("feedInteraction", handleFeedInteraction);

    socket.on("connect", () => {
      console.log("Connected to Socket.io server");
    });

    socket.on("notifications", (data) => {
      console.log("notificationssss", JSON.parse(data));
      let notifyData = JSON.parse(data);
      dispatch(handleDefaultToastData(JSON.parse(data)));
      dispatch(handleShowDefaultToast(true));

      if (data) {
        // toast.success(notifyData?.message ?? "");

        CallCustomToast(dispatch, {
          messageHeading: "Notification",
          toastStatus: "Success",
          messageBody: notifyData?.message,
        });
      } else {
        toast.error(`Error`);
      }
    });

    return () => {
      socket.on("disconnect", () => {
        console.log("Disconnected from Socket.IO Server");
      });
    };
  }, []);

  //   useEffect(() => {
  //     subscribeToEvent("notification", (data) => {
  //       console.log(JSON.parse(data));
  //       dispatch(handleDefaultToastData(JSON.parse(data)));
  //       dispatch(handleShowDefaultToast(true));

  //       //   dispatch(handleShowSuccessToast("Success")); // or 'Error' based on data
  //     });

  //     return () => {
  //       // Optional: Cleanup on unmount
  //       disconnectSocket();
  //     };
  //   }, []);
  return <ToastContainer />;
};

export default DefaultNotificationViewer;
