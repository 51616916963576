import React, { useState, useEffect, CSSProperties } from "react";
import "./postCardTopInfo.css";
import { more_btn } from "../../images";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosCalls } from "../../../_api";
import ClipLoader from "react-spinners/ClipLoader";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import { useDispatch } from "react-redux";
import { emitEvent } from "../../../utils/socketService";

const PostCardTopInfo = ({ showFollowBtn, handlePostMoreBtn, userPost }) => {
  const [ownerDetails, setOwnerDetails] = useState({});
  const [likePost, setFollowPost] = useState(false);
  const [isLoadingFollow, setIsLoadingFollow] = useState(false);
  const dispatch = useDispatch();
  const getObjectFromLocalStorage = () => {
    const item = localStorage.getItem("userData");
    const itemized = JSON.parse(item);
    setOwnerDetails(itemized);
    console.log(ownerDetails, "oejen");
  };
  useEffect(() => {
    getObjectFromLocalStorage();
  }, []);

  // const override: CSSProperties = {
  //   display: "block",
  //   margin: "0 auto",
  //   borderColor: "red",
  // };
  const handleFollowUser = async (e) => {
    const data = {
      followingId: userPost?.userId?._id,
      actionType: "follow",
    };
    setIsLoadingFollow(true);
    const res = await axiosCalls(`users/user-follow`, "POST", data);

    if (res) {
      console.log(res);
      setIsLoadingFollow(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });

        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        // onSuccessPostImages();
        // onSuccessMessagePost(res?.message);

        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
        setFollowPost(true);
        // console.log(postComment, "comments");
        // Emit event after successful follow
        emitEvent("feedInteraction", res.data);
      }
    }
  };

  const handleUnFollowUser = async (e) => {
    const data = {
      followingId: userPost?.userId?._id,
      actionType: "unfollow",
    };
    setIsLoadingFollow(true);
    const res = await axiosCalls(`users/user-follow`, "POST", data);

    if (res) {
      console.log(res);
      setIsLoadingFollow(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        // onSuccessPostImages();
        // onSuccessMessagePost(res?.message);

        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
        setFollowPost(true);
        // console.log(postComment, "comments");
        // Emit event after successful unfollow
        emitEvent("feedInteraction", res.data);
      }
    }
  };
  return (
    <>
      <div className="post_card_top_info_body">
        {showFollowBtn ? (
          <div>
            {userPost?.createdBy !== ownerDetails?.email ? (
              <div
                onClick={() => {
                  {
                    userPost?.isUserFollowing
                      ? handleUnFollowUser()
                      : handleFollowUser();
                  }
                }}
                className="post_card_top_info_followtag"
              >
                {!isLoadingFollow &&
                  (userPost?.isUserFollowing ? "Unfollow" : "Follow")}

                <ClipLoader
                  color="#ef6924"
                  loading={isLoadingFollow}
                  // cssOverride={override}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div className="post_card_top_info_followtagempty"></div>
            )}
          </div>
        ) : (
          <div className="post_card_top_info_followtagempty"></div>
        )}

        <div
          onClick={handlePostMoreBtn}
          className="post_card_top_info_more_btn"
        >
          <img src={more_btn} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PostCardTopInfo;
