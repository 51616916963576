import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import AuthBtn from "../button/authBtn/AuthBtn";
import { axiosFormData } from "../../_api";
import InfoModal from "../infoModal/InfoModal";
import { handleProfileCoverImageModal } from "../../redux/userData";
import { CallCustomToast } from "../../utils/CallCustomToast";
const ProfileCoverImageUpload = () => {
  const dispatch = useDispatch();

  const [profileImage, setProfileImage] = useState(true);
  const [uploadPost, setUploadPost] = useState(false);

  const [selectedImages, setSelectedImages] = useState([]);
  const [displaySelectImages, setDisplaySelectedImages] = useState([]);

  const [showAddCaption, setShowAddCaption] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    dispatch(handleProfileCoverImageModal(false));
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);

    if (files?.length > 0) {
      const newDisplayImages = [];
      const newSelectedImages = [];

      for (let i = 0; i < files.length; i++) {
        // Check if the file type is video/mp4
        if (files[i].type === "video/mp4") {
          // Handle video file
          const reader = new FileReader();
          reader.onloadend = () => {
            // Generate a thumbnail from the video
            const video = document.createElement("video");
            video.src = reader.result;
            video.addEventListener("loadedmetadata", () => {
              // Create a canvas element to generate thumbnail
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");
              canvas.width = 200; // Set thumbnail width
              canvas.height =
                (canvas.width / video.videoWidth) * video.videoHeight;
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              // Convert thumbnail to data URL
              const thumbnail = canvas.toDataURL("image/jpeg");
              newDisplayImages.push(thumbnail);
              setDisplaySelectedImages([...newDisplayImages]);
            });
          };
          reader.readAsDataURL(files[i]);
          newSelectedImages.push(files[i]);
        } else {
          // Handle image file
          const reader = new FileReader();
          reader.onloadend = () => {
            newDisplayImages.push(reader.result);
            setDisplaySelectedImages([...newDisplayImages]);
          };
          reader.readAsDataURL(files[i]);

          newSelectedImages.push(files[i]);
        }
      }

      setSelectedImages([...selectedImages, ...newSelectedImages]);
    }
    console.log(selectedImages, "seletedimage");
  };
  const handleDeleteImagePost = (index) => {
    const updatedSelectedImages = [...selectedImages];
    updatedSelectedImages.splice(index, 1);
    setSelectedImages(updatedSelectedImages);

    const updatedDisplayImages = [...displaySelectImages];
    updatedDisplayImages.splice(index, 1);
    setDisplaySelectedImages(updatedDisplayImages);
  };

  const handleUploadPost = (status) => {
    setUploadPost(status);
  };

  const onSuccessPostImages = () => {
    // dispatch(handleToggleCreatePost(false));

    handleUploadPost(false);

    setSelectedImages([]);
    setDisplaySelectedImages([]);

    setShowAddCaption(false);
  };

  const handleUploadProfileImage = async (e) => {
    const formData = new FormData();

    selectedImages.forEach((file, index) => {
      formData.append("coverImage", file);
    });
    setIsLoading(true);
    const res = await axiosFormData(
      "authentication/personal-information",
      "POST",
      formData
    );

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        onSuccessPostImages();
        // onSuccessMessagePost(res?.message);
        // handleCloseModal();
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };
  return (
    <InfoModal
      handleCloseModal={handleCloseModal}
      height="fit-content"
      minHeight="0"
      width="450px"
    >
      <ToastContainer />
      <div> Upload Cover Image</div>

      <div className="post_upload_modal_body_gen">
        <div className="post_upload_boxes_body">
          <input type="file" accept="image/*" onChange={handleImageChange} />+
        </div>
      </div>

      {/* {displaySelectImages?.length > 0 && (
        <div className="post_upload_uploaded_img_body">
          {displaySelectImages?.map((image, index) => (
            <div className="post_upload_uploaded_img_each" key={index}>
              <img
                src={image}
                alt={`Uploaded ${index + 1}`}
                // style={{
                //   maxWidth: "300px",
                //   maxHeight: "300px",
                //   marginBottom: "10px",
                // }}
              />
              <div
                onClick={() => {
                  handleDeleteImagePost(index);
                }}
                className="post_upload_cancel_icon"
              >
                x
              </div>
            </div>
          ))}
        </div>
      )} */}

      {displaySelectImages?.length > 0 && (
        <div className="post_upload_uploaded_img_body">
          {displaySelectImages?.map((image, index) => (
            <div className="post_upload_uploaded_img_each" key={index}>
              {/* Check file extension to determine if it's a video */}
              {image?.name && /\.(mp4|webm|ogg)$/i.test(image?.name) ? (
                // Render video element for video files
                <video controls className="uploaded-video">
                  <source
                    src={URL?.createObjectURL(image)}
                    type={image?.type || "video/mp4"} // Provide default type if not available
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                // Render img element for image files
                <img
                  src={image}
                  alt={`Uploaded ${index + 1}`}
                  className="uploaded-image"
                />
              )}
              <div
                onClick={() => {
                  handleDeleteImagePost(index);
                }}
                className="post_upload_cancel_icon"
              >
                x
              </div>
            </div>
          ))}
        </div>
      )}

      {profileImage && (
        <div className="post_upload_modal_body_btn">
          {selectedImages.length > 0 ? (
            <AuthBtn
              handleClick={() => {
                handleUploadProfileImage();
              }}
              btnText="Upload"
              isLoading={isLoading}
              width="120px"
            />
          ) : null}
        </div>
      )}
    </InfoModal>
  );
};

export default ProfileCoverImageUpload;
