// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help_support_search_body_gen {
  width: 100%;
  /* height: 280px; */
  height: 100%;
  border-radius: 15px;
  background: #ef6924;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
  position: relative;
}

.help_support_search_body_inputbody {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  /* gap: 10px; */
  width: 50%;
}

.help_support_search_body_InputHead {
  font-size: 24px;
  color: #fff;
  font-weight: 600;
}

.help_support_search_body_input {
  width: 100%;
}

.help_support_search_body_left_logo {
  position: absolute;
  right: 10px;
  bottom: 0;
  width: 30%;
}

.help_support_search_body_left_logo img {
  width: 100%;
  object-fit: contain;
}

.feedback_label_supporttt {
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 10px;
}
@media screen and (max-width: 500px) {
  .help_support_search_body_inputbody {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/helpSupportComp/helpSupportSearchCard/helpSupportSearchCard.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;EACpB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,2BAA2B;EAC3B,sBAAsB;EACtB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".help_support_search_body_gen {\n  width: 100%;\n  /* height: 280px; */\n  height: 100%;\n  border-radius: 15px;\n  background: #ef6924;\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-direction: column;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-top: 40px;\n  padding-bottom: 40px;\n  box-sizing: border-box;\n  position: relative;\n}\n\n.help_support_search_body_inputbody {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  flex-direction: column;\n  /* gap: 10px; */\n  width: 50%;\n}\n\n.help_support_search_body_InputHead {\n  font-size: 24px;\n  color: #fff;\n  font-weight: 600;\n}\n\n.help_support_search_body_input {\n  width: 100%;\n}\n\n.help_support_search_body_left_logo {\n  position: absolute;\n  right: 10px;\n  bottom: 0;\n  width: 30%;\n}\n\n.help_support_search_body_left_logo img {\n  width: 100%;\n  object-fit: contain;\n}\n\n.feedback_label_supporttt {\n  color: #fff;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 600;\n  margin-bottom: 5px;\n  margin-top: 10px;\n}\n@media screen and (max-width: 500px) {\n  .help_support_search_body_inputbody {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
