import React from "react";
import "./mobileStoriesModal.css";
import InfoModal from "../infoModal/InfoModal";
import StoriesScrollBody from "../storiesScrollBody/StoriesScrollBody";
import { useDispatch } from "react-redux";
import { handleMobileStoryModal } from "../../redux/userData";

const MobileStoriesModal = () => {
  const dispatch = useDispatch();
  return (
    <InfoModal
      padding="0px"
      handleCloseModal={() => {
        dispatch(handleMobileStoryModal(false));
      }}
    >
      <StoriesScrollBody />
    </InfoModal>
  );
};

export default MobileStoriesModal;
