import React from "react";
import "./exploreTabCard.css";
import { luvhub_matchsample } from "../../images";
import { useNavigate } from "react-router-dom";
import useProfileImage from "../../../utils/useProfileImage";
import {
  handleGetEachExploreData,
  handleShowExplorePage,
} from "../../../redux/userData";
import { useDispatch } from "react-redux";

const ExploreTabCard = ({ handleLuvHubPageTab, isScreenWide, data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initials = `${data?.user?.firstName?.charAt(0)?.toUpperCase() ?? ""}${
    data?.user?.lastName?.charAt(0)?.toUpperCase() ?? ""
  }`;

  const handleClick = async () => {
    await dispatch(handleGetEachExploreData(data));
    dispatch(handleShowExplorePage(false));
    if (!isScreenWide) {
      navigate("/luvhub/explore");
    } else {
      handleLuvHubPageTab("explore");
    }
  };
  return (
    <div onClick={handleClick} className="explore_tab_card_matches_card__body">
      <div className="explore_tab_card_matches_card_image_topbody_main">
        {data?.publicImages[0] ? (
          <img src={data?.publicImages[0]?.url} />
        ) : (
          initials
        )}
      </div>
      <div className="explore_tab_card_matches_card_image_topbody_card_down">
        {data?.user?.userName}
      </div>
    </div>
  );
};

export default ExploreTabCard;
