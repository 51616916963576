import React from "react";
import "./loginRightCarousel.css";
import { motion } from "framer-motion";

const LoginRightCarousel = ({
  headerImg,
  circleImg,
  headerText,
  headerParagraph,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1.5 }}
      className="login_right_inner_body"
    >
      <div className="login_right_inner_body_headImg">
        <img src={headerImg} />
      </div>
      <div className="login_right_inner_body_circleImg">
        <img src={circleImg} />
      </div>
      <div className="login_right_inner_body_headText">{headerText}</div>
      <div className="login_right_inner_body_bodyText">{headerParagraph}</div>
    </motion.div>
  );
};

export default LoginRightCarousel;
