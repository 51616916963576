import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosCalls } from "../../../_api";
import "./mobileStoriesBody.css";
import extractAllAttachmentDetails from "../../../utils/ExtractAllAttachmentDetails";
import EachStoriesBar from "../../layout/storiesBar/eachStoriesBar/EachStoriesBar";
import {
  handlePreviewStoriesMedia,
  handleStatusPreviewPostMedia,
  handleStatusPreviewStoriesMedia,
} from "../../../redux/userData";
import { film, sample_profile_picss, stories_sampleShow } from "../../images";
import { CallCustomToast } from "../../../utils/CallCustomToast";

const MobileStoriesBody = () => {
  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorLoading, setOnErrorLoading] = useState(false);
  const [displayEachStories, setDisplayEachStories] = useState([]);
  const [displayEachMutedStories, setDisplayEachMutedStories] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    handleGetUserStories();
  }, []);

  useEffect(() => {
    if (stories.length > 0) {
      setDisplayEachStories(
        extractAllAttachmentDetails(stories[0]?.userStories)
      );
      setDisplayEachMutedStories(
        extractAllAttachmentDetails(stories[0]?.userStories, "muted")
      );

      console.log(displayEachMutedStories, "333333");
      console.log(displayEachStories, "4444444");
    }
  }, [stories]);

  const handleGetUserStories = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosCalls("stories/fetch-stories", "GET");

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        // console.log(res?.er.data?.message);
        setOnErrorLoading(true);

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        setOnErrorLoading(false);
        setStories(res?.data);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };

  return (
    <div className="mobile_stories_bodyGen">
      {/* <div className="profile_info_pics_stories">
        <img src={film} />
      </div> */}

      {stories.map((data, index) => (
        <EachStoriesBar
          handleEachStories={() => {
            dispatch(handleStatusPreviewStoriesMedia(true));
            dispatch(
              handlePreviewStoriesMedia(data?.userStories?.primeStories)
            );
          }}
          eachStories={data}
        />
      ))}
    </div>
  );
};

export default MobileStoriesBody;
