import React from "react";
import "./aboutTabSideBar.css";
import AboutTabSideBarOption from "../aboutTabSideBarOption/AboutTabSideBarOption";

const AboutTabSideBar = ({ tab, handleTabChange }) => {
  return (
    <div className="profile_about_tab_sidebar_body">
      <AboutTabSideBarOption
        onClickFunc={() => {
          handleTabChange(0);
        }}
        tab={tab === 0 ? "Active" : "Non-Active"}
        text="Overview"
      />
      <AboutTabSideBarOption
        onClickFunc={() => {
          handleTabChange(1);
        }}
        tab={tab === 1 ? "Active" : "Non-Active"}
        text="Work and education"
      />
      <AboutTabSideBarOption
        onClickFunc={() => {
          handleTabChange(2);
        }}
        tab={tab === 2 ? "Active" : "Non-Active"}
        text="Address"
      />
    </div>
  );
};

export default AboutTabSideBar;
