// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_body_general_container {
  width: calc(100% - 290px);
}

.profile_top_home_body_tab_ {
  width: 100%;
  border-bottom: 0.99px solid #e2e4e9;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 28px;
  margin-top: 10px;
}

.profile_top_home_body_tab_each_active {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding-right: 10px;
  padding-left: 10px;
  color: #525866;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid #ef6924;
}

.profile_top_home_body_tab_each {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding-right: 10px;
  padding-left: 10px;
  border-bottom: 1px solid #fff;
  color: #525866;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.profile_top_home_body_tab_each:hover {
  border-bottom: 1px solid #ef6924;
}

@media screen and (max-width: 850px) {
  .profile_body_general_container {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/profileBody/profileBody.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,mCAAmC;EACnC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,6BAA6B;EAC7B,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".profile_body_general_container {\n  width: calc(100% - 290px);\n}\n\n.profile_top_home_body_tab_ {\n  width: 100%;\n  border-bottom: 0.99px solid #e2e4e9;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n  height: 28px;\n  margin-top: 10px;\n}\n\n.profile_top_home_body_tab_each_active {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 30px;\n  padding-right: 10px;\n  padding-left: 10px;\n  color: #525866;\n  font-size: 12px;\n  font-weight: 600;\n  border-bottom: 1px solid #ef6924;\n}\n\n.profile_top_home_body_tab_each {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 30px;\n  padding-right: 10px;\n  padding-left: 10px;\n  border-bottom: 1px solid #fff;\n  color: #525866;\n  font-size: 12px;\n  font-weight: 600;\n  cursor: pointer;\n}\n\n.profile_top_home_body_tab_each:hover {\n  border-bottom: 1px solid #ef6924;\n}\n\n@media screen and (max-width: 850px) {\n  .profile_body_general_container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
