// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon_auth_btn_body {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 16px;

  box-sizing: border-box;
  outline: none;
  border-radius: 10px;
  border: 1px solid;
  box-shadow: 0px 1px 2px 0px #9d3d0b7a;

  box-shadow: 0px 0px 0px 1px #e45911;

  border-image-source: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  /* border: 1px solid #e7e7e8; */
  background: #ef6924;
}

.icon_auth_btn_body:hover {
  background: #e15813;
}

.icon_auth_btn_body_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_auth_btn_body_texttt {
  color: #fff;

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/button/iconAuthBtn/iconAuthBtn.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;EACR,gBAAgB;;EAEhB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,qCAAqC;;EAErC,mCAAmC;;EAEnC;;;;GAIC;EACD,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;;EAEX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".icon_auth_btn_body {\n  width: 100%;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 5px;\n  margin-top: 16px;\n\n  box-sizing: border-box;\n  outline: none;\n  border-radius: 10px;\n  border: 1px solid;\n  box-shadow: 0px 1px 2px 0px #9d3d0b7a;\n\n  box-shadow: 0px 0px 0px 1px #e45911;\n\n  border-image-source: linear-gradient(\n    180deg,\n    rgba(255, 255, 255, 0.12) 0%,\n    rgba(255, 255, 255, 0) 100%\n  );\n  /* border: 1px solid #e7e7e8; */\n  background: #ef6924;\n}\n\n.icon_auth_btn_body:hover {\n  background: #e15813;\n}\n\n.icon_auth_btn_body_icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.icon_auth_btn_body_texttt {\n  color: #fff;\n\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 600;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
