import React, { useState, useEffect } from "react";
import "./authInputIcon.css";
import { closeEye, mail_icon, openEye } from "../../images";

const AuthInputIcon = ({
  inputPlaceHolder,
  inputLabel,
  inputValue,
  inputName,
  inputOnchange,
  width,
  validateInput,
  error,
  inputLeftIcon,
  required,
  inputType,
  marginTop,
}) => {
  return (
    <div className="auth_input_tag_container" style={{ width, marginTop }}>
      <div className="auth_input_tag_label">
        {" "}
        {inputLabel}{" "}
        {required && (
          <span
            style={{ color: "#F41010", fontSize: "13px", fontWeight: "600" }}
          >
            *
          </span>
        )}
      </div>
      <div className="auth_input_tag_parent">
        <div className="auth_input_tag_icon_left">
          <img src={inputLeftIcon} />
        </div>
        <div className="auth_input_tag_parent_container">
          <input
            type={inputType}
            placeholder={inputPlaceHolder}
            value={inputValue}
            name={inputName}
            onChange={inputOnchange}
            onBlur={validateInput}
            error={error}
            required={required}
          />
        </div>
      </div>
      {error && <div className="auth_input_error_message">{error}</div>}
    </div>
  );
};

export default AuthInputIcon;
