import React, { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../../../sidebarAccordion/SidebarAccordion";
import SidebarOption from "../../../sidebarOption/SidebarOption";
import HomeSideBar from "../../homeSideBar/HomeSideBar";
import {
  handleLogoutModal,
  handleMobileStoryModal,
  handleShowChatBox,
  handleToggleCreatePost,
  handleToggleNotification,
  handleToggleProfileOption,
  handleViewAnotherUserProfile,
} from "../../../../redux/userData";
import {
  bell,
  bell_sidebar,
  bookmark,
  copy_plus,
  heart_sidebar,
  help_circle,
  lock_keyhole,
  log_out_mobile,
  mobile_film,
  settings,
  talk_stuffnav_logo,
  user_round_sidebar,
  user_settings_round,
} from "../../../images";

const HomeLeftNav = ({ setOpen }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;
  const navigate = useNavigate();

  const splitLocation = pathname.split("/");

  const [show, setShow] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };

  const handleSetOpenFunc = () => {
    setOpen(false);
  };
  const handleParentClick = () => {
    setOpen(false);
  };

  const handleChildClick = (event) => {
    event.stopPropagation();
  };
  return (
    <div style={{ width: "100%" }}>
      <SidebarOption
        path="/"
        icon={user_round_sidebar}
        text="Home"
        onClickFunc={() => {
          navigate("/");
          setOpen(false);
        }}
        showChevronIcon={true}
      />

      <SidebarOption
        icon={mobile_film}
        text="Stories"
        onClickFunc={() => {
          // dispatch(handleToggleCreatePost(true));
          dispatch(handleMobileStoryModal(true));
          setOpen(false);
        }}
        showChevronIcon={false}
      />

      <SidebarOption
        icon={bell}
        text="Notifications"
        noOfNotification="3"
        onClickFunc={() => {
          dispatch(handleToggleNotification(true));
          setOpen(false);
          // handleToggleNotification(true);
        }}
        showChevronIcon={false}
      />
      <SidebarOption
        icon={copy_plus}
        text="Create New post"
        onClickFunc={() => {
          dispatch(handleToggleCreatePost(true));
          setOpen(false);
        }}
        showChevronIcon={false}
      />

      <div style={{ marginTop: "40px", width: "100%" }}></div>
      <SidebarOption
        path="/bookmarks"
        icon={bookmark}
        text="Bookmarks"
        onClickFunc={() => {
          navigate("/bookmarks");
          setOpen(false);
        }}
        showChevronIcon={true}
      />

      <Accordion
        accordionBodyIcon={settings}
        setOpenFunc={handleSetOpenFunc}
        // path="/admin/product"
        // icon={<AdminProductsIcon />}
        title="Settings"
        content={[
          {
            section_name: "Your Account",
            section_route: "/account",
            icon: user_settings_round,
          },
          {
            section_name: "Security and Privacy",
            section_route: "/security-privacy",
            icon: lock_keyhole,
          },
          {
            section_name: "Notification Preferences",
            section_route: "/notification-preferences",
            icon: bell_sidebar,
          },
          {
            section_name: "Interests",
            section_route: "/interests",
            icon: heart_sidebar,
          },
        ]}
      />
      <SidebarOption
        path="/help-support"
        icon={help_circle}
        text="Support & Help"
        onClickFunc={() => {
          navigate("/help-support");
          setOpen(false);
        }}
        showChevronIcon={true}
      />
    </div>
  );
};

export default HomeLeftNav;
