import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import "./index.css";
import HomeSideBar from "./homeSideBar/HomeSideBar";
import StoriesBar from "./storiesBar/StoriesBar";
import AboutTabSideBar from "../profileTabs/aboutTab/aboutTabSideBar/AboutTabSideBar";
import { handlePostOptionPopModal } from "../../redux/userData";
import { useDispatch } from "react-redux";
import JWT from "jwt-decode";
import BottomNavbar from "./bottomNavbar/BottomNavbar";
import SupaMallSideBar from "../../screens/supaMall/supaMallSideBar/SupaMallSideBar";
import LuvhubSideBar from "../../screens/luvhub/luvhubSideBar/LuvhubSideBar";
import LuvhubHome from "../../screens/luvhub/luvhubHome/LuvhubHome";

// import { useAuth } from "../helpers/AuthContext";

export const ProtectedLayout = ({ children }) => {
  const [auth, setAuth] = useState(null);
  // const { isAuthenticated, logout } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const path = location.pathname;

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    const popUpElement = document.querySelector(
      ".post_card_body_option_dropdown"
    );
    if (popUpElement && !popUpElement.contains(event.target)) {
      dispatch(handlePostOptionPopModal(null));
    }
  };

  useEffect(() => {
    handleAuth();
  }, [auth]);

  const handleAuth = () => {
    let token = localStorage.getItem("token");
    if (token == "" || token == undefined || token == null) {
      return setAuth(false);
    }
    validateToken(token);
  };

  const validateToken = () => {
    try {
      let token_expired = localStorage.getItem("token");
      let decoded = JWT(token_expired);

      console.log("decoded>>>>>", decoded);
      console.log("user email...", decoded?.email);
      localStorage.setItem("User Role", decoded?.role);
      console.log("user role", decoded?.role);

      let decode_string = decoded["exp"];
      var current_time = Date.now() / 1000;
      if (decode_string < current_time) {
        localStorage.clear();
        setAuth(false);
        window.location.href = "/login";
      } else {
        setAuth(true);
      }
    } catch (e) {
      localStorage.clear();
      setAuth(false);
      //what you need to do incase the jwt is not valid
      console.log(e); //for your own debugging
    }
  };

  return (
    <div className="auth">
      {auth == null ? (
        <div>Please Wait...</div>
      ) : auth == true ? (
        <div className="header">
          <Navbar />

          {location.pathname.startsWith("/supamall") ? (
            <div className="supa_layout__content">
              {/* <SupaMallSideBar /> */}

              {children}
            </div>
          ) : location.pathname.startsWith("/luvhub/home") ? (
            <LuvhubSideBar />
          ) : location.pathname.startsWith("/luvhub/matches") ? (
            <LuvhubHome />
          ) : (
            <div className="layout__content">
              <HomeSideBar />

              {children}
            </div>
          )}

          <BottomNavbar />
        </div>
      ) : (
        navigate(`/login`)
      )}
    </div>
  );
};
