import React from "react";
import "./homeFourtTrendingSection.css";
import ProductCard from "../productCard/ProductCard";

const HomeFourtTrendingSection = () => {
  return (
    <div className="supamall_homefouthbody_section_bbbb">
      <div className="supamall_homefouthbody_section_bbbb_heading">
        Trending
      </div>
      <div className="supamall_homefouthbody_section">
        <ProductCard />
        <ProductCard />
        <ProductCard />
        <ProductCard />
        <ProductCard />
        <ProductCard />
        <ProductCard />
      </div>
    </div>
  );
};

export default HomeFourtTrendingSection;
