import React from "react";
import "./editRegContent.css";
import { pencil_line, square_user } from "../images";

const EditRegContent = ({
  sectionIcon,
  sectionHead,
  sectionText,
  anotherUserProfile,
}) => {
  return (
    <div className="summary_content_reg_container_each">
      <div className="summary_content_reg_container_each_first_flex">
        <div className="summary_content_reg_container_icon">
          <img src={sectionIcon} />
        </div>
        <div className="summary_content_reg_container_name">
          <div className="summary_content_reg_container_nameT">
            {sectionHead}
          </div>
          <div className="summary_content_reg_containerTT">{sectionText}</div>
        </div>
      </div>

      {!anotherUserProfile && (
        <div className="summary_content_reg_container_edit">
          <img src={pencil_line} />
        </div>
      )}
    </div>
  );
};

export default EditRegContent;
