import React from "react";
import "./matchesCardCont.css";
import {
  chevron_right,
  chevronLeftlegal,
  luvhub_matchsample,
} from "../../images";
import MatchesCard from "../matchesCard/MatchesCard";
import GoBackBtn from "../../button/gobackBtn/GoBackBtn";
import { useDispatch } from "react-redux";
import { handleGetMoreMatchesReqName } from "../../../redux/userData";
import { useNavigate } from "react-router-dom";

const MatchesCardCont = ({ handleLuvHubPageTab, isScreenWide, data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = async () => {
    await dispatch(handleGetMoreMatchesReqName(data?.category));

    if (!isScreenWide) {
      navigate("/luvhub/matches");
    } else {
      handleLuvHubPageTab("matches");
    }
  };
  return (
    <div className="matches_card_cont_bodyy">
      <div className="matches_card_cont_bodyy_toptext">{data?.category}</div>

      <MatchesCard
        isScreenWide={isScreenWide}
        handleLuvHubPageTab={handleLuvHubPageTab}
        data={data}
      />

      <div onClick={handleClick} className="matches_card_cont_bodyy_view_gen">
        <div className="matches_card_cont_bodyy_view_text">View all</div>
        <div className="matches_card_cont_bodyy_view_img">
          <img src={chevron_right} />
        </div>
      </div>
    </div>
  );
};

export default MatchesCardCont;
