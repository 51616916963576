import React from "react";

const Heart = ({ fill }) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1033_22932)">
        <path
          d="M12.4731 8.73993C13.3968 7.83483 14.3329 6.74994 14.3329 5.33029C14.3329 4.426 13.9737 3.55875 13.3342 2.91931C12.6948 2.27988 11.8275 1.92065 10.9232 1.92065C9.83216 1.92065 9.06344 2.23062 8.13353 3.16052C7.20363 2.23062 6.43491 1.92065 5.34383 1.92065C4.43954 1.92065 3.57228 2.27988 2.93285 2.91931C2.29342 3.55875 1.93419 4.426 1.93419 5.33029C1.93419 6.75614 2.86409 7.84103 3.79399 8.73993L8.13353 13.0795L12.4731 8.73993Z"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1033_22932">
          <rect
            width="14.8784"
            height="14.8784"
            fill="white"
            transform="translate(0.694321 0.060791)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Heart;
