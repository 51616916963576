import React, { useState, useEffect } from "react";
import "./bookmarks.css";
import HomeMargin from "../../../components/homeMargin/HomeMargin";
import InnerAccordionHeader from "../../../components/innerAccordionHeader/InnerAccordionHeader";
import {
  bookmark_icon,
  minigadget,
  oneMinutes,
  post_fourImage,
  post_oneImage,
  post_threeImage,
  post_twoImage,
  sampleVid,
} from "../../../components/images";
import PostCardComponent from "../../../components/postCardComponent/PostCardComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { axiosCalls } from "../../../_api";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import { useDispatch } from "react-redux";

const Bookmarks = () => {
  const [userPost, setUserPost] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [onErrorLoading, setOnErrorLoading] = useState(false);
  const postData = [
    {
      postText:
        " Today began with the gentle rays of the sun peeking through my curtains, coaxing me out of bed. The aroma of freshly brewed coffee filled the air as I prepared for the day ahead.",
      postUser: "John Pablo",
      imageFiles: [post_oneImage, post_fourImage, minigadget, oneMinutes],
    },
    {
      postText:
        " Today began with the gentle rays of the sun peeking through my curtains, coaxing me out of bed. The aroma of freshly brewed coffee filled the air as I prepared for the day ahead.",
      postUser: "John Pablo",
      imageFiles: [post_twoImage, post_threeImage, sampleVid],
    },
  ];

  useEffect(() => {
    handleGetUserPost();
  }, []);

  const handleGetUserPost = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosCalls(
      "feeds/feed-bookmarks?feedId=65c43cce55b5e2bb6f5deee8",
      "GET"
    );

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        setOnErrorLoading(true);
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        setUserPost(res?.data);
        setOnErrorLoading(false);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };

  return (
    <HomeMargin>
      <InnerAccordionHeader
        headerText="Bookmarked Items"
        ringIcon={bookmark_icon}
        subText="Your Collection of Bookmarked Treasures"
      />
      <div className="bookmarks_body_postt">
        {isLoading ? (
          // Show skeleton while loading
          <Skeleton count={5} height={500} />
        ) : (
          // Show user posts when loaded
          userPost?.map((data, index) => (
            <PostCardComponent
              userPost={data}
              index={index}
              postCaption={data?.caption || <Skeleton count={2} />}
              postUser={data?.createdBy}
              showFollowBtn={true}
              imageFiles={data?.attachments || <Skeleton />}
            />
          ))
        )}

        {onErrorLoading && (
          <div className="network_xerror_body">
            Network error{" "}
            <AuthBtn
              width="200px"
              btnText="Reload"
              handleClick={handleGetUserPost}
            />
          </div>
        )}
      </div>
    </HomeMargin>
  );
};

export default Bookmarks;
