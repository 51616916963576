import React, { useState, useEffect } from "react";
import "./profileInfoSidebarOption.css";
import { chevron_up, sample_image } from "../images";
import { axiosCalls } from "../../_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { handleInterestHomeModal } from "../../redux/userData";

const ProfileInfoSidebarOption = ({ onClickFunc, showChevron }) => {
  const storedJsonString = localStorage.getItem("userData");
  const storedObject = JSON.parse(storedJsonString);
  const [isLoading, setIsLoading] = useState(false);

  const [onErrorLoading, setOnErrorLoading] = useState(false);
  const [userDataUpdated, setUserDataUpdated] = useState(null);
  const [showMeSelectedRack, setShowMeSelectedRack] = useState([]);
  const [interestSelectedRack, setInterestSelectedRack] = useState([]);
  const [lookingForSelectedRack, setLookingForSelectedRack] = useState([]);

  const dispatch = useDispatch();

  const handleGetUserData = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosCalls("accounts/account-info", "GET");

    if (res) {
      console.log(res, "userData updated");
      setIsLoading(false);
      if (!res?.status) {
        // console.log(res?.er.data?.message);
        setOnErrorLoading(true);
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        setOnErrorLoading(false);
        setUserDataUpdated(res?.data);

        // Dispatch only after setting state
        if (
          res?.data?.interests?.showMe?.length === 0 ||
          res?.data?.interests?.interests?.length === 0 ||
          res?.data?.interests?.lookingFor?.length === 0
        ) {
          dispatch(handleInterestHomeModal(true));
        }
      }
    }
  };

  useEffect(() => {
    handleGetUserData();
  }, []);
  return (
    <div onClick={onClickFunc} className="profile_info_sidebar_body">
      <div className="profile_info_sidebar_detailsss">
        {userDataUpdated?.profileImage ? (
          <div className="profile_info_pics">
            <img src={userDataUpdated?.profileImage} />
          </div>
        ) : (
          <div className="profile_info_pics_border">
            <div className="profile_info_pics_border_inner">
              {userDataUpdated?.firstName // if profile_pic is null, show only the first letters of the names
                ?.split(" ")
                ?.map((name) => name.charAt(0).toUpperCase())
                .join("")}
              {userDataUpdated?.lastName // if profile_pic is null, show only the first letters of the names
                ?.split(" ")
                ?.map((name) => name.charAt(0).toUpperCase())
                .join("")}
            </div>
          </div>
        )}

        <div className="profile_info_details_body">
          <div className="profile_info_details_name">
            {storedObject?.firstName} {storedObject?.lastName}
          </div>
          <div className="profile_info_details_email">
            {storedObject?.email}
          </div>
        </div>
      </div>
      {showChevron && (
        <div className="profile_info_details_chevronUP">
          <img src={chevron_up} />
        </div>
      )}
    </div>
  );
};

export default ProfileInfoSidebarOption;
