import React from "react";

const BottomPlus = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2724_3149)">
        <path
          d="M10.2178 7.78662V11.5062"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.3584 9.64648H12.078"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.3181 5.30688H7.11878C6.43401 5.30688 5.87891 5.86199 5.87891 6.54675V12.7461C5.87891 13.4309 6.43401 13.986 7.11878 13.986H13.3181C14.0029 13.986 14.558 13.4309 14.558 12.7461V6.54675C14.558 5.86199 14.0029 5.30688 13.3181 5.30688Z"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.39905 10.2665C2.71712 10.2665 2.15918 9.70855 2.15918 9.02662V2.82727C2.15918 2.14534 2.71712 1.5874 3.39905 1.5874H9.59839C10.2803 1.5874 10.8383 2.14534 10.8383 2.82727"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2724_3149">
          <rect
            width="14.8784"
            height="14.8784"
            fill="white"
            transform="translate(0.918945 0.347656)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BottomPlus;
