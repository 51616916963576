import { useState, useEffect } from "react";

const useCurrencyFormatter = (amount) => {
  const [formattedAmount, setFormattedAmount] = useState("");

  useEffect(() => {
    if (amount !== null && amount !== undefined) {
      const formatter = new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
      });
      setFormattedAmount(formatter.format(amount));
    }
  }, [amount]);

  return formattedAmount;
};

export default useCurrencyFormatter;
