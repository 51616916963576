import React from "react";

const BottomMessages = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0142 9.70637C15.0142 10.2563 14.7958 10.7836 14.407 11.1724C14.0181 11.5613 13.4908 11.7797 12.9409 11.7797H6.72089L2.57422 15.9264V4.52304C2.57422 3.38271 3.50722 2.44971 4.64755 2.44971H12.9409C13.4908 2.44971 14.0181 2.66815 14.407 3.05697C14.7958 3.4458 15.0142 3.97316 15.0142 4.52304V9.70637Z"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.1605 9.70654H21.2339C21.7837 9.70654 22.3111 9.92498 22.6999 10.3138C23.0887 10.7026 23.3072 11.23 23.3072 11.7799V23.1832L19.1605 19.0365H12.9405C12.3906 19.0365 11.8633 18.8181 11.4745 18.4293C11.0856 18.0405 10.8672 17.5131 10.8672 16.9632V15.9265"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BottomMessages;
