// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_following_tab_body {
  width: 100%;
  border: 0.99px solid #e2e4e9;

  box-sizing: border-box;
  padding: 15px;
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  min-height: 500px;
}
`, "",{"version":3,"sources":["webpack://./src/components/profileTabs/followingTab/followingTab.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,4BAA4B;;EAE5B,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".profile_following_tab_body {\n  width: 100%;\n  border: 0.99px solid #e2e4e9;\n\n  box-sizing: border-box;\n  padding: 15px;\n  border-radius: 20px;\n  margin-top: 30px;\n  margin-bottom: 30px;\n  min-height: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
