import React from "react";
import "./homeAccountInfoCard.css";

const HomeAccountInfoCard = ({ username, username_head }) => {
  return (
    <div className="home_account_info_card_body">
      <div className="home_account_info_card_body_userhea">{username}</div>
      <div className="home_account_info_card_body_usersub">{username_head}</div>
    </div>
  );
};

export default HomeAccountInfoCard;
