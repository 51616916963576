// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supamall_home_sidebar_body {
  width: 100%;
  /* height: 100%; */
  padding: 12px;
  border: 1px solid #e2e4e9;
  border-radius: 15px;
  position: relative;
  box-sizing: border-box;
  /* margin-top: 30px; */
  /* left: 0; */
}

.supamall_home_sidebar_body_top_body {
  width: 25%;
}

.supamall_home_sidebar_headingggg {
  color: #0a0d14;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}
@media screen and (max-width: 850px) {
  .supamall_home_sidebar_body {
    display: none;
  }
  .supamall_home_sidebar_body_top_body {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/supaMall/supaMallSideBar/supaMallSideBar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;EACtB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".supamall_home_sidebar_body {\n  width: 100%;\n  /* height: 100%; */\n  padding: 12px;\n  border: 1px solid #e2e4e9;\n  border-radius: 15px;\n  position: relative;\n  box-sizing: border-box;\n  /* margin-top: 30px; */\n  /* left: 0; */\n}\n\n.supamall_home_sidebar_body_top_body {\n  width: 25%;\n}\n\n.supamall_home_sidebar_headingggg {\n  color: #0a0d14;\n  font-size: 12px;\n  font-weight: 600;\n  margin-bottom: 10px;\n}\n@media screen and (max-width: 850px) {\n  .supamall_home_sidebar_body {\n    display: none;\n  }\n  .supamall_home_sidebar_body_top_body {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
