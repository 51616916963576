// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary_content_reg_container_each {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.summary_content_reg_container_each_first_flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.summary_content_reg_container_icon {
  width: 40px;
  height: 40px;
  background: #f6f8fa;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.summary_content_reg_container_name {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.summary_content_reg_container_nameT {
  font-size: 12px;
  font-weight: 400;
  color: #868c98;
}

.summary_content_reg_containerTT {
  font-size: 12px;
  font-weight: 600;
  color: #0a0d14;
}

.summary_content_reg_container_edit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/editRegContent/editRegContent.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,QAAQ;EACR,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".summary_content_reg_container_each {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 5px;\n  margin-bottom: 10px;\n  margin-top: 10px;\n}\n\n.summary_content_reg_container_each_first_flex {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.summary_content_reg_container_icon {\n  width: 40px;\n  height: 40px;\n  background: #f6f8fa;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.summary_content_reg_container_name {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-direction: column;\n  gap: 5px;\n}\n\n.summary_content_reg_container_nameT {\n  font-size: 12px;\n  font-weight: 400;\n  color: #868c98;\n}\n\n.summary_content_reg_containerTT {\n  font-size: 12px;\n  font-weight: 600;\n  color: #0a0d14;\n}\n\n.summary_content_reg_container_edit {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 15px;\n  height: 15px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
