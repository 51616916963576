import React from "react";
import "./chatBoxMobile.css";
import ChatBox from "../../screens/chatSection/chatBox/ChatBox";
import { useSelector } from "react-redux";

const ChatBoxMobile = () => {
  const { showEachUserChat } = useSelector(
    (state) => state.showEachUserChatBody.showEachUserChatBody
  );

  return (
    <div className="chat_box_mobileModalBody">
      <div className="chat_box_mobileContent">
        <ChatBox />
      </div>
    </div>
  );
};

export default ChatBoxMobile;
