// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.termsandcondition_heading {
  color: #0a0d14;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  text-align: left;
  margin-top: 70px;
  margin-top: 15px;
}

.termsandcondition_heading_logo {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
}

.terms_condi_bodyyy {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 992px) {
  .termsandcondition_heading_logo {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/legal/termsandcondition/termsandCondition.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".termsandcondition_heading {\n  color: #0a0d14;\n  font-size: 20px;\n  font-weight: 600;\n  font-style: normal;\n  text-align: left;\n  margin-top: 70px;\n  margin-top: 15px;\n}\n\n.termsandcondition_heading_logo {\n  margin-top: 50px;\n  margin-bottom: 50px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100px;\n}\n\n.terms_condi_bodyyy {\n  width: 80%;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n@media screen and (max-width: 992px) {\n  .termsandcondition_heading_logo {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
