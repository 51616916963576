// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help_supportLinks_card_body__ {
  border: 1px solid #e2e4e9;
  width: calc(50% - 5px);
  border-radius: 20px;
  padding: 25px;
  box-sizing: border-box;
}

.help_supportLinks_card_body__head {
  color: #0a0d14;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.help_supportLinks_card_body__border {
  border-bottom: 1px solid #e2e4e9;
  width: 100%;
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .help_supportLinks_card_body__ {
    width: calc(100% - 5px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/helpSupportComp/helpSupportLinksCard/helpSupportLinksCard.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gCAAgC;EAChC,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".help_supportLinks_card_body__ {\n  border: 1px solid #e2e4e9;\n  width: calc(50% - 5px);\n  border-radius: 20px;\n  padding: 25px;\n  box-sizing: border-box;\n}\n\n.help_supportLinks_card_body__head {\n  color: #0a0d14;\n  font-size: 14px;\n  font-weight: 600;\n  margin-bottom: 10px;\n}\n\n.help_supportLinks_card_body__border {\n  border-bottom: 1px solid #e2e4e9;\n  width: 100%;\n  margin-top: 20px;\n}\n\n@media screen and (max-width: 600px) {\n  .help_supportLinks_card_body__ {\n    width: calc(100% - 5px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
