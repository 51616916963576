import React, { useState } from "react";
import "./passwordReset.css";
import AuthBodyMargin from "../../../components/authBodyMargin/AuthBodyMargin";
import GoBackBtn from "../../../components/button/gobackBtn/GoBackBtn";
import {
  mail,
  mailDummy,
  talk_stuff_authLogo,
} from "../../../components/images";
import AuthInputIcon from "../../../components/defaultInput/authInputnIcon/AuthInputIcon";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";
import AuthForwardBtn from "../../../components/button/authForwardBtn/AuthForwardBtn";
import { useNavigate } from "react-router-dom";
import SuccessResetPasswordModal from "../../../components/successResetPasswordModal/SuccessResetPasswordModal";
import { axiosCalls } from "../../../_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import { useDispatch } from "react-redux";

const PasswordReset = () => {
  const [successModal, setSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState({
    email: "",
  });

  const [formData, setFormData] = useState({
    email: "",
  });

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "email":
          if (!value) {
            stateObj[name] = "Please enter Email Address.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    validateInput(e);
  };

  const handlePasswordResetSubmit = async (e) => {
    const data = {
      email: formData.email,
    };

    // showLoader();
    setIsLoading(true);
    const res = await axiosCalls(
      "authentication/send-password-reset-link",
      "POST",
      data
    );

    if (res) {
      setIsLoading(false);
      // hideLoader();
      console.log(res);
      if (!res?.status) {
        console.log(res?.message);
        // toast.error(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.message,
        });
        return;
      } else if (res?.status) {
        localStorage.setItem(formData.email, "resetEmail");
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
        setSuccessModal(true);
      }
    }
  };

  const handleSuccessReset = () => {
    navigate("/login");
  };
  return (
    <AuthBodyMargin>
      <ToastContainer />
      <div className="termsandcondition_heading_logo">
        <img src={talk_stuff_authLogo} />
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handlePasswordResetSubmit();
        }}
        className="register_verify_input_body"
      >
        <div className="register_verify_input_body_headerr">Password Reset</div>
        <div className="register_verify_input_body_head_textt">
          Kindly provide your email address to receive instructions for
          resetting your password.
        </div>

        <AuthInputIcon
          inputLeftIcon={mail}
          inputLabel="Email address"
          inputPlaceHolder="talkstuff@gmail.com"
          width="100%"
          inputName="email"
          inputOnchange={handleChange}
          inputType="email"
          inputValue={formData.email}
          error={error.email}
          validateInput={validateInput}
          required={true}
        />

        <AuthBtn
          isLoading={isLoading}
          // handleClick={() => {
          //   setSuccessModal(true);
          // }}
          btnText="Send recovery link"
        />

        <div className="register_goback_login_body">
          <div className="register_goback_login_body_text">
            Already have an account?
          </div>
          <AuthForwardBtn
            handleGoForward={() => {
              navigate("/login");
            }}
            forwardBtnText="Login"
          />
        </div>
      </form>

      {successModal && (
        <div>
          <SuccessResetPasswordModal
            mailDummy={mailDummy}
            successBody=" Kindly review your mailbox; we have forwarded a link to reset your
            password."
            successHeader=" Password reset link sent"
            handleSuccessReset={handleSuccessReset}
          />
        </div>
      )}
    </AuthBodyMargin>
  );
};

export default PasswordReset;
