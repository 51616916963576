import React from "react";
import "./postCardCaption.css";
import PostCardEngage from "../postCardEngage/PostCardEngage";
import PostCardCaptionText from "../postCardCaptionText/PostCardCaptionText";
import { sampleLikedImg } from "../../images";

const PostCardCaption = ({ postCaption, postUser, userPost }) => {
  return (
    <div className="post_card_caption_body">
      <PostCardCaptionText
        userPost={userPost}
        postCaption={postCaption}
        postUser={postUser}
      />

      <PostCardEngage userPost={userPost} sampleLikedImg={sampleLikedImg} />
    </div>
  );
};

export default PostCardCaption;
