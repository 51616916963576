import React, { useState } from "react";
import "./supamallFilterCard.css";
import { chevron_right_icon } from "../../images";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
const SupamallFilterCard = () => {
  const [selectedCondition, setSelectedCondition] = useState(null);

  const handleRadioChange = (condition) => {
    if (selectedCondition === condition) {
      setSelectedCondition(null); // Untick the radio button if it is already selected
    } else {
      setSelectedCondition(condition); // Tick the radio button
    }
  };
  return (
    <div className="supamall_filter_card_bodyGen">
      <div className="supamall_filter_card_bodyHead">Filter</div>
      <div className="supamall_filter_card_bodymodal">
        <div className="supamall_filter_card_bodytext_gen">
          <div className="supamall_filter_card_bodytext_loca">Location</div>
          <div className="supamall_filter_card_bodytext_direct">
            All Nigeria
          </div>
        </div>

        <div className="supamall_filter_card_bodytext_genIconRight">
          <img src={chevron_right_icon} />
        </div>
      </div>

      <div className="supamall_filter_card_body_rangeBody">Price Range</div>
      <Slider className="custom-slider" />

      <div className="supamall_filter_card_body_condition_head">Condition</div>
      <div className="supamall_filter_card_body_condit_radio">
        <label>
          <input
            type="radio"
            name="condition"
            checked={selectedCondition === "New"}
            onChange={() => handleRadioChange("New")}
          />
          New
        </label>
        <label>
          <input
            type="radio"
            name="condition"
            checked={selectedCondition === "Used"}
            onChange={() => handleRadioChange("Used")}
          />
          Used
        </label>
      </div>
    </div>
  );
};

export default SupamallFilterCard;
