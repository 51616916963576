import React, { useState } from "react";
import "./createPostModal.css";
import InfoModal from "../../infoModal/InfoModal";
import { useDispatch, useSelector } from "react-redux";
import {
  handleGeneralToastBody,
  handleToggleCreatePost,
  handleShowSuccessToast,
  handleToastData,
  handleToastHeaderData,
} from "../../../redux/userData";
import ModalHeaderIconCont from "../../modalHeaderIconCont/ModalHeaderIconCont";
import {
  book_image,
  copy_plus_post,
  user_round_post,
  user_round_stories,
} from "../../images";
import CreatePostInnerCard from "../createPostInnerCard/CreatePostInnerCard";
import PostUploadModal from "../postUploadModal/PostUploadModal";
import { axiosCalls, axiosFormData } from "../../../_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScreenWidth from "../../../utils/ScreenWidth";
import { CallCustomToast } from "../../../utils/CallCustomToast";

const CreatePostModal = () => {
  const dispatch = useDispatch();

  const [uploadPost, setUploadPost] = useState(false);
  const [uploadStory, setUploadStory] = useState(false);

  const [selectedImages, setSelectedImages] = useState([]);
  const [displaySelectImages, setDisplaySelectedImages] = useState([]);
  const [selectedImagesStories, setSelectedImagesStories] = useState([]);
  const [displayStoryImages, setDisplayStoryImages] = useState([]);

  const [showAddCaption, setShowAddCaption] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    captionText: "",
  });

  const [formValue, setFormValue] = useState({
    captionText: " ",
    storiesText: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const handleAddImageCaption = (status) => {
    // if (selectedImages.length < 1) {
    //   alert("please add post images");
    // } else {
    setShowAddCaption(status);
    // }
  };

  // const handleImageChange = (event) => {
  //   const files = Array.from(event.target.files);

  //   if (files?.length > 0) {
  //     const newDisplayImages = [];
  //     const newSelectedImages = [];

  //     for (let i = 0; i < files.length; i++) {
  //       // Check if the file type is video/mp4
  //       if (files[i].type === "video/mp4") {
  //         // Handle video file
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //           // Generate a thumbnail from the video
  //           const video = document.createElement("video");
  //           video.src = reader.result;
  //           video.addEventListener("loadedmetadata", () => {
  //             // Create a canvas element to generate thumbnail
  //             const canvas = document.createElement("canvas");
  //             const context = canvas.getContext("2d");
  //             canvas.width = 200; // Set thumbnail width
  //             canvas.height =
  //               (canvas.width / video.videoWidth) * video.videoHeight;
  //             context.drawImage(video, 0, 0, canvas.width, canvas.height);
  //             // Convert thumbnail to data URL
  //             const thumbnail = canvas.toDataURL("image/jpeg");
  //             newDisplayImages.push(thumbnail);
  //             setDisplaySelectedImages([...newDisplayImages]);
  //           });
  //         };
  //         reader.readAsDataURL(files[i]);
  //         newSelectedImages.push(files[i]);
  //       } else {
  //         // Handle image file
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //           newDisplayImages.push(reader.result);
  //           setDisplaySelectedImages([...newDisplayImages]);
  //         };
  //         reader.readAsDataURL(files[i]);

  //         newSelectedImages.push(files[i]);
  //       }
  //     }

  //     setSelectedImages([...selectedImages, ...newSelectedImages]);
  //   }
  //   console.log(selectedImages, "seletedimage");
  // };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);

    if (files?.length > 0) {
      const newDisplayImages = [...displaySelectImages]; // Maintain existing displayed images
      const newSelectedImages = [...selectedImages]; // Maintain existing selected images

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Check if the file type is video/mp4
        if (file.type === "video/mp4") {
          // Handle video file
          const reader = new FileReader();
          reader.onloadend = () => {
            // Generate a thumbnail from the video
            const video = document.createElement("video");
            video.src = reader.result;
            video.addEventListener("loadedmetadata", () => {
              // Create a canvas element to generate thumbnail
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");
              canvas.width = 200; // Set thumbnail width
              canvas.height =
                (canvas.width / video.videoWidth) * video.videoHeight;
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              // Convert thumbnail to data URL
              const thumbnail = canvas.toDataURL("image/jpeg");
              newDisplayImages.push(thumbnail); // Add thumbnail to displayed images
              setDisplaySelectedImages([...newDisplayImages]);
            });
          };
          reader.readAsDataURL(file);
        } else {
          // Handle image file
          const reader = new FileReader();
          reader.onloadend = () => {
            newDisplayImages.push(reader.result); // Add image to displayed images
            setDisplaySelectedImages([...newDisplayImages]);
          };
          reader.readAsDataURL(file);
        }

        newSelectedImages.push(file); // Add file to selected images
      }

      setSelectedImages(newSelectedImages);
    }

    console.log(selectedImages, "seletedimage");
  };

  const handleDeleteImagePost = (index) => {
    const updatedSelectedImages = [...selectedImages];
    updatedSelectedImages.splice(index, 1);
    setSelectedImages(updatedSelectedImages);

    const updatedDisplayImages = [...displaySelectImages];
    updatedDisplayImages.splice(index, 1);
    setDisplaySelectedImages(updatedDisplayImages);
  };

  const handleDeleteImageStories = (index) => {
    const updatedImages = [...selectedImagesStories];
    updatedImages?.splice(index, 1);
    setSelectedImagesStories(updatedImages);

    const updatedDisplayImages = [...displayStoryImages];
    updatedDisplayImages.splice(index, 1);
    setDisplayStoryImages(updatedDisplayImages);
  };

  const handleStoriesImageChange = (event) => {
    const files = Array.from(event.target.files);
    if (files?.length > 0) {
      const newDisplayImages = [...displayStoryImages];
      const newSelectedImages = [...selectedImagesStories];
      for (let i = 0; i < files.length; i++) {
        // Check if the file type is video/mp4
        if (files[i].type === "video/mp4") {
          // Handle video file
          const reader = new FileReader();
          reader.onloadend = () => {
            // Generate a thumbnail from the video
            const video = document.createElement("video");
            video.src = reader.result;
            video.addEventListener("loadedmetadata", () => {
              // Create a canvas element to generate thumbnail
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");
              canvas.width = 200; // Set thumbnail width
              canvas.height =
                (canvas.width / video.videoWidth) * video.videoHeight;
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              // Convert thumbnail to data URL
              const thumbnail = canvas.toDataURL("image/jpeg");
              newDisplayImages.push(thumbnail);
              setDisplayStoryImages([...newDisplayImages]);
            });
          };
          reader.readAsDataURL(files[i]);
          newSelectedImages.push(files[i]);
        } else {
          // Handle image file
          const reader = new FileReader();
          reader.onloadend = () => {
            newDisplayImages.push(reader.result);
            setDisplayStoryImages([...newDisplayImages]);
          };
          reader.readAsDataURL(files[i]);

          newSelectedImages.push(files[i]);
        }
      }

      setSelectedImagesStories([...selectedImages, ...newSelectedImages]);
    }
    console.log(selectedImagesStories, "seletedimage");
  };
  // const handleStoriesImageChange = (event) => {
  //   const files = event.target.files;
  //   const imagesArray = [];

  //   for (let i = 0; i < files.length; i++) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       imagesArray.push(reader.result);
  //       setSelectedImagesStories([...selectedImagesStories, ...imagesArray]);
  //     };
  //     reader.readAsDataURL(files[i]);
  //   }
  // };

  const handleUploadPost = (status) => {
    setUploadPost(status);
  };

  const handleUploadStories = (status) => {
    setUploadStory(status);
  };

  const onSuccessPostImages = () => {
    // dispatch(handleToggleCreatePost(false));

    handleUploadPost(false);
    handleUploadStories(false);
    setSelectedImages([]);
    setDisplaySelectedImages([]);
    setDisplayStoryImages([]);
    setSelectedImagesStories([]);
    setFormValue({
      captionText: " ",
      storiesText: "",
    });
    setShowAddCaption(false);
  };

  const onSuccessMessagePost = (message) => {
    dispatch(handleGeneralToastBody(message));
  };
  const handleCreatePostFeed = async (e) => {
    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({ caption: formValue?.captionText })
    );

    // Append each selected image directly to FormData
    selectedImages.forEach((file, index) => {
      formData.append("attachments", file);
    });
    setIsLoading(true);
    const res = await axiosFormData("feeds/create-feed", "POST", formData);

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        // dispatch(handleShowSuccessToast("Error"));
        // dispatch(handleToastData(res?.er?.data?.message));

        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        onSuccessPostImages();
        dispatch(handleToggleCreatePost(false));
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
        // dispatch(handleShowSuccessToast("Success"));
        // dispatch(handleToastHeaderData("Updated"));
        // dispatch(handleToastData(res?.message));
        // onSuccessMessagePost(res?.message);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };
  const handleCreateStoriesFeed = async (e) => {
    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({ caption: formValue?.storiesText })
    );

    // Append each selected image directly to FormData
    selectedImagesStories.forEach((file, index) => {
      formData.append("attachments", file);
    });
    setIsLoading(true);
    const res = await axiosFormData("stories/upload-stories", "POST", formData);

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        onSuccessPostImages();
        // onSuccessMessagePost(res?.message);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        dispatch(handleToggleCreatePost(false));

        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
      }
    }
  };
  return (
    <InfoModal
      handleCloseModal={() => {
        dispatch(handleToggleCreatePost(false));
      }}
      // height="300px"
      // minHeight="0"
      modalPointer={false}
      width="600px"
      maxHeight="800px"
    >
      <ToastContainer />
      <ModalHeaderIconCont
        modalHeaderIcon={copy_plus_post}
        modalHeaderName="Create New Post"
      />

      <CreatePostInnerCard
        handleClickCard={() => {
          handleUploadPost(true);
        }}
        innerHead="Feed Post"
        innerIcon={copy_plus_post}
        innerTextSub=" Create a new feed post for friends to see"
      />

      <CreatePostInnerCard
        handleClickCard={() => {
          handleUploadStories(true);
        }}
        innerHead="Story"
        innerIcon={user_round_post}
        innerTextSub=" Upload a story post for viewers to see"
      />

      {uploadPost && (
        <PostUploadModal
          handleCloseModal={() => {
            handleUploadPost(false);
          }}
          selectedImages={selectedImages}
          displaySelectImages={displaySelectImages}
          handleAddImageCaption={handleAddImageCaption}
          handleImageChange={handleImageChange}
          showAddCaption={showAddCaption}
          postUploadHeading="Create Post"
          postUploadIcon={book_image}
          postModal={true}
          storiesModal={false}
          handleDeleteImagePost={handleDeleteImagePost}
          handleChange={handleChange}
          formValue={formValue}
          handleCreatePostFeed={handleCreatePostFeed}
          isLoading={isLoading}
        />
      )}

      {uploadStory && (
        <PostUploadModal
          handleCloseModal={() => {
            handleUploadStories(false);
          }}
          displaySelectImages={displayStoryImages}
          selectedImages={selectedImagesStories}
          handleImageChange={handleStoriesImageChange}
          postUploadHeading="Create Stories"
          postUploadIcon={user_round_stories}
          postModal={false}
          storiesModal={true}
          handleDeleteImagePost={handleDeleteImageStories}
          handleCreatePostFeed={handleCreateStoriesFeed}
          isLoading={isLoading}
        />
      )}
    </InfoModal>
  );
};

export default CreatePostModal;
