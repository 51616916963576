const extractAllAttachmentDetails = (stories, storyType = "prime") => {
  const assetBaseUrl =
    "https://talkstuff-store.fra1.cdn.digitaloceanspaces.com";
  if (storyType === "muted") {
    return (
      stories?.mutedStories
        .flatMap((story) => story.attachment)
        ?.map((d) => {
          let newView = {
            ...d,
            url: `${assetBaseUrl}${d?.url}`,
            type: d?.mimeType,
            videoThumbnail: `${assetBaseUrl}${d?.thumbNail}`,
          };
          return newView;
        }) ?? []
    );
  }
  return (
    stories?.primeStories
      .flatMap((story) => story.attachment)
      ?.map((d) => {
        let newView = {
          ...d,
          url: `${assetBaseUrl}${d?.url}`,
          type: d?.mimeType,
          videoThumbnail: `${assetBaseUrl}${d?.thumbNail}`,
        };
        return newView;
      }) ?? []
  );
};

export default extractAllAttachmentDetails;
