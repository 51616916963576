import React, { useState, useEffect } from "react";
import "./supaMallProductForm.css";
import Footer from "../../../../components/supamallComp/footer/Footer";
import SelectInput from "../../../../components/defaultInput/selectInput/SelectInput";
import { info_supamall_product_add } from "../../../../components/images";
import DefaultTextArea from "../../../../components/defaultInput/defaultTextArea/DefaultTextArea";
import AuthInput from "../../../../components/defaultInput/authInput/AuthInput";
import AuthBtn from "../../../../components/button/authBtn/AuthBtn";
import Breadcrumbs from "../../../../components/supamallComp/breadCrumb/BreadCrumb";
import {
  deliveryData,
  nigeriaStates,
  productCategory,
  typeData,
} from "../../../../components/defaultInput/selectInput/SelectData";
import { axiosCalls, axiosFormData } from "../../../../_api";
import { CallCustomToast } from "../../../../utils/CallCustomToast";
import { useDispatch } from "react-redux";
import SelectInputProduct from "../../../../components/defaultInput/selectInputProduct/SelectInputProduct";
import { v4 as uuidv4 } from "uuid";
import { hideLoader, showLoader } from "../../../../loader";

const SupaMallProductForm = () => {
  const [uploadPost, setUploadPost] = useState(false);
  const [uploadStory, setUploadStory] = useState(false);

  const [selectedImages, setSelectedImages] = useState([]);
  const [displaySelectImages, setDisplaySelectedImages] = useState([]);
  const [productCategory, setProductCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubLoading, setIsSubLoading] = useState(false);
  const [onErrorLoadig, setOnErrorLoading] = useState(false);
  const [onErrorSubLoadig, setOnErrorSubLoading] = useState(false);
  const [productSubCategory, setProductSubCategory] = useState(null);
  const [categoryId, setCategoryId] = useState("65ffd6ba8df82dc0c7929f22");
  const accessoriesId = "65fff5fc27fbd26dc2634c29";
  const servicesId = "65ffd6ba8df82dc0c7929f22";
  const jobsId = "65ffd6ce8df82dc0c7929f2a";
  const [productFormData, setProductFormData] = useState({
    category: "",
    subCategory: "",
    location: "",
    type: "",
    quantity: "",
    name: "",
    description: "",
    price: "",
    phoneNumber: "",
    delivery: "",
    promoteAds: true,
  });

  const [productFormDataError, setProductFormDataError] = useState({
    category: "",
    subCategory: "",
    location: "",
    type: "",
    quantity: "",
    name: "",
    description: "",
    price: "",
    phoneNumber: "",
    delivery: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetProductCategory();
    // handleGetMainProductCategory();
  }, []);

  useEffect(() => {
    if (productFormData.category) {
      handleGetProductSubCategory();
    }
  }, [productFormData.category]);

  // const handleGetMainProductCategory = async (e) => {
  //   setIsLoading(true);
  //   setOnErrorLoading(false);
  //   const res = await axiosCalls(
  //     `admin/supamall/category/fetch-categories`,
  //     "GET"
  //   );

  //   if (res) {
  //     console.log(res);
  //     setIsLoading(false);

  //     if (!res?.status) {
  //       setOnErrorLoading(true);

  //       CallCustomToast(dispatch, {
  //         messageHeading: "Error",
  //         toastStatus: "Error",
  //         messageBody: res?.er?.data?.message,
  //       });
  //       return;
  //     } else if (res?.status) {
  //       // setProductCategory(res?.data);
  //       setOnErrorLoading(false);
  //       console.log(res?.data, "caTEGORYYYYYY");
  //     }
  //   }
  // };

  const handleGetProductCategory = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosCalls(
      `admin/supamall/category/fetch-subcategories-by-category?subId=${categoryId}`,
      "GET"
    );

    if (res) {
      console.log(res);
      setIsLoading(false);

      if (!res?.status) {
        setOnErrorLoading(true);

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        setProductCategory(res?.data);
        setOnErrorLoading(false);
        console.log(res?.data, "caTEGORYYYYYY");
      }
    }
  };

  const handleGetProductSubCategory = async (e) => {
    setIsSubLoading(true);
    setOnErrorSubLoading(false);
    const res = await axiosCalls(
      `admin/supamall/category/fetch-subcategories-by-category?subId=${productFormData?.category}`,
      "GET"
    );

    if (res) {
      console.log(res);
      setIsSubLoading(false);

      if (!res?.status) {
        setOnErrorSubLoading(true);

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        setProductSubCategory(res?.data);
        setOnErrorSubLoading(false);
        console.log(res?.data);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductFormData({
      ...productFormData,
      [name]: value,
    });

    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setProductFormDataError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "category":
          if (!value) {
            stateObj[name] = "Please enter select category";
          }
          break;
        case "location":
          if (!value) {
            stateObj[name] = "Please enter location";
          }
          break;
        case "type":
          if (!value) {
            stateObj[name] = "Please enter type";
          }
          break;
        case "quantity":
          if (!value) {
            stateObj[name] = "Please enter quantity";
          }
          break;
        case "name":
          if (!value) {
            stateObj[name] = "Please enter name";
          }
          break;
        case "description":
          if (!value) {
            stateObj[name] = "Please enter description";
          }
          break;
        case "price":
          if (!value) {
            stateObj[name] = "Please enter price";
          }
          break;
        case "phoneNumber":
          if (!value) {
            stateObj[name] = "Please enter phone number";
          }
          break;
        case "delivery":
          if (!value) {
            stateObj[name] = "Please select delivery";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);

    const totalFiles = selectedImages.length + files.length;

    if (totalFiles > 3) {
      alert("You can only upload a maximum of 3 files.");
      return;
    }

    if (files?.length > 0) {
      const newDisplayImages = [...displaySelectImages]; // Maintain existing displayed images
      const newSelectedImages = [...selectedImages]; // Maintain existing selected images

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.type === "video/mp4") {
          // Handle video file
          const reader = new FileReader();
          reader.onloadend = () => {
            // Generate a thumbnail from the video
            const video = document.createElement("video");
            video.src = reader.result;
            video.addEventListener("loadedmetadata", () => {
              // Create a canvas element to generate thumbnail
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");
              canvas.width = 200; // Set thumbnail width
              canvas.height =
                (canvas.width / video.videoWidth) * video.videoHeight;
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              // Convert thumbnail to data URL
              const thumbnail = canvas.toDataURL("image/jpeg");
              newDisplayImages.push(thumbnail); // Add thumbnail to displayed images
              setDisplaySelectedImages([...newDisplayImages]);
            });
          };
          reader.readAsDataURL(file);
        } else {
          // Handle image file
          const reader = new FileReader();
          reader.onloadend = () => {
            newDisplayImages.push(reader.result); // Add image to displayed images
            setDisplaySelectedImages([...newDisplayImages]);
          };
          reader.readAsDataURL(file);
        }

        newSelectedImages.push(file); // Add file to selected images
      }

      setSelectedImages(newSelectedImages);
    }

    console.log(selectedImages, "seletedimage");
  };

  const handleDeleteImagePost = (index) => {
    const updatedSelectedImages = [...selectedImages];
    updatedSelectedImages.splice(index, 1);
    setSelectedImages(updatedSelectedImages);

    const updatedDisplayImages = [...displaySelectImages];
    updatedDisplayImages.splice(index, 1);
    setDisplaySelectedImages(updatedDisplayImages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();

      if (selectedImages?.length === 0) {
        return alert("Please upload product photos");
      }

      const {
        name,
        price,
        description,
        quantity,
        category,
        phoneNumber,
        location,
        promoteAds,
        delivery,
        type,
        subCategory,
      } = productFormData;

      const form = {
        name,
        basePrice: price,
        description,
        inventory: quantity,
        category: category,
        // gender: type,
        // location,
        // phone: phoneNumber,
        // promote: promoteAds,
        sku: `sku${uuidv4()}`,
        subcategory: subCategory,
        // delivery,
      };

      if (!name || !price || !description || !quantity) {
        return alert("Fill all fields to continue");
      }
      formData.append("data", JSON.stringify(form));
      if (selectedImages?.length > 0) {
        selectedImages.forEach((asset, index) => {
          formData.append("attachments", asset);
        });
      }

      console.log("FORM TO SUBMIT>>>", JSON.stringify(formData, null, 2));
      console.log("FORM ", JSON.stringify(formData));

      showLoader();
      setIsLoading(true);
      const res = await axiosFormData(
        "supamall/products/create-product",
        "POST",
        formData
      );
      // console.log('RES HERE>>>', res?.er);

      if (res) {
        setIsLoading(false);
        hideLoader();

        if (!res?.status) {
          console.log(JSON.stringify(res?.er, null, 2));
          CallCustomToast(dispatch, {
            messageHeading: "Error",
            toastStatus: "Error",
            messageBody: res?.er?.data?.message,
          });
          return;
        } else {
          if (res?.status) {
            setIsLoading(false);
            CallCustomToast(dispatch, {
              messageHeading: "Success",
              toastStatus: "Success",
              messageBody: res?.er?.data?.message,
            });

            setSelectedImages([]);
            setProductFormData({
              category: "",
              location: "",
              type: "",
              quantity: "",
              name: "",
              description: "",
              price: "",
              phoneNumber: "",
              delivery: "",
              promoteAds: true,
            });

            setTimeout(() => {
              window.location.href = "/supamall";
            }, 2000);
          }
        }
      }
    } catch (err) {
      console.log("err creating product", { err });

      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "Something went wrong",
      });
    }
  };

  const handlePromoteAdsChange = (value) => {
    setProductFormData({
      ...productFormData,
      promoteAds: value,
    });
  };
  return (
    <div className="supamall_product_form_body_gen">
      <Breadcrumbs pathArray={["Home", "SupaMall", "Product Form"]} />
      <form className="supamall_product_form_body_form_gen">
        <div className="supamall_product_form_body_text_add__">Post an Ad</div>
        {/* <SelectInput
          rolesData={productCategory}
          selectLabel="Category"
          width="100%"
          required={true}
          selectOnchange={handleChange}
          selectValue={productFormData?.category}
          selectName="category"
        /> */}

        <SelectInputProduct
          rolesData={productCategory}
          selectLabel="Category"
          width="100%"
          required={true}
          selectOnchange={handleChange}
          selectValue={productFormData?.category}
          selectName="category"
        />

        <SelectInputProduct
          rolesData={productSubCategory}
          selectLabel="Sub-category"
          width="100%"
          required={true}
          selectOnchange={handleChange}
          selectValue={productFormData?.subCategory}
          selectName="subCategory"
        />

        <SelectInput
          selectLabel="Location"
          selectOnchange={handleChange}
          selectValue={productFormData?.location}
          selectName="location"
          rolesData={nigeriaStates}
          width="100%"
          required={true}
        />

        <div className="supamall_product_form_addImages_gen">
          Add image{" "}
          <span
            style={{ color: "#F41010", fontSize: "13px", fontWeight: "600" }}
          >
            *{" "}
          </span>
        </div>
        <div className="supamall_product_form_addImages_top_head">
          At least add 1 or 2 images
        </div>
        <div className="supamall_product_form_addImages_flex_cards">
          <div className="supamall_product_form_addImages_card_add">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
            +
          </div>
          <div className="supamall_product_form_addImages_card_add">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
            +
          </div>
          <div className="supamall_product_form_addImages_card_add">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
            +
          </div>
        </div>

        {displaySelectImages?.length > 0 && (
          <div className="post_upload_uploaded_img_body">
            {displaySelectImages?.map((image, index) => (
              <div className="post_upload_uploaded_img_each" key={index}>
                {image?.name && /\.(mp4|webm|ogg)$/i.test(image?.name) ? (
                  <video controls className="uploaded-video">
                    <source
                      src={URL?.createObjectURL(image)}
                      type={image?.type || "video/mp4"}
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={image}
                    alt={`Uploaded ${index + 1}`}
                    className="uploaded-image"
                  />
                )}
                <div
                  onClick={() => {
                    handleDeleteImagePost(index);
                  }}
                  className="post_upload_cancel_icon"
                >
                  x
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="supamall_product_form_addImages_upload_card_body">
          <div className="supamall_product_form_addImages_upload_card_info">
            <img src={info_supamall_product_add} />
          </div>
          <div className="supamall_product_form_addImages_upload_card_text">
            Supported formats are .jpg, .gif and .png, 5MB max
          </div>
        </div>

        <div className="supamall_product_form_select_gen__flex">
          <SelectInput
            width="50%"
            selectLabel="Type"
            required={true}
            selectOnchange={handleChange}
            selectValue={productFormData?.type}
            selectName="type"
            rolesData={typeData}
          />

          <AuthInput
            width="50%"
            inputLabel="Quantity"
            required={true}
            inputPlaceHolder="Enter quantity"
            inputName="quantity"
            inputOnchange={handleChange}
            inputType="text"
            inputValue={productFormData?.quantity}
            error={productFormDataError?.quantity}
            validateInput={validateInput}
          />
        </div>
        <AuthInput
          width="100%"
          inputLabel="Name"
          required={true}
          inputPlaceHolder="Enter product name"
          inputName="name"
          inputOnchange={handleChange}
          inputType="text"
          inputValue={productFormData?.name}
          error={productFormDataError?.name}
          validateInput={validateInput}
        />
        <DefaultTextArea
          width="100%"
          inputPlaceholder="Enter your product description"
          required={true}
          inputLabel="Description"
          inputName="description"
          inputOnchange={handleChange}
          inputValue={productFormData?.description}
          error={productFormDataError?.description}
          validateInput={validateInput}
        />

        <div className="supamall_product_form_select_gen__flex">
          <AuthInput
            width="50%"
            inputLabel="Price"
            required={true}
            inputPlaceHolder="N"
            inputName="price"
            inputOnchange={handleChange}
            inputType="number"
            inputValue={productFormData?.price}
            error={productFormDataError?.price}
            validateInput={validateInput}
          />
          <AuthInput
            width="50%"
            inputLabel="Your phone number"
            required={true}
            inputPlaceHolder="Enter phone number"
            inputName="phoneNumber"
            inputOnchange={handleChange}
            inputType="number"
            inputValue={productFormData?.phoneNumber}
            error={productFormDataError?.phoneNumber}
            validateInput={validateInput}
          />
        </div>

        <SelectInput
          required={true}
          selectOnchange={handleChange}
          selectValue={productFormData?.delivery}
          selectName="delivery"
          rolesData={deliveryData}
          selectLabel="Delivery"
          width="100%"
        />
        <div className="supamall_product_form_select_promote_adssss">
          Promote Ads ?
        </div>

        <ul className="dropdown-menu">
          <li>
            <label>
              <input
                type="checkbox"
                checked={productFormData?.promoteAds === true}
                onChange={() => handlePromoteAdsChange(true)}
              />
              Yes
            </label>
          </li>
          <li>
            <label>
              <input
                type="checkbox"
                checked={productFormData?.promoteAds === false}
                onChange={() => handlePromoteAdsChange(false)}
              />
              No
            </label>
          </li>
        </ul>

        <div className="supamall_product_form_select_authBtn_body__">
          <AuthBtn handleClick={handleSubmit} width="150px" btnText="Post Ad" />
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default SupaMallProductForm;
