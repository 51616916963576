import React from "react";
import "./interests.css";
import HomeMargin from "../../../components/homeMargin/HomeMargin";
import InnerAccordionHeader from "../../../components/innerAccordionHeader/InnerAccordionHeader";
import { heart_head } from "../../../components/images";
import InterestCard from "../../../components/interestComp/interestCard/InterestCard";

const Interests = () => {
  return (
    <HomeMargin>
      <InnerAccordionHeader
        headerText="Interest"
        ringIcon={heart_head}
        subText=" Choose your interests to personalize your experience"
      />

      <InterestCard />
    </HomeMargin>
  );
};

export default Interests;
