import React from "react";
import "./selectInputProduct.css";

const SelectInputProduct = ({
  selectLabel,
  width,
  selectName,
  selectId,
  selectValue,
  selectOnchange,
  selectDisabled,
  rolesData,
  required,
  validateInput,
  error,
}) => {
  return (
    <div style={{ width }} className="select_tag_container">
      <div className="select_tag_container_label">
        {selectLabel}{" "}
        {required && (
          <span
            style={{ color: "#F41010", fontSize: "13px", fontWeight: "600" }}
          >
            *
          </span>
        )}
      </div>
      <div className="select_tag_input_container">
        <select
          name={selectName}
          id={selectId}
          value={selectValue}
          onChange={selectOnchange}
          className="selectTag_sel"
          disabled={selectDisabled}
          style={{
            background: selectDisabled ? "#F7FAFC" : "#fff",
            color: selectDisabled ? "#CBD5E0" : "#2D3748",
            cursor: selectDisabled && "not-allowed",
          }}
          required={required}
          onBlur={validateInput}
        >
          <option value="">Select</option>
          {rolesData?.map((roles) => (
            <option key={roles?.name} value={roles?._id}>
              {roles?.name}
            </option>
          ))}
          {/* <option value="option1">Option 1</option>
          <option value="option2">Option 2</option>
          <option value="option3">Option 3</option> */}
        </select>
      </div>
      {error && <div className="auth_dinput_error_message">{error}</div>}
    </div>
  );
};

export default SelectInputProduct;
