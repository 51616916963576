// socketService.js
import { io } from "socket.io-client";

const token = localStorage.getItem("token");

const socket = io(`https://feedfanout.talkstuff.social?token=${token}`);

const subscribeToEvent = (event, callback) => {
  socket.on(event, callback);
};

// socket.on()

const emitEvent = (event, data) => {
  socket.emit(event, data);
};

const disconnectSocket = () => {
  socket.disconnect();
};

export { subscribeToEvent, emitEvent, disconnectSocket };
