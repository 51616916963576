import React, { useState } from "react";
import "./postCardGridShow.css";
import {
  mediaPlayer,
  post_oneImage,
  post_threeImage,
  post_twoImage,
} from "../../images";
import { useDispatch, useSelector } from "react-redux";
import {
  handlePreviewPostMedia,
  handleStatusPreviewPostMedia,
} from "../../../redux/userData";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const baseImageUrl = "https://talkstuff-store.fra1.cdn.digitaloceanspaces.com";
export const PostCardOne = ({ images }) => {
  const dispatch = useDispatch();
  return (
    <div className="post_card_grid_showBody">
      <div
        onClick={() => {
          dispatch(handlePreviewPostMedia(images));
          dispatch(handleStatusPreviewPostMedia(true));
        }}
        className="post_card_grid_showBodyfirstImg"
      >
        {images[0]?.mimeType === "video" ? (
          <div className="post_card_video_play_show_parent">
            <div className="post_card_video_play_show">
              <img src={mediaPlayer} />
            </div>
            <div className="post_card_video_play_show_thumbNail">
              <LazyLoadImage
                src={`${baseImageUrl}${images[0]?.thumbNail}`}
                effect="blur"
                wrapperProps={{
                  style: { transitionDelay: "0.5s" },
                }}
              />

              {/* <img src={`${baseImageUrl}${images[0]?.thumbNail}`} /> */}
            </div>
          </div>
        ) : (
          <LazyLoadImage
            src={`${baseImageUrl}${images[0]?.url}`}
            effect="blur"
            wrapperProps={{
              style: { transitionDelay: "0.5s" },
            }}
          />

          // <img src={`${baseImageUrl}${images[0]?.url}`} />
        )}
      </div>
    </div>
  );
};

export const PostCardTwo = ({ images }) => {
  const dispatch = useDispatch();
  return (
    <div className="post_card_grid_showBodypostCardTwo">
      <div
        onClick={() => {
          dispatch(handlePreviewPostMedia(images));
          dispatch(handleStatusPreviewPostMedia(true));
        }}
        className="post_card_grid_showBody_one"
      >
        {images[0]?.mimeType === "video" ? (
          <div className="post_card_video_play_show_parent">
            <div className="post_card_video_play_show">
              <img src={mediaPlayer} />
            </div>
            <div className="post_card_video_play_show_thumbNail">
              <LazyLoadImage
                src={`${baseImageUrl}${images[0]?.thumbNail}`}
                effect="blur"
                wrapperProps={{
                  style: { transitionDelay: "0.5s" },
                }}
              />
            </div>
          </div>
        ) : (
          <LazyLoadImage
            src={`${baseImageUrl}${images[0]?.url}`}
            effect="blur"
            wrapperProps={{
              style: { transitionDelay: "0.5s" },
            }}
          />
        )}
      </div>
      <div
        onClick={() => {
          dispatch(handlePreviewPostMedia(images));
          dispatch(handleStatusPreviewPostMedia(true));
        }}
        className="post_card_grid_showBody_two"
      >
        {images[1]?.mimeType === "video" ? (
          <div className="post_card_video_play_show_parent">
            <div className="post_card_video_play_show">
              <img src={mediaPlayer} />
            </div>
            <div className="post_card_video_play_show_thumbNail">
              <LazyLoadImage
                src={`${baseImageUrl}${images[1]?.thumbNail}`}
                effect="blur"
                wrapperProps={{
                  style: { transitionDelay: "0.5s" },
                }}
              />
            </div>
          </div>
        ) : (
          <LazyLoadImage
            src={`${baseImageUrl}${images[1]?.url}`}
            effect="blur"
            wrapperProps={{
              style: { transitionDelay: "0.5s" },
            }}
          />
        )}
      </div>
    </div>
  );
};

export const PostCardThree = ({ images }) => {
  const dispatch = useDispatch();
  return (
    <div className="post_card_grid_showBody">
      <div
        onClick={() => {
          dispatch(handlePreviewPostMedia(images));
          dispatch(handleStatusPreviewPostMedia(true));
        }}
        className="post_card_grid_showBodyThree_one"
      >
        {images[0]?.mimeType === "video" ? (
          <div className="post_card_video_play_show_parent">
            <div className="post_card_video_play_show">
              <img src={mediaPlayer} />
            </div>
            <div className="post_card_video_play_show_thumbNail">
              <LazyLoadImage
                src={`${baseImageUrl}${images[0]?.thumbNail}`}
                effect="blur"
                wrapperProps={{
                  style: { transitionDelay: "0.5s" },
                }}
              />
            </div>
          </div>
        ) : (
          <LazyLoadImage
            src={`${baseImageUrl}${images[0]?.url}`}
            effect="blur"
            wrapperProps={{
              style: { transitionDelay: "0.5s" },
            }}
          />
        )}
      </div>
      <div className="post_card_grid_showBodyThree_twoBody">
        <div
          onClick={() => {
            dispatch(handlePreviewPostMedia(images));
            dispatch(handleStatusPreviewPostMedia(true));
          }}
          className="post_card_grid_showBodyThree_two"
        >
          {images[1]?.mimeType === "video" ? (
            <div className="post_card_video_play_show_parent">
              <div className="post_card_video_play_show">
                <img src={mediaPlayer} />
              </div>
              <div className="post_card_video_play_show_thumbNail">
                <LazyLoadImage
                  src={`${baseImageUrl}${images[1]?.thumbNail}`}
                  effect="blur"
                  wrapperProps={{
                    style: { transitionDelay: "0.5s" },
                  }}
                />
              </div>
            </div>
          ) : (
            <LazyLoadImage
              src={`${baseImageUrl}${images[1]?.url}`}
              effect="blur"
              wrapperProps={{
                style: { transitionDelay: "0.5s" },
              }}
            />
          )}
        </div>
        <div
          onClick={() => {
            dispatch(handlePreviewPostMedia(images));
            dispatch(handleStatusPreviewPostMedia(true));
          }}
          className="post_card_grid_showBodyThree_three"
        >
          {images[2]?.mimeType === "video" ? (
            <div className="post_card_video_play_show_parent">
              <div className="post_card_video_play_show">
                <img src={mediaPlayer} />
              </div>
              <div className="post_card_video_play_show_thumbNail">
                <LazyLoadImage
                  src={`${baseImageUrl}${images[2]?.thumbNail}`}
                  effect="blur"
                  wrapperProps={{
                    style: { transitionDelay: "0.5s" },
                  }}
                />
              </div>
            </div>
          ) : (
            <LazyLoadImage
              src={`${baseImageUrl}${images[2]?.url}`}
              effect="blur"
              wrapperProps={{
                style: { transitionDelay: "0.5s" },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const PostCardFour = ({ images }) => {
  const dispatch = useDispatch();
  return (
    <div className="post_card_grid_showBody">
      <div className="post_card_grid_showBodyFour_oneBody">
        <div
          onClick={() => {
            dispatch(handlePreviewPostMedia(images));
            dispatch(handleStatusPreviewPostMedia(true));
          }}
          className="post_card_grid_showBodyFour_one"
        >
          {images[0]?.mimeType === "video" ? (
            <div className="post_card_video_play_show_parent">
              <div className="post_card_video_play_show">
                <img src={mediaPlayer} />
              </div>
              <div className="post_card_video_play_show_thumbNail">
                <LazyLoadImage
                  src={`${baseImageUrl}${images[0]?.thumbNail}`}
                  effect="blur"
                  wrapperProps={{
                    style: { transitionDelay: "0.5s" },
                  }}
                />
              </div>
            </div>
          ) : (
            <LazyLoadImage
              src={`${baseImageUrl}${images[0]?.url}`}
              effect="blur"
              wrapperProps={{
                style: { transitionDelay: "0.5s" },
              }}
            />
          )}
        </div>
        <div
          onClick={() => {
            dispatch(handlePreviewPostMedia(images));
            dispatch(handleStatusPreviewPostMedia(true));
          }}
          className="post_card_grid_showBodyFour_two"
        >
          {images[1]?.mimeType === "video" ? (
            <div className="post_card_video_play_show_parent">
              <div className="post_card_video_play_show">
                <img src={mediaPlayer} />
              </div>
              <div className="post_card_video_play_show_thumbNail">
                <LazyLoadImage
                  src={`${baseImageUrl}${images[1]?.thumbNail}`}
                  effect="blur"
                  wrapperProps={{
                    style: { transitionDelay: "0.5s" },
                  }}
                />
              </div>
            </div>
          ) : (
            <LazyLoadImage
              src={`${baseImageUrl}${images[1]?.url}`}
              effect="blur"
              wrapperProps={{
                style: { transitionDelay: "0.5s" },
              }}
            />
          )}
        </div>
      </div>

      <div className="post_card_grid_showBodyFour_secondBody">
        <div
          onClick={() => {
            dispatch(handlePreviewPostMedia(images));
            dispatch(handleStatusPreviewPostMedia(true));
          }}
          className="post_card_grid_showBodyFour_secondOne"
        >
          {images[2]?.mimeType === "video" ? (
            <div className="post_card_video_play_show_parent">
              <div className="post_card_video_play_show">
                <img src={mediaPlayer} />
              </div>
              <div className="post_card_video_play_show_thumbNail">
                <LazyLoadImage
                  src={`${baseImageUrl}${images[2]?.thumbNail}`}
                  effect="blur"
                  wrapperProps={{
                    style: { transitionDelay: "0.5s" },
                  }}
                />
              </div>
            </div>
          ) : (
            <LazyLoadImage
              src={`${baseImageUrl}${images[2]?.url}`}
              effect="blur"
              wrapperProps={{
                style: { transitionDelay: "0.5s" },
              }}
            />
          )}
        </div>
        <div
          onClick={() => {
            dispatch(handlePreviewPostMedia(images));
            dispatch(handleStatusPreviewPostMedia(true));
          }}
          className="post_card_grid_showBodyFour_secondTwoGen"
        >
          {images[3]?.mimeType === "video" ? (
            <div className="post_card_grid_showBodyFour_secondTwo">
              {images?.length > 4 && (
                <div className="post_card_video_length_cover">
                  +{images.length - 4}
                </div>
              )}
              <div className="post_card_video_play_show_parent">
                <div className="post_card_video_play_show">
                  <img src={mediaPlayer} />
                </div>
                <div className="post_card_video_play_show_thumbNail">
                  <LazyLoadImage
                    src={`${baseImageUrl}${images[3]?.thumbNail}`}
                    effect="blur"
                    wrapperProps={{
                      style: { transitionDelay: "0.5s" },
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="post_card_grid_showBodyFour_secondTwo">
              {images?.length > 4 && (
                <div className="post_card_video_length_cover">
                  +{images.length - 4}
                </div>
              )}
              <LazyLoadImage
                src={`${baseImageUrl}${images[3]?.url}`}
                effect="blur"
                wrapperProps={{
                  style: { transitionDelay: "0.5s" },
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
