import React from "react";
import "./photographyServices.css";
import Breadcrumbs from "../../../../components/supamallComp/breadCrumb/BreadCrumb";
import HomeFourtTrendingSection from "../../../../components/supamallComp/homeFourtTrendingSection/HomeFourtTrendingSection";
import Footer from "../../../../components/supamallComp/footer/Footer";

const PhotographyServices = () => {
  return (
    <div className="supermarket_category_bodyGennn_conta">
      <Breadcrumbs />

      <HomeFourtTrendingSection />

      <Footer />
    </div>
  );
};

export default PhotographyServices;
