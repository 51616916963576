import React, { useState, useEffect } from "react";
import "./privacyPolicy.css";
import { useLocation, useNavigate } from "react-router-dom";
import AuthBodyMargin from "../../../components/authBodyMargin/AuthBodyMargin";
import { newSingleLogo, talk_stuff_authLogo } from "../../../components/images";
import LegalBodyComponent from "../../../components/legalBodyComponent/LegalBodyComponent";
import GoBackBtn from "../../../components/button/gobackBtn/GoBackBtn";
import { motion } from "framer-motion";

const PrivacyPolicy = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const privacyPolicyData = [
    {
      heading: "",
      paragraph:
        "Users should carefully review and agree to the Privacy Policy, which outlines how their data is collected, processed, and protected. The Privacy Policy provides detailed information on data handling practices, security measures, and user rights.",
    },
    {
      heading: "Ads Promotions:",
      paragraph:
        "TalkStuff may display advertisements and promotions within the app. Users agree to the terms set by advertisers, and TalkStuff is not responsible for the content or offerings of third-party advertisements.",
    },
    {
      heading: "Employment and Shopping Services:",
      paragraph:
        "Users engaging in employment or shopping services within TalkStuff are subject to additional terms specified in those services. Specific terms related to employment opportunities or shopping transactions will be outlined separately for user understanding.",
    },
    {
      heading: "Termination:",
      paragraph:
        "TalkStuff reserves the right to terminate accounts for violation of terms. Account termination may result from breaches of these terms, and users will be notified of the reason for termination.",
    },
    {
      heading: "Dispute Resolution:",
      paragraph:
        "Disputes will be resolved through arbitration in accordance with applicable laws. Any disputes arising between users and TalkStuff will be settled through a fair and impartial arbitration process.",
    },
    {
      heading: "Modifications:",
      paragraph:
        "TalkStuff may update these terms, and users will be notified of significant changes. Users are encouraged to regularly review the terms, and major updates will be communicated to users in a timely manner.",
    },
    {
      heading: "Limitation of Liability:",
      paragraph:
        "TalkStuff is not liable for any direct or indirect damages resulting from app usage. Users acknowledge that TalkStuff shall not be held responsible for any losses or damages incurred while using the app.",
    },
  ];

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <AuthBodyMargin>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5 }}
        className="privacy_policy_heading_logo"
      >
        <img src={newSingleLogo} />
      </motion.div>

      {/* <GoBackBtn handleGoBack={handleGoBack} /> */}
      <div className="terms_condi_bodyyy">
        <div className="privacy_policy_heading">Privacy Policy</div>

        {privacyPolicyData.map((data, index) => (
          <LegalBodyComponent
            paragraphBody={data?.paragraph}
            paragraphHeading={data?.heading}
          />
        ))}
      </div>
    </AuthBodyMargin>
  );
};

export default PrivacyPolicy;
