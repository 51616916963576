import React, { useEffect, useState } from "react";
import "./navbar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  align_left,
  bell,
  film,
  heart,
  messages_square,
  search,
  secondary_language_img,
  store,
  talk_stuffnav_logo,
} from "../../images";
import {
  handleMobileStoryModal,
  handleShowChatBox,
  handleShowGeneralSearch,
  handleToggleNotification,
} from "../../../redux/userData";
import { useNavigate, useLocation } from "react-router-dom";
import Burger from "../mobileNav/Burger";
import Store from "../../images/files/Store";
import Heart from "../../images/files/Heart";

const Navbar = ({ headerName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  // const { openMobileNavbar } = useSelector(
  //   (state) => state.openMobileNavbarBody.openMobileNavbarBody
  // );

  const controlNavbar = () => {
    if (window.scrollY > 70) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);
  return (
    <>
      {screenWidth < 850 ? (
        <div className="mobilenavbar-optMenuFam">
          <div>
            <Burger open={open} setOpen={setOpen} />
          </div>
          {/* <div
            onClick={() => {
              navigate("/");
              dispatch(handleShowChatBox(false));
            }}
            className="navbar_logo_"
          >
            <img src={talk_stuffnav_logo} />
          </div> */}

          <div className="navbar_last_flex_body">
            <div
              onClick={() => {
                dispatch(handleMobileStoryModal(true));
              }}
              className="navbar_last_flex_searchBody"
            >
              <img src={film} />
            </div>
            <div
              onClick={() => {
                dispatch(handleShowGeneralSearch(true));
                dispatch(handleShowChatBox(false));
              }}
              className="navbar_last_flex_searchBody"
            >
              <img src={search} />
            </div>

            <div
              onClick={() => {
                dispatch(handleToggleNotification(true));
                dispatch(handleShowChatBox(false));

                // handleToggleNotification(true);
              }}
              className="navbar_last_flex_searchBody"
            >
              <img src={bell} />
            </div>
            {/* <div className="navbar_last_flex_search_countryFlag">
              <img src={secondary_language_img} />
            </div>
            <div className="navbar_language_select_body">
              <select>
                <option value="1">English</option>
                <option value="2"> Spanish</option>
                <option value="3">French</option>
                <option value="4">Arabic</option>
              </select>
            </div> */}
          </div>
        </div>
      ) : (
        <div className="navbar_body">
          <div
            onClick={() => {
              navigate("/");
            }}
            className="navbar_logo_"
          >
            <img src={talk_stuffnav_logo} />
          </div>

          <div className="navbar_middleflex_body">
            <div
              onMouseEnter={() => setHoveredIcon("store")}
              onMouseLeave={() => setHoveredIcon(null)}
              onClick={() => {
                navigate("/supamall");
              }}
              className="navbar_middleflex_icon_body"
            >
              <div className="navbar_middleflex_icon">
                {/* <img src={store} /> */}
                <Store
                  fill={
                    location.pathname.startsWith("/supamall") ||
                    hoveredIcon === "store"
                      ? "#EF6924"
                      : "#525866"
                  }
                />
              </div>
              <div
                style={{
                  color: location.pathname.startsWith("/supamall") && "#EF6924",
                }}
                className="navbar_middleflex_icon_text"
              >
                SupaMall
              </div>
            </div>

            <div
              onClick={() => {
                navigate("/chat");
              }}
              className="navbar_message_body"
            >
              <div className="navbar_message_body_icon">
                <img src={messages_square} />
              </div>
            </div>
            <div
              onClick={() => {
                navigate("/luvhub/home");
              }}
              onMouseEnter={() => setHoveredIcon("heart")}
              onMouseLeave={() => setHoveredIcon(null)}
              className="navbar_middleflex_icon_body"
            >
              <div className="navbar_middleflex_icon">
                {/* <img src={heart} /> */}

                <Heart
                  fill={
                    location.pathname.startsWith("/luvhub") ||
                    hoveredIcon === "heart"
                      ? "#EF6924"
                      : "#525866"
                  }
                />
              </div>
              <div
                style={{
                  color: location.pathname.startsWith("/luvhub") && "#EF6924",
                }}
                className="navbar_middleflex_icon_text"
              >
                LuvHub
              </div>
            </div>
          </div>

          <div className="navbar_last_flex_body">
            <div
              onClick={() => {
                dispatch(handleShowGeneralSearch(true));
              }}
              className="navbar_last_flex_searchBody"
            >
              <img src={search} />
            </div>
            <div className="navbar_last_flex_search_countryFlag">
              <img src={secondary_language_img} />
            </div>
            <div className="navbar_language_select_body">
              <select>
                <option value="1">English</option>
                <option value="2"> Spanish</option>
                <option value="3">French</option>
                <option value="4">Arabic</option>
              </select>
            </div>
          </div>
          <div className="navbar_bottom_border"></div>
        </div>
      )}
    </>
  );
};

export default Navbar;
