import React from "react";
import "./eachNotificationCard.css";
import { default_notification_pics } from "../../images";
import useFormattedFullTime from "../../../utils/useFormattedFullTime";

const EachNotificationCard = ({ data }) => {
  const { createdAt, actor, message, title } = data;

  const formattedTime = useFormattedFullTime(createdAt);
  return (
    <div className="each_notification_section_body">
      <div className="each_notification_section_body_left">
        {actor?.profileImage ? (
          <div className="post_card_caption_text_info_head_image_profile">
            <img src={`${actor?.profileImage}`} />
          </div>
        ) : (
          <div className="profile_tab_body_details_image_body">
            <div className="profile_tab_body_details_image">
              {actor?.firstName ??
                "" // if profile_pic is null, show only the first letters of the names
                  ?.split(" ")
                  ?.map((name) => name.charAt(0).toUpperCase())
                  .join("")}
              {actor?.lastName ??
                "" // if profile_pic is null, show only the first letters of the names
                  ?.split(" ")
                  ?.map((name) => name.charAt(0).toUpperCase())
                  .join("")}
            </div>
          </div>
        )}

        <div className="each_notification_section_body_nameSection">
          <div className="each_notification_section_body_nameSection_name">
            {actor?.firstName ?? ""} {actor?.lastName ?? ""}
          </div>
          <div className="each_notification_section_body_nameSection_tag">
            {message ?? ""}
          </div>
          <div className="each_notification_section_body_nameSection_time">
            {formattedTime}
          </div>
        </div>
      </div>

      <div className="each_notification_active_dot"></div>
    </div>
  );
};

export default EachNotificationCard;
