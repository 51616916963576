import React, { useState, useEffect } from "react";
import "./supamallEachProduct.css";
import Footer from "../../../components/supamallComp/footer/Footer";
import SupaMallEachSectionOne from "../../../components/supamallComp/supmallEachSectionOne/SupaMallEachSectionOne";
import { axiosCalls } from "../../../_api";
import { hideLoader, showLoader } from "../../../loader";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SupamallEachSectionTwo from "../supamallEachSectionTwo/SupamallEachSectionTwo";

const SupaMallEachProduct = () => {
  const [productData, setProductData] = useState(null);
  const [loadUserData, setLoadUserData] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [onUserError, setOnUserError] = useState(false);
  const { eachProduct } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    getProductData();
  }, []);

  const getProductData = async () => {
    setIsProductLoading(true);
    showLoader();

    const res = await axiosCalls(
      `supamall/products/get-product-details?productId=${eachProduct}`,
      "GET"
    );

    if (res) {
      hideLoader();

      setIsProductLoading(false);
      if (res?.er) {
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });

        return;
      } else if (res?.status) {
        setUserId(res?.product?.userId);
        setProductData(res);
        setLoadUserData(true);
      }
    }
  };

  return (
    <div className="supamall_each_home_body_gen">
      <SupaMallEachSectionOne
        isProductLoading={isProductLoading}
        productData={productData}
      />

      <SupamallEachSectionTwo productData={productData} />

      <Footer />
    </div>
  );
};

export default SupaMallEachProduct;
