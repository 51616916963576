import React from "react";
import "./postCard.css";
import PostCardImage from "./postCardImage/PostCardImage";
import PostCardCaption from "./postCardCaption/PostCardCaption";
import PostCardEngage from "./postCardEngage/PostCardEngage";
import PostCardTopInfo from "./postCardTopInfo/PostCardTopInfo";
import PostCardComponent from "../postCardComponent/PostCardComponent";
import {
  minigadget,
  oneMinutes,
  post_fourImage,
  post_oneImage,
  post_threeImage,
  post_twoImage,
  sampleVid,
} from "../images";
import { axiosCalls } from "../../_api";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AuthBtn from "../button/authBtn/AuthBtn";
import { FaLeaf } from "react-icons/fa";
import { CallCustomToast } from "../../utils/CallCustomToast";
import { useDispatch } from "react-redux";
import { subscribeToEvent, disconnectSocket } from "../../utils/socketService";
import { io } from "socket.io-client";

const token = localStorage.getItem("token");

const socket = io(`https://feedfanout.talkstuff.social?token=${token}`);

const PostCard = () => {
  const [userPost, setUserPost] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorLoading, setOnErrorLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetUserPost();
    window.addEventListener("scroll", handleScroll);

    // Subscribe to real-time updates
    // subscribeToEvent("newFeed", handleNewFeed);
    // subscribeToEvent("feedInteraction", handleFeedInteraction);

    socket.on("connect", () => {
      console.log("Connected to Socket.io server");
    });

    socket.on("feed-created", (data) => {
      handleNewFeed(JSON.parse(data));
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      // disconnectSocket();

      socket.on("disconnect", () => {
        console.log("Disconnected from Socket.IO Server");
      });
    };
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 500
    ) {
      handleGetUserPost();
    }
  };

  const handleGetUserPost = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosCalls(
      `feeds/fetch-feeds?page=${currentPage}&limit=10`,
      "GET"
    );

    if (res) {
      console.log(res);
      setIsLoading(false);

      if (!res?.status) {
        // console.log(res?.er.data?.message);
        setOnErrorLoading(true);

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        setUserPost((prevPosts) => [...prevPosts, ...res.data]);
        setCurrentPage((prevPage) => prevPage + 1);
        setOnErrorLoading(false);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };

  const handleNewFeed = (newFeed) => {
    setUserPost((prevPosts) => [newFeed, ...prevPosts]);
  };

  const handleFeedInteraction = (updatedFeed) => {
    setUserPost((prevPosts) =>
      prevPosts.map((post) =>
        post._id === updatedFeed._id ? updatedFeed : post
      )
    );
  };

  return (
    <div className="post_card_body____">
      {isLoading ? (
        // Show skeleton while loading
        <Skeleton count={5} height={500} />
      ) : (
        // Show user posts when loaded
        userPost?.map((data, index) => (
          <PostCardComponent
            userPost={data}
            index={index}
            postCaption={data?.caption || <Skeleton count={2} />}
            postUser={data?.createdBy}
            showFollowBtn={true}
            imageFiles={data?.attachments || <Skeleton />}
          />
        ))
      )}
      {onErrorLoading && (
        <div className="network_xerror_body">
          Network error{" "}
          <AuthBtn
            width="200px"
            btnText="Reload"
            handleClick={handleGetUserPost}
          />
        </div>
      )}
    </div>
  );
};

export default PostCard;
