import React from "react";
import "./helpSupportLinksCardRack.css";
import { chevron_righttt } from "../../images";

const HelpSupportLinksCardRack = () => {
  return (
    <div className="help_support_links_card_rackBody">
      <div className="help_support_links_card_rack_text">FAQs</div>

      <div className="help_support_links_card_rack_chevBod">
        <div className="help_support_links_card_rack_chevBodText">View</div>
        <div className="help_support_links_card_rack_chevBod_icon">
          <img src={chevron_righttt} />
        </div>
      </div>
    </div>
  );
};

export default HelpSupportLinksCardRack;
