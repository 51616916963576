// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.white_btn_body {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
  margin-top: 16px;
}

.white_btn_body:hover {
  background: #f6f6fa;
}

.white_btn_body input {
  font-family: "Open Sans", sans-serif;

  width: 100%;
  height: 100%;
  box-sizing: border-box;
  outline: none;
  border-radius: 10px;
  /* border: 1px solid #e7e7e8; */
  border: none;
  background: #fff;
  /* padding: 10px 18px 10px 34px; */

  color: #525866;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  box-shadow: 0px 0.9918952584266663px 1.9837905168533325px 0px #5258660d;
  border: 1px solid #e2e4e9;
}
`, "",{"version":3,"sources":["webpack://./src/components/button/whiteBtn/whiteBtn.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,eAAe;EACf,+BAA+B;EAC/B,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;;EAEpC,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,+BAA+B;EAC/B,YAAY;EACZ,gBAAgB;EAChB,kCAAkC;;EAElC,cAAc;;EAEd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,uEAAuE;EACvE,yBAAyB;AAC3B","sourcesContent":[".white_btn_body {\n  width: 100%;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  cursor: pointer;\n  transition: 0.3s ease-in-out 0s;\n  margin-top: 16px;\n}\n\n.white_btn_body:hover {\n  background: #f6f6fa;\n}\n\n.white_btn_body input {\n  font-family: \"Open Sans\", sans-serif;\n\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  outline: none;\n  border-radius: 10px;\n  /* border: 1px solid #e7e7e8; */\n  border: none;\n  background: #fff;\n  /* padding: 10px 18px 10px 34px; */\n\n  color: #525866;\n\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 400;\n  cursor: pointer;\n  box-shadow: 0px 0.9918952584266663px 1.9837905168533325px 0px #5258660d;\n  border: 1px solid #e2e4e9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
