import React, { useState, useEffect } from "react";
import "./commentsModal.css";
import InfoModal from "../../infoModal/InfoModal";
import { useSelector, useDispatch } from "react-redux";
import { handleStatusPostComment } from "../../../redux/userData";
import AuthBtn from "../../button/authBtn/AuthBtn";
import { axiosCalls } from "../../../_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MutatingDots } from "react-loader-spinner";
import ScreenWidth from "../../../utils/ScreenWidth";
import { CallCustomToast } from "../../../utils/CallCustomToast";

const CommentsModal = ({ userPost }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingComment, setIsLoadingComment] = useState(false);
  const [showReplyComment, setShowReplyComment] = useState(false);
  const [postComment, setPostComment] = useState([]);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [selectedCommentReplyText, setSelectedCommentReplyText] =
    useState(null);
  const [selectedCommentReplyId, setSelectedCommentReplyId] = useState("");
  const [isLoadingCommentReply, setIsLoadingCommentReply] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState(true);

  const storedJsonString = localStorage.getItem("userData");
  const storedObject = JSON.parse(storedJsonString);
  const [formValue, setFormValue] = useState({
    comment: "",
    replyComment: "",
  });

  useEffect(() => {
    handleGetFeedComment();

    // console.log(showAllPostComment, "cjjjdjjjsjd");
  }, []);

  const { showAllPostComment } = useSelector(
    (state) => state.showAllPostCommentBody.showAllPostCommentBody
  );
  useEffect(() => {
    if (selectedCommentReplyId !== "") {
      handleGetFeedCommentReply();
    }
  }, [selectedCommentReplyId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const handleShowReplyComment = (status) => {
    setShowReplyComment(status);
  };

  const { previewPostComment } = useSelector(
    (state) => state.previewPostCommentBody.previewPostCommentBody
  );

  const handleCommentFeed = async (e) => {
    const data = {
      feedId: previewPostComment?._id,
      comment: formValue?.comment,
      type: "comment",
    };

    setIsLoading(true);
    const res = await axiosCalls("feeds/comment-feed", "POST", data);

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        toast.error(res?.er?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      } else if (res?.status) {
        // onSuccessPostImages();
        // onSuccessMessagePost(res?.message);
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });

        setFormValue({ ...formValue, comment: "" });
      }
    }
  };

  const handleReplyCommentFeed = async (e) => {
    const data = {
      feedId: previewPostComment?._id,
      comment: formValue?.replyComment,
      commentId: selectedCommentId,
      type: "reply",
    };

    setIsLoading(true);
    const res = await axiosCalls("feeds/comment-feed", "POST", data);

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        // onSuccessPostImages();
        // onSuccessMessagePost(res?.message);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });

        setFormValue({ ...formValue, replyComment: "" });
        setShowReplyInput(true);
        setSelectedCommentId(null);
      }
    }
  };
  const handleGetFeedComment = async (e) => {
    setIsLoadingComment(true);
    const res = await axiosCalls(
      `feeds/fetch-feed-comments?feedId=${previewPostComment?._id}&page=1`,
      "Get"
    );

    if (res) {
      console.log(res);
      setIsLoadingComment(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        toast.error(res?.er?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      } else if (res?.status) {
        // onSuccessPostImages();
        // onSuccessMessagePost(res?.message);
        setPostComment(res?.data);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        // const resData = res?.data;

        // console.log(postComment, "comments");
      }
    }
  };

  const handleGetFeedCommentReply = async (e) => {
    setIsLoadingCommentReply(true);
    const res = await axiosCalls(
      `feeds/fetch-comments-replies?commentId=${selectedCommentReplyId}&page=1`,
      "Get"
    );

    if (res) {
      console.log(res);
      setIsLoadingCommentReply(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        // onSuccessPostImages();
        // onSuccessMessagePost(res?.message);
        setSelectedCommentReplyText(res?.data);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        // const resData = res?.data;

        console.log(selectedCommentReplyText, "rrrrrrrrrrr");
      }
    }
  };

  return (
    <InfoModal
      handleCloseModal={() => {
        dispatch(handleStatusPostComment(false));
      }}
      padding="10px"
      width="600px"
    >
      <ToastContainer />
      <div className="comment_caption_text_info_head">
        {storedObject?.avatar ? (
          <div className="comment_caption_text_info_head_image">
            <img
              src={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com/${storedObject?.avatar}`}
            />
          </div>
        ) : (
          <div className="comment_body_details_image_body">
            <div className="comment_body_details_image">
              {storedObject?.firstName // if profile_pic is null, show only the first letters of the names
                ?.split(" ")
                ?.map((name) => name.charAt(0).toUpperCase())
                .join("")}
              {storedObject?.lastName // if profile_pic is null, show only the first letters of the names
                ?.split(" ")
                ?.map((name) => name.charAt(0).toUpperCase())
                .join("")}
            </div>
          </div>
        )}
        <div>
          <div className="comment_caption_text_info_head_username">
            {storedObject?.firstName} {storedObject.lastName}
          </div>
          <div className="comment_caption_text_info_head_email">
            {storedObject?.email}
          </div>
        </div>
      </div>

      {showReplyInput && (
        <div className="comment_uploaded_add_captionBody">
          {/* <div className="comment_uploaded_add_captionText">Add a caption</div> */}

          <div className="comment_uploaded_add_captionText_input">
            <textarea
              onChange={handleChange}
              name="comment"
              value={formValue?.comment}
              placeholder="Enter comment"
            />
          </div>
          <div className="comment_uploaded_add_captionText_btnn">
            {" "}
            <AuthBtn
              handleClick={(e) => {
                e.preventDefault();
                handleCommentFeed();
              }}
              btnText="Send"
              width="120px"
              isLoading={isLoading}
            />
          </div>
        </div>
      )}

      {isLoadingComment ? (
        // Show skeleton while loading
        // <Skeleton count={5} height={500} />
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#008dcb"
            secondaryColor="#008dcb"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          {postComment?.length >= 1 ? (
            <div className="show_allcomments_body_topBody">
              {/* <div className="show_allcomments_body"> */}
              {postComment?.map((data, index) => {
                const createdAt = new Date(data.createdAt);
                const now = new Date();
                const timeDifferenceMillis = now - createdAt;
                const timeDifferenceSeconds = Math.floor(
                  timeDifferenceMillis / 1000
                );

                let formattedTime;
                if (timeDifferenceSeconds < 60) {
                  formattedTime = `${timeDifferenceSeconds} seconds ago`;
                } else if (timeDifferenceSeconds < 3600) {
                  const minutesDifference = Math.floor(
                    timeDifferenceSeconds / 60
                  );
                  formattedTime = `${minutesDifference} minutes ago`;
                } else if (timeDifferenceSeconds < 86400) {
                  const hoursDifference = Math.floor(
                    timeDifferenceSeconds / 3600
                  );
                  formattedTime = `${hoursDifference} hours ago`;
                } else if (timeDifferenceSeconds < 604800) {
                  // 7 days (1 week)
                  const daysDifference = Math.floor(
                    timeDifferenceSeconds / 86400
                  );
                  formattedTime = `${daysDifference} days ago`;
                } else if (timeDifferenceSeconds < 2629746) {
                  // 30.44 days (1 month)
                  const weeksDifference = Math.floor(
                    timeDifferenceSeconds / 604800
                  );
                  formattedTime = `${weeksDifference} weeks ago`;
                } else if (timeDifferenceSeconds < 31556952) {
                  // 365.25 days (1 year)
                  const monthsDifference = Math.floor(
                    timeDifferenceSeconds / 2629746
                  );
                  formattedTime = `${monthsDifference} months ago`;
                } else {
                  const yearsDifference = Math.floor(
                    timeDifferenceSeconds / 31556952
                  );
                  formattedTime = `${yearsDifference} years ago`;
                }
                return (
                  <div style={{ width: "100%", marginBottom: "20px" }}>
                    <div className="show_allcomments_body">
                      {data?.userId?.profileImage ? (
                        <div className="comment_caption_text_info_head_image">
                          <img
                            src={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com/${data?.userId?.profileImage}`}
                          />
                        </div>
                      ) : (
                        <div className="comment_body_details_image_body">
                          <div className="comment_body_details_image">
                            {data?.userId?.firstName // if profile_pic is null, show only the first letters of the names
                              ?.split(" ")
                              ?.map((name) => name.charAt(0).toUpperCase())
                              .join("")}
                            {data?.userId?.lastName // if profile_pic is null, show only the first letters of the names
                              ?.split(" ")
                              ?.map((name) => name.charAt(0).toUpperCase())
                              .join("")}
                          </div>
                        </div>
                      )}
                      <div className="show_comments_name_all_body">
                        <div className="show_comments_name_all_body_name_dateCounterBody">
                          <div className="show_comments_name_all_body_name">
                            {data?.userId?.firstName} {data?.userId?.lastName}
                          </div>
                          <div className="show_comments_name_all_body_name_dateCounter">
                            {formattedTime}
                          </div>
                        </div>
                        <div className="show_comments_name_all_body_comment">
                          {data?.comment}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      {data?.repliesCount >= 1 && (
                        <div
                          onClick={() => {
                            setSelectedCommentReplyId(data._id);
                            handleGetFeedCommentReply();
                          }}
                          className="others_reply_gen_family_body"
                        >
                          View {selectedCommentReplyText?.feedComments.length}{" "}
                          reply thread
                        </div>
                      )}

                      {selectedCommentId === data._id ? (
                        <div
                          className="show_comments_name_all_body_reply"
                          onClick={() => {
                            setSelectedCommentId(null);
                            setShowReplyInput(true);
                          }}
                        >
                          Cancel
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            // handleShowReplyComment(!showReplyComment);
                            setSelectedCommentId(data?._id);
                            setShowReplyInput(false);
                          }}
                          className="show_comments_name_all_body_reply"
                        >
                          Reply
                        </div>
                      )}
                    </div>
                    {data._id ===
                      selectedCommentReplyText?.feedComments[0]?.commentId && (
                      <div>
                        {selectedCommentReplyText?.feedComments?.length >=
                          1 && (
                          <div
                            // onClick={() => {
                            //   setSelectedCommentReplyId(data._id);
                            //   handleGetFeedCommentReply();
                            // }}
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "center",
                              width: "100%",
                              flexDirection: "column",
                            }}
                          >
                            {selectedCommentReplyText?.feedComments?.map(
                              (data, index) => {
                                const createdAt = new Date(data.createdAt);
                                const now = new Date();
                                const timeDifferenceMillis = now - createdAt;
                                const timeDifferenceSeconds = Math.floor(
                                  timeDifferenceMillis / 1000
                                );

                                let formattedTime;
                                if (timeDifferenceSeconds < 60) {
                                  formattedTime = `${timeDifferenceSeconds} seconds ago`;
                                } else if (timeDifferenceSeconds < 3600) {
                                  const minutesDifference = Math.floor(
                                    timeDifferenceSeconds / 60
                                  );
                                  formattedTime = `${minutesDifference} minutes ago`;
                                } else if (timeDifferenceSeconds < 86400) {
                                  const hoursDifference = Math.floor(
                                    timeDifferenceSeconds / 3600
                                  );
                                  formattedTime = `${hoursDifference} hours ago`;
                                } else if (timeDifferenceSeconds < 604800) {
                                  // 7 days (1 week)
                                  const daysDifference = Math.floor(
                                    timeDifferenceSeconds / 86400
                                  );
                                  formattedTime = `${daysDifference} days ago`;
                                } else if (timeDifferenceSeconds < 2629746) {
                                  // 30.44 days (1 month)
                                  const weeksDifference = Math.floor(
                                    timeDifferenceSeconds / 604800
                                  );
                                  formattedTime = `${weeksDifference} weeks ago`;
                                } else if (timeDifferenceSeconds < 31556952) {
                                  // 365.25 days (1 year)
                                  const monthsDifference = Math.floor(
                                    timeDifferenceSeconds / 2629746
                                  );
                                  formattedTime = `${monthsDifference} months ago`;
                                } else {
                                  const yearsDifference = Math.floor(
                                    timeDifferenceSeconds / 31556952
                                  );
                                  formattedTime = `${yearsDifference} years ago`;
                                }

                                return (
                                  <div className="show_allcomments_body_thread">
                                    {data?.userId?.profileImage ? (
                                      <div className="comment_caption_text_info_head_image_thread">
                                        <img
                                          src={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com/${data?.userId?.profileImage}`}
                                        />
                                      </div>
                                    ) : (
                                      <div className="comment_body_details_image_body_thread">
                                        <div className="comment_body_details_image_thread">
                                          {data?.userId?.firstName
                                            ?.split(" ")
                                            ?.map((name) =>
                                              name.charAt(0).toUpperCase()
                                            )
                                            .join("")}
                                          {data?.userId?.lastName
                                            ?.split(" ")
                                            ?.map((name) =>
                                              name.charAt(0).toUpperCase()
                                            )
                                            .join("")}
                                        </div>
                                      </div>
                                    )}

                                    <div className="show_comments_name_all_body_thread">
                                      <div className="show_comments_name_all_body_name_dateCounterBody">
                                        <div className="show_comments_name_all_body_name_thread">
                                          {data?.userId?.firstName}{" "}
                                          {data?.userId?.lastName}
                                        </div>
                                        <div className="show_comments_name_all_body_name_dateCounter">
                                          {formattedTime}
                                        </div>
                                      </div>
                                      <div className="show_comments_name_all_body_comment_thread">
                                        {data?.comment}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {selectedCommentId === data._id && (
                      <div className="comment_uploaded_add_captionBody">
                        <div className="comment_uploaded_add_captionText_input">
                          <textarea
                            onChange={handleChange}
                            name="replyComment"
                            value={formValue.replyComment}
                            placeholder="Reply comment"
                          />
                        </div>
                        <div className="comment_uploaded_add_captionText_btnn">
                          <AuthBtn
                            handleClick={handleReplyCommentFeed}
                            btnText="Reply"
                            width="120px"
                            isLoading={isLoading}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              {/* </div> */}
            </div>
          ) : (
            <div className="no_comment_text_genn">
              Be the first to comment on this post!
            </div>
          )}{" "}
        </div>
      )}
    </InfoModal>
  );
};

export default CommentsModal;
