import React, { useState, useEffect } from "react";
import "./personalInfoRegister.css";
import RegInfoBodyMargin from "../../../components/regInfoBodyMargin/RegInfoBodyMargin";
import {
  check_circled,
  chevron_right__,
  lock,
  map_pin,
  personal_user_icon,
  talk_stuff_authLogo,
  user_round,
  x_circle,
} from "../../../components/images";
import GoBackBtn from "../../../components/button/gobackBtn/GoBackBtn";
import { useNavigate } from "react-router-dom";
import RegIdentitySteps from "../../../components/regIdentitySteps/RegIdentitySteps";
import AuthTopIcon from "../../../components/authTopIcon/AuthTopIcon";
import AuthInputIcon from "../../../components/defaultInput/authInputnIcon/AuthInputIcon";
import AuthInput from "../../../components/defaultInput/authInput/AuthInput";
import SelectInput from "../../../components/defaultInput/selectInput/SelectInput";
import { genderData } from "../../../components/defaultInput/selectInput/SelectData";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";
import SuccessRegisterModal from "../../../components/successRegisterModal/SuccessRegisterModal";
import AuthPasswordInput from "../../../components/defaultInput/authPasswordInput/AuthPasswordInput";
import { axiosCalls } from "../../../_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import { useDispatch } from "react-redux";

const PersonalInfoRegister = () => {
  const [value, setValue] = useState();
  const [tab, setTab] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [eightCharac, setEightCharac] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [specialCharacter, setSpecialCharacter] = useState(false);
  const [oneUpperCase, setOneUpperCase] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    dob: "",
    gender: "",
    phone: "",
    location: "",
    password: "",
    confirmPassword: "",
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    dob: "",
    gender: "",
    phone: "",
    location: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const storedData = localStorage.getItem("regEmail");
    if (storedData) {
      setEmail(storedData);
    }
  }, []);

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: value, // Assuming 'phone' is the name of the property in formData for phone number
    });

    // Validate if the phone number is empty or null
    setError((prevError) => ({
      ...prevError,
      phone: value ? "" : "Phone number is required",
    }));
  };

  const formatDateString = (dateString) => {
    const parts = dateString.split("-");
    const day = parts[2];
    const month = parts[1];
    const year = parts[0];

    return `${day}/${month}/${year}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "firstName":
          // Validation for full name
          if (!value) {
            stateObj[name] = "Please enter first name.";
          }
          break;
        case "lastName":
          // Validation for full name
          if (!value) {
            stateObj[name] = "Please enter last name.";
          }
          break;
        case "userName":
          // Validation for username
          if (!value) {
            stateObj[name] = "Please enter username";
          }
          break;
        case "dob":
          // Validation for date of birth
          const selectedDate = new Date(value);
          const today = new Date();
          if (selectedDate > today) {
            stateObj[name] =
              "Date of birth cannot be greater than today's date.";
          }
          break;

        case "password":
          // Validation for password
          if (!value) {
            stateObj[name] = "Please enter password.";
          } else if (
            formData.confirmPassword &&
            value !== formData.confirmPassword
          ) {
            stateObj["confirmPassword"] =
              "Password and confirm password does not match.";
          } else {
            // Check for password criteria
            if (value.length >= 8) {
              setEightCharac(true);
            } else {
              setEightCharac(false);
            }
            if (value.match(/[0-9]/)) {
              // Check if value contains at least one number
              setOneNumber(true);
            } else {
              setOneNumber(false);
            }
            if (/[A-Z]/.test(value)) {
              setOneUpperCase(true);
            } else {
              setOneUpperCase(false);
            }
            if (/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
              // Check if value contains at least one special character
              setSpecialCharacter(true);
            } else {
              setSpecialCharacter(false);
            }
            stateObj["confirmPassword"] = formData.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          // Validation for confirm password
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (formData.password && value !== formData.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handlePersonalInfoSubmit = async (e) => {
    const formatDateString = (dateString) => {
      const parts = dateString.split("-");
      const day = parts[2];
      const month = parts[1];
      const year = parts[0];

      return `${day}-${month}-${year}`;
    };

    const data = {
      userName: formData.userName,
      email: email,
      location: formData.location,
      dob: formatDateString(formData.dob),
      firstName: formData.firstName,
      lastName: formData.lastName,
      phone: formData.phone,
      gender: formData.gender,
      password: formData.password,
    };

    // showLoader();
    setIsLoading(true);
    const res = await axiosCalls(
      "authentication/profile-with-password",
      "PUT",
      data
    );

    if (res) {
      setIsLoading(false);
      // hideLoader();
      console.log(res);
      if (!res?.status) {
        // toast.error(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.message,
        });
        return;
      } else if (res?.status) {
        localStorage.setItem("userInfo", JSON.stringify(res));
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });

        setSuccessMessage(res?.message);

        setSuccessModal(true);
      }
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleTabTwo = () => {
    setTab(2);
  };

  const handleContinue = () => {
    navigate("/registration-summary");
  };
  return (
    <RegInfoBodyMargin>
      <ToastContainer />
      <div className="personal_info_reg_body">
        <div className="termsandcondition_heading_logo">
          <img src={talk_stuff_authLogo} />
        </div>
        <RegIdentitySteps
          stepNum="1"
          stepStage="Personal"
          background={tab === 0 ? "#EF6924" : "#fff"}
          color={tab === 0 ? "#fff" : "#525866"}
        />
        <RegIdentitySteps
          stepNum="2"
          stepStage="Password"
          background={tab === 1 ? "#EF6924" : "#fff"}
          color={tab === 1 ? "#fff" : "#525866"}
        />
        <RegIdentitySteps
          stepNum="3"
          stepStage="Summary"
          background={tab === 2 ? "#EF6924" : "#fff"}
          color={tab === 2 ? "#fff" : "#525866"}
        />
      </div>
      <form
      // onSubmit={(e) => {
      //   e.preventDefault();
      //   console.log(formData);
      // }}
      >
        {tab === 0 && (
          <div className="personal_info_reg_tab_body">
            <AuthTopIcon userDummyIcon={personal_user_icon} />

            <div className="personal_info_input_body_headerr">
              Personal Information
            </div>
            <div className="personal_info_input_body_head_textt">
              Please take a moment to fill in the required information to create
              your account.
            </div>

            <div className="personal_info_input_flex_xx">
              <AuthInputIcon
                inputPlaceHolder="Talk "
                inputLabel="First Name"
                required={true}
                width="50%"
                inputLeftIcon={user_round}
                inputName="firstName"
                inputOnchange={handleChange}
                inputType="text"
                inputValue={formData.firstName}
                error={error.firstName}
                validateInput={validateInput}
              />
              <AuthInputIcon
                inputPlaceHolder="Stuff"
                inputLabel="Last Name"
                required={true}
                width="50%"
                inputLeftIcon={user_round}
                inputName="lastName"
                inputOnchange={handleChange}
                inputType="text"
                inputValue={formData.lastName}
                error={error.lastName}
                validateInput={validateInput}
              />
            </div>
            <AuthInput
              required={true}
              inputPlaceHolder="TalkStuff"
              inputLabel="Username"
              width="100%"
              inputName="userName"
              inputOnchange={handleChange}
              inputType="text"
              inputValue={formData.userName}
              error={error.userName}
              validateInput={validateInput}
            />
            <div className="personal_info_input_flex_xx">
              <AuthInput
                inputLabel="Date of Birth"
                required={true}
                width="50%"
                inputType="date"
                inputName="dob"
                inputOnchange={handleChange}
                inputValue={formData.dob}
                error={error.dob}
                validateInput={validateInput}
              />
              <SelectInput
                width="50%"
                selectLabel="Gender"
                required={true}
                rolesData={genderData}
                selectName="gender"
                selectOnchange={handleChange}
                selectValue={formData.gender}
                error={error.gender}
                validateInput={validateInput}
              />
            </div>

            <div className="personal_info_phone_number">
              Phone Number{" "}
              <span
                style={{
                  color: "#F41010",
                  fontSize: "13px",
                  fontWeight: "600",
                }}
              >
                *{" "}
              </span>
            </div>
            <div className="personal_info_phone_body">
              <PhoneInput
                international
                defaultCountry="NG"
                value={formData.phone}
                onChange={handlePhoneChange}
              />
              {error.phone && (
                <div className="personal_info_phone_body_error_message">
                  {error.phone}
                </div>
              )}
            </div>

            <AuthInputIcon
              inputPlaceHolder="Maitama"
              inputLabel="Location (optional)"
              width="100%"
              inputLeftIcon={map_pin}
              // required={false}
              inputName="location"
              inputOnchange={handleChange}
              inputType="text"
              inputValue={formData.location}
              error={error.location}
              validateInput={validateInput}
            />

            <AuthBtn
              handleClick={() => {
                // navigate("/personal-data-register");
                setTab(1);
              }}
              btnText="Continue"
              width="100%"
              btnDisabled={
                formData?.firstName === "" ||
                formData?.lastName === "" ||
                formData?.userName === "" ||
                formData?.dob === "" ||
                formData?.gender === "" ||
                formData?.phone === "" ||
                error?.phone !== "" ||
                error?.dob
              }
            />

            <div className="fixed_language_select_body_tw">
              <select>
                <option value="1">English</option>
                <option value="2"> Spanish</option>
                <option value="3">French</option>
                <option value="4">Arabic</option>
              </select>
            </div>
          </div>
        )}
        {tab === 1 && (
          <div className="personal_info_reg_tab_body">
            <div className="personal_info_reg_goBack">
              <GoBackBtn
                handleGoBack={() => {
                  setTab(0);
                }}
              />
            </div>
            <AuthTopIcon userDummyIcon={lock} />

            <div className="personal_info_input_body_headerr">
              Password Setup
            </div>
            <div className="personal_info_input_body_head_textt">
              Set up a secure password to protect your account
            </div>
            <AuthPasswordInput
              passwordLabel="Create a Password"
              passwordPlaceHolder="******"
              width="100%"
              required={true}
              passwordName="password"
              passwordOnchange={handleChange}
              passwordValue={formData.password}
              error={error.password}
              validateInput={validateInput}
            />
            <AuthPasswordInput
              passwordLabel="Confirm Password"
              passwordPlaceHolder="******"
              width="100%"
              required={true}
              passwordName="confirmPassword"
              passwordOnchange={handleChange}
              passwordValue={formData.confirmPassword}
              error={error.confirmPassword}
              validateInput={validateInput}
            />

            <div className="personal_password_validation_checkHead">
              Password must contain at least;
            </div>
            <div className="personal_password_validation_checkflex_body">
              <div className="personal_password_validation_checkflex_icon">
                <img src={eightCharac ? check_circled : x_circle} />
              </div>
              <div className="personal_password_validation_checkflex_text">
                At least 8 characters
              </div>
            </div>
            <div className="personal_password_validation_checkflex_body">
              <div className="personal_password_validation_checkflex_icon">
                <img src={oneUpperCase ? check_circled : x_circle} />
              </div>
              <div className="personal_password_validation_checkflex_text">
                At least 1 uppercase
              </div>
            </div>
            <div className="personal_password_validation_checkflex_body">
              <div className="personal_password_validation_checkflex_icon">
                <img src={oneNumber ? check_circled : x_circle} />
              </div>
              <div className="personal_password_validation_checkflex_text">
                At least 1 number
              </div>
            </div>
            <div className="personal_password_validation_checkflex_body">
              <div className="personal_password_validation_checkflex_icon">
                <img src={specialCharacter ? check_circled : x_circle} />
              </div>
              <div className="personal_password_validation_checkflex_text">
                Special character
              </div>
            </div>
            <AuthBtn
              isLoading={isLoading}
              btnDisabled={
                formData.password === "" ||
                formData.confirmPassword === "" ||
                oneNumber === false ||
                oneUpperCase === false ||
                eightCharac === false ||
                specialCharacter === false ||
                error.confirmPassword !== ""
              }
              handleClick={(e) => {
                e.preventDefault();
                handlePersonalInfoSubmit();
              }}
              btnText="Continue"
              width="100%"
            />

            <div className="fixed_language_select_body_tw">
              <select>
                <option value="1">English</option>
                <option value="2"> Spanish</option>
                <option value="3">French</option>
                <option value="4">Arabic</option>
              </select>
            </div>
          </div>
        )}
      </form>
      {successModal && (
        <div>
          <SuccessRegisterModal
            handleContinue={handleContinue}
            successMessage={successMessage}
            handleTabTwo={handleTabTwo}
          />
        </div>
      )}
      {tab === 2 && (
        <div className="personal_info_reg_tab_body">
          <div className="personal_info_reg_goBack">
            <GoBackBtn handleGoBack={handleGoBack} />
          </div>
          <AuthTopIcon userDummyIcon={personal_user_icon} />

          <div className="personal_info_input_body_headerr">
            Personal Information
          </div>
          <div className="personal_info_input_body_head_textt">
            Please take a moment to fill in the required information to create
            your account.
          </div>

          <AuthBtn
            handleClick={() => {
              // navigate("/personal-data-register");
            }}
            btnText="Continue"
            width="100%"
          />

          <div className="fixed_language_select_body_tw">
            <select>
              <option value="1">English</option>
              <option value="2"> Spanish</option>
              <option value="3">French</option>
              <option value="4">Arabic</option>
            </select>
          </div>
        </div>
      )}
    </RegInfoBodyMargin>
  );
};

export default PersonalInfoRegister;
