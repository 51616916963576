// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ribbon-container {
  width: 100%;
  overflow: hidden;
  background: linear-gradient(to right, #ff6600, #ff9900);
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.ribbon {
  display: flex;
  width: 200%;
  animation: moveRibbon 10s linear infinite;
}

.ribbon-content {
  display: flex;
  width: 50%;
}

.ribbon span {
  padding: 10px 20px;
  font-size: 30px;
  font-weight: 700;
  color: #000000;
  white-space: nowrap;
}

@keyframes moveRibbon {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 850px) {
  .ribbon span {
    font-size: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/supamallComp/homeBannerSection/homeBannerSection.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,uDAAuD;EACvD,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".ribbon-container {\n  width: 100%;\n  overflow: hidden;\n  background: linear-gradient(to right, #ff6600, #ff9900);\n  position: relative;\n  margin-top: 50px;\n  margin-bottom: 50px;\n  max-width: 1300px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.ribbon {\n  display: flex;\n  width: 200%;\n  animation: moveRibbon 10s linear infinite;\n}\n\n.ribbon-content {\n  display: flex;\n  width: 50%;\n}\n\n.ribbon span {\n  padding: 10px 20px;\n  font-size: 30px;\n  font-weight: 700;\n  color: #000000;\n  white-space: nowrap;\n}\n\n@keyframes moveRibbon {\n  0% {\n    transform: translateX(0%);\n  }\n  100% {\n    transform: translateX(-50%);\n  }\n}\n\n@media screen and (max-width: 850px) {\n  .ribbon span {\n    font-size: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
