// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alt_login_btn {
  width: 110px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e4e9;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.alt_login_btn:hover {
  background: #f6f6fa;
}
`, "",{"version":3,"sources":["webpack://./src/components/button/altLoginBtn/altLoginBtn.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".alt_login_btn {\n  width: 110px;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid #e2e4e9;\n  background: #fff;\n  border-radius: 10px;\n  cursor: pointer;\n  transition: 0.3s ease-in-out 0s;\n}\n\n.alt_login_btn:hover {\n  background: #f6f6fa;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
