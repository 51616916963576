// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supamall_each_home_body_gen {
  width: 100%;
  margin-top: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/supaMall/supaMallEachProduct/supamallEachProduct.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".supamall_each_home_body_gen {\n  width: 100%;\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
