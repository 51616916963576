// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about_sidebar_body_option_container {
  cursor: pointer;
  /* transition: 0.3s ease-in-out 0s; */
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid #fff;
  margin-bottom: 5px;
  width: 100%;
}

.about_sidebar_body_option_container:hover {
  box-shadow: 0px 1px 2px 0px #5258660d;
  border: 1px solid #e2e4e9;
  /* transition: 0.3s ease-in-out 0s; */

  color: #0a0d14;
}
.about_sidebar_body_option_container_active {
  cursor: pointer;
  /* transition: 0.3s ease-in-out 0s; */
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 5px;
  width: 100%;
  box-shadow: 0px 1px 2px 0px #5258660d;
  border: 1px solid #e2e4e9;
  /* transition: 0.3s ease-in-out 0s; */

  color: #0a0d14;
}

.about_sidebar_body_option_text {
  font-size: 13px;
  font-weight: 600;
  color: #525866;
}

.about_sidebar_notification_chevron_right {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/profileTabs/aboutTab/aboutTabSideBarOption/aboutTabSideBarOption.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,qCAAqC;EACrC,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,qCAAqC;EACrC,yBAAyB;EACzB,qCAAqC;;EAErC,cAAc;AAChB;AACA;EACE,eAAe;EACf,qCAAqC;EACrC,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,kBAAkB;EAClB,WAAW;EACX,qCAAqC;EACrC,yBAAyB;EACzB,qCAAqC;;EAErC,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".about_sidebar_body_option_container {\n  cursor: pointer;\n  /* transition: 0.3s ease-in-out 0s; */\n  padding: 10px;\n  border-radius: 10px;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 10px;\n  border: 1px solid #fff;\n  margin-bottom: 5px;\n  width: 100%;\n}\n\n.about_sidebar_body_option_container:hover {\n  box-shadow: 0px 1px 2px 0px #5258660d;\n  border: 1px solid #e2e4e9;\n  /* transition: 0.3s ease-in-out 0s; */\n\n  color: #0a0d14;\n}\n.about_sidebar_body_option_container_active {\n  cursor: pointer;\n  /* transition: 0.3s ease-in-out 0s; */\n  padding: 10px;\n  border-radius: 10px;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 10px;\n  margin-bottom: 5px;\n  width: 100%;\n  box-shadow: 0px 1px 2px 0px #5258660d;\n  border: 1px solid #e2e4e9;\n  /* transition: 0.3s ease-in-out 0s; */\n\n  color: #0a0d14;\n}\n\n.about_sidebar_body_option_text {\n  font-size: 13px;\n  font-weight: 600;\n  color: #525866;\n}\n\n.about_sidebar_notification_chevron_right {\n  width: 16px;\n  height: 16px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
