import React from "react";
import "./authTopIcon.css";

const AuthTopIcon = ({ userDummyIcon }) => {
  return (
    <div className="login_top_input_user_icon">
      <img src={userDummyIcon} />
    </div>
  );
};

export default AuthTopIcon;
