import React, { useState } from "react";
import "./supamallEachSectionTwo.css";
import { Rating } from "react-simple-star-rating";
import {
  bookmark_sect_btn,
  matches_info,
  shopping_basket,
} from "../../../components/images";
import useCurrencyFormatter from "../../../utils/useCurrencyFormatter";

const SupamallEachSectionTwo = ({ productData }) => {
  const [rating, setRating] = useState(0);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };
  const basePriceFormatted = useCurrencyFormatter(
    productData?.product?.basePrice
  );

  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => {
    console.log(value, index);
  };
  return (
    <div className="supamall_each_section_two_genBodyy_yyyy">
      <div className="supamall_each_section_two_genBodyy">
        <div className="supamall_each_section_two_firstsecBody">
          <div className="supamall_each_section_two_firstsecBody_left">
            <div className="supamall_each_section_two_firstsecBody_leftHead">
              {productData?.product?.name}
            </div>

            <div className="supamall_each_section_two_firstsecBody_left_sss">
              <div className="most_popular_card_supamall_body_eachSecTwo">
                <Rating
                  onClick={handleRating}
                  onPointerEnter={onPointerEnter}
                  onPointerLeave={onPointerLeave}
                  onPointerMove={onPointerMove}
                  size={15}
                />
              </div>

              <div className="supamall_each_section_two_firstsecBody_left_ss_rat">
                {productData?.product?.ratings} verified ratings
              </div>
              <div className="supamall_each_section_two_firstsecBody_left_ss_see">
                See all
              </div>
            </div>
            <div className="supamall_each_section_two_firstsecBody_left_price">
              {basePriceFormatted}
            </div>
            <div className="supamall_each_section_two_firstsecBody_left_descr">
              {productData?.product?.description}
            </div>
          </div>

          <div className="supamall_each_section_two_firstsecBody_right">
            <div className="each_sectionTwo_card_supamall_body_button_body">
              <div className="each_sectionTwo_card_supamall_body_button_icon">
                <img src={shopping_basket} />
              </div>
              <div className="each_sectionTwo_card_supamall_body_button">
                Add to cart
              </div>
            </div>
            <div className="each_sectionTwo_white_card_supamall_body_button_body">
              <div className="each_sectionTwo_white_card_supamall_body_button_icon">
                <img src={bookmark_sect_btn} />
              </div>
              <div className="each_sectionTwo_white_card_supamall_body_button">
                Save Item
              </div>
            </div>
          </div>
        </div>

        <div className="supamall_each_section_two_genBodyy__infoImport">
          <div className="supamall_each_section_two_genBodyy__infoImport_icon">
            <img src={matches_info} />
          </div>

          <div className="supamall_each_section_two_genBodyy__infoImport_text">
            Ensure a safe purchase by communicating with the seller, checking
            reviews, using secure payment methods, and staying alert to red
            flags.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupamallEachSectionTwo;
