// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all_notification_body_section {
  width: 100%;
  overflow-y: scroll;
  height: 500px;
  padding-bottom: 200px;
  box-sizing: border-box;
  padding-top: 10px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* Hiding scrollbar for Chrome, Safari and Opera */
.all_notification_body_section::-webkit-scrollbar {
  display: none;
}

/* .all_notification_body_section {
  scrollbar-width: thin; 
  scrollbar-color: #999999 #f0f0f0; 
}

.all_notification_body_section::-webkit-scrollbar {
  width: 8px; 
  background-color: #f0f0f0; 
}

.all_notification_body_section::-webkit-scrollbar-thumb {
  background-color: #999999; 
} */
`, "",{"version":3,"sources":["webpack://./src/components/notificationsCard/allNotification/allNotification.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,qBAAqB;EACrB,sBAAsB;EACtB,iBAAiB;EACjB,qBAAqB,EAAE,YAAY;EACnC,wBAAwB,EAAE,gBAAgB;AAC5C;;AAEA,kDAAkD;AAClD;EACE,aAAa;AACf;;AAEA;;;;;;;;;;;;GAYG","sourcesContent":[".all_notification_body_section {\n  width: 100%;\n  overflow-y: scroll;\n  height: 500px;\n  padding-bottom: 200px;\n  box-sizing: border-box;\n  padding-top: 10px;\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none; /* IE and Edge */\n}\n\n/* Hiding scrollbar for Chrome, Safari and Opera */\n.all_notification_body_section::-webkit-scrollbar {\n  display: none;\n}\n\n/* .all_notification_body_section {\n  scrollbar-width: thin; \n  scrollbar-color: #999999 #f0f0f0; \n}\n\n.all_notification_body_section::-webkit-scrollbar {\n  width: 8px; \n  background-color: #f0f0f0; \n}\n\n.all_notification_body_section::-webkit-scrollbar-thumb {\n  background-color: #999999; \n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
