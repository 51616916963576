import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyLoadingHook = ({ imageSrc, effect }) => {
  return (
    <LazyLoadImage
      src={imageSrc}
      effect={effect}
      wrapperProps={{
        style: { transitionDelay: "0.5s" },
      }}
    />
  );
};

export default LazyLoadingHook;
