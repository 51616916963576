import React, { useRef, useState, useEffect } from "react";

import "./storiesViewer.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import ReactPlayer from "react-player";

import { useDispatch, useSelector } from "react-redux";
import { handleStatusPreviewStoriesMedia } from "../../redux/userData";
// SwiperCore.use([Navigation, Pagination]);

// import {
//   post_fourImage,
//   post_oneImage,
//   post_threeImage,
//   post_twoImage,
//   minigadget,
// } from "../images";
import InfoModal from "../../components/infoModal/InfoModal";
import { paperclip } from "../../components/images";
import AuthBtn from "../../components/button/authBtn/AuthBtn";
import useFormattedFullTime from "../useFormattedFullTime";

// const images = [
//   {
//     url: post_oneImage,
//   },
//   {
//     url: post_fourImage,
//   },
//   {
//     url: post_twoImage,
//   },
//   {
//     url: post_threeImage,
//   },
// ];

const StoriesViewer = () => {
  const dispatch = useDispatch();
  const { previewStoriesMedia } = useSelector(
    (state) => state.previewStoriesMediaBody.previewStoriesMediaBody
  );
  const [inView, setInView] = useState(
    Array(previewStoriesMedia?.length).fill(false)
  );

  useEffect(() => {
    console.log(previewStoriesMedia, "stories preview");
  }, []);

  const lastObject = previewStoriesMedia[previewStoriesMedia.length - 1];

  const formattedTime = useFormattedFullTime(lastObject.createdAt);

  const getFileComponent = (file, index) => {
    const fileExtension = getFileExtension(file);
    if (
      fileExtension === "jpg" ||
      fileExtension === "png" ||
      fileExtension === "jpeg" ||
      fileExtension === "webp"
    ) {
      return (
        <img
          src={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com${file}`}
          alt={`Slide ${index}`}
          id={`slide-${index}`}
        />
      );
    } else if (fileExtension === "mp4") {
      return (
        // <video controls>
        //   <source src={file} type="video/mp4" />
        //   Your browser does not support the video tag.
        // </video>

        <div className="post_video_playerr_body" id={`slide-${index}`}>
          <ReactPlayer
            url={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com${file}`}
            playing={false}
            controls={true}
            width="100%"
            height="100%"
            // style={{ marginTop: "100px" }}
            onPlay={() => handleVideoPlay(index)}
            onPause={() => handleVideoPause(index)}
          />
        </div>
      );
    } else {
      return <div>No preview available</div>;
    }
  };

  const getFileExtension = (filename) => {
    return filename?.split(".").pop().toLowerCase();
  };

  const handleVideoPlay = (index) => {
    const newInView = [...inView];
    newInView[index] = true;
    setInView(newInView);
  };

  const handleVideoPause = (index) => {
    const newInView = [...inView];
    newInView[index] = false;
    setInView(newInView);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries?.forEach((entry, index) => {
          if (entry.isIntersecting) {
            handleVideoPlay(index);
          } else {
            handleVideoPause(index);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    previewStoriesMedia?.forEach((_, index) => {
      const element = document.getElementById(`slide-${index}`);
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <InfoModal
      handleCloseModal={() => {
        dispatch(handleStatusPreviewStoriesMedia(false));
      }}
      width="600px"
    >
      <div className="story_modal_username_">
        {lastObject?.userId?.firstName} {lastObject?.userId?.lastName}
      </div>
      <div className="story_modal_user_date">{formattedTime}</div>

      <Swiper
        className="mySwiper"
        // pagination={{
        //   dynamicBullets: true,
        //   clickable: true,
        // }}
        pagination={{ clickable: true, type: "progressbar" }}
        navigation
        modules={[Pagination, Navigation, Scrollbar, A11y]}
      >
        {previewStoriesMedia?.map((image, index) => (
          <SwiperSlide key={index}>
            {getFileComponent(image?.attachment?.url, index)}
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="story_modal_user_img_footer_gen">
        <div className="story_modal_user_img_footer_paper_icon">
          <img src={paperclip} />
        </div>
        <div className="story_modal_user_img_footer_paper_input">
          <input placeholder="Write something" />
        </div>

        <div className="story_modal_user_img_footer_paper_btn">
          <AuthBtn btnText="Send" width="100%" />
        </div>
      </div>
    </InfoModal>
  );
};

export default StoriesViewer;
