import React from "react";
import "./authBodyMargin.css";
import { motion } from "framer-motion";

const AuthBodyMargin = ({ children }) => {
  return (
    <div className="auth_body">
      {children}
      <div className="fixed_body">
        <div className="fixed_language_select_body">
          <select>
            <option value="1">English</option>
            <option value="2"> Spanish</option>
            <option value="3">French</option>
            <option value="4">Arabic</option>
          </select>
        </div>
        <div className="fixed_side_plane"></div>
      </div>
    </div>
  );
};

export default AuthBodyMargin;
