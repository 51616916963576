// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reg_info_body {
  max-width: 1200px;
  min-height: 100vh;
  /* margin-top: 100px; */
  background: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

/* Hiding scrollbar for Chrome, Safari and Opera */
.reg_info_body::-webkit-scrollbar {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/regInfoBodyMargin/regInfoBodyMargin.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB,EAAE,YAAY;EACnC,wBAAwB,EAAE,gBAAgB;AAC5C;;AAEA,kDAAkD;AAClD;EACE,aAAa;AACf","sourcesContent":[".reg_info_body {\n  max-width: 1200px;\n  min-height: 100vh;\n  /* margin-top: 100px; */\n  background: #fff;\n  overflow-x: hidden;\n  overflow-y: scroll;\n  position: relative;\n  width: 100%;\n  box-sizing: border-box;\n  margin-left: auto;\n  margin-right: auto;\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none; /* IE and Edge */\n}\n\n/* Hiding scrollbar for Chrome, Safari and Opera */\n.reg_info_body::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
