import React from "react";

const useProfileImage = (
  firstName,
  lastName,
  borderWidth,
  borderHeight,
  innerWidth,
  innerHeight,
  fontSize,
  marginLeft,
  marginRight,
  outerRadius,
  innerRadius
) => {
  const initials = `${firstName?.charAt(0)?.toUpperCase() ?? ""}${
    lastName?.charAt(0)?.toUpperCase() ?? ""
  }`;

  return (
    <div
      style={{
        width: borderWidth,
        height: borderHeight,
        marginLeft,
        marginRight,
        borderRadius: outerRadius,
      }}
      className="profile_info_pics_border"
    >
      <div
        style={{
          width: innerWidth,
          height: innerHeight,
          fontSize,
          borderRadius: innerRadius,
        }}
        className="profile_info_pics_border_inner"
      >
        {initials}
      </div>
    </div>
  );
};

export default useProfileImage;
