import React from "react";
import "./homeSecondSection.css";
import {
  homesecond_supamallleft,
  homesecond_supamallleftab,
} from "../../images";

const HomeSecondSection = () => {
  return (
    <div className="supamall_homesecondbody_section">
      <div className="supamall_homesecondbody_section_left">
        <img src={homesecond_supamallleft} />
      </div>
      <div className="supamall_homesecondbody_section_right">
        <div className="supamall_homesecondbody_sectionrigth_left">
          <img src={homesecond_supamallleftab} />
        </div>

        <div className="supamall_homesecondbody_sectionrigth_right">
          <div className="supamall_homesecondbody_sectionrigth_righthead">
            Get 50% OFF
          </div>
          <div className="supamall_homesecondbody_sectionrigth_rightpara">
            When you make your first purchase
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSecondSection;
