// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sender_chat_card_genBody {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* flex-direction: column; */
}

.sender_chat_card_genBody_inner {
  width: 40%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 20px;
}

.sender_chat_card_genBodyDate {
  color: #666666;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 7px;
}

.sender_chat_card_genBodyMessage {
  width: 100%;

  background: #c6e5f2;
  border-radius: 20px 0px 20px 20px;
  padding: 10px;
  box-sizing: border-box;
  color: #0a0d14;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 7px;
}

.sender_chat_card_genBody_check {
  display: flex;
  align-items: center;
  gap: 2px;
}

.sender_chat_card_genBody_checkIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sender_chat_card_genBody_checkSeen {
  color: #525866;
  font-size: 10px;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/screens/chatSection/senderChatCard/senderChatCard.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,qBAAqB;EACrB,yBAAyB;EACzB,4BAA4B;AAC9B;;AAEA;EACE,UAAU;EACV,aAAa;EACb,qBAAqB;EACrB,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;;EAEX,mBAAmB;EACnB,iCAAiC;EACjC,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".sender_chat_card_genBody {\n  width: 100%;\n  display: flex;\n  align-items: flex-end;\n  justify-content: flex-end;\n  /* flex-direction: column; */\n}\n\n.sender_chat_card_genBody_inner {\n  width: 40%;\n  display: flex;\n  align-items: flex-end;\n  justify-content: flex-end;\n  flex-direction: column;\n  margin-bottom: 20px;\n}\n\n.sender_chat_card_genBodyDate {\n  color: #666666;\n  font-size: 10px;\n  font-weight: 500;\n  margin-bottom: 7px;\n}\n\n.sender_chat_card_genBodyMessage {\n  width: 100%;\n\n  background: #c6e5f2;\n  border-radius: 20px 0px 20px 20px;\n  padding: 10px;\n  box-sizing: border-box;\n  color: #0a0d14;\n  font-size: 12px;\n  font-weight: 400;\n  margin-bottom: 7px;\n}\n\n.sender_chat_card_genBody_check {\n  display: flex;\n  align-items: center;\n  gap: 2px;\n}\n\n.sender_chat_card_genBody_checkIcon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.sender_chat_card_genBody_checkSeen {\n  color: #525866;\n  font-size: 10px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
