// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.helpSupport_bar_body_footer_btn_body {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  /* gap: 5px; */
}

.helpSupport_bar_body_footer_btn_body_each {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.help_support_linkscardGen_body {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.help_support_main_homemmm {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  box-sizing: border-box;
}
/* 
.stories_bar_body_footer_btn_body_each img {
  object-fit: contain;
} */

@media screen and (max-width: 1300px) {
  .help_support_main_homemmm {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (max-width: 600px) {
  .help_support_linkscardGen_body {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/homeScreens/helpSupport/helpSupport.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,8BAA8B;EAC9B,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,sBAAsB;AACxB;AACA;;;GAGG;;AAEH;EACE;IACE,mBAAmB;IACnB,kBAAkB;EACpB;AACF;AACA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".helpSupport_bar_body_footer_btn_body {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  gap: 10px;\n  /* gap: 5px; */\n}\n\n.helpSupport_bar_body_footer_btn_body_each {\n  width: fit-content;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.help_support_linkscardGen_body {\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  gap: 10px;\n  margin-left: auto;\n  margin-right: auto;\n  flex-wrap: wrap;\n  margin-bottom: 20px;\n}\n\n.help_support_main_homemmm {\n  max-width: 1300px;\n  margin-left: auto;\n  margin-right: auto;\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  flex-direction: column;\n  gap: 20px;\n  margin-top: 30px;\n  box-sizing: border-box;\n}\n/* \n.stories_bar_body_footer_btn_body_each img {\n  object-fit: contain;\n} */\n\n@media screen and (max-width: 1300px) {\n  .help_support_main_homemmm {\n    padding-right: 20px;\n    padding-left: 20px;\n  }\n}\n@media screen and (max-width: 600px) {\n  .help_support_linkscardGen_body {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
