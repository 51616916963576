import React, { useState } from "react";
import "./mostPopularCard.css";
import {
  placeholder_supamall_image,
  sample_supa_productt,
  shopping_basket,
  shopping_basket_two,
} from "../../images";
import { Rating } from "react-simple-star-rating";
import { useNavigate } from "react-router-dom";
import { imagePrefix } from "../../../utils/callImagePrefix";
import LazyLoadingHook from "../../../utils/lazyLoadingHook";
import useCurrencyFormatter from "../../../utils/useCurrencyFormatter";

const MostPopularCard = ({ categoryProducts, title }) => {
  const [rating, setRating] = useState(0);
  const navigate = useNavigate();

  // const formatCurrency = (amount) => useCurrencyFormatter(amount);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(number);
  };

  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => {
    console.log(value, index);
  };
  return (
    <div className="most_popular_body_gennnnn">
      {categoryProducts?.length === 0 ? (
        <div className="most_popular_nill">
          <div className="most_popular_nill_icon">
            <img src={shopping_basket_two} />
          </div>
          <div className="most_popular_nill_text">No products</div>
        </div>
      ) : (
        categoryProducts?.map((data) => {
          return (
            <div className="most_popular_card_supamall_body">
              <div className="most_popular_card_supamall_tagtop">
                Most Popular
              </div>
              <div className="most_popular_card_supamall_body_body_initial">
                <div
                  onClick={() => {
                    navigate(`/supamall/${data?._id}`);
                  }}
                  className="most_popular_card_supamall_body_iniital_prImage"
                >
                  <LazyLoadingHook
                    imageSrc={
                      `${imagePrefix}${data?.baseImages[0]}` ||
                      placeholder_supamall_image
                    }
                  />
                </div>
                <div className="most_popular_card_supamall_body_price_name">
                  <div className="most_popular_card_supamall_body_productcat">
                    {data?.name}
                  </div>

                  <div className="most_popular_card_supamall_body_name_stargen">
                    <div className="most_popular_card_supamall_body_starname">
                      <div className="most_popular_card_supamall_body_name">
                        {title}
                      </div>
                      <div className="most_popular_card_supamall_body_price">
                        {formatNumber(data?.basePrice)}
                      </div>
                    </div>

                    <div className="most_popular_card_supamall_body_starmain">
                      <Rating
                        onClick={handleRating}
                        onPointerEnter={onPointerEnter}
                        onPointerLeave={onPointerLeave}
                        onPointerMove={onPointerMove}
                        size={15}
                      />
                    </div>
                  </div>

                  <div className="most_popular_card_supamall_body_location">
                    {data?.location}
                  </div>
                </div>
              </div>

              <div className="most_popular_card_supamall_body_button_body">
                <div className="most_popular_card_supamall_body_button_icon">
                  <img src={shopping_basket} />
                </div>
                <div className="most_popular_card_supamall_body_button">
                  Add to cart
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default MostPopularCard;
