import React, { useState } from "react";
import "./sidebarAccordion.css"; // Import your CSS file for styling
import { useLocation, useNavigate } from "react-router-dom";

import { chevron_up_sidebar, lock_keyhole, settings } from "../images";
import { useDispatch } from "react-redux";
import { handleOpenSettingModal } from "../../redux/userData";

const Accordion = ({
  title,
  content,
  icon,
  path,
  setOpenFunc,
  accordionBodyIcon,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [hoveredItemIndex, setHoveredItemIndex] = useState(-1); // Track hovered item index
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleItemMouseEnter = (index) => {
    setHoveredItemIndex(index); // Set the index of the hovered item
  };

  const handleItemMouseLeave = () => {
    setHoveredItemIndex(-1); // Reset the index when leaving the item
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  // Check if any section route matches the current location pathname
  const isActiveAccordion = content?.some(
    (item) => item?.section_route === location?.pathname
  );

  return (
    <div>
      <div
        onClick={() => {
          toggleAccordion();
        }}
        className={isActiveAccordion ? "accordion-active" : "accordion"} // Update class based on isActiveAccordion
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="sidebar_body_option_icon_mainFlex_accordion">
          <div className="settings_accordion_head_icon">
            <img src={accordionBodyIcon} />
          </div>
          <div className="sidebar_accordion_option_icon">{title}</div>
        </div>
        <div className={`accordion_chevron ${isOpen ? "open" : ""}`}>
          <img src={chevron_up_sidebar} />
        </div>
      </div>
      {isOpen && (
        <div className="accordion-content">
          {Array?.isArray(content) ? (
            content?.map((item, index) => (
              <div
                className={
                  location.pathname === item?.section_route
                    ? "accordion_item_body_active"
                    : "accordion_item_body"
                }
                // className="accordion_item_body"
                onMouseEnter={() => handleItemMouseEnter(index)} // Pass index to track hover
                onMouseLeave={handleItemMouseLeave} // Reset hover state
                onClick={() => {
                  navigate(item?.section_route);
                  setOpenFunc();
                }}
              >
                <div className="accordion_item_secondary_body">
                  <div className="accordion_bullet_body">
                    <img src={item?.icon} />
                  </div>
                  <div className="accordion_item" key={index}>
                    {item?.section_name}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div style={{ width: "100%", fontSize: "12px" }}>
              No content yet
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Accordion;
