// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea_input_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
}

.textarea_input_label {
  color: #0a0d14;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 5px;
}

.textarea_input_parent {
  width: 100%;
}

.textarea_input_parent textarea {
  width: 100%;
  height: 150px;
  padding: 10px 16px;
  /* padding-left: 16px;
  padding-right: 16px; */
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #e0e1e0;
  background: #fff;
  outline: none;

  color: #2d3748;

  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.textarea_input_parent ::placeholder {
  font-family: "Open Sans", sans-serif;
  color: #cbd5e0;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.textarea_input_parent textarea:focus {
  border: 1px solid #008dcb;
  box-shadow: 0px 0px 3px 0px #008dcb99;
}
.textarea_error_message {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  color: #e53e3e;
}
`, "",{"version":3,"sources":["webpack://./src/components/defaultInput/defaultTextArea/defaultTextArea.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB;wBACsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;;EAEb,cAAc;;EAEd,oCAAoC;EACpC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,oCAAoC;EACpC,cAAc;;EAEd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,qCAAqC;AACvC;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".textarea_input_container {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  margin-top: 16px;\n}\n\n.textarea_input_label {\n  color: #0a0d14;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 600;\n  margin-bottom: 5px;\n}\n\n.textarea_input_parent {\n  width: 100%;\n}\n\n.textarea_input_parent textarea {\n  width: 100%;\n  height: 150px;\n  padding: 10px 16px;\n  /* padding-left: 16px;\n  padding-right: 16px; */\n  box-sizing: border-box;\n  border-radius: 8px;\n  border: 1px solid #e0e1e0;\n  background: #fff;\n  outline: none;\n\n  color: #2d3748;\n\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n}\n.textarea_input_parent ::placeholder {\n  font-family: \"Open Sans\", sans-serif;\n  color: #cbd5e0;\n\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 400;\n}\n\n.textarea_input_parent textarea:focus {\n  border: 1px solid #008dcb;\n  box-shadow: 0px 0px 3px 0px #008dcb99;\n}\n.textarea_error_message {\n  font-size: 11px;\n  font-style: normal;\n  font-weight: 400;\n  color: #e53e3e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
