import React from "react";

const Store = ({ fill }) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1033_22911)">
        <path
          d="M1.49783 4.40045L4.23174 1.66654C4.34709 1.55051 4.48426 1.45845 4.63534 1.39568C4.78643 1.3329 4.94844 1.30065 5.11205 1.30078H10.2823C10.4459 1.30065 10.6079 1.3329 10.759 1.39568C10.9101 1.45845 11.0473 1.55051 11.1626 1.66654L13.8965 4.40045"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.7377 7.5V12.4595C2.7377 12.7883 2.86833 13.1037 3.10085 13.3362C3.33337 13.5687 3.64874 13.6993 3.97757 13.6993H11.4168C11.7456 13.6993 12.061 13.5687 12.2935 13.3362C12.526 13.1037 12.6567 12.7883 12.6567 12.4595V7.5"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.55699 13.6993V11.2196C9.55699 10.8908 9.42636 10.5754 9.19384 10.3429C8.96132 10.1104 8.64595 9.97974 8.31712 9.97974H7.07725C6.74841 9.97974 6.43305 10.1104 6.20053 10.3429C5.96801 10.5754 5.83738 10.8908 5.83738 11.2196V13.6993"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.49783 4.40039H13.8965"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.8965 4.40039V6.26019C13.8965 6.58903 13.7659 6.90439 13.5334 7.13691C13.3009 7.36943 12.9855 7.50006 12.6567 7.50006C12.2944 7.48013 11.9485 7.34306 11.671 7.1095C11.597 7.05605 11.508 7.02728 11.4168 7.02728C11.3255 7.02728 11.2366 7.05605 11.1626 7.1095C10.885 7.34306 10.5391 7.48013 10.1769 7.50006C9.81471 7.48013 9.46878 7.34306 9.19122 7.1095C9.11725 7.05605 9.02831 7.02728 8.93705 7.02728C8.84579 7.02728 8.75685 7.05605 8.68287 7.1095C8.40531 7.34306 8.05938 7.48013 7.69718 7.50006C7.33497 7.48013 6.98905 7.34306 6.71148 7.1095C6.63751 7.05605 6.54857 7.02728 6.45731 7.02728C6.36605 7.02728 6.27711 7.05605 6.20314 7.1095C5.92557 7.34306 5.57965 7.48013 5.21744 7.50006C4.85523 7.48013 4.50931 7.34306 4.23174 7.1095C4.15777 7.05605 4.06883 7.02728 3.97757 7.02728C3.88631 7.02728 3.79737 7.05605 3.7234 7.1095C3.44584 7.34306 3.09991 7.48013 2.7377 7.50006C2.40887 7.50006 2.0935 7.36943 1.86098 7.13691C1.62846 6.90439 1.49783 6.58903 1.49783 6.26019V4.40039"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1033_22911">
          <rect
            width="14.8784"
            height="14.8784"
            fill="white"
            transform="translate(0.257965 0.060791)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Store;
