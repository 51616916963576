// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interest_card_rack_heading__genBody {
  width: 100%;
  margin-top: 10px;
}

.interest_card_rack_heading__gen {
  font-size: 14px;
  font-weight: 400;
  color: #525866;
  margin-bottom: 5px;
}
.interest_card_rack_heading__gen span {
  color: #f4100f;
}

.interest_card_rack_heading__flex {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 5px;
  box-sizing: border-box;
  width: 100%;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/interestComp/interestCardRack/interestCardRack.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,QAAQ;EACR,sBAAsB;EACtB,WAAW;EACX,eAAe;AACjB","sourcesContent":[".interest_card_rack_heading__genBody {\n  width: 100%;\n  margin-top: 10px;\n}\n\n.interest_card_rack_heading__gen {\n  font-size: 14px;\n  font-weight: 400;\n  color: #525866;\n  margin-bottom: 5px;\n}\n.interest_card_rack_heading__gen span {\n  color: #f4100f;\n}\n\n.interest_card_rack_heading__flex {\n  display: flex;\n  align-items: center;\n  /* justify-content: center; */\n  gap: 5px;\n  box-sizing: border-box;\n  width: 100%;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
