// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success_register_body_texthh {
  font-size: 14px;
  font-weight: 400;
  color: #525866;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/successResetPasswordModal/successResetPasswordModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".success_register_body_texthh {\n  font-size: 14px;\n  font-weight: 400;\n  color: #525866;\n  text-align: center;\n  margin-top: 10px;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
