import React, { useEffect } from "react";
import "./chatBoxSection.css";
import ReceiverChatCard from "../receiverChatCard/ReceiverChatCard";
import SenderChatCard from "../senderChatCard/SenderChatCard";
import {
  clip_text,
  languages___,
  mic_chat,
  smileplus_icon,
} from "../../../components/images";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";

const ChatBoxSection = () => {
  return (
    <div className="chat_box_parent_gen">
      <div className="receiver_chatCard_genBody_topDate">Aug 3, 2023</div>
      <ReceiverChatCard
        messageTime="12:10 am"
        message="Where are we hosting it?"
      />
      <SenderChatCard message="At the facility today" messageTime="12:10 am" />
      <ReceiverChatCard messageTime="12:10 am" message="Oh mehn this is hard" />
      <SenderChatCard
        messageTime="12:10 am"
        message="I have told you times without numbers"
      />
      <ReceiverChatCard messageTime="12:10 am" message="Oh mehn this is hard" />
      <SenderChatCard
        messageTime="12:10 am"
        message="I have told you times without numbers"
      />
      <ReceiverChatCard messageTime="12:10 am" message="Oh mehn this is hard" />
      <SenderChatCard
        messageTime="12:10 am"
        message="I have told you times without numbers"
      />
      <ReceiverChatCard messageTime="12:10 am" message="Oh mehn this is hard" />
      <SenderChatCard
        messageTime="12:10 am"
        message="I have told you times without numbers"
      />
      <ReceiverChatCard messageTime="12:10 am" message="Oh mehn this is hard" />
      <SenderChatCard
        messageTime="12:10 am"
        message="I have told you times without numbers"
      />
      <ReceiverChatCard messageTime="12:10 am" message="Oh mehn this is hard" />
      <SenderChatCard
        messageTime="12:10 am"
        message="I have told you times without numbers"
      />
      <ReceiverChatCard messageTime="12:10 am" message="Oh mehn this is hard" />
      <SenderChatCard
        messageTime="12:10 am"
        message="I have told you times without numbers"
      />
      <ReceiverChatCard messageTime="12:10 am" message="Oh mehn this is hard" />
      <SenderChatCard
        messageTime="12:10 am"
        message="I have told you times without numbers"
      />
    </div>
  );
};

export default ChatBoxSection;
