import React, { useEffect } from "react";
import "./childAbuseAndExploitation.css";
import { motion } from "framer-motion";

import AuthBodyMargin from "../../../components/authBodyMargin/AuthBodyMargin";
import { newSingleLogo } from "../../../components/images";
import LegalBodyComponent from "../../../components/legalBodyComponent/LegalBodyComponent";
import { useLocation, useNavigate } from "react-router-dom";

const ChildAbuseAndExploitation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const privacyPolicyData = [
    {
      heading: "",
      paragraph:
        "TalkStuff is committed to protecting children from sexual abuse and exploitation. We enforce strict safety measures to prevent, detect, and respond to any form of child sexual abuse or exploitation on our platform. These standards apply to all users and content shared on our platform.",
    },
    {
      heading: "Prohibited Content and Activities",
      paragraph: "We strictly prohibit the following:",
    },
    {
      paragraph:
        "Child sexual abuse material (CSAM), including images, videos, live streams, and text-based content.",
    },
    {
      paragraph: "Sexual solicitation, grooming, or coercion of minors.",
    },
    {
      paragraph: "Sharing or requesting sexually explicit content of minors.",
    },
    {
      paragraph: "Sexualized comments or messages directed at minors.",
    },
    {
      paragraph:
        "Any attempt to exploit, traffic, or harm children online or offline.",
    },
    {
      paragraph:
        "The use of artificial intelligence to generate or distribute CSAE content.",
    },
    {
      heading: "Reporting and Moderation:",
      paragraph:
        "We have a dedicated reporting system that allows users to report any suspected CSAE content or behavior.",
    },
    {
      paragraph:
        "Reports of CSAE are reviewed and handled with urgency by our trained moderation team.",
    },
    {
      paragraph:
        "We work closely with law enforcement agencies and child protection organizations to report and take action against offenders.",
    },
    {
      paragraph:
        "Accounts involved in CSAE violations will be permanently banned, and relevant authorities will be notified.",
    },

    {
      heading: "Safety Features and Controls:",
      paragraph: "To protect minors on our platform, we provide the following:",
    },
    {
      paragraph:
        "Strict age verification processes to prevent underage sign-ups.",
    },
    {
      paragraph:
        "AI-driven content monitoring to detect and remove harmful material.",
    },
    {
      paragraph:
        "Restricted direct messaging features for minors to limit interactions with unknown users.",
    },
    {
      paragraph:
        "Parental control tools for monitoring and managing children's activity.",
    },
    {
      paragraph:
        "Educational resources to inform users about online safety and CSAE prevention.",
    },
    {
      heading: "Law Enforcement and Compliance:",
      paragraph:
        "We comply with all applicable local and international laws regarding child safety and exploitation.",
    },
    {
      paragraph:
        "We report CSAE incidents to relevant authorities and cooperate in investigations.",
    },
    {
      paragraph:
        "Our policies align with industry best practices and regulatory requirements, including COPPA, GDPR, and other child protection laws.",
    },
    {
      heading: "Community Awareness and Education:",
      paragraph:
        "We promote child safety awareness through campaigns and partnerships with child protection organizations.",
    },
    {
      paragraph:
        "We encourage parents, educators, and guardians to engage in digital literacy and online safety discussions with minors.",
    },
    {
      paragraph:
        "We provide regular updates on evolving threats and best practices for child protection.",
    },
    {
      heading: "Enforcement and Accountability:",
      paragraph:
        "Violations of this policy will result in immediate action, including content removal, account suspension, and legal action if necessary.",
    },
    {
      paragraph:
        "We maintain transparency in our enforcement efforts by publishing reports on CSAE prevention and response measures.",
    },
    {
      paragraph:
        "Users who repeatedly engage in CSAE violations will be permanently banned and reported to law enforcement.",
    },
    {
      heading: "Contact and Reporting:",
      paragraph:
        "If you encounter any content or behavior that violates our CSAE policy, please report it immediately through our in-app reporting system or contact our support team at contact@talkstuff.social.",
    },
  ];

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <AuthBodyMargin>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5 }}
        className="privacy_policy_heading_logo"
      >
        <img src={newSingleLogo} />
      </motion.div>

      {/* <GoBackBtn handleGoBack={handleGoBack} /> */}
      <div className="terms_condi_bodyyy">
        <div className="privacy_policy_heading">
          Safety Standards Against Child Sexual Abuse and Exploitation (CSAE)
        </div>

        {privacyPolicyData.map((data, index) => (
          <LegalBodyComponent
            paragraphBody={data?.paragraph}
            paragraphHeading={data?.heading}
          />
        ))}
      </div>
    </AuthBodyMargin>
  );
};

export default ChildAbuseAndExploitation;
