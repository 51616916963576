import { createSlice } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

export const notificationToggleSlice = createSlice({
  name: "notificationToggle",
  initialState: {
    notificationModal: false,
  },
  reducers: {
    handleToggleNotification: (state, action) => {
      state.notificationModal = action.payload;
    },
  },
});

export const createPostToggleSlice = createSlice({
  name: "postToggle",
  initialState: {
    postModal: false,
  },
  reducers: {
    handleToggleCreatePost: (state, action) => {
      state.postModal = action.payload;
    },
  },
});

export const profileOptionToggleSlice = createSlice({
  name: "profileOptionToggle",
  initialState: {
    profileOptionModal: false,
  },
  reducers: {
    handleToggleProfileOption: (state, action) => {
      state.profileOptionModal = action.payload;
    },
  },
});

export const viewProfileToggleSlice = createSlice({
  name: "viewProfileToggle",
  initialState: {
    viewProfileComponent: false,
  },
  reducers: {
    handleToggleViewProfile: (state, action) => {
      state.viewProfileComponent = action.payload;
    },
  },
});

export const logoutModalToggleSlice = createSlice({
  name: "logoutModalToggle",
  initialState: {
    logoutModal: false,
  },
  reducers: {
    handleLogoutModal: (state, action) => {
      state.logoutModal = action.payload;
    },
  },
});

export const storyModalToggleSlice = createSlice({
  name: "storyModalToggle",
  initialState: {
    storyModal: false,
  },
  reducers: {
    handleStoryModal: (state, action) => {
      state.storyModal = action.payload;
    },
  },
});

export const interestHomeModalToggleSlice = createSlice({
  name: "interestHomeModalToggle",
  initialState: {
    interestHomeModal: false,
  },
  reducers: {
    handleInterestHomeModal: (state, action) => {
      state.interestHomeModal = action.payload;
    },
  },
});

export const postOptionPopModalSlice = createSlice({
  name: "postOptionPopModalToggle",
  initialState: {
    postOptionPopModal: null,
  },
  reducers: {
    handlePostOptionPopModal: (state, action) => {
      state.postOptionPopModal = action.payload;
    },
  },
});

export const generalToastSlice = createSlice({
  name: "generalToastBody",
  initialState: {
    generalToast: "",
  },
  reducers: {
    handleGeneralToastBody: (state, action) => {
      state.generalToast = action.payload;
    },
  },
});

export const previewPostMediaSlice = createSlice({
  name: "previewPostMediaBody",
  initialState: {
    previewPostMedia: [],
  },
  reducers: {
    handlePreviewPostMedia: (state, action) => {
      state.previewPostMedia = action.payload;
    },
  },
});

export const statusPreviewPostMediaSlice = createSlice({
  name: "statusPreviewPostMediaBody",
  initialState: {
    statusPreviewPostMedia: false,
  },
  reducers: {
    handleStatusPreviewPostMedia: (state, action) => {
      state.statusPreviewPostMedia = action.payload;
    },
  },
});

export const statusPostCommentSlice = createSlice({
  name: "statusPostCommentBody",
  initialState: {
    statusPostComment: false,
  },
  reducers: {
    handleStatusPostComment: (state, action) => {
      state.statusPostComment = action.payload;
    },
  },
});

export const previewPostCommentSlice = createSlice({
  name: "previewPostCommentBody",
  initialState: {
    previewPostComment: null,
  },
  reducers: {
    handlePreviewPostComment: (state, action) => {
      state.previewPostComment = action.payload;
    },
  },
});

export const showAllPostCommentSlice = createSlice({
  name: "showAllPostCommentBody",
  initialState: {
    showAllPostComment: null,
  },
  reducers: {
    handleShowAllPostComment: (state, action) => {
      state.showAllPostComment = action.payload;
    },
  },
});

export const showGeneralSearchSlice = createSlice({
  name: "showGeneralSearchBody",
  initialState: {
    showGeneralSearch: false,
  },
  reducers: {
    handleShowGeneralSearch: (state, action) => {
      state.showGeneralSearch = action.payload;
    },
  },
});

export const previewStoriesMediaSlice = createSlice({
  name: "previewStoriesMediaBody",
  initialState: {
    previewStoriesMedia: [],
  },
  reducers: {
    handlePreviewStoriesMedia: (state, action) => {
      state.previewStoriesMedia = action.payload;
    },
  },
});

export const statusPreviewStoriesMediaSlice = createSlice({
  name: "statusPreviewStoriesMediaBody",
  initialState: {
    statusPreviewStoriesMedia: false,
  },
  reducers: {
    handleStatusPreviewStoriesMedia: (state, action) => {
      state.statusPreviewStoriesMedia = action.payload;
    },
  },
});

export const profileImageModalSlice = createSlice({
  name: "profileImageModalBody",
  initialState: {
    profileImageModal: false,
  },
  reducers: {
    handleProfileImageModal: (state, action) => {
      state.profileImageModal = action.payload;
    },
  },
});

export const profileCoverImageModalSlice = createSlice({
  name: "profileCoverImageModalBody",
  initialState: {
    profileCoverImageModal: false,
  },
  reducers: {
    handleProfileCoverImageModal: (state, action) => {
      state.profileCoverImageModal = action.payload;
    },
  },
});

export const viewAnotherUserProfileSlice = createSlice({
  name: "viewAnotherUserProfileBody",
  initialState: {
    viewAnotherUserProfile: false,
  },
  reducers: {
    handleViewAnotherUserProfile: (state, action) => {
      state.viewAnotherUserProfile = action.payload;
    },
  },
});

export const collectUserIdSlice = createSlice({
  name: "collectUserIdBody",
  initialState: {
    collectUserId: null,
  },
  reducers: {
    handleCollectUserId: (state, action) => {
      state.collectUserId = action.payload;
    },
  },
});

export const openMobileNavbarSlice = createSlice({
  name: "openMobileNavbarBody",
  initialState: {
    openMobileNavbar: false,
  },
  reducers: {
    handleOpenMobileNavbar: (state, action) => {
      state.openMobileNavbar = action.payload;
    },
  },
});

export const showChatBoxSlice = createSlice({
  name: "showChatBoxBody",
  initialState: {
    showChatBox: false,
  },
  reducers: {
    handleShowChatBox: (state, action) => {
      state.showChatBox = action.payload;
    },
  },
});

export const showEachUserChatSlice = createSlice({
  name: "showEachUserChatBody",
  initialState: {
    showEachUserChat: null,
  },
  reducers: {
    handleShowEachUserChat: (state, action) => {
      state.showEachUserChat = action.payload;
    },
  },
});

export const showVideoCallModalSlice = createSlice({
  name: "showVideoCallBody",
  initialState: {
    showVideoCall: false,
  },
  reducers: {
    handleShowVideoCall: (state, action) => {
      state.showVideoCall = action.payload;
    },
  },
});

export const openSettingsModalSlice = createSlice({
  name: "openSettingsModalBody",
  initialState: {
    openSettingsModal: false,
  },
  reducers: {
    handleOpenSettingModal: (state, action) => {
      state.openSettingsModal = action.payload;
    },
  },
});

export const showSuccessToastSlice = createSlice({
  name: "showSuccessToastBody",
  initialState: {
    showSuccessToast: null,
  },
  reducers: {
    handleShowSuccessToast: (state, action) => {
      state.showSuccessToast = action.payload;
    },
  },
});

export const defaultToastSlice = createSlice({
  name: "defaultToastBody",
  initialState: {
    showDefaultToast: null,
  },
  reducers: {
    handleShowDefaultToast: (state, action) => {
      state.showDefaultToast = action.payload;
    },
  },
});

export const showErrorToastSlice = createSlice({
  name: "showErrorToastBody",
  initialState: {
    showErrorToast: false,
  },
  reducers: {
    handleShowErrorToast: (state, action) => {
      state.showErrorToast = action.payload;
    },
  },
});

export const toastDataSlice = createSlice({
  name: "toastDataBody",
  initialState: {
    toastData: null,
  },
  reducers: {
    handleToastData: (state, action) => {
      state.toastData = action.payload;
    },
  },
});

export const defaultToastDataSlice = createSlice({
  name: "defaultToastDataBody",
  initialState: {
    defaultToastData: null,
  },
  reducers: {
    handleDefaultToastData: (state, action) => {
      state.defaultToastData = action.payload;
    },
  },
});

export const toastHeaderDataSlice = createSlice({
  name: "toastHeaderDataBody",
  initialState: {
    toastHeaderData: null,
  },
  reducers: {
    handleToastHeaderData: (state, action) => {
      state.toastHeaderData = action.payload;
    },
  },
});

export const mobileStoryModalSlice = createSlice({
  name: "mobileStoryModalBody",
  initialState: {
    mobileStoryModal: false,
  },
  reducers: {
    handleMobileStoryModal: (state, action) => {
      state.mobileStoryModal = action.payload;
    },
  },
});

export const agoraVideoCallTokenSlice = createSlice({
  name: "agoraVideoCallTokenBody",
  initialState: {
    agoraVideoCallToken: "",
  },
  reducers: {
    handleAgoraVideoCallToken: (state, action) => {
      state.agoraVideoCallToken = action.payload;
    },
  },
});

export const eachMatchesModalSlice = createSlice({
  name: "eachMatchesModalBody",
  initialState: {
    eachMatchesModal: false,
  },
  reducers: {
    handleEachMatchesModal: (state, action) => {
      state.eachMatchesModal = action.payload;
    },
  },
});

export const getEachChannelTokenSlice = createSlice({
  name: "getEachChannelTokenBody",
  initialState: {
    getEachChannelToken: null,
  },
  reducers: {
    handleGetEachChannelToken: (state, action) => {
      state.getEachChannelToken = action.payload;
    },
  },
});

export const showLuvHubLocationSlice = createSlice({
  name: "showLuvHubLocationBody",
  initialState: {
    showLuvHubLocation: false,
  },
  reducers: {
    handleShowLuvHubLocation: (state, action) => {
      state.showLuvHubLocation = action.payload;
    },
  },
});

export const showExplorePageSlice = createSlice({
  name: "showExplorePageBody",
  initialState: {
    showExplorePage: false,
  },
  reducers: {
    handleShowExplorePage: (state, action) => {
      state.showExplorePage = action.payload;
    },
  },
});

export const getMoreMatchesReqNameSlice = createSlice({
  name: "getMoreMatchesReqNameBody",
  initialState: {
    getMoreMatchesReqName: null,
  },
  reducers: {
    handleGetMoreMatchesReqName: (state, action) => {
      state.getMoreMatchesReqName = action.payload;
    },
  },
});

export const getEachMatchModalDataSlice = createSlice({
  name: "getEachMatchModalDataBody",
  initialState: {
    getEachMatchModalData: null,
  },
  reducers: {
    handleGetEachMatchModalData: (state, action) => {
      state.getEachMatchModalData = action.payload;
    },
  },
});

export const getEachExploreDataSlice = createSlice({
  name: "getEachExploreDataBody",
  initialState: {
    getEachExploreData: null,
  },
  reducers: {
    handleGetEachExploreData: (state, action) => {
      state.getEachExploreData = action.payload;
    },
  },
});

export const chatOptionPopModalSlice = createSlice({
  name: "chatOptionPopModalBody",
  initialState: {
    chatOptionPopModal: null,
  },
  reducers: {
    handleChatOptionPopModal: (state, action) => {
      state.chatOptionPopModal = action.payload;
    },
  },
});
export const serverChatDataSlice = createSlice({
  name: "serverChatDataBody",
  initialState: {
    serverChatData: null,
  },
  reducers: {
    handleServerChatData: (state, action) => {
      state.serverChatData = action.payload;
    },
  },
});

export const changePasswordModalSlice = createSlice({
  name: "changePasswordModalBody",
  initialState: {
    changePasswordModal: false,
  },
  reducers: {
    handleChangePasswordModal: (state, action) => {
      state.changePasswordModal = action.payload;
    },
  },
});

export const deactivateModalSlice = createSlice({
  name: "deactivateModalBody",
  initialState: {
    deactivateModal: false,
  },
  reducers: {
    handleDeactivateModal: (state, action) => {
      state.deactivateModal = action.payload;
    },
  },
});

export const showBlockedUserSlice = createSlice({
  name: "showBlockedUserBlock",
  initialState: {
    showBlockedUser: false,
  },
  reducers: {
    handleShowBlockedUsed: (state, action) => {
      state.showBlockedUser = action.payload;
    },
  },
});

export const showBlockingUserSlice = createSlice({
  name: "showBlockingUserBlock",
  initialState: {
    showBlockingUser: false,
  },
  reducers: {
    handleShowBlockingUsed: (state, action) => {
      state.showBlockingUser = action.payload;
    },
  },
});

// export const collectAnotherUserData = createSlice({
//   name: "openSettingsModalBody",
//   initialState: {
//     openSettingsModal: false,
//   },
//   reducers: {
//     handleOpenSettingModal: (state, action) => {
//       state.openSettingsModal = action.payload;
//     },
//   },
// });

export const { handleToastData } = toastDataSlice.actions;
export const { handleMobileStoryModal } = mobileStoryModalSlice.actions;
export const { handleToastHeaderData } = toastHeaderDataSlice.actions;
export const { handleShowErrorToast } = showErrorToastSlice.actions;
export const { handleShowSuccessToast } = showSuccessToastSlice.actions;
export const { handleToggleNotification } = notificationToggleSlice.actions;
export const { handleToggleCreatePost } = createPostToggleSlice.actions;
export const { handleToggleProfileOption } = profileOptionToggleSlice.actions;
export const { handleToggleViewProfile } = viewProfileToggleSlice.actions;
export const { handleLogoutModal } = logoutModalToggleSlice.actions;
export const { handleStoryModal } = storyModalToggleSlice.actions;
export const { handleInterestHomeModal } = interestHomeModalToggleSlice.actions;
export const { handlePostOptionPopModal } = postOptionPopModalSlice.actions;
export const { handleGeneralToastBody } = generalToastSlice.actions;
export const { handlePreviewPostMedia } = previewPostMediaSlice.actions;
export const { handleStatusPreviewPostMedia } =
  statusPreviewPostMediaSlice.actions;
export const { handleStatusPostComment } = statusPostCommentSlice.actions;
export const { handlePreviewPostComment } = previewPostCommentSlice.actions;
export const { handleShowAllPostComment } = showAllPostCommentSlice.actions;
export const { handleShowGeneralSearch } = showGeneralSearchSlice.actions;
export const { handleStatusPreviewStoriesMedia } =
  statusPreviewStoriesMediaSlice.actions;
export const { handlePreviewStoriesMedia } = previewStoriesMediaSlice.actions;
export const { handleProfileImageModal } = profileImageModalSlice.actions;
export const { handleProfileCoverImageModal } =
  profileCoverImageModalSlice.actions;

export const { handleViewAnotherUserProfile } =
  viewAnotherUserProfileSlice.actions;

export const { handleCollectUserId } = collectUserIdSlice.actions;
export const { handleOpenMobileNavbar } = openMobileNavbarSlice.actions;
export const { handleShowChatBox } = showChatBoxSlice.actions;
export const { handleShowEachUserChat } = showEachUserChatSlice.actions;

export const { handleShowVideoCall } = showVideoCallModalSlice.actions;

export const { handleOpenSettingModal } = openSettingsModalSlice.actions;
export const { handleAgoraVideoCallToken } = agoraVideoCallTokenSlice.actions;
export const { handleEachMatchesModal } = eachMatchesModalSlice.actions;
export const { handleGetEachChannelToken } = getEachChannelTokenSlice.actions;
export const { handleDefaultToastData } = defaultToastDataSlice.actions;
export const { handleShowDefaultToast } = defaultToastSlice.actions;
export const { handleShowLuvHubLocation } = showLuvHubLocationSlice.actions;
export const { handleShowExplorePage } = showExplorePageSlice.actions;
export const { handleGetMoreMatchesReqName } =
  getMoreMatchesReqNameSlice.actions;
export const { handleGetEachMatchModalData } =
  getEachMatchModalDataSlice.actions;
export const { handleGetEachExploreData } = getEachExploreDataSlice.actions;
export const { handleChatOptionPopModal } = chatOptionPopModalSlice.actions;
export const { handleServerChatData } = serverChatDataSlice.actions;
export const { handleChangePasswordModal } = changePasswordModalSlice.actions;
export const { handleDeactivateModal } = deactivateModalSlice.actions;
export const { handleShowBlockedUsed } = showBlockedUserSlice.actions;
export const { handleShowBlockingUsed } = showBlockingUserSlice.actions;
// export default notificationToggleSlice.reducer;

// Combine reducers
export const rootReducer = combineReducers({
  mobileStoryModalBody: mobileStoryModalSlice.reducer,
  showErrorToastBody: showErrorToastSlice.reducer,
  showSuccessToastBody: showSuccessToastSlice.reducer,
  toastDataBody: toastDataSlice.reducer,
  toastHeaderDataBody: toastHeaderDataSlice.reducer,
  notificationToggle: notificationToggleSlice.reducer,
  postToggle: createPostToggleSlice.reducer,
  profileOptionToggle: profileOptionToggleSlice.reducer,
  viewProfileToggle: viewProfileToggleSlice.reducer,
  logoutModalToggle: logoutModalToggleSlice.reducer,
  storyModalToggle: storyModalToggleSlice.reducer,
  interestHomeModalToggle: interestHomeModalToggleSlice.reducer,
  postOptionPopModalToggle: postOptionPopModalSlice.reducer,
  generalToastBody: generalToastSlice.reducer,
  previewPostMediaBody: previewPostMediaSlice.reducer,
  statusPreviewPostMediaBody: statusPreviewPostMediaSlice.reducer,
  statusPostCommentBody: statusPostCommentSlice.reducer,
  previewPostCommentBody: previewPostCommentSlice.reducer,
  showAllPostCommentBody: showAllPostCommentSlice.reducer,
  showGeneralSearchBody: showGeneralSearchSlice.reducer,
  previewStoriesMediaBody: previewStoriesMediaSlice.reducer,
  statusPreviewStoriesMediaBody: statusPreviewStoriesMediaSlice.reducer,
  profileImageModalBody: profileImageModalSlice.reducer,
  profileCoverImageModalBody: profileCoverImageModalSlice.reducer,
  viewAnotherUserProfileBody: viewAnotherUserProfileSlice.reducer,
  collectUserIdBody: collectUserIdSlice.reducer,
  openMobileNavbarBody: openMobileNavbarSlice.reducer,
  showChatBoxBody: showChatBoxSlice.reducer,
  showEachUserChatBody: showEachUserChatSlice.reducer,
  showVideoCallBody: showVideoCallModalSlice.reducer,
  openSettingsModalBody: openSettingsModalSlice.reducer,
  agoraVideoCallTokenBody: agoraVideoCallTokenSlice.reducer,
  eachMatchesModalBody: eachMatchesModalSlice.reducer,
  getEachChannelTokenBody: getEachChannelTokenSlice.reducer,
  defaultToastDataBody: defaultToastDataSlice.reducer,
  defaultToastBody: defaultToastSlice.reducer,
  showLuvHubLocationBody: showLuvHubLocationSlice.reducer,
  showExplorePageBody: showExplorePageSlice.reducer,
  getMoreMatchesReqNameBody: getMoreMatchesReqNameSlice.reducer,
  getEachMatchModalDataBody: getEachMatchModalDataSlice.reducer,
  getEachExploreDataBody: getEachExploreDataSlice.reducer,
  chatOptionPopModalBody: chatOptionPopModalSlice.reducer,
  serverChatDataBody: serverChatDataSlice.reducer,
  changePasswordModalBody: changePasswordModalSlice.reducer,
  deactivateModalBody: deactivateModalSlice.reducer,
  showBlockedUserBody: showBlockedUserSlice.reducer,
  showBlockingUserBody: showBlockingUserSlice.reducer,
});
