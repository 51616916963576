// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.each_notification_section_body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}

.each_notification_section_body_left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.each_notification_section_body_left_img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 0.91px solid #e2e4e9;
  box-shadow: 0px 1.8165713548660278px 3.6331427097320557px 0px #1b1c1d0a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.each_notification_section_body_left_img img {
  width: 100%;
  object-fit: contain;
}

.each_notification_section_body_nameSection {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2px;
}

.each_notification_section_body_nameSection_name {
  color: #0a0d14;
  font-size: 12px;
  font-weight: 700;
}

.each_notification_section_body_nameSection_tag {
  color: #0a0d14;
  font-size: 12px;
  font-weight: 400;
}

.each_notification_section_body_nameSection_time {
  color: #0a0d14;
  font-size: 11px;
  font-weight: 400;
}

.each_notification_active_dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #008dcb;
}
`, "",{"version":3,"sources":["webpack://./src/components/notificationsCard/eachNotificationCard/eachNotificationCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,4BAA4B;EAC5B,uEAAuE;EACvE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,2BAA2B;EAC3B,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".each_notification_section_body {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 10px;\n  margin-bottom: 20px;\n}\n\n.each_notification_section_body_left {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.each_notification_section_body_left_img {\n  width: 35px;\n  height: 35px;\n  border-radius: 50%;\n  border: 0.91px solid #e2e4e9;\n  box-shadow: 0px 1.8165713548660278px 3.6331427097320557px 0px #1b1c1d0a;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.each_notification_section_body_left_img img {\n  width: 100%;\n  object-fit: contain;\n}\n\n.each_notification_section_body_nameSection {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  flex-direction: column;\n  gap: 2px;\n}\n\n.each_notification_section_body_nameSection_name {\n  color: #0a0d14;\n  font-size: 12px;\n  font-weight: 700;\n}\n\n.each_notification_section_body_nameSection_tag {\n  color: #0a0d14;\n  font-size: 12px;\n  font-weight: 400;\n}\n\n.each_notification_section_body_nameSection_time {\n  color: #0a0d14;\n  font-size: 11px;\n  font-weight: 400;\n}\n\n.each_notification_active_dot {\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  background: #008dcb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
