import {
  AutomobilesCategoryPage,
  BookmarksPage,
  ChatHomePage,
  ComputingCategoryPage,
  DeleteAccountPage,
  ExplorePageCardPage,
  FashionCategoryPage,
  FurnitureCatgoryPage,
  HealthCategoryPage,
  HelpSupportPage,
  HomeAppliancesCategoryPage,
  HomePage,
  InterestsPage,
  LoginPage,
  LuvhubHomePage,
  LuvhubSideBarPage,
  NotificationPreferencesPage,
  PasswordInputPage,
  PasswordResetPage,
  PersonalInfoRegisterPage,
  PhonesTabletCategoryPage,
  PhotographyServicesPage,
  PostGridPage,
  PrivacyPolicyPage,
  RealEstateCategoryPage,
  RegisterPage,
  RegisterSummaryPage,
  SecurityAndPrivacyPage,
  StylistServicesPage,
  SupaMallEachProductPage,
  SupaMallHomePage,
  SupaMallJobsFormPage,
  SupaMallProductFormPage,
  SupaMallSellCategoryPage,
  SupaMallServicesFormPage,
  SupamallCartPage,
  SupermarketCategoryPage,
  TermsandConditionPage,
  UiUxJobsPage,
  UserAccountPage,
  VerifyRegisterPage,
  ViewProfilePage,
} from "./pages";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotificationsCard from "./components/notificationsCard/NotificationsCard";
import CreatePostModal from "./components/createPost/createPostModal/CreatePostModal";
import PostUploadModal from "./components/createPost/postUploadModal/PostUploadModal";
import ProfileOptionModal from "./components/profileOptionModal/ProfileOptionModal";
import LogoutModal from "./components/logoutModal/LogoutModal";
import StoryModalCard from "./components/storyModal/storyModalCard/StoryModalCard";
import InterestHomeModal from "./components/interestComp/interestHomeModal/InterestHomeModal";
import { ToastContainer } from "react-toastify";
import PostGridViewer from "./components/postGridViewer/PostGridViewer";
import CommentsModal from "./components/comments/commentsModal/CommentsModal";
import GeneralSearchModal from "./components/generalSearchModal/GeneralSearchModal";
import StoriesViewer from "./utils/storiesViewer/StoriesViewer";
import ProfileImageUpload from "./components/profileImageUpload/ProfileImageUpload";
import ProfileCoverImageUpload from "./components/profileCoverImageUpload/ProfileCoverImageUpload";
import ChatBoxMobile from "./components/chatBoxMobile/ChatBoxMobile";
import MobileSettingsBody from "./components/mobileSettingsModal/mobileSettingsBody/MobileSettingsBody";
import CustomToast from "./utils/CustomToast";
import MobileStoriesModal from "./components/mobileStoriesModal/MobileStoriesModal";
import EachMatchesModal from "./components/luvHubComp/eachMatchesModal/EachMatchesModal";
import CallScreen from "./components/agoraComponent/basics/CallScreen";
import DefaultNotificationViewer from "./utils/defaultNotificationViewer/DefaultNotificationViewer";
import LuvHubLocationModal from "./components/luvHubComp/luvHubLocationModal/LuvHubLocationModal";
import ChangePasswordModal from "./components/changePasswordModal/ChangePasswordModal";
import DeactivateAccountModal from "./components/deactivateAccountModal/DeactivateAccountModal";
import {
  ShowBlockedUserModal,
  ShowBlockingUserModal,
} from "./components/blockUserFamModal/BlockUserFamModal";

const RoutesE = () => {
  let routes = useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/chat",
      element: <ChatHomePage />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicyPage />,
    },
    {
      path: "/terms-condition",
      element: <TermsandConditionPage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/register",
      element: <RegisterPage />,
    },
    {
      path: "/verify-register",
      element: <VerifyRegisterPage />,
    },
    {
      path: "/personal-data-register",
      element: <PersonalInfoRegisterPage />,
    },
    {
      path: "/password-reset",
      element: <PasswordResetPage />,
    },
    {
      path: "/password-reset-password/:resetToken",
      element: <PasswordInputPage />,
    },
    {
      path: "/registration-summary",
      element: <RegisterSummaryPage />,
    },

    {
      path: "/interests",
      element: <InterestsPage />,
    },

    {
      path: "/notification-preferences",
      element: <NotificationPreferencesPage />,
    },

    {
      path: "/security-privacy",
      element: <SecurityAndPrivacyPage />,
    },
    {
      path: "/account",
      element: <UserAccountPage />,
    },
    {
      path: "/bookmarks",
      element: <BookmarksPage />,
    },
    {
      path: "/user-profile",
      element: <ViewProfilePage />,
    },
    {
      path: "/help-support",
      element: <HelpSupportPage />,
    },
    {
      path: "/supamall",
      element: <SupaMallHomePage />,
    },
    {
      path: "/supamall/:eachProduct",
      element: <SupaMallEachProductPage />,
    },

    {
      path: "/supamall/supermarket",
      element: <SupermarketCategoryPage />,
    },
    {
      path: "/supamall/real-estate",
      element: <RealEstateCategoryPage />,
    },
    {
      path: "/supamall/phones-tablet",
      element: <PhonesTabletCategoryPage />,
    },
    {
      path: "/supamall/home-appliances",
      element: <HomeAppliancesCategoryPage />,
    },
    {
      path: "/supamall/health",
      element: <HealthCategoryPage />,
    },
    {
      path: "/supamall/furniture",
      element: <FurnitureCatgoryPage />,
    },
    {
      path: "/supamall/fashion",
      element: <FashionCategoryPage />,
    },
    {
      path: "/supamall/computing",
      element: <ComputingCategoryPage />,
    },
    {
      path: "/supamall/automobiles",
      element: <AutomobilesCategoryPage />,
    },
    {
      path: "/supamall/stylist",
      element: <StylistServicesPage />,
    },
    {
      path: "/supamall/ui-ux",
      element: <UiUxJobsPage />,
    },
    {
      path: "/supamall/photography",
      element: <PhotographyServicesPage />,
    },
    {
      path: "/supamall/cart",
      element: <SupamallCartPage />,
    },
    {
      path: "/supamall/sell-category",
      element: <SupaMallSellCategoryPage />,
    },
    {
      path: "/supamall/sell-product",
      element: <SupaMallProductFormPage />,
    },
    {
      path: "/supamall/sell-service",
      element: <SupaMallServicesFormPage />,
    },
    {
      path: "/supamall/sell-job",
      element: <SupaMallJobsFormPage />,
    },
    {
      path: "/luvhub/matches",
      element: <LuvhubHomePage />,
    },
    {
      path: "/luvhub/explore",
      element: <ExplorePageCardPage />,
    },
    {
      path: "/luvhub/home",
      element: <LuvhubSideBarPage />,
    },
    {
      path: "/delete-account",
      element: <DeleteAccountPage />,
    },
  ]);
  return routes;
};

const AppWrapper = () => {
  const { notificationModal } = useSelector(
    (state) => state.notificationToggle.notificationToggle
  );

  const { postModal } = useSelector((state) => state.postToggle.postToggle);

  const { profileOptionModal } = useSelector(
    (state) => state.profileOptionToggle.profileOptionToggle
  );

  const { logoutModal } = useSelector(
    (state) => state.logoutModalToggle.logoutModalToggle
  );

  const { storyModal } = useSelector(
    (state) => state.storyModalToggle.storyModalToggle
  );

  const { interestHomeModal } = useSelector(
    (state) => state.interestHomeModalToggle.interestHomeModalToggle
  );

  const { generalToast } = useSelector(
    (state) => state.generalToastBody.generalToastBody
  );

  const { previewPostMedia } = useSelector(
    (state) => state.previewPostMediaBody.previewPostMediaBody
  );

  const { statusPreviewPostMedia } = useSelector(
    (state) => state.statusPreviewPostMediaBody.statusPreviewPostMediaBody
  );

  const { statusPreviewStoriesMedia } = useSelector(
    (state) => state.statusPreviewStoriesMediaBody.statusPreviewStoriesMediaBody
  );

  const { statusPostComment } = useSelector(
    (state) => state.statusPostCommentBody.statusPostCommentBody
  );

  const { showGeneralSearch } = useSelector(
    (state) => state.showGeneralSearchBody.showGeneralSearchBody
  );

  const { profileImageModal } = useSelector(
    (state) => state.profileImageModalBody.profileImageModalBody
  );

  const { profileCoverImageModal } = useSelector(
    (state) => state.profileCoverImageModalBody.profileCoverImageModalBody
  );

  const { openSettingsModal } = useSelector(
    (state) => state.openSettingsModalBody.openSettingsModalBody
  );

  const { showSuccessToast } = useSelector(
    (state) => state.showSuccessToastBody.showSuccessToastBody
  );

  const { mobileStoryModal } = useSelector(
    (state) => state.mobileStoryModalBody.mobileStoryModalBody
  );
  const { showVideoCall } = useSelector(
    (state) => state.showVideoCallBody.showVideoCallBody
  );

  const { eachMatchesModal } = useSelector(
    (state) => state.eachMatchesModalBody.eachMatchesModalBody
  );

  const { showDefaultToast } = useSelector(
    (state) => state.defaultToastBody.defaultToastBody
  );
  const { showLuvHubLocation } = useSelector(
    (state) => state.showLuvHubLocationBody.showLuvHubLocationBody
  );
  const { getEachMatchModalData } = useSelector(
    (state) => state.getEachMatchModalDataBody.getEachMatchModalDataBody
  );
  const { changePasswordModal } = useSelector(
    (state) => state.changePasswordModalBody.changePasswordModalBody
  );
  const { deactivateModal } = useSelector(
    (state) => state.deactivateModalBody.deactivateModalBody
  );
  const { showBlockedUser } = useSelector(
    (state) => state.showBlockedUserBody.showBlockedUserBody
  );
  const { showBlockingUser } = useSelector(
    (state) => state.showBlockingUserBody.showBlockingUserBody
  );

  return (
    <Router>
      <RoutesE />
      {notificationModal && <NotificationsCard />}
      {postModal && <CreatePostModal />}
      {profileOptionModal && <ProfileOptionModal />}
      {logoutModal && <LogoutModal />}
      {storyModal && <StoryModalCard />}
      {interestHomeModal && <InterestHomeModal />}
      {generalToast && (
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
          theme="dark"
        />
      )}
      {statusPreviewPostMedia && <PostGridViewer />}
      {statusPostComment && <CommentsModal />}
      {showGeneralSearch && <GeneralSearchModal />}
      {profileImageModal && <ProfileImageUpload />}
      {profileCoverImageModal && <ProfileCoverImageUpload />}
      {showVideoCall && <CallScreen />}
      {openSettingsModal && <MobileSettingsBody />}
      {mobileStoryModal && <MobileStoriesModal />}
      {showSuccessToast !== null && <CustomToast />}
      {statusPreviewStoriesMedia && <StoriesViewer />}
      {getEachMatchModalData !== null && <EachMatchesModal />}
      <DefaultNotificationViewer />
      {showLuvHubLocation && <LuvHubLocationModal />}
      {changePasswordModal && <ChangePasswordModal />}
      {deactivateModal && <DeactivateAccountModal />}
      {showBlockedUser && <ShowBlockedUserModal />}
      {showBlockingUser && <ShowBlockingUserModal />}
    </Router>
  );
};

export default AppWrapper;
