// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary_info_reg_body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.summary_info_reg_tab_body {
  width: 370px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* position: relative; */
}

.summary_content_reg_container {
  border: 1px solid #e7e7e8;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.summary_content_bottom_border {
  border-bottom: 1px solid #e2e4e9;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .summary_info_reg_body {
    flex-direction: column;
    gap: 20px;
  }

  .summary_info_reg_tab_body {
    max-width: 370px;
    width: 80%;
    margin-top: 50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/auth/registerSummary/registerSummary.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;;EAEb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gCAAgC;EAChC,WAAW;AACb;;AAEA;EACE;IACE,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,gBAAgB;IAChB,UAAU;IACV,gBAAgB;EAClB;AACF","sourcesContent":[".summary_info_reg_body {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.summary_info_reg_tab_body {\n  width: 370px;\n  height: 100%;\n  margin-left: auto;\n  margin-right: auto;\n  display: flex;\n\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  /* position: relative; */\n}\n\n.summary_content_reg_container {\n  border: 1px solid #e7e7e8;\n  width: 100%;\n  padding: 15px;\n  box-sizing: border-box;\n  border-radius: 20px;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n\n.summary_content_bottom_border {\n  border-bottom: 1px solid #e2e4e9;\n  width: 100%;\n}\n\n@media screen and (max-width: 600px) {\n  .summary_info_reg_body {\n    flex-direction: column;\n    gap: 20px;\n  }\n\n  .summary_info_reg_tab_body {\n    max-width: 370px;\n    width: 80%;\n    margin-top: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
