import React, { useState, useEffect } from "react";
import "./blockUserFamModal.css";
import InfoModal from "../infoModal/InfoModal";
import ModalHeaderIconCont from "../modalHeaderIconCont/ModalHeaderIconCont";
import { ban_deact, photo_sample_tab2, searchHelp } from "../images";
import AuthForwardBtn from "../button/authForwardBtn/AuthForwardBtn";
import {
  handleShowBlockedUsed,
  handleShowBlockingUsed,
} from "../../redux/userData";
import { useDispatch } from "react-redux";
import WhiteBtn from "../button/whiteBtn/WhiteBtn";
import AuthBtn from "../button/authBtn/AuthBtn";
import AuthInputIcon from "../defaultInput/authInputnIcon/AuthInputIcon";
import { axiosCalls } from "../../_api";
import { CallCustomToast } from "../../utils/CallCustomToast";
import UseClipRoundLoader from "../../utils/UseClipRoundLoader";
const followersList = [
  { user: " Leslie Alexander", profileImage: photo_sample_tab2 },
  { user: " Leslie Alexander", profileImage: photo_sample_tab2 },
  { user: " Leslie Alexander", profileImage: photo_sample_tab2 },
];
export const ShowBlockedUserModal = () => {
  const dispatch = useDispatch();

  return (
    <InfoModal
      width="600px"
      handleCloseModal={() => {
        dispatch(handleShowBlockedUsed(false));
      }}
    >
      <ModalHeaderIconCont
        modalHeaderIcon={ban_deact}
        modalHeaderName="Blocked Users"
      />

      <div
        onClick={() => {
          dispatch(handleShowBlockingUsed(true));
        }}
        className="show_blocked_user_modal_add"
      >
        + Add Users
      </div>

      {/* <div className="show_blocked_user_roww">
        <div className="show_blocked_user_roww_firstFlex">
          <div className="show_blocked_user_roww_firstFlex_photo">
            <img src={photo_sample_tab2} />
          </div>
          <div className="show_blocked_user_roww_firstFlex_name">
            Leslie Alexander
          </div>
        </div>
        <button className="show_blocked_user_roww_firstFlex_btn">
          Unblock
        </button>
      </div> */}

      <div className="security_tagging_media_body_btn_flex">
        <WhiteBtn
          handleBtn={() => {
            dispatch(handleShowBlockedUsed(false));
          }}
          whiteBtnText="Cancel"
          width="200px"
        />
        <AuthBtn btnText="Update Changes" width="200px" />
      </div>
    </InfoModal>
  );
};

export const ShowBlockingUserModal = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorLoading, setOnErrorLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [userRelation, setUserRelation] = useState(null);
  const capitalizeFirstLetter = (text) => {
    return text.toLowerCase().replace(/^\w/, (char) => char.toUpperCase());
  };

  useEffect(() => {
    handleGetUserRelations();
  }, []);

  const handleCheckboxChange = (content) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.includes(content)
        ? prevOptions.filter((option) => option !== content)
        : [...prevOptions, content]
    );
  };

  const handleGetUserRelations = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosCalls(
      "accounts/account-profile-relations?page=1&limit=50",
      "GET"
    );

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        setOnErrorLoading(true);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        setOnErrorLoading(false);
        setUserRelation(res?.data?.followers);
      }
    }
  };
  return (
    <InfoModal
      handleCloseModal={() => {
        dispatch(handleShowBlockingUsed(false));
      }}
      width="500px"
    >
      <ModalHeaderIconCont
        // modalHeaderIcon={ban_deact}
        modalHeaderName="Friends"
      />
      <AuthInputIcon
        marginTop="8px"
        width="100%"
        inputPlaceHolder="Search"
        inputLeftIcon={searchHelp}
      />

      {isLoading ? (
        <div
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <UseClipRoundLoader color="#008dcb" />
        </div>
      ) : (
        <div className="block_user_blocking_sel">
          <ul>
            {userRelation?.map((content, index) => (
              <li>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(content)}
                    onChange={() => {
                      handleCheckboxChange(content);
                      console.log(selectedOptions, "seledtedddd");
                    }}
                  />
                  <div className="show_blocking_user_imagee">
                    <img src={content?.profileImage} />
                  </div>
                  <div className="show_blocking_user_imagee_textt">
                    {capitalizeFirstLetter(content?.userName)}
                  </div>
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="security_tagging_media_body_btn_flex">
        <AuthBtn btnText="Add Users" width="200px" />
      </div>
    </InfoModal>
  );
};
