import React from "react";
import "./supaMallSideBar.css";
import Accordion from "../../../components/sidebarAccordion/SidebarAccordion";
import {
  bell_sidebar,
  heart_sidebar,
  lock_keyhole,
  user_settings_round,
  supamall_home_appliance,
  supamall_car_front,
  supamall_estate,
  supamall_heart,
  supamall_laptop,
  supamall_smartphone,
  supamall_armchair,
  supamall_shirt,
  supamall_store,
  settings,
  user_round_sidebar,
  bookmark_icon,
  bookmark,
  shopping_basket_nav,
} from "../../../components/images";
import SidebarOption from "../../../components/sidebarOption/SidebarOption";
import { useNavigate } from "react-router-dom";

const SupaMallSideBar = () => {
  const navigate = useNavigate();
  return (
    <div className="supamall_home_sidebar_body_top_body">
      <div className="supamall_home_sidebar_body">
        <div className="supamall_home_sidebar_headingggg">Categories</div>
        <Accordion
          setOpenFunc={() => {
            console.log("");
          }}
          // path="/admin/product"
          // icon={<AdminProductsIcon />}
          title="Products"
          content={[
            {
              section_name: "Supermarket",
              section_route: "/supamall/supermarket",
              icon: supamall_store,
            },
            {
              section_name: "Fashion",
              section_route: "/supamall/fashion",
              icon: supamall_shirt,
            },
            {
              section_name: "Furniture",
              section_route: "/supamall/furniture",
              icon: supamall_armchair,
            },
            {
              section_name: "Phones & Tablet",
              section_route: "/supamall/phones-tablet",
              icon: supamall_smartphone,
            },
            {
              section_name: "Computing",
              section_route: "/supamall/computing",
              icon: supamall_laptop,
            },
            {
              section_name: "Health",
              section_route: "/supamall/health",
              icon: supamall_heart,
            },
            {
              section_name: "Real estate",
              section_route: "/supamall/real-estate",
              icon: supamall_estate,
            },
            {
              section_name: "Automobiles",
              section_route: "/supamall/automobiles",
              icon: supamall_car_front,
            },
            {
              section_name: "Home appliances",
              section_route: "/supamall/home-appliances",
              icon: supamall_home_appliance,
            },
          ]}
        />
        <Accordion
          setOpenFunc={() => {
            console.log("");
          }}
          // path="/admin/product"
          // icon={<AdminProductsIcon />}
          title="Services"
          content={[
            {
              section_name: "Photography",
              section_route: "/supamall/photography",
              // icon: supamall_store,
            },
            {
              section_name: "Stylist",
              section_route: "/supamall/stylist",
              // icon: supamall_shirt,
            },
            {
              section_name: "Graphics design",
              // section_route: "/security-privacy",
              // icon: supamall_shirt,
            },
            {
              section_name: "Consultation",
              // section_route: "/security-privacy",
              // icon: supamall_shirt,
            },
            {
              section_name: "Education",
              // section_route: "/security-privacy",
              // icon: supamall_shirt,
            },
            {
              section_name: "Training",
              // section_route: "/security-privacy",
              // icon: supamall_shirt,
            },
            {
              section_name: "Home services",
              // section_route: "/security-privacy",
              // icon: supamall_shirt,
            },

            {
              section_name: "Others",
              // section_route: "/security-privacy",
              // icon: supamall_shirt,
            },
          ]}
        />
        <Accordion
          // accordionBodyIcon={settings}
          setOpenFunc={() => {
            console.log("");
          }}
          // path="/admin/product"
          // icon={<AdminProductsIcon />}
          title="Jobs"
          content={[
            {
              section_name: "UI/UX",
              section_route: "/supamall/ui-ux",
              // icon: supamall_store,
            },
            {
              section_name: "Accounting",
              // section_route: "/security-privacy",
              // icon: supamall_shirt,
            },
            {
              section_name: "Banker",
              // section_route: "/security-privacy",
              // icon: supamall_shirt,
            },
            {
              section_name: "Developer",
              section_route: "/security-privacy",
              // icon: supamall_shirt,
            },
            {
              section_name: "Lawyer",
              // section_route: "/security-privacy",
              // icon: supamall_shirt,
            },
            {
              section_name: "Cleaner",
              // section_route: "/security-privacy",
              // icon: supamall_shirt,
            },

            {
              section_name: "Analyst",
              // section_route: "/security-privacy",
              // icon: supamall_shirt,
            },

            {
              section_name: "Others",
              // section_route: "/security-privacy",
              // icon: supamall_shirt,
            },
          ]}
        />
      </div>

      <div style={{ marginTop: "20px" }} className="supamall_home_sidebar_body">
        <SidebarOption
          icon={user_round_sidebar}
          text="Home"
          showChevronIcon={true}
          path="/"
          onClickFunc={() => {
            navigate("/");
          }}
        />
        <SidebarOption
          // icon={}
          text="Sell"
          showChevronIcon={true}
          path="/supamall/sell-category"
          onClickFunc={() => {
            navigate("/supamall/sell-category");
          }}
        />
        <SidebarOption
          icon={bookmark}
          text="Saved Items"
          showChevronIcon={true}
          path="/supamall/cart"
          onClickFunc={() => {
            navigate("/supamall/cart");
          }}
        />
        <SidebarOption
          icon={shopping_basket_nav}
          text="Cart"
          noOfNotification="2"
          showChevronIcon={true}
          path="/supamall/cart"
          onClickFunc={() => {
            navigate("/supamall/cart");
          }}
        />
      </div>
    </div>
  );
};

export default SupaMallSideBar;
