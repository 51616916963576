import { configureStore } from "@reduxjs/toolkit";
import counterReducer, { rootReducer } from "./userData";

export default configureStore({
  reducer: {
    // notificationToggle: counterReducer,
    showErrorToastBody: rootReducer,
    mobileStoryModalBody: rootReducer,
    showSuccessToastBody: rootReducer,
    toastDataBody: rootReducer,
    toastHeaderDataBody: rootReducer,
    notificationToggle: rootReducer,
    postToggle: rootReducer,
    profileOptionToggle: rootReducer,
    viewProfileToggle: rootReducer,
    logoutModalToggle: rootReducer,
    storyModalToggle: rootReducer,
    interestHomeModalToggle: rootReducer,
    postOptionPopModalToggle: rootReducer,
    generalToastBody: rootReducer,
    previewPostMediaBody: rootReducer,
    statusPreviewPostMediaBody: rootReducer,
    statusPostCommentBody: rootReducer,
    previewPostCommentBody: rootReducer,
    showAllPostCommentBody: rootReducer,
    showGeneralSearchBody: rootReducer,
    previewStoriesMediaBody: rootReducer,
    statusPreviewStoriesMediaBody: rootReducer,
    profileImageModalBody: rootReducer,
    profileCoverImageModalBody: rootReducer,
    viewAnotherUserProfileBody: rootReducer,
    collectUserIdBody: rootReducer,
    openMobileNavbarBody: rootReducer,
    showChatBoxBody: rootReducer,
    showChatBoxBody: rootReducer,
    showEachUserChatBody: rootReducer,
    showVideoCallBody: rootReducer,
    openSettingsModalBody: rootReducer,
    agoraVideoCallTokenBody: rootReducer,
    eachMatchesModalBody: rootReducer,
    getEachChannelTokenBody: rootReducer,
    defaultToastBody: rootReducer,
    showLuvHubLocationBody: rootReducer,
    showExplorePageBody: rootReducer,
    getMoreMatchesReqNameBody: rootReducer,
    getEachMatchModalDataBody: rootReducer,
    getEachExploreDataBody: rootReducer,
    chatOptionPopModalBody: rootReducer,
    serverChatDataBody: rootReducer,
    changePasswordModalBody: rootReducer,
    deactivateModalBody: rootReducer,
    showBlockedUserBody: rootReducer,
    showBlockingUserBody: rootReducer,
  },
});
