import React from "react";
import "./innerAccordionHeader.css";
import AuthTopIcon from "../authTopIcon/AuthTopIcon";

const InnerAccordionHeader = ({ ringIcon, headerText, subText }) => {
  return (
    <div className="user_account_home_body_head">
      <AuthTopIcon userDummyIcon={ringIcon} />
      <div className="user_account_home_heading_flex">
        <div className="user_account_home_heading_text">{headerText}</div>
        <div className="user_account_home_heading_text_sub">{subText}</div>
      </div>
    </div>
  );
};

export default InnerAccordionHeader;
