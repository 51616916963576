// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interests_card_bodyGen {
  border: 1px solid #e2e4e9;
  background: #fff;
  padding: 30px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.interests_card_bodyHead {
  margin-bottom: 10px;
}

.interest_card_input_body {
  width: 100%;
  margin-top: 30px;
}

.interest_card_input_body_label {
  font-size: 14px;
  font-weight: 400;
  color: #525866;
}

.interest_card_footer_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/interestComp/interestCard/interestCard.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,UAAU;EACV,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;EACX,SAAS;EACT,gBAAgB;AAClB","sourcesContent":[".interests_card_bodyGen {\n  border: 1px solid #e2e4e9;\n  background: #fff;\n  padding: 30px;\n  width: 70%;\n  margin-left: auto;\n  margin-right: auto;\n  border-radius: 20px;\n}\n\n.interests_card_bodyHead {\n  margin-bottom: 10px;\n}\n\n.interest_card_input_body {\n  width: 100%;\n  margin-top: 30px;\n}\n\n.interest_card_input_body_label {\n  font-size: 14px;\n  font-weight: 400;\n  color: #525866;\n}\n\n.interest_card_footer_btn {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  width: 100%;\n  gap: 10px;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
