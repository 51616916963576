// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register_verify_input_body {
  width: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.register_verify_input_body_headerr {
  font-size: 20px;
  font-weight: 600;
  color: #0a0d14;
  margin-top: 20px;
  text-align: center;
}
.register_verify_input_body_head_textt {
  font-size: 13px;
  font-weight: 400;
  color: #525866;
  font-style: normal;
  margin-top: 2px;
  /* width: 320px; */
  text-align: center;
}

.register_verify_resend_code_body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.register_verify_resend_code_text {
  font-size: 12px;
  font-weight: 400;
  color: #525866;
  font-style: normal;
}

.register_verify_resend_code_btn {
  color: #008dcb;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .fixed_side_plane {
    display: none;
  }

  .register_verify_input_body {
    max-width: 370px;
    width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/auth/verifyRegister/verifyRegister.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,UAAU;EACZ;AACF","sourcesContent":[".register_verify_input_body {\n  width: 370px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  margin-left: auto;\n  margin-right: auto;\n  margin-top: 40px;\n}\n\n.register_verify_input_body_headerr {\n  font-size: 20px;\n  font-weight: 600;\n  color: #0a0d14;\n  margin-top: 20px;\n  text-align: center;\n}\n.register_verify_input_body_head_textt {\n  font-size: 13px;\n  font-weight: 400;\n  color: #525866;\n  font-style: normal;\n  margin-top: 2px;\n  /* width: 320px; */\n  text-align: center;\n}\n\n.register_verify_resend_code_body {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 10px;\n  margin-top: 20px;\n  margin-bottom: 10px;\n}\n\n.register_verify_resend_code_text {\n  font-size: 12px;\n  font-weight: 400;\n  color: #525866;\n  font-style: normal;\n}\n\n.register_verify_resend_code_btn {\n  color: #008dcb;\n  font-size: 12px;\n  font-weight: 400;\n  font-style: normal;\n  cursor: pointer;\n  text-decoration: underline;\n}\n\n@media screen and (max-width: 600px) {\n  .fixed_side_plane {\n    display: none;\n  }\n\n  .register_verify_input_body {\n    max-width: 370px;\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
