import React, { useEffect } from "react";
import "./followingTab.css";
import EachFollowerCard from "../../eachFollowerCard/EachFollowerCard";

const FollowingTab = ({ userData }) => {
  useEffect(() => {
    console.log(userData, "snippet");
  }, []);

  return (
    <div className="profile_following_tab_body">
      {userData?.followings?.map((data, index) => (
        <EachFollowerCard data={data} />
      ))}
    </div>
  );
};

export default FollowingTab;
