// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supamall_home_body_gen {
  /* max-width: 1300px;
  margin-left: auto;
  margin-right: auto; */
  width: 100%;
  margin-top: 30px;
}

.supamall_home_body_gen_firstsection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.supamall_home_body_gen_firstsection_row {
  width: calc(75% - 20px);
}

.loadMore_card_supamall_body_button_body {
  width: 200px;

  border: 1px solid;
  box-shadow: 0px 1px 2px 0px #9d3d0b7a;
  border-image-source: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: #ef6924;
  color: #fff;

  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  height: 40px;
  transition: 0.7s ease-in-out 0s;
  margin-top: 50px;
  margin-bottom: 30px;
}

@media screen and (max-width: 1300px) {
  .supamall_home_body_gen_firstsection {
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 850px) {
  .supamall_home_body_gen_firstsection_row {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/supaMall/supaMallHome/supaMallHome.css"],"names":[],"mappings":"AAAA;EACE;;uBAEqB;EACrB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,8BAA8B;EAC9B,WAAW;EACX,SAAS;EACT,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;;EAEZ,iBAAiB;EACjB,qCAAqC;EACrC;;;;GAIC;EACD,mBAAmB;EACnB,WAAW;;EAEX,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;EACR,eAAe;EACf,iBAAiB;EACjB,kBAAkB;;EAElB,YAAY;EACZ,+BAA+B;EAC/B,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".supamall_home_body_gen {\n  /* max-width: 1300px;\n  margin-left: auto;\n  margin-right: auto; */\n  width: 100%;\n  margin-top: 30px;\n}\n\n.supamall_home_body_gen_firstsection {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  width: 100%;\n  gap: 20px;\n  max-width: 1300px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.supamall_home_body_gen_firstsection_row {\n  width: calc(75% - 20px);\n}\n\n.loadMore_card_supamall_body_button_body {\n  width: 200px;\n\n  border: 1px solid;\n  box-shadow: 0px 1px 2px 0px #9d3d0b7a;\n  border-image-source: linear-gradient(\n    180deg,\n    rgba(255, 255, 255, 0.12) 0%,\n    rgba(255, 255, 255, 0) 100%\n  );\n  background: #ef6924;\n  color: #fff;\n\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 5px;\n  cursor: pointer;\n  margin-left: auto;\n  margin-right: auto;\n\n  height: 40px;\n  transition: 0.7s ease-in-out 0s;\n  margin-top: 50px;\n  margin-bottom: 30px;\n}\n\n@media screen and (max-width: 1300px) {\n  .supamall_home_body_gen_firstsection {\n    box-sizing: border-box;\n    padding-left: 20px;\n    padding-right: 20px;\n  }\n}\n\n@media screen and (max-width: 850px) {\n  .supamall_home_body_gen_firstsection_row {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
