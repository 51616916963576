import React, { useState, useEffect } from "react";
import "./allUsersChat.css";
import { chat_search, more_horizontal } from "../../../components/images";
import AuthInputIcon from "../../../components/defaultInput/authInputnIcon/AuthInputIcon";
import EachChatUser from "../../../components/chatComponents/eachChatUser/EachChatUser";
import { axiosCalls } from "../../../_api";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import { useDispatch } from "react-redux";
import UseClipRoundLoader from "../../../utils/UseClipRoundLoader";

const AllUsersChat = () => {
  const [tab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorLoading, setOnErrorLoading] = useState(false);
  const [userRelation, setUserRelation] = useState(null);
  const handleTabSelection = (status) => {
    setTab(status);
  };

  useEffect(() => {
    handleGetUserRelations();
  }, []);

  const dispatch = useDispatch();

  const handleGetUserRelations = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosCalls(
      "accounts/account-profile-relations?page=1&limit=50",
      "GET"
    );

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        // console.log(res?.er.data?.message);
        setOnErrorLoading(true);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        setOnErrorLoading(false);
        setUserRelation(res?.data?.followings);
        console.log(userRelation, "user relation");
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };

  return (
    <div className="all_users_chat_body">
      <div className="all_users_chat_header_horizon">
        <div className="all_user_chat_text_horizon">Chat</div>
        <div className="all_user_chat_text_icon">
          <img src={more_horizontal} />
        </div>
      </div>

      <div className="all_user_chatbody_sectionGen">
        <div className="all_user_chatBody_toggleBody">
          <div
            className={
              tab === 0 ? "all_user_chatToggle_Active" : "all_user_chatToggle_"
            }
            // onClick={() => {
            //   handleTabSelection(0);
            // }}
          >
            <div
              className={
                tab === 0
                  ? "all_user_chatToggle_text_active"
                  : "all_user_chatToggle_text"
              }
            >
              Chats
            </div>
            <div className="all_user_chatToggle_text_notifyNum">3</div>
          </div>
          {/* <div
            className={
              tab === 1 ? "all_user_chatToggle_Active" : "all_user_chatToggle_"
            }
            onClick={() => {
              handleTabSelection(1);
            }}
          >
            <div
              className={
                tab === 1
                  ? "all_user_chatToggle_text_active"
                  : "all_user_chatToggle_text"
              }
            >
              SupaMall
            </div>

            <div className="all_user_chatToggle_text_notifyNum">2</div>
          </div>
          <div
            className={
              tab === 2 ? "all_user_chatToggle_Active" : "all_user_chatToggle_"
            }
            onClick={() => {
              handleTabSelection(2);
            }}
          >
            <div
              className={
                tab === 2
                  ? "all_user_chatToggle_text_active"
                  : "all_user_chatToggle_text"
              }
            >
              LuvHub
            </div>
            <div className="all_user_chatToggle_text_notifyNum">6</div>
          </div> */}
        </div>

        {tab == 0 && (
          <div className="all_userSectionss">
            <div className="all_user_chat_body___search_">
              <AuthInputIcon
                width="100%"
                inputPlaceHolder="Search"
                inputLeftIcon={chat_search}
              />
            </div>

            <div className="all_userSectionss_chatScroll">
              {isLoading ? (
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <UseClipRoundLoader size={50} color="#ef6a23" />
                </div>
              ) : (
                <div style={{ width: "100%", height: "100%" }}>
                  {userRelation?.map((data) => (
                    <EachChatUser data={data} />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {/* {tab == 1 && (
          <div className="all_userSectionss">
            <div className="all_user_chat_body___search_">
              <AuthInputIcon
                width="100%"
                inputPlaceHolder="Search"
                inputLeftIcon={chat_search}
              />
            </div>

            <EachChatUser />
          </div>
        )}

        {tab == 2 && (
          <div className="all_userSectionss">
            <div className="all_user_chat_body___search_">
              <AuthInputIcon
                width="100%"
                inputPlaceHolder="Search"
                inputLeftIcon={chat_search}
              />
            </div>

            <EachChatUser />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default AllUsersChat;
