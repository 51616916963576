import React from "react";
import "./legalBodyComponent.css";

const LegalBodyComponent = ({ paragraphHeading, paragraphBody }) => {
  return (
    <div className="legal_body_component_body">
      <div className="legal_body_component_heading">{paragraphHeading}</div>
      <div className="legal_body_component_paragraphBody">{paragraphBody}</div>
    </div>
  );
};

export default LegalBodyComponent;
