import React from "react";
import "./verifyRegister.css";
import AuthBodyMargin from "../../../components/authBodyMargin/AuthBodyMargin";
import {
  auth_key_reg,
  newSingleLogo,
  talk_stuff_authLogo,
} from "../../../components/images";
import AuthInput from "../../../components/defaultInput/authInput/AuthInput";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";
import GoBackBtn from "../../../components/button/gobackBtn/GoBackBtn";
import { useNavigate } from "react-router-dom";
import AuthTopIcon from "../../../components/authTopIcon/AuthTopIcon";

const VerifyRegister = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <AuthBodyMargin>
      <div className="termsandcondition_heading_logo">
        <img src={newSingleLogo} />
      </div>

      <GoBackBtn handleGoBack={handleGoBack} />
      {/* </motion.div> */}
      <div className="register_verify_input_body">
        <AuthTopIcon userDummyIcon={auth_key_reg} />
        <div className="register_verify_input_body_headerr">
          Verify Your Email Address
        </div>
        <div className="register_verify_input_body_head_textt">
          We just sent a verification code to talkstuff@gmail.com. Please enter
          the code below to confirm your identity.
        </div>

        <AuthInput
          inputPlaceHolder="Enter 6-digit code"
          inputLabel="Verification code"
          width="100%"
        />

        <div className="register_verify_resend_code_body">
          <div className="register_verify_resend_code_text">
            If you haven't received the code, you can{" "}
          </div>
          <div className="register_verify_resend_code_btn">Resend code</div>
        </div>

        <AuthBtn
          handleClick={() => {
            navigate("/personal-data-register");
          }}
          btnText="Verify"
          width="100%"
        />
      </div>
    </AuthBodyMargin>
  );
};

export default VerifyRegister;
