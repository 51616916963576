// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supamall_home_body_gen_firstsection_row_cart {
  width: calc(75% - 20px);
  border: 1px solid #e2e4e9;
  border-radius: 20px;
  padding: 13px;
  box-sizing: border-box;
}

.supamall_home_body_gen_firstsection_row_cart_top_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.supamall_home_body_gen_firstsection_row_cart_top_flex_cartT {
  color: #0a0d14;
  font-size: 15px;
  font-weight: 600px;
}

.supamall_home_body_gen_firstsection_row_cart_top_flexcartN {
  color: #0a0d14;
  font-size: 13px;
  font-weight: 600;
}

.supamall_home_body_gen_firstsection_row_cart_top_flexcartN span {
  color: #525866;
  font-size: 13px;
  font-weight: 400;
}

.bottom_border_supamall_cart_left {
  border-bottom: 1px solid #e2e4e9;
  width: 100%;
  margin-bottom: 20px;
}

@media screen and (max-width: 850px) {
  .supamall_home_body_gen_firstsection_row_cart {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/supaMall/supamallCart/supamallCart.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;EAChC,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".supamall_home_body_gen_firstsection_row_cart {\n  width: calc(75% - 20px);\n  border: 1px solid #e2e4e9;\n  border-radius: 20px;\n  padding: 13px;\n  box-sizing: border-box;\n}\n\n.supamall_home_body_gen_firstsection_row_cart_top_flex {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  box-sizing: border-box;\n  margin-bottom: 20px;\n}\n\n.supamall_home_body_gen_firstsection_row_cart_top_flex_cartT {\n  color: #0a0d14;\n  font-size: 15px;\n  font-weight: 600px;\n}\n\n.supamall_home_body_gen_firstsection_row_cart_top_flexcartN {\n  color: #0a0d14;\n  font-size: 13px;\n  font-weight: 600;\n}\n\n.supamall_home_body_gen_firstsection_row_cart_top_flexcartN span {\n  color: #525866;\n  font-size: 13px;\n  font-weight: 400;\n}\n\n.bottom_border_supamall_cart_left {\n  border-bottom: 1px solid #e2e4e9;\n  width: 100%;\n  margin-bottom: 20px;\n}\n\n@media screen and (max-width: 850px) {\n  .supamall_home_body_gen_firstsection_row_cart {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
