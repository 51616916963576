import React, { useState } from "react";
import "./changePasswordModal.css";
import InfoModal from "../infoModal/InfoModal";
import { handleChangePasswordModal } from "../../redux/userData";
import { useDispatch } from "react-redux";
import ModalHeaderIconCont from "../modalHeaderIconCont/ModalHeaderIconCont";
import { lock_keyholeee } from "../images";
import AuthInput from "../defaultInput/authInput/AuthInput";
import AuthBtn from "../button/authBtn/AuthBtn";
import WhiteBtn from "../button/whiteBtn/WhiteBtn";
import AuthPasswordInput from "../defaultInput/authPasswordInput/AuthPasswordInput";
import { CallCustomToast } from "../../utils/CallCustomToast";
import { axiosCalls } from "../../_api";

const ChangePasswordModal = () => {
  const dispatch = useDispatch();

  const [formValue, setFormValue] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [eightCharac, setEightCharac] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [oneUpperCase, setOneUpperCase] = useState(false);
  const [error, setError] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "newPassword":
          // Validation for password
          if (!value) {
            stateObj[name] = "Please enter password.";
          } else if (
            formValue.confirmPassword &&
            value !== formValue.confirmPassword
          ) {
            stateObj["confirmPassword"] =
              "Password and confirm password does not match.";
          } else {
            // Check for password criteria
            if (value.length >= 8) {
              setEightCharac(true);
            } else {
              setEightCharac(false);
              stateObj[name] = "Password is less than 8 characters";
            }
            if (value.match(/[0-9]/)) {
              // Check if value contains at least one number
              setOneNumber(true);
            } else {
              setOneNumber(false);
              stateObj[name] = "Password has no number";
            }
            if (/[A-Z]/.test(value)) {
              setOneUpperCase(true);
            } else {
              setOneUpperCase(false);
              stateObj[name] = "Password has no uppercase";
            }
            stateObj["confirmPassword"] = formValue.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          // Validation for confirm password
          if (!value) {
            stateObj[name] = "Please enter confirm password.";
          } else if (formValue.newPassword && value !== formValue.newPassword) {
            stateObj[name] = "Password and confirm password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handlePasswordInputSubmit = async (e) => {
    const data = {
      password: formValue?.newPassword,
      oldPassword: formValue?.currentPassword,
    };

    // showLoader();
    setIsLoading(true);
    const res = await axiosCalls(
      "accounts/account-change-password",
      "PATCH",
      data
    );

    if (res) {
      setIsLoading(false);
      // hideLoader();
      console.log(res);
      if (!res?.status) {
        console.log(res?.message);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.message,
        });

        return;
      } else if (res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });

        setFormValue({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        dispatch(handleChangePasswordModal(false));
      }
    }
  };

  return (
    <InfoModal
      handleCloseModal={() => {
        dispatch(handleChangePasswordModal(false));
      }}
      width="600px"
    >
      <ModalHeaderIconCont
        modalHeaderIcon={lock_keyholeee}
        modalHeaderName="Password"
        modalHeaderParagraph="Update your password and stay protected"
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handlePasswordInputSubmit();
        }}
      >
        <AuthPasswordInput
          passwordLabel="Current Password"
          passwordValue={formValue?.currentPassword}
          passwordName="currentPassword"
          passwordPlaceHolder="TalkStuff"
          required={true}
          passwordOnchange={handleChange}
        />
        <AuthPasswordInput
          passwordLabel="New Password"
          passwordValue={formValue?.newPassword}
          passwordName="newPassword"
          passwordPlaceHolder="TalkStuff"
          required={true}
          passwordOnchange={handleChange}
          error={error.newPassword}
          validateInput={validateInput}
        />
        <AuthPasswordInput
          passwordLabel="Confirm Password"
          passwordValue={formValue?.confirmPassword}
          passwordName="confirmPassword"
          passwordPlaceHolder="TalkStuff"
          required={true}
          passwordOnchange={handleChange}
          error={error.confirmPassword}
          validateInput={validateInput}
        />

        <div className="change_password_bodyyyyssbtn_flex">
          <WhiteBtn
            handleBtn={() => {
              dispatch(handleChangePasswordModal(false));
            }}
            width="200px"
            whiteBtnText="Discard"
          />
          <AuthBtn
            isLoading={isLoading}
            btnDisabled={
              formValue.newPassword === "" ||
              formValue.confirmPassword === "" ||
              oneNumber === false ||
              oneUpperCase === false ||
              eightCharac === false ||
              error.confirmPassword !== ""
            }
            width="200px"
            btnText="Update Password"
          />
        </div>
      </form>
    </InfoModal>
  );
};

export default ChangePasswordModal;
