import React from "react";
import "./homeThirdSection.css";
import CategoryCard from "../categoryCard/CategoryCard";
import {
  auto_mobile_supa,
  fashion_supa,
  sample_category_image,
} from "../../images";

const HomeThirdSection = () => {
  return (
    <div className="supamall_homethirdbody_section">
      <CategoryCard catText="Phone & Tablet" image={sample_category_image} />
      <CategoryCard catText="Vehicles" image={auto_mobile_supa} />
      <CategoryCard catText="Fashion" image={fashion_supa} />
    </div>
  );
};

export default HomeThirdSection;
