import React, { useEffect } from "react";
import "./explorePageCard.css";
import ExplorePageImageSwiper from "../explorePageImageSwiper/ExplorePageImageSwiper";
import ExplorePageReportCard from "../explorePageReportCard/ExplorePageReportCard";
import { useDispatch, useSelector } from "react-redux";
import GoBackBtn from "../../button/gobackBtn/GoBackBtn";
import { useNavigate } from "react-router-dom";

const ExplorePageCard = ({
  setShowPageReportTab,
  showPageReportTab,
  isScreenWide,
}) => {
  const { showExplorePage } = useSelector(
    (state) => state.showExplorePageBody.showExplorePageBody
  );

  const { getEachExploreData } = useSelector(
    (state) => state.getEachExploreDataBody.getEachExploreDataBody
  );

  useEffect(() => {
    console.log("hdhcbdhbfd", getEachExploreData);
  }, []);

  const navigate = useNavigate();
  return (
    <div className="explore___home_bodyyy__genf">
      {!isScreenWide && (
        <div className="explore___home_bodyyy__genf_goBack">
          <GoBackBtn
            handleGoBack={() => {
              navigate(-1);
            }}
          />
        </div>
      )}
      <ExplorePageImageSwiper getEachExploreData={getEachExploreData} />
      {showExplorePage && (
        <ExplorePageReportCard getEachExploreData={getEachExploreData} />
      )}
    </div>
  );
};

export default ExplorePageCard;
