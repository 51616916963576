import React, { useState, useEffect } from "react";
import PostCardTopInfo from "../postCard/postCardTopInfo/PostCardTopInfo";
import PostCardImage from "../postCard/postCardImage/PostCardImage";
import PostCardCaptionText from "../postCard/postCardCaptionText/PostCardCaptionText";
import PostCardCaption from "../postCard/postCardCaption/PostCardCaption";
import ProfileOptionEach from "../profileOptionModal/profileOptionEach/ProfileOptionEach";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  post_bookmark,
  post_message_circle,
  post_share_two,
  post_user_round,
} from "../images";
import "./postCardComponent.css";
import {
  handleCollectUserId,
  handlePostOptionPopModal,
  handlePreviewPostComment,
  handleViewAnotherUserProfile,
} from "../../redux/userData";
import PostGridViewer from "../postGridViewer/PostGridViewer";
import {
  PostCardFour,
  PostCardOne,
  PostCardThree,
  PostCardTwo,
} from "../postCard/postCardGridShow/PostCardGridShow";
import { axiosCalls } from "../../_api";
import { CallCustomToast } from "../../utils/CallCustomToast";
import { useLocation } from "react-router-dom";

const PostCardComponent = ({
  showFollowBtn,
  postCaption,
  postUser,
  index,
  files,
  imageFiles,
  userPost,
}) => {
  const [checked, setChecked] = useState(false);
  const [ownerDetails, setOwnerDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  // const dropdownRef = useRef(null);

  // const triggerRef = useRef(null);
  // const toggleDropdown = () => setIsOpen(!isOpen);

  //   useEffect(() => {
  //     const handleClickOutside = (event) => {
  //       if (
  //         dropdownRef.current &&
  //         !dropdownRef.current.contains(event.target) &&
  //         !triggerRef.current.contains(event.target)
  //       ) {
  //         setIsOpen(false);
  //       }
  //     };

  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }, []);
  const getObjectFromLocalStorage = () => {
    const item = localStorage.getItem("userData");
    const itemized = JSON.parse(item);
    setOwnerDetails(itemized);
    console.log(ownerDetails, "oejen");
  };
  useEffect(() => {
    getObjectFromLocalStorage();
  }, []);

  const navigate = useNavigate();

  const handleProfileOptionSwitch = () => {
    setChecked(!checked);
  };
  const dispatch = useDispatch();

  const { postOptionPopModal } = useSelector(
    (state) => state.postOptionPopModalToggle.postOptionPopModalToggle
  );
  const postOptionData = [
    {
      postText: "View profile",
      postIcon: { post_user_round },
    },
    {
      postText: "Message Kristin Watsone",
      postIcon: { post_share_two },
    },
    {
      postText: "Share post",
      postIcon: { post_bookmark },
    },
    {
      postText: "Bookmark poste",
      postIcon: { post_bookmark },
    },
  ];

  const handleBookmarkPost = async (e) => {
    const data = {
      feedId: userPost?._id,
    };
    setIsLoading(true);
    const res = await axiosCalls("feeds/feed-bookmarks", "POST", data);

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });

        return;
      } else if (res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
      }
    }
  };

  const handleDeletePost = async (e) => {
    const data = {
      feedId: userPost?._id,
    };
    //  setIsLoading(true);
    const res = await axiosCalls("feeds/delete-feed", "DELETE", data);

    if (res) {
      console.log(res);
      //  setIsLoading(false);
      if (!res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });

        return;
      } else if (res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
      }
    }
  };
  // useEffect(() => {
  //   console.log(userPost, "user post");
  // }, []);

  return (
    <div className="post_card_body">
      <PostCardTopInfo
        userPost={userPost}
        handlePostMoreBtn={(event) => {
          event.stopPropagation();

          dispatch(handlePostOptionPopModal(index));
        }}
        showFollowBtn={showFollowBtn}
      />

      {imageFiles?.length === 1 && <PostCardOne images={imageFiles} />}
      {imageFiles?.length === 2 && <PostCardTwo images={imageFiles} />}
      {imageFiles?.length === 3 && <PostCardThree images={imageFiles} />}
      {imageFiles?.length >= 4 && <PostCardFour images={imageFiles} />}

      {/* <PostGridViewer images={imageFiles} /> */}

      <PostCardCaption
        userPost={userPost}
        postCaption={postCaption}
        postUser={postUser}
      />

      {postOptionPopModal == index && (
        <div className="post_card_body_option_dropdown">
          <ProfileOptionEach
            handleProfileOptionSwitch={() => {
              // dispatch(handlePostOptionPopModal(index));
            }}
            checked={checked}
            showSwitch={false}
            profileOptionText="View profile"
            profileOptionIcon={post_user_round}
            handleOptionClick={() => {
              {
                userPost?.userId?._id !== ownerDetails?._id
                  ? dispatch(handleViewAnotherUserProfile(true))
                  : dispatch(handleViewAnotherUserProfile(false));
              }
              dispatch(handleCollectUserId(userPost?.userId?._id));
              navigate("/user-profile");

              dispatch(handlePostOptionPopModal(null));
            }}
            // path="/user-profile"
          />
          <ProfileOptionEach
            handleProfileOptionSwitch={() => {
              // dispatch(handlePostOptionPopModal(index));
            }}
            checked={checked}
            showSwitch={false}
            profileOptionText={`Message ${userPost?.userId?.userName || ""}`}
            profileOptionIcon={post_message_circle}
            handleOptionClick={() => {
              navigate("/chat");
              dispatch(handlePostOptionPopModal(null));

              // dispatch(handleToggleProfileOption(false));
            }}
            // path="/user-profile"
          />
          {/* <ProfileOptionEach
            handleProfileOptionSwitch={() => {
              // dispatch(handlePostOptionPopModal(index));
            }}
            checked={checked}
            showSwitch={false}
            profileOptionText="Share post"
            profileOptionIcon={post_share_two}
            handleOptionClick={() => {
              // navigate("/user-profile");
              // dispatch(handleToggleProfileOption(false));
            }}
            // path="/user-profile"
          /> */}
          <ProfileOptionEach
            handleProfileOptionSwitch={() => {
              // dispatch(handlePostOptionPopModal(index));
            }}
            checked={checked}
            showSwitch={false}
            profileOptionText="Bookmark post"
            profileOptionIcon={post_bookmark}
            handleOptionClick={() => {
              handleBookmarkPost();
              dispatch(handlePostOptionPopModal(null));
            }}
            // path="/user-profile"
          />
          {location.pathname === "/user-profile" &&
            userPost?.createdBy === ownerDetails?.email && (
              <ProfileOptionEach
                handleProfileOptionSwitch={() => {
                  // dispatch(handlePostOptionPopModal(index));
                }}
                checked={checked}
                showSwitch={false}
                profileOptionText="Delete Post"
                // profileOptionIcon={post_bookmark}
                handleOptionClick={() => {
                  handleDeletePost();
                  dispatch(handlePostOptionPopModal(null));
                }}
                color="#ea0722"
                // path="/user-profile"
              />
            )}
        </div>
      )}
    </div>
  );
};

export default PostCardComponent;
