// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create_postwarning_modal_media_body_btn_flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}
.create_postwarning_modal_media_header {
  color: #525866;
  font-size: 12px;
  font-weight: 400;
  margin-top: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/createPost/createPostWarningModal/createPostWarningModal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,SAAS;EACT,WAAW;EACX,sBAAsB;AACxB;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".create_postwarning_modal_media_body_btn_flex {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 10px;\n  width: 100%;\n  box-sizing: border-box;\n}\n.create_postwarning_modal_media_header {\n  color: #525866;\n  font-size: 12px;\n  font-weight: 400;\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
