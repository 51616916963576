import React from "react";
import "./mediumModalHeader.css";
import { user_mo, x_lo_mo } from "../images";

const MediumModalHeader = ({
  mediumModalIcon,
  mediumModalText,
  mediumModalSubText,
  showXIcon,
}) => {
  return (
    <div className="logout_modal_toggle_body">
      <div className="logout_modal_toggle_left_body">
        <div className="logout_modal_toggle_left_img">
          <img src={mediumModalIcon} />
        </div>
        <div className="logout_modal_toggle_left_text_body">
          <div className="logout_modal_toggle_left_text_head">
            {mediumModalText}
          </div>
          <div className="logout_modal_toggle_left_text_sub">
            {mediumModalSubText}
          </div>
        </div>
      </div>

      {showXIcon && (
        <div className="logout_modal_toggle_right_img">
          <img src={x_lo_mo} />
        </div>
      )}
    </div>
  );
};

export default MediumModalHeader;
