// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post_card_body {
  border: 1px solid #e2e4e9;

  background: #ffffff;

  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-direction: column;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}
.network_xerror_body {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.post_card_body____ {
  width: calc(100% - 400px);
}

.network_error_body {
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 1150px) {
  .post_card_body____ {
    width: 100%;
  }

  .post_card_body {
    gap: 7px;
  }
}

@media screen and (max-width: 850px) {
  .post_card_body____ {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/postCard/postCard.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;;EAEzB,mBAAmB;;EAEnB,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,QAAQ;EACV;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".post_card_body {\n  border: 1px solid #e2e4e9;\n\n  background: #ffffff;\n\n  padding: 16px;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n  flex-direction: column;\n  border-radius: 20px;\n  width: 100%;\n  margin-bottom: 20px;\n  position: relative;\n}\n.network_xerror_body {\n  margin-left: auto;\n  margin-right: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.post_card_body____ {\n  width: calc(100% - 400px);\n}\n\n.network_error_body {\n  width: fit-content;\n  margin-top: 200px;\n  margin-left: auto;\n  margin-right: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n@media screen and (max-width: 1150px) {\n  .post_card_body____ {\n    width: 100%;\n  }\n\n  .post_card_body {\n    gap: 7px;\n  }\n}\n\n@media screen and (max-width: 850px) {\n  .post_card_body____ {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
