import React from "react";
import "./categoryHeaderTop.css";
import { category_header_image, category_watermark } from "../../images";

const CategoryHeaderTop = ({ name }) => {
  return (
    <div className="category_header_top_gen_body">
      <div className="category_header_top_gen_body_textt">{name}</div>

      <div className="category_header_top_gen_body_genn">
        <div className="category_header_top_gen_body_mainImage">
          <img src={category_header_image} />
        </div>
        <div className="category_header_top_gen_body_water_mark">
          <img src={category_watermark} />
        </div>
      </div>
    </div>
  );
};

export default CategoryHeaderTop;
