import React from "react";
import "./bottomNavbar.css";
import {
  bottom_home,
  bottom_luvhub,
  bottom_messages,
  bottom_plus_copy,
  bottom_supamall,
  chat_mobile_icon,
  mobile_heart,
  mobile_store,
} from "../../images";
import BottomHome from "../../images/files/BottomHome";
import BottomPlus from "../../images/files/BottomPlus";
import BottomLuvhub from "../../images/files/BottomLuvhub";
import BottomMessages from "../../images/files/BottomMessages";
import BottomSupamall from "../../images/files/BottomSupamall";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  handlePostOptionPopModal,
  handleShowChatBox,
  handleToggleCreatePost,
  handleToggleProfileOption,
  handleViewAnotherUserProfile,
} from "../../../redux/userData";
import MobileStore from "../../images/files/MobileStore";
import MobileHeart from "../../images/files/MobileHeart";

const BottomNavbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { postModal } = useSelector((state) => state.postToggle.postToggle);

  return (
    <div className="bottom_navbar_bodyGen">
      {/* <div
        onClick={() => {
          navigate("/");
          dispatch(handleShowChatBox(false));
        }}
        className="bottom_navbar_bodyGen_icon"
      >
        <div className="bottom_navbar_bodyGen_iconEach">
          <BottomHome
            fill={location.pathname === "/" ? "#e45911" : "#525866"}
          />
        </div>
        <div
          className={
            location.pathname === "/"
              ? "bottom_navbar_bodyGen_iconEach_text_active"
              : "bottom_navbar_bodyGen_iconEach_text"
          }
        >
          Home
        </div>
      </div> */}

      {/* <div
        onClick={() => {
          dispatch(handleToggleCreatePost(true));
          dispatch(handleShowChatBox(false));
        }}
        className="bottom_navbar_bodyGen_icon"
      >
        <div className="bottom_navbar_bodyGen_iconEach">
          <BottomPlus fill="#525866" />
        </div>
        <div
      
          className="bottom_navbar_bodyGen_iconEach_text"
        >
          Add
        </div>
      </div>

      <div className="bottom_navbar_bodyGen_icon">
        <div className="bottom_navbar_bodyGen_iconEach">
          <BottomLuvhub fill="#525866" />
        </div>
        <div className="bottom_navbar_bodyGen_iconEach_text">LuvHub</div>
      </div>

      <div
        onClick={() => {
          navigate("/chat");
          dispatch(handleShowChatBox(false));
        }}
        className="bottom_navbar_bodyGen_icon"
      >
        <div className="bottom_navbar_bodyGen_iconEach">
          <BottomMessages
            fill={location.pathname === "/chat" ? "#e45911" : "#525866"}
          />
        </div>
        <div
          className={
            location.pathname === "/chat"
              ? "bottom_navbar_bodyGen_iconEach_text_active"
              : "bottom_navbar_bodyGen_iconEach_text"
          }
        >
          Chat
        </div>
      </div>

      <div className="bottom_navbar_bodyGen_icon">
        <div className="bottom_navbar_bodyGen_iconEach">
          <BottomSupamall fill="#525866" />
        </div>
        <div className="bottom_navbar_bodyGen_iconEach_text">SupaMall</div>
      </div>

      <div
        onClick={() => {
          navigate("/user-profile");
          dispatch(handleShowChatBox(false));
          dispatch(handleViewAnotherUserProfile(false));
        }}
        className="bottom_navbar_bodyGen_icon"
      >
        <div className="bottom_navbar_bodyGen_iconEach">
          <BottomHome
            fill={location.pathname === "/user-profile" ? "#e45911" : "#525866"}
          />
        </div>
        <div
          className={
            location.pathname === "/user-profile"
              ? "bottom_navbar_bodyGen_iconEach_text_active"
              : "bottom_navbar_bodyGen_iconEach_text"
          }
        >
          Profile
        </div>
      </div> */}

      <div
        onClick={() => {
          navigate("/supamall");
        }}
        className="bottom_navbar_chat_bodyyy"
      >
        <div className="bottom_navbar_chat_bodyyy_icon">
          <MobileStore
            fill={
              location.pathname.startsWith("/supamall") ? "#EF6924" : "#525866"
            }
          />
        </div>
        <div
          style={{
            color: location.pathname.startsWith("/supamall") && "#EF6924",
          }}
          className="bottom_navbar_chat_bodyyy_text"
        >
          SupaMall
        </div>
      </div>
      <div
        onClick={() => {
          navigate("/chat");
        }}
        className="bottom_navbar_chat_icon"
      >
        <img src={chat_mobile_icon} />
      </div>
      <div
        onClick={() => {
          navigate("/luvhub/home");
        }}
        className="bottom_navbar_chat_bodyyy"
      >
        <div className="bottom_navbar_chat_bodyyy_icon">
          <MobileHeart
            fill={
              location.pathname.startsWith("/luvhub") ? "#EF6924" : "#525866"
            }
          />
        </div>
        <div
          style={{
            color: location.pathname.startsWith("/luvhub") && "#EF6924",
          }}
          className="bottom_navbar_chat_bodyyy_text"
        >
          LuvHub
        </div>
      </div>
    </div>
  );
};

export default BottomNavbar;
