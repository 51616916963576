import React, { useState, useEffect } from "react";
import "./notificationsCard.css";
import { useDispatch, useSelector } from "react-redux";
import InfoModal from "../infoModal/InfoModal";
import { handleToggleNotification } from "../../redux/userData";
import ModalHeaderIconCont from "../modalHeaderIconCont/ModalHeaderIconCont";
import AllNotification from "./allNotification/AllNotification";
import SupaMallNotification from "./supaMallNotification/SupaMallNotification";
import LuvHubNotification from "./luvHubNotification/LuvHubNotification";
import { bell_noti } from "../images";
import ScreenWidth from "../../utils/ScreenWidth";
import { axiosCalls, axiosNotificationCalls } from "../../_api";
import { CallCustomToast } from "../../utils/CallCustomToast";

const NotificationsCard = () => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState(0);
  const [userNotifications, setUserNotifications] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [onErrorLoading, setOnErrorLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    handleGetUserNotifications();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 500
    ) {
      handleGetUserNotifications();
    }
  };

  const handleTab = (status) => {
    setTab(status);
  };

  const handleGetUserNotifications = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosNotificationCalls(
      `notifications/fetch-notifications?page=${currentPage}`,
      "GET"
    );

    if (res) {
      console.log(res);
      setIsLoading(false);

      if (!res?.status) {
        // console.log(res?.er.data?.message);
        setOnErrorLoading(true);

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });

        return;
      } else if (res?.status) {
        const resData = res.data || [];
        console.log(resData, "user_notifications");
        setCurrentPage((prevPage) => prevPage + 1);
        setOnErrorLoading(false);
        setUserNotifications((prevPosts) => [...prevPosts, ...resData]);
      }
    }
  };

  return (
    <InfoModal
      handleCloseModal={() => {
        dispatch(handleToggleNotification(false));
      }}
      height="500px"
      minHeight="0"
      width="600px"
      overflowY="hidden"
    >
      <div className="notification_family_gen_bodyyyyy">
        {/* <div className="notifications_stickyHeadd"> */}
        <ModalHeaderIconCont
          modalHeaderIcon={bell_noti}
          modalHeaderName="Notification"
          showRead={true}
        />

        <div className="notifications_card_tabs_body">
          <div
            onClick={() => {
              handleTab(0);
            }}
            className={
              tab === 0
                ? "notifications_card_tabs_each_active"
                : "notifications_card_tabs_each"
            }
          >
            All
          </div>
          <div
            onClick={() => {
              handleTab(1);
            }}
            className={
              tab === 1
                ? "notifications_card_tabs_each_active"
                : "notifications_card_tabs_each"
            }
          >
            SupaMall
          </div>
          <div
            onClick={() => {
              handleTab(2);
            }}
            className={
              tab === 2
                ? "notifications_card_tabs_each_active"
                : "notifications_card_tabs_each"
            }
          >
            LuvHub
          </div>
        </div>
        {/* </div> */}

        <div className="notification_details_bodyyyyyyy">
          {tab === 0 && (
            <AllNotification
              isLoading={isLoading}
              userNotifications={userNotifications}
            />
          )}

          {tab === 1 && <SupaMallNotification />}

          {tab === 2 && <LuvHubNotification />}
        </div>
      </div>
    </InfoModal>
  );
};

export default NotificationsCard;
