import React from "react";
import { flag_report, location_map__ } from "../../images";
import AuthBtn from "../../button/authBtn/AuthBtn";
import WhiteBtn from "../../button/whiteBtn/WhiteBtn";
import "./explorePageReportCard.css";
import useCalculateAge from "../../../utils/useCalculateAge";

const ExplorePageReportCard = ({ getEachExploreData }) => {
  const age = useCalculateAge(getEachExploreData?.user?.dob);

  return (
    <div className="explore_page_report_card_gen_bodyy">
      <div className="explore_page_report_card_name_year_body">
        <div className="explore_page_report_card_name_">
          {getEachExploreData?.user?.userName ?? ""}
        </div>
        <div className="explore_page_report_card_year_">{age ?? ""}</div>
      </div>
      <div className="explore_page_report_card_location_body_">
        <div className="explore_page_report_card_location_icon">
          <img src={location_map__} />
        </div>
        <div className="explore_page_report_card_location_distance">
          10 Kilometers away
        </div>
      </div>
      <div className="explore_page_report_card_interest_body_">
        <div className="explore_page_report_card_interest_head">Interests</div>
        <div className="explore_page_report_card_interest_flex">
          {getEachExploreData?.interests?.map((interest, index) => (
            <div className="explore_page_report_card_interest_flex_each">
              {interest}
            </div>
          ))}
        </div>
      </div>

      <div className="explore_page_report_card_looking_for_body">
        <div className="explore_page_report_card_looking_for_head">
          Looking for
        </div>
        <div className="explore_page_report_card_looking_for_body_header">
          New friends
        </div>
      </div>

      {/* <div className="explore_page_report_card_passion_body_">
        <div className="explore_page_report_card_passion_head">Passion</div>
        <div className="explore_page_report_card_interest_flex">
          <div className="explore_page_report_card_interest_flex_each">
            Cooking
          </div>
       
        </div>
      </div> */}

      <div className="explore_page_report_card_report_body_">
        <div className="explore_page_report_card_report_flag">
          <img src={flag_report} />
        </div>
        <div className="explore_page_report_card_report_text">Report</div>
      </div>
      <div className="explore_page_report_card_down_btn">
        <AuthBtn btnText="Message" width="100%" />
        <WhiteBtn whiteBtnText="Close" width="100%" />
      </div>
    </div>
  );
};

export default ExplorePageReportCard;
