import talk_stuff_white from "./files/talk_stuff_white.svg";
import talk_stuff_authLogo from "./files/talk_stuff_authLogo.svg";
import chevronLeftlegal from "./files/chevronLeftlegal.svg";
import user_login_dummy from "./files/user_login_dummy.svg";
import login_carousel_imgOne from "./files/login_carousel_imgOne.svg";
import login_carousel_imgTwo from "./files/login_carousel_imgTwo.svg";
import login_carousel_imgThree from "./files/login_carousel_imgThree.svg";
import login_carousel_circleOne from "./files/login_carousel_circleOne.svg";
import login_carousel_circleTwo from "./files/login_carousel_circleTwo.svg";
import login_carousel_circleThree from "./files/login_carousel_circleThree.svg";
import ellipse from "./files/ellipse.png";
import appleLogo from "./files/appleLogo.svg";
import googleBtn from "./files/googleBtn.svg";
import twitterLogo from "./files/twitterLogo.svg";
import openEye from "./files/openEye.svg";
import closeEye from "./files/closeEye.svg";
import mail_icon from "./files/mail_icon.svg";
import lock_icon from "./files/lock_icon.svg";
import chevron_right from "./files/chevron_right.svg";
import userRegister_plus from "./files/userRegister_plus.svg";
import auth_key_reg from "./files/auth_key_reg.svg";
import chevron_right__ from "./files/chevron_right__.svg";
import personal_user_icon from "./files/personal_user_icon.svg";
import user_round from "./files/user_round.svg";
import map_pin from "./files/map_pin.svg";
import lock from "./files/lock.svg";
import x_circle from "./files/x_circle.svg";
import check_circle from "./files/check_circle.svg";
import check from "./files/check.svg";
import square_user from "./files/square_user.svg";
import pencil_line from "./files/pencil_line.svg";
import user_roundd from "./files/user_roundd.svg";
import phone from "./files/phone.svg";
import mail from "./files/mail.svg";
import mailDummy from "./files/mailDummy.svg";
import talk_stuffnav_logo from "./files/talk_stuffnav_logo.svg";
import store from "./files/store.svg";
import messages_square from "./files/messages_square.svg";
import heart from "./files/heart.svg";
import search from "./files/search.svg";
import secondary_language_img from "./files/secondary_language_img.svg";
import user_round_sidebar from "./files/user_round_sidebar.svg";
import chevron_right_icon from "./files/chevron_right_icon.svg";
import bell from "./files/bell.svg";
import copy_plus from "./files/copy_plus.svg";
import bookmark from "./files/bookmark.svg";
import help_circle from "./files/help_circle.svg";
import sample_image from "./files/sample_image.svg";
import chevron_up from "./files/chevron_up.svg";
import settings from "./files/settings.svg";
import chevron_up_sidebar from "./files/chevron_up_sidebar.svg";
import lock_keyhole from "./files/lock_keyhole.svg";
import more_btn from "./files/more_btn.svg";
import heartforlike from "./files/heartforlike.svg";
import sampleLikedImg from "./files/sampleLikedImg.svg";
import heart_liked from "./files/heart_liked.svg";
import comment_circle from "./files/comment_circle.svg";
import user_settings_round from "./files/user_settings_round.svg";
import bell_sidebar from "./files/bell_sidebar.svg";
import heart_sidebar from "./files/heart_sidebar.svg";
import user_round_cog from "./files/user_round_cog.svg";
import lock_keyhole_head from "./files/lock_keyhole_head.svg";
import bell_head from "./files/bell_head.svg";
import heart_head from "./files/heart_head.svg";
import film from "./files/film.svg";
import sample_profile_picss from "./files/sample_profile_picss.jpeg";
import bell_noti from "./files/bell_noti.svg";
import check_check from "./files/check_check.svg";
import default_notification_pics from "./files/default_notification_pics.png";
import stories_sample_image from "./files/stories_sample_image.png";
import stories_eye from "./files/stories_eye.svg";
import modal_pointer from "./files/modal_pointer.svg";
import copy_plus_post from "./files/copy_plus_post.svg";
import chevron_right_post from "./files/chevron_right_post.svg";
import user_round_post from "./files/user_round_post.svg";
import book_image from "./files/book_image.svg";
import user_round_stories from "./files/user_round_stories.svg";
import x_image from "./files/x_image.svg";
import apple_store_downloadBtn from "./files/apple_store_downloadBtn.svg";
import google_playstore_btn from "./files/google_playstore_btn.svg";

import profile_pop_user from "./files/profile_pop_user.svg";

import profile_option_eye from "./files/profile_option_eye.svg";

import profile_option_plus from "./files/profile_option_plus.svg";
import profile_option_logout from "./files/profile_option_logout.svg";
import user_mo from "./files/user_mo.svg";
import x_lo_mo from "./files/x_lo_mo.svg";
import log_out_img_icon from "./files/log_out_img_icon.svg";
import backdrop_sample from "./files/backdrop_sample.jpeg";
import pencil_profile from "./files/pencil_profile.svg";
import pencil_white_profile from "./files/pencil_white_profile.svg";
import sample_follower_img from "./files/sample_follower_img.png";
import more_vertical from "./files/more_vertical.svg";
import photo_library_sample from "./files/photo_library_sample.png";
import photo_sample_tab2 from "./files/photo_sample_tab2.png";
import stories_sampleShow from "./files/stories_sampleShow.png";
import paperclip from "./files/paperclip.svg";
import bookmark_icon from "./files/bookmark_icon.svg";

import help_circle_icon from "./files/help_circle_icon.svg";
import searchHelp from "./files/searchHelp.svg";
import talkStuff_support_card from "./files/talkStuff_support_card.svg";
import chevron_righttt from "./files/chevron_righttt.svg";
import plus_interest from "./files/plus_interest.svg";
import heartInterest from "./files/heartInterest.svg";
import post_user_round from "./files/post_user_round.svg";
import post_message_circle from "./files/post_message_circle.svg";
import post_share_two from "./files/post_share_two.svg";
import post_bookmark from "./files/post_bookmark.svg";
import check_circled from "./files/check_circled.svg";
import post_oneImage from "./files/post_oneImage.jpeg";
import post_twoImage from "./files/post_twoImage.jpeg";
import post_threeImage from "./files/post_threeImage.jpeg";
import post_fourImage from "./files/post_fourImage.jpeg";
import minigadget from "./files/minigadget.mp4";
import sampleVid from "./files/sampleVid.mp4";
import oneMinutes from "./files/oneMinutes.mp4";
import mediaPlayer from "./files/mediaPlayer.svg";
import message_profile from "./files/message_profile.svg";
import more_vertical_profile from "./files/more_vertical_profile.svg";
import more_horizontal from "./files/more_horizontal.svg";
import chat_search from "./files/chat_search.svg";
import check_check_message from "./files/check_check_message.svg";
import video from "./files/video.svg";
import phone_outgoing from "./files/phone_outgoing.svg";
import more_horizontal__ from "./files/more_horizontal__.svg";
import languages___ from "./files/languages___.svg";
import smileplus_icon from "./files/smileplus_icon.svg";
import clip_text from "./files/clip_text.svg";
import mic_chat from "./files/mic_chat.svg";
import align_left from "./files/align_left.svg";
import bottom_home from "./files/bottom_home.svg";
import bottom_luvhub from "./files/bottom_luvhub.svg";
import bottom_messages from "./files/bottom_messages.svg";
import bottom_plus_copy from "./files/bottom_plus_copy.svg";
import bottom_supamall from "./files/bottom_supamall.svg";
import arrow_left from "./files/arrow_left.svg";
import chat_mobile_icon from "./files/chat_mobile_icon.svg";
import mobile_store from "./files/mobile_store.svg";
import mobile_heart from "./files/mobile_heart.svg";
import mobile_film from "./files/mobile_film.svg";
import log_out_mobile from "./files/log_out_mobile.svg";
import success_toast from "./files/success_toast.svg";
import close_toast from "./files/close_toast.svg";
import error_toastIcon from "./files/error_toastIcon.svg";
import supamall_store from "./files/supamall_store.svg";
import supamall_shirt from "./files/supamall_shirt.svg";
import supamall_armchair from "./files/supamall_armchair.svg";
import supamall_smartphone from "./files/supamall_smartphone.svg";
import supamall_laptop from "./files/supamall_laptop.svg";

import supamall_heart from "./files/supamall_heart.svg";
import supamall_estate from "./files/supamall_estate.svg";
import supamall_car_front from "./files/supamall_car_front.svg";
import supamall_home_appliance from "./files/supamall_home_appliance.svg";
import homesecond_supamallleft from "./files/homesecond_supamallleft.png";
import homesecond_supamallleftab from "./files/homesecond_supamallleftab.png";
import sample_category_image from "./files/sample_category_image.svg";
import arrow_up_supa from "./files/arrow_up_supa.svg";
import fashion_supa from "./files/fashion_supa.svg";
import auto_mobile_supa from "./files/auto_mobile_supa.png";
import sample_supa_productt from "./files/sample_supa_productt.jpeg";
import shopping_basket from "./files/shopping_basket.svg";
import chevron_right_more from "./files/chevron_right_more.svg";
import laptop_sample from "./files/laptop_sample.svg";
import delivery_footer from "./files/delivery_footer.svg";
import payment_supamall_footer from "./files/payment_supamall_footer.svg";
import support_footer_supamall from "./files/support_footer_supamall.svg";
import talk_stuff_footer_white from "./files/talk_stuff_footer_white.svg";
import mail_input_icon from "./files/mail_input_icon.svg";
import only_logo_footer_supamall from "./files/only_logo_footer_supamall.svg";
import footer_apple_btn_supamall from "./files/footer_apple_btn_supamall.svg";

import footer_google_btn_supamall from "./files/footer_google_btn_supamall.svg";
import x_icon_footerSupamall from "./files/x_icon_footerSupamall.svg";
import facebook_footer_supamall from "./files/facebook_footer_supamall.svg";
import instagram_footer_supamall from "./files/instagram_footer_supamall.svg";
import youtube_footer_supamall from "./files/youtube_footer_supamall.svg";
import eac_sec_one from "./files/eac_sec_one.jpeg";
import supamall_user_icon from "./files/supamall_user_icon.svg";
import supamall_messages_square from "./files/supamall_messages_square.svg";
import supamall_star from "./files/supamall_star.svg";
import message_circle_message from "./files/message_circle_message.svg";
import supamall_phone from "./files/supamall_phone.svg";
import category_watermark from "./files/category_watermark.svg";
import category_header_image from "./files/category_header_image.jpeg";
import shopping_basket_nav from "./files/shopping_basket_nav.svg";
import supamall_cart_clock from "./files/supamall_cart_clock.svg";
import supamall_map_pin from "./files/supamall_map_pin.svg";
import supamall_carteye from "./files/supamall_carteye.svg";
import supamall_cartmessage_circle from "./files/supamall_cartmessage_circle.svg";
import sell_categ_icon from "./files/sell_categ_icon.svg";
import info_supamall_product_add from "./files/info_supamall_product_add.svg";
import shopping_basket_two from "./files/shopping_basket_two.svg";
import luvhub_matchsample from "./files/luvhub_matchsample.jpeg";
import simpleBell from "./files/simpleBell.mp3";
import x_cancel from "./files/x_cancel.svg";
import love_matches_iconn from "./files/love_matches_iconn.svg";
import matches_profile_pics from "./files/matches_profile_pics.jpeg";
import matches_info from "./files/matches_info.svg";
import placeholder_supamall_image from "./files/placeholder_supamall_image.jpg";
import bookmark_sect_btn from "./files/bookmark_sect_btn.svg";
import ellipse_luvhub_hh from "./files/ellipse_luvhub_hh.svg";
import location_map__ from "./files/location_map__.svg";
import flag_report from "./files/flag_report.svg";
import info__alert from "./files/info__alert.svg";
import empty_luvhubbb__ from "./files/empty_luvhubbb__.svg";
import x_cancelll from "./files/x_cancelll.svg";
import lock_keyholeee from "./files/lock_keyholeee.svg";
import ban_deact from "./files/ban_deact.svg";
import user_round_block from "./files/user_round_block.svg";
import newFlexedLogo from "./files/newFlexedLogo.svg";
import newColumnLogo from "./files/newColumnLogo.svg";
import newSingleLogo from "./files/newSingleLogo.png";
import newFlexedLogoPng from "./files/newFlexedLogo.png";

export {
  newFlexedLogoPng,
  newSingleLogo,
  newFlexedLogo,
  newColumnLogo,
  user_round_block,
  ban_deact,
  lock_keyholeee,
  x_cancelll,
  empty_luvhubbb__,
  info__alert,
  flag_report,
  location_map__,
  ellipse_luvhub_hh,
  bookmark_sect_btn,
  placeholder_supamall_image,
  matches_info,
  love_matches_iconn,
  matches_profile_pics,
  x_cancel,
  simpleBell,
  luvhub_matchsample,
  shopping_basket_two,
  info_supamall_product_add,
  supamall_cartmessage_circle,
  sell_categ_icon,
  supamall_carteye,
  supamall_map_pin,
  supamall_cart_clock,
  shopping_basket_nav,
  category_header_image,
  category_watermark,
  supamall_phone,
  message_circle_message,
  supamall_star,
  supamall_messages_square,
  supamall_user_icon,
  eac_sec_one,
  youtube_footer_supamall,
  instagram_footer_supamall,
  facebook_footer_supamall,
  x_icon_footerSupamall,
  footer_google_btn_supamall,
  footer_apple_btn_supamall,
  only_logo_footer_supamall,
  mail_input_icon,
  talk_stuff_footer_white,
  support_footer_supamall,
  payment_supamall_footer,
  delivery_footer,
  laptop_sample,
  shopping_basket,
  chevron_right_more,
  sample_supa_productt,
  auto_mobile_supa,
  fashion_supa,
  arrow_up_supa,
  homesecond_supamallleftab,
  sample_category_image,
  homesecond_supamallleft,
  supamall_home_appliance,
  supamall_car_front,
  supamall_estate,
  supamall_heart,
  supamall_laptop,
  supamall_smartphone,
  supamall_armchair,
  supamall_shirt,
  supamall_store,
  error_toastIcon,
  close_toast,
  success_toast,
  log_out_mobile,
  mobile_film,
  mobile_heart,
  mobile_store,
  chat_mobile_icon,
  arrow_left,
  bottom_home,
  bottom_luvhub,
  bottom_messages,
  bottom_plus_copy,
  bottom_supamall,
  mic_chat,
  align_left,
  clip_text,
  smileplus_icon,
  languages___,
  more_horizontal__,
  phone_outgoing,
  video,
  more_horizontal,
  check_check_message,
  chat_search,
  more_vertical_profile,
  message_profile,
  mediaPlayer,
  oneMinutes,
  sampleVid,
  minigadget,
  post_oneImage,
  post_twoImage,
  post_threeImage,
  post_fourImage,
  check_circled,
  post_bookmark,
  post_share_two,
  post_message_circle,
  post_user_round,
  heartInterest,
  plus_interest,
  chevron_righttt,
  talkStuff_support_card,
  searchHelp,
  help_circle_icon,
  bookmark_icon,
  paperclip,
  stories_sampleShow,
  photo_sample_tab2,
  photo_library_sample,
  more_vertical,
  sample_follower_img,
  backdrop_sample,
  pencil_white_profile,
  pencil_profile,
  log_out_img_icon,
  profile_option_logout,
  x_lo_mo,
  profile_option_plus,
  talk_stuff_white,
  profile_option_eye,
  talk_stuff_authLogo,
  chevronLeftlegal,
  user_login_dummy,
  login_carousel_imgOne,
  login_carousel_imgTwo,
  login_carousel_imgThree,
  login_carousel_circleOne,
  login_carousel_circleTwo,
  login_carousel_circleThree,
  ellipse,
  appleLogo,
  googleBtn,
  twitterLogo,
  openEye,
  closeEye,
  mail_icon,
  lock_icon,
  chevron_right,
  userRegister_plus,
  auth_key_reg,
  chevron_right__,
  personal_user_icon,
  user_round,
  map_pin,
  lock,
  x_circle,
  check_circle,
  check,
  square_user,
  pencil_line,
  user_roundd,
  phone,
  mail,
  mailDummy,
  talk_stuffnav_logo,
  store,
  messages_square,
  heart,
  search,
  secondary_language_img,
  user_round_sidebar,
  chevron_right_icon,
  bell,
  copy_plus,
  bookmark,
  help_circle,
  sample_image,
  chevron_up,
  settings,
  chevron_up_sidebar,
  lock_keyhole,
  more_btn,
  heartforlike,
  sampleLikedImg,
  heart_liked,
  comment_circle,
  user_settings_round,
  bell_sidebar,
  heart_sidebar,
  user_round_cog,
  lock_keyhole_head,
  bell_head,
  heart_head,
  film,
  sample_profile_picss,
  bell_noti,
  check_check,
  default_notification_pics,
  stories_sample_image,
  stories_eye,
  modal_pointer,
  copy_plus_post,
  chevron_right_post,
  user_round_post,
  book_image,
  user_round_stories,
  x_image,
  apple_store_downloadBtn,
  google_playstore_btn,
  profile_pop_user,
  user_mo,
};
