import React from "react";
import "./aboutOverviewLocation.css";
import EditRegContent from "../../../editRegContent/EditRegContent";

const AboutOverviewLocation = ({ ownerDetails, anotherUserProfile }) => {
  return (
    <div className="about_tab_overview_body">
      <div className="about_overview_content_reg_container">
        <EditRegContent
          anotherUserProfile={anotherUserProfile}
          //   sectionIcon={square_user}
          sectionHead="Location"
          sectionText={ownerDetails?.location ?? "--"}
        />

        <div className="about_overview_content_bottom_border"></div>
        <EditRegContent
          anotherUserProfile={anotherUserProfile}
          //   sectionIcon={user_roundd}
          sectionHead="Country"
          sectionText={ownerDetails?.country ?? "--"}
        />
      </div>
    </div>
  );
};

export default AboutOverviewLocation;
