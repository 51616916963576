// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.matches_card_cont_bodyy_toptext {
  color: #0a0d14;
  font-size: 12px;
  font-weight: 600;

  margin-top: 13px;
  margin-bottom: 13px;
}

.matches_card_cont_bodyy_view_gen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  box-sizing: border-box;
  padding: 10px;
  transition: 0.3s ease-in-out 0s;
  background: #fff;
  margin-top: 13px;
  margin-bottom: 5px;
  cursor: pointer;
}

.matches_card_cont_bodyy_view_gen:hover {
  transition: 0.3s ease-in-out 0s;

  background: #d8eff8;

  box-shadow: 0px 0.99px 1.98px 0px #5258660d;
  border-radius: 10px;
}

.matches_card_cont_bodyy_view_text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #525866;
  font-size: 12.89px;
  font-weight: 600;
}

.matches_card_cont_bodyy_view_img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/luvHubComp/matchesCardCont/matchesCardCont.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;;EAEhB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,sBAAsB;EACtB,aAAa;EACb,+BAA+B;EAC/B,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,+BAA+B;;EAE/B,mBAAmB;;EAEnB,2CAA2C;EAC3C,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":[".matches_card_cont_bodyy_toptext {\n  color: #0a0d14;\n  font-size: 12px;\n  font-weight: 600;\n\n  margin-top: 13px;\n  margin-bottom: 13px;\n}\n\n.matches_card_cont_bodyy_view_gen {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 20px;\n  box-sizing: border-box;\n  padding: 10px;\n  transition: 0.3s ease-in-out 0s;\n  background: #fff;\n  margin-top: 13px;\n  margin-bottom: 5px;\n  cursor: pointer;\n}\n\n.matches_card_cont_bodyy_view_gen:hover {\n  transition: 0.3s ease-in-out 0s;\n\n  background: #d8eff8;\n\n  box-shadow: 0px 0.99px 1.98px 0px #5258660d;\n  border-radius: 10px;\n}\n\n.matches_card_cont_bodyy_view_text {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #525866;\n  font-size: 12.89px;\n  font-weight: 600;\n}\n\n.matches_card_cont_bodyy_view_img {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
