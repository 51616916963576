import React, { useState, useEffect } from "react";
import SplashScreen from "../../../components/splashScreen/SplashScreen";
import "./home.css";
import StoriesBar from "../../../components/layout/storiesBar/StoriesBar";
import PostCard from "../../../components/postCard/PostCard";
import HomeMargin from "../../../components/homeMargin/HomeMargin";
import NotificationsCard from "../../../components/notificationsCard/NotificationsCard";
import { useDispatch, useSelector } from "react-redux";
import { handleInterestHomeModal } from "../../../redux/userData";
import MobileStoriesBody from "../../../components/mobileStories/mobileStoriesBody/MobileStoriesBody";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import { axiosCalls } from "../../../_api";

const Home = () => {
  // const [showSplash, setShowSplash] = useState(true);
  const dispatch = useDispatch();
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [userData, setUserData] = useState({});

  // useEffect(() => {
  //   const isFirstLoad = localStorage.getItem("isFirstLoad") === null;

  //   if (isFirstLoad) {
  //     // If it's the first load, hide the splash screen after a delay
  //     setTimeout(() => {
  //       setShowSplash(false);
  //       localStorage.setItem("isFirstLoad", "false");
  //     }, 3000); // Adjust the delay as needed
  //   } else {
  //     // If it's not the first load, hide the splash screen immediately
  //     setShowSplash(false);
  //   }
  // }, []);

  // const getUserAccountInfo = async () => {
  //   // Fetch user data from local storage
  //   const storedUserData = JSON.parse(localStorage.getItem("userData"));
  //   if (!storedUserData?._id) {
  //     CallCustomToast(dispatch, {
  //       messageHeading: "Error",
  //       toastStatus: "Error",
  //       messageBody: "User ID not found.",
  //     });
  //     return;
  //   }

  //   setUserData(storedUserData);

  //   try {
  //     setLoadingInfo(true);
  //     const res = await axiosCalls(
  //       `accounts/account-info?userId=${storedUserData._id}`,
  //       "GET"
  //     );

  //     if (res) {
  //       setLoadingInfo(false);

  //       if (!res?.status) {
  //         CallCustomToast(dispatch, {
  //           messageHeading: "Error",
  //           toastStatus: "Error",
  //           messageBody: res?.er?.data?.message,
  //         });
  //       } else {
  //         if (res?.status) {
  //           // Process and preload any necessary data
  //           // setInterestSelectedRack(res?.data?.interests?.interests);
  //           // setLookingForSelectedRack(res?.data?.interests?.lookingFor);
  //           // setShowMeSelectedRack(res?.data?.interests?.showMe);
  //         }
  //       }
  //     }
  //   } catch (err) {
  //     console.log("Error getting account info", { err });

  //     CallCustomToast(dispatch, {
  //       messageHeading: "Error",
  //       toastStatus: "Error",
  //       messageBody: "Something went wrong",
  //     });
  //   }
  // };

  useEffect(() => {
    // getUserAccountInfo();
    // dispatch(handleInterestHomeModal(true));
  }, []);

  return (
    <div className="home_margin_general">
      <MobileStoriesBody />

      <PostCard />

      <StoriesBar />
    </div>
  );
};

export default Home;
