import React from "react";
import "./eachPhotoCard.css";
import { more_vertical, photo_library_sample } from "../../../images";
import LazyLoadingHook from "../../../../utils/lazyLoadingHook";

const EachPhotoCard = ({ photo }) => {
  const baseImageUrl =
    "https://talkstuff-store.fra1.cdn.digitaloceanspaces.com";
  return (
    <div className="each_photo_card_body_gen">
      <div className="each_photo_card_image">
        {/* <img src={`${baseImageUrl}${photo?.url}`} /> */}

        <LazyLoadingHook
          effect="blur"
          imageSrc={`${baseImageUrl}${photo?.url}`}
        />
      </div>
      <div className="each_photo_card_image_icon_opt">
        <img src={more_vertical} />
      </div>
    </div>
  );
};

export default EachPhotoCard;
