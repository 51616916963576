import React, { useState } from "react";

import "./leftNav.css";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import HomeSideBar from "../homeSideBar/HomeSideBar";
import ProfileInfoSidebarOption from "../../profileInfoSidebarOption/ProfileInfoSidebarOption";
import SidebarOption from "../../sidebarOption/SidebarOption";
import Accordion from "../../sidebarAccordion/SidebarAccordion";
import { useDispatch, useSelector } from "react-redux";
import {
  handleLogoutModal,
  handleMobileStoryModal,
  handleShowChatBox,
  handleToggleCreatePost,
  handleToggleNotification,
  handleToggleProfileOption,
  handleViewAnotherUserProfile,
} from "../../../redux/userData";
import {
  bell,
  bell_sidebar,
  bookmark,
  copy_plus,
  heart_sidebar,
  help_circle,
  lock_keyhole,
  log_out_mobile,
  mobile_film,
  newFlexedLogo,
  newFlexedLogoPng,
  settings,
  talk_stuffnav_logo,
  user_round_sidebar,
  user_settings_round,
} from "../../images";
import HomeLeftNav from "./homeLeftNav/HomeLeftNav";
import SupamallLeftNav from "./supamallLeftNav/SupamallLeftNav";
import LuvHubLeftfNav from "./luvHubLeftNav/LuvHubLeftfNav";

const LeftNav = ({
  open,
  setOpen,
  openLogoutBtn,
  closeLogoutBtn,
  openLogoutPage,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;
  const navigate = useNavigate();

  const splitLocation = pathname.split("/");

  const [show, setShow] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };

  const handleSetOpenFunc = () => {
    setOpen(false);
  };
  const handleParentClick = () => {
    setOpen(false);
  };

  const handleChildClick = (event) => {
    event.stopPropagation();
  };
  return (
    <div
      onClick={handleParentClick}
      // className={
      //   open
      //     ? "sidemenu_family_cont_gennnn"
      //     : "sidemenu_family_cont_gennnn_inact"
      // }

      className="sidemenu_family_cont_gennnn"
    >
      <div
        // open={open}
        // style={{
        //   transform: `${open ? "translateX(25%)" : "translateX(-100%)"}`,
        //   transition: "transform 0.3s ease-in-out",
        // }}
        onClick={handleChildClick}
        className="sidemenu-family-cont"
      >
        <div
          onClick={() => {
            navigate("/");
            dispatch(handleShowChatBox(false));
          }}
          className="navbar_logo_"
        >
          {/* <img src={talk_stuffnav_logo} /> */}
          <img src={newFlexedLogoPng} />
        </div>
        {/* <div className="top-navbar-rel"> */}
        {/* <div className="cont-main"> */}
        {/* <div className="home_sidebar_body"> */}

        {location.pathname.startsWith("/supamall") ? (
          <SupamallLeftNav />
        ) : location.pathname.startsWith("/luvhub") ? (
          <LuvHubLeftfNav />
        ) : (
          <HomeLeftNav setOpen={setOpen} />
        )}

        <div className="home_sidebar_bottom_border"></div>

        <div style={{ marginTop: "40px", width: "100%" }}></div>
        {/* <ProfileInfoSidebarOption
            onClickFunc={() => {
              dispatch(handleToggleProfileOption(true));
            }}
          /> */}

        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: "0",
            left: "10px",
          }}
        >
          <SidebarOption
            icon={log_out_mobile}
            text="Logout"
            onClickFunc={() => {
              // dispatch(handleToggleCreatePost(true));

              dispatch(handleLogoutModal(true));

              setOpen(false);
            }}
            showChevronIcon={false}
          />

          <ProfileInfoSidebarOption
            onClickFunc={() => {
              // dispatch(handleToggleProfileOption(true));

              navigate("/user-profile");
              dispatch(handleShowChatBox(false));
              dispatch(handleViewAnotherUserProfile(false));
              setOpen(false);
            }}
            showChevron={false}
          />
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default LeftNav;
