import React, { useState, useEffect } from "react";
import "./profileTopCard.css";
import {
  backdrop_sample,
  message_profile,
  more_vertical_profile,
  pencil_profile,
  sample_profile_picss,
  settings,
} from "../images";
import IconAuthBtn from "../button/iconAuthBtn/IconAuthBtn";
import { axiosCalls } from "../../_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import {
  handleOpenSettingModal,
  handleProfileCoverImageModal,
  handleProfileImageModal,
} from "../../redux/userData";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import { CallCustomToast } from "../../utils/CallCustomToast";
import LazyLoadingHook from "../../utils/lazyLoadingHook";

const ProfileTopCard = ({
  userData,
  anotherUserProfile,
  userDataUpdated,
  collectUserId,
}) => {
  const storedJsonString = localStorage.getItem("userData");
  const storedObject = JSON.parse(storedJsonString);
  // const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorLoading, setOnErrorLoading] = useState(false);

  const [ownerDetails, setOwnerDetails] = useState({});
  const [likePost, setFollowPost] = useState(false);
  const [isLoadingFollow, setIsLoadingFollow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // useEffect(() => {
  //   handleGetUserData();
  // }, []);

  const baseImageUrl =
    "https://talkstuff-store.fra1.cdn.digitaloceanspaces.com";

  const handleFollowUser = async (e) => {
    const data = {
      followingId: collectUserId,
      actionType: "follow",
    };
    setIsLoadingFollow(true);
    const res = await axiosCalls(`users/user-follow`, "POST", data);

    if (res) {
      console.log(res);
      setIsLoadingFollow(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er.data?.message,
        });
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        // onSuccessPostImages();
        // onSuccessMessagePost(res?.message);

        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });

        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        setFollowPost(true);
        // console.log(postComment, "comments");
      }
    }
  };

  const handleUnFollowUser = async (e) => {
    const data = {
      followingId: collectUserId,
      actionType: "unfollow",
    };
    setIsLoadingFollow(true);
    const res = await axiosCalls(`users/user-follow`, "POST", data);

    if (res) {
      console.log(res);
      setIsLoadingFollow(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.message,
        });
        return;
      } else if (res?.status) {
        // onSuccessPostImages();
        // onSuccessMessagePost(res?.message);

        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
        setFollowPost(true);
        // console.log(postComment, "comments");
      }
    }
  };

  return (
    <div className="profile_top_card_bodyy">
      <ToastContainer />
      <div className="profile_top_card_image_body">
        {userDataUpdated?.coverImage ? (
          <div className="profile_top_card_image_backdrop">
            <LazyLoadingHook
              imageSrc={`${userDataUpdated?.coverImage}`}
              effect="blur"
            />
          </div>
        ) : (
          <div className="profile_top_card_image_backdrop"></div>
        )}

        <div className="profile_top_card_image_profile_picsBody">
          {userDataUpdated?.profileImage ? (
            <div className="profile_top_card_image_profile_pics">
              <LazyLoadingHook
                imageSrc={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com/${userDataUpdated?.profileImage}`}
              />
            </div>
          ) : (
            <div className="profile_body_details_image_body">
              <div className="profile_top_card_image_profile_pics">
                {userDataUpdated?.firstName // if profile_pic is null, show only the first letters of the names
                  ?.split(" ")
                  ?.map((name) => name.charAt(0).toUpperCase())
                  .join("")}
                {userDataUpdated?.lastName // if profile_pic is null, show only the first letters of the names
                  ?.split(" ")
                  ?.map((name) => name.charAt(0).toUpperCase())
                  .join("")}
              </div>
            </div>
          )}

          {!anotherUserProfile && (
            <div
              onClick={() => {
                dispatch(handleProfileImageModal(true));
              }}
              className="profile_top_card_image_profile_picsEdit"
            >
              <img src={pencil_profile} />
            </div>
          )}
        </div>

        {!anotherUserProfile && (
          <div
            onClick={() => {
              dispatch(handleProfileCoverImageModal(true));
            }}
            className="profile_top_card_image_cover_Editbtn"
          >
            <div className="profile_top_card_image_cover_EditIcon">
              <img src={pencil_profile} />
            </div>
            <div className="profile_top_card_image_cover_EditText">
              Edit cover image
            </div>
          </div>
        )}

        {/* {!anotherUserProfile && (
          <div
            onClick={() => {
              dispatch(handleOpenSettingModal(true));
            }}
            className="profile_top_card_image_cover_Editbtn_setting"
          >
            <div className="profile_top_card_image_cover_EditIcon">
              <img src={settings} />
            </div>
          </div>
        )} */}
      </div>

      <div className="profile_top_card_image_cover_boddy">
        {!anotherUserProfile ? (
          <div className="profile_top_card_image_cover_name">
            {storedObject?.firstName ?? ""} {storedObject?.lastName ?? ""}
          </div>
        ) : (
          <div className="profile_top_card_image_cover_name">
            {userDataUpdated?.firstName ?? ""} {userDataUpdated?.lastName ?? ""}
          </div>
        )}

        {!anotherUserProfile ? (
          <div className="profile_top_card_image_cover_profileTag">
            @{storedObject?.userName ?? ""}
          </div>
        ) : (
          <div className="profile_top_card_image_cover_profileTag">
            @{userDataUpdated?.userName ?? ""}
          </div>
        )}

        {!anotherUserProfile ? (
          <div className="profile_top_card_image_cover_profileTag">
            Wholesaler
          </div>
        ) : (
          <div className="profile_top_card_image_cover_profileTag">
            Wholesaler
          </div>
        )}

        <div className="profile_top_card_image_cover_followBodyFlex">
          <div className="profile_top_card_image_cover_followBody">
            <div className="profile_top_card_image_cover_followCount">
              {userData?.followingsPagination?.totalCount ?? 0}
            </div>
            <div className="profile_top_card_image_cover_followCountText">
              Following
            </div>
          </div>
          <div className="profile_top_card_image_cover_followBody">
            <div className="profile_top_card_image_cover_followCount">
              {userData?.followersPagination?.totalCount ?? 0}
            </div>
            <div className="profile_top_card_image_cover_followCountText">
              Followers
            </div>
          </div>
          <div className="profile_top_card_image_cover_followBody">
            <div className="profile_top_card_image_cover_followCount">
              {userData?.likesCount ?? 0}
            </div>
            <div className="profile_top_card_image_cover_followCountText">
              Likes
            </div>
          </div>
        </div>
        {anotherUserProfile && (
          <div className="another_profile_more_body">
            <div
              onClick={() => {
                {
                  userData?.isUserFollowing
                    ? handleUnFollowUser()
                    : handleFollowUser();
                }
              }}
              className="another_profile_more_body_follow"
            >
              {!isLoadingFollow &&
                (userDataUpdated?.isFollower ? "Unfollow" : "Follow")}

              <ClipLoader
                color="#008dcb"
                loading={isLoadingFollow}
                // cssOverride={override}
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>{" "}
            <div
              onClick={() => {
                navigate("/chat");
              }}
              className="another_profile_more_body_message"
            >
              <img src={message_profile} />
            </div>{" "}
            <div className="another_profile_more_body_moreVertical">
              <img src={more_vertical_profile} />
            </div>
          </div>
        )}
        {!anotherUserProfile && (
          <IconAuthBtn width="120px" btnText="Edit profile" />
        )}
      </div>
    </div>
  );
};

export default ProfileTopCard;
