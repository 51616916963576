import React, { useState, useEffect } from "react";
import ProfileTopCard from "../profileTopCard/ProfileTopCard";
import PostTab from "../profileTabs/postTab/PostTab";
import AboutTab from "../profileTabs/aboutTab/AboutTab";
import FollowersTab from "../profileTabs/followersTab/FollowersTab";
import FollowingTab from "../profileTabs/followingTab/FollowingTab";
import PhotosTab from "../profileTabs/photosTab/PhotosTab";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosCalls } from "../../_api";
import { useSelector } from "react-redux";

const AnotherUserProfile = ({ userId }) => {
  const [tab, setTab] = useState(0);

  const handleSetTab = (status) => {
    setTab(status);
  };
  const [userPhotos, setUserPhotos] = useState(null);
  const [isPhotosLoading, setIsPhotosLoading] = useState(false);

  const [onErrorPhotosLoading, setOnErrorPhotosLoading] = useState(false);
  const baseImageUrl =
    "https://talkstuff-store.fra1.cdn.digitaloceanspaces.com";

  const [userPost, setUserPost] = useState([]);
  const [isPostTabLoading, setIsPostTabLoading] = useState(false);
  const [onErrorPostTabLoading, setOnErrorPostTabLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorLoading, setOnErrorLoading] = useState(false);
  const [userRelation, setUserRelation] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [anotherUserProfile, setAnotherUserProfile] = useState(true);

  useEffect(() => {
    // handleGetUserPost();
    // handleGetUserPhotos();
    handleGetUserRelations();
    handleGetUserInfo();
  }, []);

  const { collectUserId } = useSelector(
    (state) => state.collectUserIdBody.collectUserIdBody
  );
  const handleGetUserPhotos = async (e) => {
    setIsPhotosLoading(true);
    setOnErrorPhotosLoading(false);
    const res = await axiosCalls(
      `accounts/account-profile-userphotos?userId=${collectUserId}&page=1&limit=50&flatten=true`,
      "GET"
    );

    if (res) {
      console.log(res, "another userphoto");
      setIsPhotosLoading(false);
      if (!res?.status) {
        // console.log(res?.er.data?.message);
        setOnErrorPhotosLoading(true);
        toast.error(res?.er?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      } else if (res?.status) {
        setUserPhotos(res?.data);
        setOnErrorPhotosLoading(false);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };
  const handleGetUserPost = async (e) => {
    setIsPostTabLoading(true);
    setOnErrorPostTabLoading(false);
    const res = await axiosCalls(
      `accounts/account-profile-userposts?userId=${collectUserId}&page=1&limit=10`,
      "GET"
    );

    if (res) {
      console.log(res, "another user post");
      setIsPostTabLoading(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        setOnErrorPostTabLoading(true);
        toast.error(res?.er?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      } else if (res?.status) {
        setOnErrorPostTabLoading(false);
        setUserPost(res?.data);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };

  const showUserPhoto = () => {
    console.log(userPhotos, "another userphot console");
  };

  const handleGetUserInfo = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosCalls(
      `accounts/account-info?userId=${collectUserId}`,
      "GET"
    );

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        setOnErrorLoading(true);
        toast.error(res?.er?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      } else if (res?.status) {
        setOnErrorLoading(false);
        setUserInfo(res?.data);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };

  const handleGetUserRelations = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);

    const res = await axiosCalls(
      `accounts/account-profile-relations?userId=${collectUserId}&page=1&limit=50`,
      "GET"
    );

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        // console.log(res?.er.data?.message);
        setOnErrorLoading(true);
        toast.error(res?.er?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      } else if (res?.status) {
        setOnErrorLoading(false);
        setUserRelation(res?.data);
        console.log(userRelation, "user relation");
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };

  return (
    <div className="profile_body_general_container">
      <ProfileTopCard
        userDataUpdated={userInfo}
        anotherUserProfile={anotherUserProfile}
        userData={userRelation}
        collectUserId={collectUserId}
      />

      <div className="profile_top_home_body_tab_">
        <div
          onClick={() => {
            handleSetTab(0);
          }}
          className={
            tab === 0
              ? "profile_top_home_body_tab_each_active"
              : "profile_top_home_body_tab_each"
          }
        >
          Post
        </div>
        <div
          onClick={() => {
            handleSetTab(1);
          }}
          className={
            tab === 1
              ? "profile_top_home_body_tab_each_active"
              : "profile_top_home_body_tab_each"
          }
        >
          About
        </div>
        <div
          onClick={() => {
            handleSetTab(2);
          }}
          className={
            tab === 2
              ? "profile_top_home_body_tab_each_active"
              : "profile_top_home_body_tab_each"
          }
        >
          Followers
        </div>
        <div
          onClick={() => {
            handleSetTab(3);
          }}
          className={
            tab === 3
              ? "profile_top_home_body_tab_each_active"
              : "profile_top_home_body_tab_each"
          }
        >
          Following
        </div>
        <div
          onClick={() => {
            // handleGetUserPhotos();
            handleSetTab(4);
            showUserPhoto();
          }}
          className={
            tab === 4
              ? "profile_top_home_body_tab_each_active"
              : "profile_top_home_body_tab_each"
          }
        >
          Photos
        </div>
      </div>

      {tab === 0 && (
        <PostTab
          userPost={userPost}
          isLoading={isPostTabLoading}
          onErrorLoading={onErrorPostTabLoading}
          handleGetUserPost={handleGetUserPost}
        />
      )}
      {tab === 1 && (
        <AboutTab
          userDataUpdated={userInfo}
          anotherUserProfile={anotherUserProfile}
        />
      )}
      {tab === 2 && <FollowersTab userData={userRelation} />}
      {tab === 3 && <FollowingTab userData={userRelation} />}
      {tab === 4 && (
        <PhotosTab
          userPhotos={userPhotos}
          isLoading={isPhotosLoading}
          onErrorLoading={onErrorPhotosLoading}
          handleGetUserPhotos={handleGetUserPhotos}
        />
      )}
    </div>
  );
};

export default AnotherUserProfile;
