// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth_btn_tag_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
}

.auth_btn_tag_parent {
  width: 100%;
  height: 40px;
  position: relative;
  transition: 0.3s ease-in-out 0s;
}

.auth_btn_tag_parent_disabled {
  opacity: 0.5;
  transition: 0.3s ease-in-out 0s;
}

.auth_btn_tag_parent input {
  font-family: "Open Sans", sans-serif;

  width: 100%;
  height: 100%;
  box-sizing: border-box;
  outline: none;
  border-radius: 10px;
  border: 1px solid;
  box-shadow: 0px 1px 2px 0px #9d3d0b7a;

  /* box-shadow: 0px 0px 0px 1px #e45911; */

  border-image-source: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  /* border: 1px solid #e7e7e8; */
  background: #ef6924;
  /* padding: 10px 18px 10px 34px; */

  color: #fff;

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.auth_btn_tag_parent input:hover {
  background: #e15813;
}
`, "",{"version":3,"sources":["webpack://./src/components/button/authBtn/authBtn.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,YAAY;EACZ,+BAA+B;AACjC;;AAEA;EACE,oCAAoC;;EAEpC,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,qCAAqC;;EAErC,yCAAyC;;EAEzC;;;;GAIC;EACD,+BAA+B;EAC/B,mBAAmB;EACnB,kCAAkC;;EAElC,WAAW;;EAEX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".auth_btn_tag_container {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  margin-top: 16px;\n}\n\n.auth_btn_tag_parent {\n  width: 100%;\n  height: 40px;\n  position: relative;\n  transition: 0.3s ease-in-out 0s;\n}\n\n.auth_btn_tag_parent_disabled {\n  opacity: 0.5;\n  transition: 0.3s ease-in-out 0s;\n}\n\n.auth_btn_tag_parent input {\n  font-family: \"Open Sans\", sans-serif;\n\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  outline: none;\n  border-radius: 10px;\n  border: 1px solid;\n  box-shadow: 0px 1px 2px 0px #9d3d0b7a;\n\n  /* box-shadow: 0px 0px 0px 1px #e45911; */\n\n  border-image-source: linear-gradient(\n    180deg,\n    rgba(255, 255, 255, 0.12) 0%,\n    rgba(255, 255, 255, 0) 100%\n  );\n  /* border: 1px solid #e7e7e8; */\n  background: #ef6924;\n  /* padding: 10px 18px 10px 34px; */\n\n  color: #fff;\n\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 600;\n  cursor: pointer;\n  transition: 0.3s ease-in-out 0s;\n}\n\n.auth_btn_tag_parent input:hover {\n  background: #e15813;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
