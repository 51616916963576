// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_supamall_bodyy {
  background: #f4f7f9;
  width: 100%;
}

.footer_supamall_bodyy__sub {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 56px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 10px;
  flex-wrap: wrap;
}
@media screen and (max-width: 850px) {
  .footer_supamall_bodyy {
    width: calc(100% + 40px);
  }
  .footer_supamall_bodyy__sub {
    margin-top: 400px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/supamallComp/footer/footer.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,SAAS;EACT,eAAe;AACjB;AACA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".footer_supamall_bodyy {\n  background: #f4f7f9;\n  width: 100%;\n}\n\n.footer_supamall_bodyy__sub {\n  max-width: 1300px;\n  margin-left: auto;\n  margin-right: auto;\n  width: 100%;\n  padding: 56px 0px 0px 0px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  box-sizing: border-box;\n  gap: 10px;\n  flex-wrap: wrap;\n}\n@media screen and (max-width: 850px) {\n  .footer_supamall_bodyy {\n    width: calc(100% + 40px);\n  }\n  .footer_supamall_bodyy__sub {\n    margin-top: 400px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
