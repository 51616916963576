import { useDispatch } from "react-redux";
import {
  handleToastHeaderData,
  handleShowSuccessToast,
  handleToastData,
} from "../redux/userData";

// Define the custom toast function
export const CallCustomToast = (
  dispatch,
  { messageHeading, toastStatus, messageBody }
) => {
  dispatch(handleShowSuccessToast(toastStatus));
  dispatch(handleToastHeaderData(messageHeading));
  dispatch(handleToastData(messageBody));

  setTimeout(() => {
    dispatch(handleShowSuccessToast(null));
  }, 4000);
};
