import { useState, useEffect } from "react";

const ScreenWidth = ({ onWidthChange }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setScreenWidth(width);
      if (onWidthChange) {
        onWidthChange(width);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [onWidthChange]);

  return screenWidth;
};

export default ScreenWidth;
