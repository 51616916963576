// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bookmarks_body_postt {
  width: 60%;
}

@media screen and (max-width: 850px) {
  .bookmarks_body_postt {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/homeScreens/bookmarks/bookmarks.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".bookmarks_body_postt {\n  width: 60%;\n}\n\n@media screen and (max-width: 850px) {\n  .bookmarks_body_postt {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
