import React from "react";
import "./newModal.css";

const NewModal = ({ children, closeNewModal, left, right, bottom }) => {
  const handleChildClick = (event) => {
    // 👇️ stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();

    // console.log("child clicked");
  };

  return (
    <div className="new_modal_body" onClick={closeNewModal}>
      <div
        style={{
          left,
          right,
          bottom,
        }}
        onClick={handleChildClick}
        className="new_modal_content"
      >
        {children}
      </div>
    </div>
  );
};

export default NewModal;
