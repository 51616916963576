// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.explore___home_bodyyy__genf {
  width: calc(100% - 340px);
  box-sizing: border-box;
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.explore___home_bodyyy__genf_goBack {
  /* margin-left: 20px; */
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 850px) {
  .explore___home_bodyyy__genf {
    width: 100%;
    flex-direction: column;
    margin-bottom: 100px;
    min-height: 80vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/luvHubComp/explorePageCard/explorePageCard.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,SAAS;AACX;AACA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,WAAW;IACX,sBAAsB;IACtB,oBAAoB;IACpB,gBAAgB;EAClB;AACF","sourcesContent":[".explore___home_bodyyy__genf {\n  width: calc(100% - 340px);\n  box-sizing: border-box;\n  margin-top: 30px;\n  display: flex;\n  align-items: flex-start;\n  gap: 20px;\n}\n.explore___home_bodyyy__genf_goBack {\n  /* margin-left: 20px; */\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n@media screen and (max-width: 850px) {\n  .explore___home_bodyyy__genf {\n    width: 100%;\n    flex-direction: column;\n    margin-bottom: 100px;\n    min-height: 80vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
