import React, { useState, useEffect } from "react";
import "./interestHomeModal.css";
import InfoModal from "../../infoModal/InfoModal";
import InterestCardRack from "../interestCardRack/InterestCardRack";
import AuthInput from "../../defaultInput/authInput/AuthInput";
import WhiteBtn from "../../button/whiteBtn/WhiteBtn";
import AuthBtn from "../../button/authBtn/AuthBtn";
import { handleInterestHomeModal } from "../../../redux/userData";
import { useDispatch } from "react-redux";
import { heartInterest } from "../../images";
import { axiosCalls } from "../../../_api";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import UseClipRoundLoader from "../../../utils/UseClipRoundLoader";
import Skeleton from "react-loading-skeleton";

const InterestHomeModal = () => {
  const [showMeSelectedRack, setShowMeSelectedRack] = useState([]);
  const [interestSelectedRack, setInterestSelectedRack] = useState([]);
  const [lookingForSelectedRack, setLookingForSelectedRack] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();

  const [showMeText, setShowMeText] = useState([]);
  const [interestInText, setInterestInText] = useState([]);
  const [lookingForText, setLookingForText] = useState([]);

  const [loadingInfo, setLoadingInfo] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const handleEachShowMeRack = (id) => {
    // Toggle selection
    if (showMeSelectedRack.includes(id)) {
      setShowMeSelectedRack(
        showMeSelectedRack.filter((rackId) => rackId !== id)
      );
    } else {
      setShowMeSelectedRack([...showMeSelectedRack, id]);
    }
  };

  const handleEachInterestRack = (id) => {
    // Toggle selection
    if (interestSelectedRack.includes(id)) {
      setInterestSelectedRack(
        interestSelectedRack.filter((rackId) => rackId !== id)
      );
    } else {
      setInterestSelectedRack([...interestSelectedRack, id]);
    }
  };

  const handleEachLookingForRack = (id) => {
    // Toggle selection
    if (lookingForSelectedRack.includes(id)) {
      setLookingForSelectedRack(
        lookingForSelectedRack.filter((rackId) => rackId !== id)
      );
    } else {
      setLookingForSelectedRack([...lookingForSelectedRack, id]);
    }
  };

  const handleInterestsSubmit = async (e) => {
    const data = {
      showMe: showMeSelectedRack,
      lookingFor: lookingForSelectedRack,
      interests: interestSelectedRack,
    };

    setIsLoading(true);
    const res = await axiosCalls("accounts/save-interests", "POST", data);

    if (res) {
      setIsLoading(false);
      console.log(res);
      if (!res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });

        dispatch(handleInterestHomeModal(false));
      }
    }
  };

  const getUserAccountInfo = async () => {
    // Fetch user data from local storage
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    if (!storedUserData?._id) {
      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "User ID not found.",
      });
      return;
    }

    setUserData(storedUserData);

    try {
      setLoadingInfo(true);
      const res = await axiosCalls(
        `accounts/account-info?userId=${storedUserData._id}`,
        "GET"
      );

      if (res) {
        setLoadingInfo(false);

        if (!res?.status) {
          CallCustomToast(dispatch, {
            messageHeading: "Error",
            toastStatus: "Error",
            messageBody: res?.er?.data?.message,
          });
        } else {
          if (res?.status) {
            // Process and preload any necessary data
            setInterestSelectedRack(res?.data?.interests?.interests);
            setLookingForSelectedRack(res?.data?.interests?.lookingFor);
            setShowMeSelectedRack(res?.data?.interests?.showMe);
          }
        }
      }
    } catch (err) {
      console.log("Error getting account info", { err });

      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "Something went wrong",
      });
    }
  };

  const getAllInterestOptions = async () => {
    try {
      setLoadingOptions(true);
      const res = await axiosCalls(
        `accounts/account-get-interests-options`,
        "GET"
      );

      if (res) {
        setLoadingOptions(false);

        if (!res?.status) {
          CallCustomToast(dispatch, {
            messageHeading: "Error",
            toastStatus: "Error",
            messageBody: res?.er?.data?.message,
          });
        } else {
          if (res?.status) {
            setInterestInText(res?.data?.interests);
            setLookingForText(res?.data?.lookingFor);
            setShowMeText(res?.data?.showMe);
          }
        }
      }
    } catch (err) {
      console.log("Error getting account info", { err });
      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "Something went wrong",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAllInterestOptions();
      await getUserAccountInfo();
    };

    fetchData();
  }, []);

  return (
    <InfoModal
      width="600px"
      handleCloseModal={() => {
        dispatch(handleInterestHomeModal(false));
      }}
    >
      {loadingOptions ? (
        <div style={{ width: "100%", height: "100%" }}>
          {/* <UseClipRoundLoader /> */}
          <Skeleton count={1} height={600} />
        </div>
      ) : (
        <div style={{ width: "100%", height: "100%" }}>
          <div className="interests_card_home_modalHeadImg">
            <img src={heartInterest} />
          </div>
          <div className="interests_card_home_modalHead">Interests</div>
          <div className="interests_card_home_modalHeadText">
            Tell us what you love! Choose your interests to personalize your
            experience.
          </div>
          <InterestCardRack
            interestText={showMeText}
            handleClick={handleEachShowMeRack}
            selectedRack={showMeSelectedRack}
            rackHeadName="Show me"
          />

          <InterestCardRack
            interestText={interestInText}
            handleClick={handleEachInterestRack}
            selectedRack={interestSelectedRack}
            rackHeadName="Interested in"
          />

          <InterestCardRack
            interestText={lookingForText}
            handleClick={handleEachLookingForRack}
            selectedRack={lookingForSelectedRack}
            rackHeadName="Looking for "
          />

          <div className="interest_card_input_body">
            <div className="interest_card_input_body_label">
              Others (optional)
            </div>
            <AuthInput width="100%" inputPlaceHolder="Please specify others" />
          </div>
          <div className="interest_card_footer_btn">
            <WhiteBtn width="130px" whiteBtnText="Close" />
            <AuthBtn
              isLoading={isLoading}
              handleClick={handleInterestsSubmit}
              width="130px"
              btnText="Update Changes"
            />
          </div>
        </div>
      )}
    </InfoModal>
  );
};

export default InterestHomeModal;
