import React, { useState, useEffect } from "react";
import "./supaMallHome.css";
import SupaMallSideBar from "../supaMallSideBar/SupaMallSideBar";
import HomeSearchContainer from "../../../components/supamallComp/homeSearchContainer/HomeSearchContainer";
import HomeSecondSection from "../../../components/supamallComp/homeSecondSection/HomeSecondSection";
import HomeThirdSection from "../../../components/supamallComp/homeThirdSection/HomeThirdSection";
import HomeFourtTrendingSection from "../../../components/supamallComp/homeFourtTrendingSection/HomeFourtTrendingSection";
import HomeBannerSection from "../../../components/supamallComp/homeBannerSection/HomeBannerSection";
import HomeFifthSecion from "../../../components/supamallComp/homeFifthSection/HomeFifthSecion";
import Footer from "../../../components/supamallComp/footer/Footer";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import { useDispatch } from "react-redux";
import { axiosCalls } from "../../../_api";
import UseClipRoundLoader from "../../../utils/UseClipRoundLoader";

const SupaMallHome = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCategory, setIsLoadingCategory] = useState(false);
  const [onErrorLoading, setOnErrorLoading] = useState(false);
  const [supamallProductCategory, setSupamallProductCategory] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const handleGetMoreCategoryData = () => {
    getSupamallProductByCategory();
    console.log(supamallProductCategory, "jdhjjd");
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getSupamallProduct();
    getSupamallProductByCategory();
  }, []);

  const getSupamallProductByCategory = async () => {
    try {
      // showLoader();
      setIsLoadingCategory(true);
      const res = await axiosCalls(
        `supamall/products/landingpage-products?page=${currentPage}&limit=1`,
        "GET"
      );

      if (res) {
        setIsLoadingCategory(false);
        // hideLoader();

        if (!res?.status) {
          CallCustomToast(dispatch, {
            messageHeading: "Error",
            toastStatus: "Error",
            messageBody: res?.er?.data?.message,
          });
          return;
        } else {
          if (res?.status) {
            setIsLoadingCategory(false);
            CallCustomToast(dispatch, {
              messageHeading: "Success",
              toastStatus: "Success",
              messageBody: res?.er?.data?.message,
            });

            setSupamallProductCategory((prevPosts) => [
              ...prevPosts,
              ...(res.data || []),
            ]);
            setCurrentPage((prevPage) => prevPage + 1);
            setOnErrorLoading(false);

            // setTimeout(() => {
            //   window.location.href = "/supamall";
            // }, 2000);
          }
        }
      }
    } catch (err) {
      console.log("err getting product", { err });

      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "Something went wrong",
      });
    }
  };

  const getSupamallProduct = async () => {
    try {
      // showLoader();
      setIsLoading(true);
      const res = await axiosCalls(
        "supamall/products/landingpage-category-products?page=1&limit=1&subcategoryId=65ffd7288df82dc0c7929f3a",
        "GET"
      );

      if (res) {
        setIsLoading(false);
        // hideLoader();

        if (!res?.status) {
          CallCustomToast(dispatch, {
            messageHeading: "Error",
            toastStatus: "Error",
            messageBody: res?.er?.data?.message,
          });
          return;
        } else {
          if (res?.status) {
            setIsLoading(false);
            // CallCustomToast(dispatch, {
            //   messageHeading: "Success",
            //   toastStatus: "Success",
            //   messageBody: res?.er?.data?.message,
            // });

            // setTimeout(() => {
            //   window.location.href = "/supamall";
            // }, 2000);
          }
        }
      }
    } catch (err) {
      console.log("err getting product", { err });

      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "Something went wrong",
      });
    }
  };

  return (
    <div className="supamall_home_body_gen">
      <div className=" supamall_home_body_gen_firstsection">
        <SupaMallSideBar />
        <div className="supamall_home_body_gen_firstsection_row">
          <HomeSearchContainer />

          <HomeSecondSection />
          <HomeThirdSection />
        </div>
      </div>

      <HomeBannerSection />
      <HomeFourtTrendingSection />

      {supamallProductCategory?.map((data, index) => (
        <HomeFifthSecion categoryData={data} />
      ))}

      <div
        onClick={handleGetMoreCategoryData}
        className="loadMore_card_supamall_body_button_body"
      >
        {isLoadingCategory ? (
          <UseClipRoundLoader size={10} color="#fff" />
        ) : (
          "Load more"
        )}
      </div>

      <Footer />
    </div>
  );
};

export default SupaMallHome;
