import React from "react";
import "./regIdentitySteps.css";
import { chevron_right__ } from "../images";

const RegIdentitySteps = ({ background, color, stepNum, stepStage }) => {
  return (
    <div className="reg_identity_body">
      <div style={{ background, color }} className="reg_identity_body_step_num">
        {stepNum}
      </div>
      <div className="reg_identity_body_step_stage">{stepStage}</div>
      <div className="reg_identity_body_step_chevron">
        <img src={chevron_right__} />
      </div>
    </div>
  );
};

export default RegIdentitySteps;
