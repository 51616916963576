import React, { useState, useEffect } from "react";
import "./authPasswordInput.css";
import { closeEye, openEye, mail_icon, lock_icon } from "../../images";

const AuthPasswordInput = ({
  passwordPlaceHolder,
  passwordLabel,
  passwordValue,
  passwordName,
  passwordOnchange,
  width,
  validateInput,
  error,
  required,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePassIcon = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="auth_password_tag_container" style={{ width }}>
      <div className="auth_password_tag_label">
        {" "}
        {passwordLabel}{" "}
        {required && (
          <span
            style={{ color: "#F41010", fontSize: "13px", fontWeight: "600" }}
          >
            *
          </span>
        )}
      </div>
      <div className="auth_password_tag_parent">
        <div className="auth_password_tag_icon_left">
          <img src={lock_icon} />
        </div>
        <div className="auth_password_tag_parent_container">
          <input
            type={showPassword ? "text" : "password"}
            placeholder={passwordPlaceHolder}
            value={passwordValue}
            name={passwordName}
            onChange={passwordOnchange}
            onBlur={validateInput}
            error={error}
            required={required}
          />
        </div>
        <div onClick={handlePassIcon} className="auth_password_tag_icon">
          <img src={showPassword ? openEye : closeEye} />
        </div>
      </div>
      {error && <div className="auth_password_error_message">{error}</div>}
    </div>
  );
};

export default AuthPasswordInput;
