import React from "react";
import "./splashScreen.css";
import { talk_stuff_white } from "../images";
import { motion } from "framer-motion";

const SplashScreen = () => {
  return (
    <div className="splash_screen_body">
      <div className="splash_screen_logo">
        <img src={talk_stuff_white} />
      </div>

      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5 }}
        className="splash_screen_body_text"
      >
        <span>Chat.</span>
        <span> Connect.</span>
        <span> Date.</span>
        <span> Buy. </span>
        <span>Sell</span>
      </motion.div>
    </div>
  );
};

export default SplashScreen;
