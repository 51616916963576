import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { chevron_right_icon } from "../../images";

const BreadcrumbContainer = styled.nav`
  padding: 10px;
  display: flex;
  align-items: center;
  max-width: 1300px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const BreadcrumbLink = styled(Link)`
  color: #525866;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  font-size: 12px;
`;

const ActiveBreadcrumbLink = styled.span`
  color: #ef6924; /* Change this to the color you want for the active breadcrumb */
  text-decoration: none;
  cursor: default;
  font-size: 12px;
`;

const BreadcrumbSeparator = styled.span`
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <BreadcrumbContainer>
      {/* <BreadcrumbLink to="/">Home</BreadcrumbLink> */}
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            key={to}
          >
            {index > 0 && (
              <BreadcrumbSeparator>
                <img src={chevron_right_icon} alt="chevron right" />
              </BreadcrumbSeparator>
            )}
            {isLast ? (
              <ActiveBreadcrumbLink>
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </ActiveBreadcrumbLink>
            ) : (
              <BreadcrumbLink to={to}>
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </BreadcrumbLink>
            )}
          </span>
        );
      })}
    </BreadcrumbContainer>
  );
};

export default Breadcrumbs;
