// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post_upload_boxes_body {
  border: 1px solid #e2e4e9;
  box-shadow: 0px 1px 2px 0px #5258660d;
  background: #fff;
  width: 90%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #868c98;
  font-size: 16px;
  border-radius: 10px;
  transition: 0.3s ease-in-out 0s;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.post_upload_boxes_body input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.post_upload_boxes_body:hover {
  background: #faf9f9;
}
`, "",{"version":3,"sources":["webpack://./src/components/createPost/uploadBoxes/uploadBoxes.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,qCAAqC;EACrC,gBAAgB;EAChB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,eAAe;EACf,mBAAmB;EACnB,+BAA+B;EAC/B,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".post_upload_boxes_body {\n  border: 1px solid #e2e4e9;\n  box-shadow: 0px 1px 2px 0px #5258660d;\n  background: #fff;\n  width: 90%;\n  height: 80px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #868c98;\n  font-size: 16px;\n  border-radius: 10px;\n  transition: 0.3s ease-in-out 0s;\n  position: relative;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.post_upload_boxes_body input {\n  opacity: 0;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  cursor: pointer;\n}\n.post_upload_boxes_body:hover {\n  background: #faf9f9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
