import React from "react";
import "./mainFooterSection.css";
import {
  facebook_footer_supamall,
  footer_apple_btn_supamall,
  footer_google_btn_supamall,
  instagram_footer_supamall,
  mail_icon,
  mail_input_icon,
  only_logo_footer_supamall,
  talk_stuff_footer_white,
  x_icon_footerSupamall,
  youtube_footer_supamall,
} from "../../images";
import AuthInputIcon from "../../defaultInput/authInputnIcon/AuthInputIcon";
import AuthBtn from "../../button/authBtn/AuthBtn";

const MainFooterSection = () => {
  return (
    <div className="main_footer_section_bodyGen">
      <div className="main_footer_section_bodyGen_sub">
        <div className="main_footer_section_body_left">
          <div className="main_footer_section_body_left_logo">
            <img src={talk_stuff_footer_white} />
          </div>

          <div className="main_footer_section_body_left_copyRight">
            (c) all rights reserved{" "}
          </div>
        </div>

        <div className="main_footer_section_bodyGen_center">
          <div className="main_footer_section_bodyGen_center_bold">
            New to TalkStuff?
          </div>

          <div className="main_footer_section_bodyGen_center_thin">
            Receive newsletters from us
          </div>
          <div className="main_footer_section_bodyGen_center_formbody">
            <AuthInputIcon
              inputPlaceHolder="Enter your email"
              inputLeftIcon={mail_input_icon}
              width="80%"
            />
            <AuthBtn btnText="Enter" width="70px" />
          </div>

          <div className="main_footer_section_bodyGen_centerQuick">
            <div className="main_footer_section_bodyGen_centerQuickGen">
              <div className="main_footer_section_bodyGen_centerQuickEach_head">
                Quick Links
              </div>
              <div className="main_footer_section_bodyGen_centerQuickEach_each">
                News Feed
              </div>
              <div className="main_footer_section_bodyGen_centerQuickEach_each">
                Products
              </div>
            </div>
            <div className="main_footer_section_bodyGen_centerQuickGen">
              <div className="main_footer_section_bodyGen_centerQuickEach_head">
                Our Company
              </div>
              <div className="main_footer_section_bodyGen_centerQuickEach_each">
                {" "}
                About us
              </div>
              <div className="main_footer_section_bodyGen_centerQuickEach_each">
                Contact
              </div>
            </div>
            <div className="main_footer_section_bodyGen_centerQuickGen">
              <div className="main_footer_section_bodyGen_centerQuickEach_head">
                Products
              </div>
              <div className="main_footer_section_bodyGen_centerQuickEach_each">
                SupaMall
              </div>
              <div className="main_footer_section_bodyGen_centerQuickEach_each">
                Explore
              </div>
            </div>
          </div>

          <div className="main_footer_section_bodyGen_centerSocial">
            <div className="main_footer_section_bodyGen_centerSocial_icon">
              <img src={x_icon_footerSupamall} />
            </div>
            <div className="main_footer_section_bodyGen_centerSocial_icon">
              <img src={facebook_footer_supamall} />
            </div>
            <div className="main_footer_section_bodyGen_centerSocial_icon">
              <img src={instagram_footer_supamall} />
            </div>
            <div className="main_footer_section_bodyGen_centerSocial_icon">
              <img src={youtube_footer_supamall} />
            </div>
          </div>
        </div>

        <div className="main_footer_section_body_right_body">
          <div className="main_footer_section_body_right_bodyTop">
            <div className="main_footer_section_body_right_bodyTop_logo">
              <img src={only_logo_footer_supamall} />
            </div>
            <div className="main_footer_section_body_right_bodyTop_textBold___">
              <div className="main_footer_section_body_right_bodyTop_textBold">
                DOWNLOAD NOW
              </div>
              <div className="main_footer_section_body_right_bodyTop_textPara">
                Enhance your experience
              </div>
            </div>
          </div>

          <div className="main_footer_section_body_right_bodyTop_mobile">
            <div className="main_footer_section_body_right_bodyTop_mobile_apple">
              <img src={footer_apple_btn_supamall} />
            </div>

            <div className="main_footer_section_body_right_bodyTop_mobile_google">
              <img src={footer_google_btn_supamall} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFooterSection;
