// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_margin_general {
  /* margin-left: auto; */
  margin-right: auto;
  margin-left: 310px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  /* flex-direction: column; */
  gap: 20px;
  margin-top: 30px;
}

@media screen and (max-width: 1150px) {
  .home_margin_general {
    margin-left: 310px;
    /* justify-content: center; */
    flex-direction: column;
    margin-top: 0px;
  }
}

@media screen and (max-width: 850px) {
  .home_margin_general {
    margin-left: 0px;
    justify-content: center;
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/homeScreens/home/home.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,6BAA6B;EAC7B,4BAA4B;EAC5B,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE;IACE,kBAAkB;IAClB,6BAA6B;IAC7B,sBAAsB;IACtB,eAAe;EACjB;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,uBAAuB;IACvB,sBAAsB;EACxB;AACF","sourcesContent":[".home_margin_general {\n  /* margin-left: auto; */\n  margin-right: auto;\n  margin-left: 310px;\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-evenly;\n  /* flex-direction: column; */\n  gap: 20px;\n  margin-top: 30px;\n}\n\n@media screen and (max-width: 1150px) {\n  .home_margin_general {\n    margin-left: 310px;\n    /* justify-content: center; */\n    flex-direction: column;\n    margin-top: 0px;\n  }\n}\n\n@media screen and (max-width: 850px) {\n  .home_margin_general {\n    margin-left: 0px;\n    justify-content: center;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
