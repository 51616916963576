import React from "react";
import "./stylistServices.css";
import Breadcrumbs from "../../../../components/supamallComp/breadCrumb/BreadCrumb";
import HomeFifthSecion from "../../../../components/supamallComp/homeFifthSection/HomeFifthSecion";
import Footer from "../../../../components/supamallComp/footer/Footer";
import HomeFourtTrendingSection from "../../../../components/supamallComp/homeFourtTrendingSection/HomeFourtTrendingSection";

const StylistServices = () => {
  return (
    <div className="supermarket_category_bodyGennn_conta">
      <Breadcrumbs />

      <HomeFourtTrendingSection />

      <Footer />
    </div>
  );
};

export default StylistServices;
