import React from "react";
import "./viewProfile.css";
import ProfileBody from "../../../components/profileBody/ProfileBody";
import StoriesBar from "../../../components/layout/storiesBar/StoriesBar";
import AnotherUserProfile from "../../../components/anotherUserProfile/AnotherUserProfile";
import { useSelector, useDispatch } from "react-redux";

const ViewProfile = () => {
  const { viewAnotherUserProfile } = useSelector(
    (state) => state.viewAnotherUserProfileBody.viewAnotherUserProfileBody
  );
  return (
    <div className="home_margin_general">
      {viewAnotherUserProfile ? <AnotherUserProfile /> : <ProfileBody />}

      <StoriesBar />
    </div>
  );
};

export default ViewProfile;
