// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success_register_input_body {
  width: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  height: 50vh;
}

.success_register_body_text {
  font-size: 20px;
  font-weight: 600;
  color: #0a0d14;
  text-align: center;
  margin-top: 20px;
}

.termsandcondition_heading_logo {
  width: 40px;
  height: 37px;
}

.termsandcondition_heading_logo img {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/successRegisterModal/successregisterModal.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".success_register_input_body {\n  width: 370px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  margin-left: auto;\n  margin-right: auto;\n  height: 50vh;\n}\n\n.success_register_body_text {\n  font-size: 20px;\n  font-weight: 600;\n  color: #0a0d14;\n  text-align: center;\n  margin-top: 20px;\n}\n\n.termsandcondition_heading_logo {\n  width: 40px;\n  height: 37px;\n}\n\n.termsandcondition_heading_logo img {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
