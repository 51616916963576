import React, { useState } from "react";
import "./passwordInput.css";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";
import AuthInputIcon from "../../../components/defaultInput/authInputnIcon/AuthInputIcon";
import {
  check_circled,
  talk_stuff_authLogo,
  x_circle,
} from "../../../components/images";
import AuthForwardBtn from "../../../components/button/authForwardBtn/AuthForwardBtn";
import SuccessResetPasswordModal from "../../../components/successResetPasswordModal/SuccessResetPasswordModal";
import { useNavigate, useParams } from "react-router-dom";
import AuthPasswordInput from "../../../components/defaultInput/authPasswordInput/AuthPasswordInput";
import AuthBodyMargin from "../../../components/authBodyMargin/AuthBodyMargin";
import { axiosCalls } from "../../../_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import { useDispatch } from "react-redux";

const PasswordInput = () => {
  const [successModal, setSuccessModal] = useState(false);
  const [eightCharac, setEightCharac] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [oneUpperCase, setOneUpperCase] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const { resetToken } = useParams();
  const handleSuccessReset = () => {
    navigate("/login");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          // Validation for password
          if (!value) {
            stateObj[name] = "Please enter password.";
          } else if (
            formData.confirmPassword &&
            value !== formData.confirmPassword
          ) {
            stateObj["confirmPassword"] =
              "Password and confirm password does not match.";
          } else {
            // Check for password criteria
            if (value.length >= 8) {
              setEightCharac(true);
            } else {
              setEightCharac(false);
            }
            if (value.match(/[0-9]/)) {
              // Check if value contains at least one number
              setOneNumber(true);
            } else {
              setOneNumber(false);
            }
            if (/[A-Z]/.test(value)) {
              setOneUpperCase(true);
            } else {
              setOneUpperCase(false);
            }
            stateObj["confirmPassword"] = formData.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          // Validation for confirm password
          if (!value) {
            stateObj[name] = "Please enter confirm password.";
          } else if (formData.password && value !== formData.password) {
            stateObj[name] = "Password and confirm password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handlePasswordInputSubmit = async (e) => {
    const email = localStorage.getItem("resetEmail");

    const data = {
      email: email,
      password: formData.password,
      resetToken: resetToken,
    };

    // showLoader();
    setIsLoading(true);
    const res = await axiosCalls(
      "authentication/reset-password",
      "PATCH",
      data
    );

    if (res) {
      setIsLoading(false);
      // hideLoader();
      console.log(res);
      if (!res?.status) {
        console.log(res?.message);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.message,
        });
        // toast.error(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
        setSuccessModal(true);
      }
    }
  };

  return (
    <AuthBodyMargin>
      <ToastContainer />
      <div className="termsandcondition_heading_logo">
        <img src={talk_stuff_authLogo} />
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handlePasswordInputSubmit();
        }}
        className="register_verify_input_body"
      >
        <div className="register_verify_input_body_headerr">Password Reset</div>
        <div className="register_verify_input_body_head_textt">
          Set up a secure password to protect your account
        </div>

        <AuthPasswordInput
          passwordLabel="Create a Password"
          passwordPlaceHolder="******"
          width="100%"
          required={true}
          passwordName="password"
          passwordOnchange={handleChange}
          passwordValue={formData.password}
          error={error.password}
          validateInput={validateInput}
        />
        <AuthPasswordInput
          passwordLabel="Confirm Password"
          passwordPlaceHolder="******"
          width="100%"
          required={true}
          passwordName="confirmPassword"
          passwordOnchange={handleChange}
          passwordValue={formData.confirmPassword}
          error={error.confirmPassword}
          validateInput={validateInput}
        />

        <div className="personal_password_validation_checkHead">
          Password must contain at least;
        </div>
        <div className="personal_password_validation_checkflex_body">
          <div className="personal_password_validation_checkflex_icon">
            <img src={eightCharac ? check_circled : x_circle} />
          </div>
          <div className="personal_password_validation_checkflex_text">
            At least 8 characters
          </div>
        </div>
        <div className="personal_password_validation_checkflex_body">
          <div className="personal_password_validation_checkflex_icon">
            <img src={oneUpperCase ? check_circled : x_circle} />
          </div>
          <div className="personal_password_validation_checkflex_text">
            At least 1 uppercase
          </div>
        </div>
        <div className="personal_password_validation_checkflex_body">
          <div className="personal_password_validation_checkflex_icon">
            <img src={oneNumber ? check_circled : x_circle} />
          </div>
          <div className="personal_password_validation_checkflex_text">
            At least 1 number
          </div>
        </div>

        <AuthBtn
          btnDisabled={
            formData.password === "" ||
            formData.confirmPassword === "" ||
            oneNumber === false ||
            oneUpperCase === false ||
            eightCharac === false ||
            error.confirmPassword !== ""
          }
          // handleClick={() => {
          //   setSuccessModal(true);
          // }}
          btnText="Submit"
        />

        <div className="register_goback_login_body">
          <div className="register_goback_login_body_text">
            Already have an account?
          </div>
          <AuthForwardBtn
            handleGoForward={() => {
              navigate("/login");
            }}
            forwardBtnText="Login"
          />
        </div>
      </form>

      {successModal && (
        <div>
          <SuccessResetPasswordModal
            successBody=" Password reset successfully."
            handleSuccessReset={handleSuccessReset}
          />
        </div>
      )}
    </AuthBodyMargin>
  );
};

export default PasswordInput;
