// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supamall_homethirdbody_section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  box-sizing: border-box;
  margin-top: 20px;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/supamallComp/homeThirdSection/homeThirdSection.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,SAAS;EACT,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".supamall_homethirdbody_section {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 20px;\n  box-sizing: border-box;\n  margin-top: 20px;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
