import React from "react";
import "./productCard.css";
import { sample_supa_productt, shopping_basket } from "../../images";
import { useNavigate } from "react-router-dom";

const ProductCard = () => {
  const navigate = useNavigate();
  return (
    <div className="product_card_supamall_body">
      <div className="product_card_supamall_body_body_initial">
        <div
          onClick={() => {
            navigate("/supamall/product");
          }}
          className="product_card_supamall_body_iniital_prImage"
        >
          <img src={sample_supa_productt} />
        </div>
        <div className="product_card_supamall_body_price_name">
          <div className="product_card_supamall_body_name">Office Table</div>
          <div className="product_card_supamall_body_price">N 49,000</div>
        </div>
      </div>

      <div className="product_card_supamall_body_button_body">
        <div className="product_card_supamall_body_button_icon">
          <img src={shopping_basket} />
        </div>
        <div className="product_card_supamall_body_button">Add to cart</div>
      </div>
    </div>
  );
};

export default ProductCard;
