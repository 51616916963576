// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sell_category_card_bodyy {
  border: 1.72px solid #e2e4e9;

  border-radius: 14px;
  padding: 13px;
  box-sizing: border-box;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sell_category_card_icon__ {
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sell_category_card_main_textt {
  color: #525866;
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sell_category_card_body_textt {
  color: #525866;
  font-size: 15px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
  text-align: center;
}

@media screen and (max-width: 850px) {
  .sell_category_card_bodyy {
    width: 100%;
    margin-bottom: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/supamallComp/sellCategoryCard/sellCategoryCard.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;;EAE5B,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;IACX,mBAAmB;EACrB;AACF","sourcesContent":[".sell_category_card_bodyy {\n  border: 1.72px solid #e2e4e9;\n\n  border-radius: 14px;\n  padding: 13px;\n  box-sizing: border-box;\n  width: 30%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.sell_category_card_icon__ {\n  width: 70px;\n  height: 70px;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n}\n\n.sell_category_card_main_textt {\n  color: #525866;\n  font-size: 18px;\n  font-weight: 700;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.sell_category_card_body_textt {\n  color: #525866;\n  font-size: 15px;\n  font-weight: 400;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  width: 90%;\n  text-align: center;\n}\n\n@media screen and (max-width: 850px) {\n  .sell_category_card_bodyy {\n    width: 100%;\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
