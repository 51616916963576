import axios from "axios";

export const baseUrl = "https://app.talkstuff.social/api/v1";

export const notificationBase = "https://notifications.talkstuff.social/api/v1";
export const agoraBaseUrl = "https://agoratoken.talkstuff.social";
export const agoraCallNotification = "https://notifications.talkstuff.social";
export const chatBaseUrl = "https://chats.talkstuff.social/api/v1";

export let timoutReq = 10000;

const token = localStorage.getItem("token");

export const axiosCalls = async (path, method, data = null) => {
  console.log(token);
  try {
    let res = await axios({
      method,
      url: `${baseUrl}/${path}`,
      data,
      // timeout: timoutReq,
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res) {
      return res?.data;
    }
  } catch (error) {
    if (error.message == "timeout of 100ms exceeded") {
      return { er: "slowNetwork" };
    }
    return { er: error?.response };
  }
};
export const axiosNotificationCalls = async (path, method, data = null) => {
  console.log(token);
  try {
    let res = await axios({
      method,
      url: `${notificationBase}/${path}`,
      data,
      // timeout: timoutReq,
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res) {
      return res?.data;
    }
  } catch (error) {
    if (error.message == "timeout of 100ms exceeded") {
      return { er: "slowNetwork" };
    }
    return { er: error?.response };
  }
};

export const axiosFormData = async (path, method, data) => {
  console.log(token);
  try {
    let res = await axios({
      method: `${method}`,
      url: `${baseUrl}/${path}`,
      data,
      // timeout: timoutReq,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    if (res) {
      return res.data;
    }
  } catch (error) {
    if (error.message == "timeout of 100ms exceeded") {
      return { er: "slowNetwork" };
    }
    return { er: error.response };
  }
};

export const axiosAgoraCall = async (path, method, data = null) => {
  console.log(token);
  try {
    let res = await axios({
      method,
      url: `${agoraBaseUrl}/${path}`,
      data,
      // timeout: timoutReq,
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res) {
      return res?.data;
    }
  } catch (error) {
    if (error.message == "timeout of 100ms exceeded") {
      return { er: "slowNetwork" };
    }
    return { er: error?.response };
  }
};

export const axiosAgoraNotificationCall = async (path, method, data = null) => {
  console.log(token);
  try {
    let res = await axios({
      method,
      url: `${agoraCallNotification}/${path}`,
      data,
      // timeout: timoutReq,
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res) {
      return res?.data;
    }
  } catch (error) {
    if (error.message == "timeout of 100ms exceeded") {
      return { er: "slowNetwork" };
    }
    return { er: error?.response };
  }
};

export const axiosChatCall = async (path, method, data = null) => {
  console.log(token);
  try {
    let res = await axios({
      method,
      url: `${chatBaseUrl}/${path}`,
      data,
      // timeout: timoutReq,
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res) {
      return res?.data;
    }
  } catch (error) {
    if (error.message == "timeout of 100ms exceeded") {
      return { er: "slowNetwork" };
    }
    return { er: error?.response };
  }
};
