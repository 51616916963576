// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout_modal_toggle_body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid #e2e4e9;
  padding-bottom: 20px;
}

.logout_modal_toggle_left_body {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logout_modal_toggle_left_img {
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #e2e4e9;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.logout_modal_toggle_left_text_body {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  flex-direction: column;
}

.logout_modal_toggle_left_text_head {
  font-size: 13px;
  font-weight: 600;
  color: #0a0d14;
}

.logout_modal_toggle_left_text_sub {
  font-size: 13px;
  font-weight: 400;
  color: #525866;
}

.logout_modal_toggle_right_img {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logout_modal_toggle_bottom_margin {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid #e2e4e9;
}
`, "",{"version":3,"sources":["webpack://./src/components/mediumModalHeader/mediumModalHeader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,WAAW;EACX,gCAAgC;EAChC,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,2BAA2B;EAC3B,QAAQ;EACR,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gCAAgC;AAClC","sourcesContent":[".logout_modal_toggle_body {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 10px;\n  width: 100%;\n  border-bottom: 1px solid #e2e4e9;\n  padding-bottom: 20px;\n}\n\n.logout_modal_toggle_left_body {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.logout_modal_toggle_left_img {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  border: 1px solid #e2e4e9;\n  width: 32px;\n  height: 32px;\n  border-radius: 50%;\n}\n\n.logout_modal_toggle_left_text_body {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n  gap: 2px;\n  flex-direction: column;\n}\n\n.logout_modal_toggle_left_text_head {\n  font-size: 13px;\n  font-weight: 600;\n  color: #0a0d14;\n}\n\n.logout_modal_toggle_left_text_sub {\n  font-size: 13px;\n  font-weight: 400;\n  color: #525866;\n}\n\n.logout_modal_toggle_right_img {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.logout_modal_toggle_bottom_margin {\n  width: 100%;\n  position: absolute;\n  border-bottom: 1px solid #e2e4e9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
