import React, { useState, useEffect } from "react";
import {
  arrow_left,
  more_horizontal__,
  phone_outgoing,
  photo_sample_tab2,
  video,
} from "../../../components/images";
import "./chatBoxHeader.css";
import { useDispatch, useSelector } from "react-redux";
import {
  handleGetEachChannelToken,
  handleShowChatBox,
  handleShowVideoCall,
} from "../../../redux/userData";
import Basics from "../../../components/agoraComponent/basics/CallScreen";
import { axiosAgoraCall } from "../../../_api";
import { v4 as uuidv4 } from "uuid";
import { CallCustomToast } from "../../../utils/CallCustomToast";

const ChatBoxHeader = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const { showEachUserChat } = useSelector(
    (state) => state.showEachUserChatBody.showEachUserChatBody
  );

  // useEffect(() => {
  //   getAgoraCallTokenSubscriber();
  // }, []);

  // const channel = showEachUserChat?._id;

  // const getAgoraCallTokenSubscriber = async () => {
  //   const data = {
  //     tokenType: "rtc",
  //     channel: channel,
  //     role: "publisher",
  //     uid: uuidv4(),
  //     expire: 3600,
  //   };

  //   const res = await axiosAgoraCall("getToken", "POST", data);

  //   if (res) {
  //     // if (!res?.status) {
  //     //   CallCustomToast(dispatch, {
  //     //     messageHeading: "Success",
  //     //     toastStatus: "Success",
  //     //     messageBody: res?.er?.data?.message,
  //     //   });
  //     //   const resData = res?.token;
  //     //   console.log(resData, "gggggggggg");
  //     //   // setToken(resData);
  //     //   dispatch(handleGetEachChannelToken(resData));
  //     //   // return;
  //     // }

  //     const resData = res?.token;
  //     console.log(resData, "gggggggggg");
  //     // setToken(resData);
  //     dispatch(handleGetEachChannelToken(resData));
  //   }
  // };

  return (
    <div className="chat_box_header_genBody">
      <div className="chat_box_header_firstFlex">
        <div
          onClick={() => {
            dispatch(handleShowChatBox(false));
          }}
          className="chat_box_header_back_arrow"
        >
          <img src={arrow_left} />
        </div>
        <div className="chat_box_header_firstFlex_profilePics">
          <img src={showEachUserChat?.profileImage} />
        </div>
        <div className="chat_box_header_firstFlexInfo">
          <div className="chat_box_header_firstFlexInfoName">
            {/* {showEachUserChat?.firstName} {showEachUserChat?.lastName} */}
            {showEachUserChat?.userName}
          </div>
          <div className="chat_box_header_firstFlexInfotext">
            Marketing businesses only
          </div>
        </div>
      </div>

      <div className="chat_box_header_secondFlexBody">
        <div
          onClick={() => {
            dispatch(handleShowVideoCall(true));
          }}
          className="chat_box_header_secondFlexBody_icons"
        >
          <img src={video} />
        </div>
        <div className="chat_box_header_secondFlexBody_icons">
          <img src={phone_outgoing} />
        </div>
        <div className="chat_box_header_secondFlexBody_icons">
          <img src={more_horizontal__} />
        </div>
      </div>
    </div>
  );
};

export default ChatBoxHeader;
