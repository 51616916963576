import React from "react";
import "./helpSupport.css";
import HomeMargin from "../../../components/homeMargin/HomeMargin";
import InnerAccordionHeader from "../../../components/innerAccordionHeader/InnerAccordionHeader";
import {
  apple_store_downloadBtn,
  google_playstore_btn,
  help_circle_icon,
} from "../../../components/images";
import HelpSupportSearchCard from "../../../components/helpSupportComp/helpSupportSearchCard/HelpSupportSearchCard";
import HelpSupportLinksCard from "../../../components/helpSupportComp/helpSupportLinksCard/HelpSupportLinksCard";

const HelpSupport = () => {
  return (
    <div className="help_support_main_homemmm">
      <InnerAccordionHeader
        headerText="Help & Support"
        ringIcon={help_circle_icon}
      />

      <HelpSupportSearchCard />

      <div className="helpSupport_bar_body_footer_btn_body">
        <div className="helpSupport_bar_body_footer_btn_body_each">
          <img src={apple_store_downloadBtn} />
        </div>
        <div className="helpSupport_bar_body_footer_btn_body_each">
          <img src={google_playstore_btn} />
        </div>
      </div>

      <div className="help_support_linkscardGen_body">
        {/* <HelpSupportLinksCard />
        <HelpSupportLinksCard />
        <HelpSupportLinksCard />
        <HelpSupportLinksCard />
        <HelpSupportLinksCard />
        <HelpSupportLinksCard />
        <HelpSupportLinksCard /> */}
      </div>
    </div>
  );
};

export default HelpSupport;
