import React from "react";
import "./whiteBtn.css";

const WhiteBtn = ({ whiteBtnText, handleBtn, width }) => {
  return (
    <div style={{ width }} onClick={handleBtn} className="white_btn_body">
      <input type="submit" value={whiteBtnText} />
    </div>
  );
};

export default WhiteBtn;
