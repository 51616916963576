import React from "react";
import "./innerAccordionBorderBody.css";

const InnerAccordionBorderBody = ({
  children,
  borderMarginHead,
  borderMarginHeadSub,
}) => {
  return (
    <div className="inner_accordion_border_body">
      <div className="inner_accordion_border_body_heading">
        {" "}
        {borderMarginHead}
      </div>
      <div className="inner_accordion_border_body_heading_sub">
        {borderMarginHeadSub}
      </div>
      {children}
    </div>
  );
};

export default InnerAccordionBorderBody;
