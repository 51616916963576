// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat_home_gen_body {
  /* margin-left: auto; */
  margin-right: auto;
  margin-left: 310px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  /* flex-direction: column; */
  gap: 20px;
  margin-top: 30px;
}

.chat_nochat_desktop_view {
  width: 70%;
}

@media screen and (max-width: 850px) {
  .chat_home_gen_body {
    width: 100%;
    margin-left: 0px;
    align-items: center;
    justify-content: center;
  }

  .chat_nochat_desktop_view {
    width: 0%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/chatSection/chatHome/chatHome.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,yBAAyB;EACzB,4BAA4B;EAC5B,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,SAAS;EACX;AACF","sourcesContent":[".chat_home_gen_body {\n  /* margin-left: auto; */\n  margin-right: auto;\n  margin-left: 310px;\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-end;\n  /* flex-direction: column; */\n  gap: 20px;\n  margin-top: 30px;\n}\n\n.chat_nochat_desktop_view {\n  width: 70%;\n}\n\n@media screen and (max-width: 850px) {\n  .chat_home_gen_body {\n    width: 100%;\n    margin-left: 0px;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .chat_nochat_desktop_view {\n    width: 0%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
