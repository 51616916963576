import React from "react";

const MobileStore = ({ fill }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2799_10977)">
        <path
          d="M1.59961 4.77643L4.33352 2.04252C4.44886 1.92649 4.58603 1.83443 4.73712 1.77166C4.8882 1.70888 5.05022 1.67663 5.21383 1.67676H10.3841C10.5477 1.67663 10.7097 1.70888 10.8608 1.77166C11.0119 1.83443 11.149 1.92649 11.2644 2.04252L13.9983 4.77643"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.83984 7.87646V12.8359C2.83984 13.1648 2.97047 13.4801 3.20299 13.7127C3.43551 13.9452 3.75088 14.0758 4.07971 14.0758H11.5189C11.8478 14.0758 12.1631 13.9452 12.3956 13.7127C12.6282 13.4801 12.7588 13.1648 12.7588 12.8359V7.87646"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.65906 14.0756V11.5958C9.65906 11.267 9.52843 10.9516 9.29591 10.7191C9.06339 10.4866 8.74802 10.356 8.41919 10.356H7.17932C6.85049 10.356 6.53512 10.4866 6.3026 10.7191C6.07008 10.9516 5.93945 11.267 5.93945 11.5958V14.0756"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.59961 4.77637H13.9983"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.9983 4.77637V6.63617C13.9983 6.965 13.8677 7.28037 13.6352 7.51289C13.4026 7.74541 13.0873 7.87604 12.7584 7.87604C12.3962 7.8561 12.0503 7.71904 11.7727 7.48548C11.6988 7.43203 11.6098 7.40325 11.5186 7.40325C11.4273 7.40325 11.3384 7.43203 11.2644 7.48548C10.9868 7.71904 10.6409 7.8561 10.2787 7.87604C9.91649 7.8561 9.57056 7.71904 9.293 7.48548C9.21903 7.43203 9.13009 7.40325 9.03882 7.40325C8.94756 7.40325 8.85862 7.43203 8.78465 7.48548C8.50709 7.71904 8.16116 7.8561 7.79895 7.87604C7.43675 7.8561 7.09082 7.71904 6.81326 7.48548C6.73929 7.43203 6.65035 7.40325 6.55909 7.40325C6.46782 7.40325 6.37888 7.43203 6.30491 7.48548C6.02735 7.71904 5.68142 7.8561 5.31922 7.87604C4.95701 7.8561 4.61108 7.71904 4.33352 7.48548C4.25955 7.43203 4.17061 7.40325 4.07935 7.40325C3.98808 7.40325 3.89914 7.43203 3.82517 7.48548C3.54761 7.71904 3.20168 7.8561 2.83948 7.87604C2.51064 7.87604 2.19528 7.74541 1.96276 7.51289C1.73024 7.28037 1.59961 6.965 1.59961 6.63617V4.77637"
          stroke={fill}
          stroke-width="1.48784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2799_10977">
          <rect
            width="14.8784"
            height="14.8784"
            fill="white"
            transform="translate(0.359375 0.437012)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MobileStore;
