export const genderData = ["Male", "Female", "Custom"];
export const productCategory = [
  "Supermarket",
  "Fashion",
  "Furniture",
  "Phones & Tablet",
  "Computing",
  "Health",
  "Real Estate",
  "Automobiles",
  "Home Appliances",
];

export const nigeriaStates = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
  "Federal Capital Territory (FCT)",
];

export const typeData = ["Male", "Female", "Unisex"];
export const deliveryData = ["Yes", "No"];
