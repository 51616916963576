import React, { useState, useEffect } from "react";
import "./securityAndPrivacy.css";
import HomeMargin from "../../../components/homeMargin/HomeMargin";
import InnerAccordionHeader from "../../../components/innerAccordionHeader/InnerAccordionHeader";
import {
  lock_keyhole_head,
  user_round_block,
} from "../../../components/images";
import { axiosCalls } from "../../../_api";
import { useDispatch } from "react-redux";
import InnerAccordionBorderBody from "../../../components/innerAccordionBorderBody/InnerAccordionBorderBody";
import HomeAccountInfoCard from "../../../components/homeAccountInfoCard/HomeAccountInfoCard";
import { getLocalStorageItem } from "../../../utils/getLocalStorageItem";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";
import WhiteBtn from "../../../components/button/whiteBtn/WhiteBtn";
import { handleShowBlockedUsed } from "../../../redux/userData";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import UseClipRoundLoader from "../../../utils/UseClipRoundLoader";

const SecurityAndPrivacy = () => {
  const [tab, setTab] = useState(0);
  const handleSetTab = (status) => {
    setTab(status);
  };
  const [userData, setUserData] = useState(null);
  const [isMediaTagLoading, setIsMediaTagLoading] = useState(false);
  const [isContentLoading, setIsContentLoading] = useState(false);

  const [onErrorLoading, setOnErrorLoading] = useState(false);
  const [userRelation, setUserRelation] = useState(null);
  const [photoTagging, setPhotoTagging] = useState(false);
  const [postProtect, setPostProtect] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const handleCheckboxChange = (content) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.includes(content)
        ? prevOptions.filter((option) => option !== content)
        : [...prevOptions, content]
    );
  };

  const dispatch = useDispatch();
  useEffect(() => {
    getUserAccountInfo();
    const data = getLocalStorageItem("userData");
    setUserData(data);
  }, []);

  const handlePhotoTagChange = (checked) => {
    setPhotoTagging(checked);
  };

  const handlePostProtectChange = (checked) => {
    setPostProtect(checked);
  };

  const contentViewable = ["TOPICS", "SENSITIVE", "INTERESTS"];
  const capitalizeFirstLetter = (text) => {
    return text.toLowerCase().replace(/^\w/, (char) => char.toUpperCase());
  };
  // const handleGetUserRelations = async (e) => {
  //   setIsLoading(true);
  //   setOnErrorLoading(false);
  //   const res = await axiosCalls(
  //     "accounts/account-profile-relations?page=1&limit=50",
  //     "GET"
  //   );

  //   if (res) {
  //     console.log(res);
  //     setIsLoading(false);
  //     if (!res?.status) {
  //       // console.log(res?.er.data?.message);
  //       setOnErrorLoading(true);

  //       return;
  //     } else if (res?.status) {
  //       setOnErrorLoading(false);
  //       setUserRelation(res?.data);
  //       console.log(userRelation, "user relation");
  //     }
  //   }
  // };

  const getUserAccountInfo = async () => {
    // Fetch user data from local storage
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    if (!storedUserData?._id) {
      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "User ID not found.",
      });
      return;
    }

    try {
      setLoadingInfo(true);
      const res = await axiosCalls(
        `accounts/account-info?userId=${storedUserData._id}`,
        "GET"
      );

      if (res) {
        setLoadingInfo(false);

        if (!res?.status) {
          CallCustomToast(dispatch, {
            messageHeading: "Error",
            toastStatus: "Error",
            messageBody: res?.er?.data?.message,
          });
        } else {
          if (res?.status) {
            // Process and preload any necessary data
            setPhotoTagging(res?.data?.photoTagging);
            setPostProtect(res?.data?.postProtect);
            setSelectedOptions(res?.data?.contentViewable);
          }
        }
      }
    } catch (err) {
      console.log("Error getting account info", { err });

      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "Something went wrong",
      });
    }
  };

  const handleMediaTag = async () => {
    const data = {
      photoTagging: photoTagging,
      postProtect: postProtect,
    };

    setIsMediaTagLoading(true);
    const res = await axiosCalls(
      "accounts/account-media-tagging-audience",
      "PATCH",
      data
    );

    if (res) {
      setIsMediaTagLoading(false);
      if (!res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
      } else if (res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
      }
    }
  };

  const handleContentToSee = async () => {
    const data = {
      contentViewable: selectedOptions,
    };

    setIsContentLoading(true);
    const res = await axiosCalls(
      "accounts/account-content-viewable",
      "PATCH",
      data
    );

    if (res) {
      setIsContentLoading(false);
      if (!res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
      } else if (res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
      }
    }
  };
  return (
    <HomeMargin>
      <InnerAccordionHeader
        headerText="Security and Privacy"
        ringIcon={lock_keyhole_head}
        subText="  Take control of your account's security and monitor its usage, including connected apps"
      />

      <div className="user_account_home_body_tab_">
        <div
          onClick={() => {
            handleSetTab(0);
          }}
          className={
            tab === 0
              ? "user_account_home_body_tab_each_active"
              : "user_account_home_body_tab_each"
          }
        >
          Blocking
        </div>
        <div
          onClick={() => {
            handleSetTab(1);
          }}
          className={
            tab === 1
              ? "user_account_home_body_tab_each_active"
              : "user_account_home_body_tab_each"
          }
        >
          Audience, media and tagging
        </div>
        <div
          onClick={() => {
            handleSetTab(2);
          }}
          className={
            tab === 2
              ? "user_account_home_body_tab_each_active"
              : "user_account_home_body_tab_each"
          }
        >
          Content you see
        </div>
      </div>

      {loadingInfo ? (
        <div
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <UseClipRoundLoader color="#008dcb" />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          {tab === 0 && (
            <InnerAccordionBorderBody
              borderMarginHead="Blocking"
              borderMarginHeadSub="Your safety is our priority. Explore security and privacy settings to keep your account protected."
            >
              <div
                onClick={() => {
                  dispatch(handleShowBlockedUsed(true));
                }}
                className="security_privacy_btn_click"
              >
                <div className="security_privacy_btn_click_img">
                  <img src={user_round_block} />
                </div>
                <div className="security_privacy_btn_click_text">
                  Manage blocking{" "}
                </div>
              </div>
            </InnerAccordionBorderBody>
          )}
          {tab === 1 && (
            <InnerAccordionBorderBody
              borderMarginHead="Audience, media and tagging"
              borderMarginHeadSub="Manage what Manage what information you allow other people on talkstuff to see."
            >
              <div className="security_tagging_media_body">
                <ul>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        checked={photoTagging}
                        onChange={() => handlePhotoTagChange(!photoTagging)}
                      />

                      <div className="security_tagging_media_body_right">
                        <div className="security_tagging_media_body_right_tex">
                          Photo tagging
                        </div>
                        <div className="security_tagging_media_body_right_subtex">
                          Anyone can tag you
                        </div>
                      </div>
                    </label>
                  </li>
                </ul>
              </div>
              <div className="security_tagging_media_body">
                <ul>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        checked={postProtect}
                        onChange={() => handlePostProtectChange(!postProtect)}
                      />

                      <div className="security_tagging_media_body_right">
                        <div className="security_tagging_media_body_right_tex">
                          Protect your post
                        </div>
                        <div className="security_tagging_media_body_right_subtex">
                          When chosen, your post and additional account details
                          become visible solely to individuals who are following
                          you
                        </div>
                      </div>
                    </label>
                  </li>
                </ul>
              </div>

              <div className="security_tagging_media_body_btn_flex">
                <WhiteBtn whiteBtnText="Discard" width="200px" />
                <AuthBtn
                  handleClick={(e) => {
                    e.preventDefault();
                    handleMediaTag();
                  }}
                  isLoading={isMediaTagLoading}
                  btnText="Update Changes"
                  width="200px"
                />
              </div>
            </InnerAccordionBorderBody>
          )}
          {tab === 2 && (
            <InnerAccordionBorderBody
              borderMarginHead="Content you see"
              borderMarginHeadSub="Select what you will like to see."
            >
              <div className="security_tagging_media_body">
                <ul>
                  {contentViewable?.map((content, index) => (
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(content)}
                          onChange={() => {
                            handleCheckboxChange(content);
                            console.log(selectedOptions, "seledtedddd");
                          }}
                        />

                        <div className="security_tagging_media_body_right_tex">
                          {capitalizeFirstLetter(content)}
                        </div>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>{" "}
              <div className="security_tagging_media_body_btn_flex">
                <WhiteBtn whiteBtnText="Discard" width="200px" />
                <AuthBtn
                  isLoading={isContentLoading}
                  handleClick={(e) => {
                    e.preventDefault(e);
                    handleContentToSee();
                  }}
                  btnText="Update Changes"
                  width="200px"
                />
              </div>
            </InnerAccordionBorderBody>
          )}
        </div>
      )}
    </HomeMargin>
  );
};

export default SecurityAndPrivacy;
