import React from "react";
import "./defaultTextArea.css";

const DefaultTextArea = ({
  inputPlaceholder,
  inputValue,
  inputName,
  inputOnchange,
  inputDisabled,
  validateInput,
  error,
  width,
  inputLabel,
  height,
  required,
}) => {
  return (
    <div className="textarea_input_container" style={{ width }}>
      {inputLabel && (
        <div className="textarea_input_label">
          {inputLabel}{" "}
          {required && (
            <span
              style={{ color: "#F41010", fontSize: "13px", fontWeight: "600" }}
            >
              *
            </span>
          )}
        </div>
      )}
      <div className="textarea_input_parent">
        <textarea
          style={{
            background: inputDisabled ? "#F7FAFC" : "#fff",
            color: inputDisabled ? "#CBD5E0" : "#2D3748",
            cursor: inputDisabled && "not-allowed",
            height,
          }}
          placeholder={inputPlaceholder}
          value={inputValue}
          name={inputName}
          onChange={inputOnchange}
          disabled={inputDisabled}
          onBlur={validateInput}
          error={error}
        />
      </div>

      {error && <div className="textarea_error_message">{error}</div>}
    </div>
  );
};

export default DefaultTextArea;
