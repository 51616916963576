import React from "react";
import "./createPostWarningModal.css";
import InfoModal from "../../infoModal/InfoModal";
import ModalHeaderIconCont from "../../modalHeaderIconCont/ModalHeaderIconCont";
import { copy_plus_post } from "../../images";
import WhiteBtn from "../../button/whiteBtn/WhiteBtn";
import AuthBtn from "../../button/authBtn/AuthBtn";

const CreatePostWarningModal = ({
  handleCloseModal,
  handleCreatePostFeed,
  isLoading,
}) => {
  return (
    <InfoModal
      handleCloseModal={() => {
        handleCloseModal(false);
      }}
      height="fit-content"
      minHeight="0"
      width="400px"
      overflowY="hidden"
      maxHeight="400px"
    >
      <ModalHeaderIconCont
        modalHeaderIcon={copy_plus_post}
        modalHeaderName="Create New Post"
      />
      <div className="create_postwarning_modal_media_header">
        This post has no Image/Video file
      </div>
      <div className="create_postwarning_modal_media_body_btn_flex">
        <WhiteBtn
          handleBtn={() => {
            handleCloseModal(false);
          }}
          whiteBtnText="Cancel"
          width="200px"
        />
        <AuthBtn
          isLoading={isLoading}
          handleClick={(e) => {
            e.preventDefault();

            handleCreatePostFeed();
          }}
          btnText="Continue"
          width="200px"
        />
      </div>
    </InfoModal>
  );
};

export default CreatePostWarningModal;
