// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interest_card_rack_each_body {
  box-shadow: 0px 1px 2px 0px #5258660d;
  border: 1px solid #e2e4e9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 8px 10px 8px;
  /* gap: 5px; */
  cursor: pointer;
  gap: 5px;
  border-radius: 10px;
}

.interest_card_rack_each_body_active {
  box-shadow: 0px 1px 2px 0px #5258660d;
  border: 1px solid #e45911;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 8px 10px 8px;
  gap: 5px;
  /* gap: 5px; */
  cursor: pointer;
  border-radius: 10px;

  /* background: green; */
}
.interest_card_rack_each_body:hover {
  background: #faf9f9;
}
.interest_card_rack_each_body_img {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.interest_card_rack_each_body_text {
  color: #525866;
  font-size: 13px;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/interestComp/interestCardRackEach/interestCardRackEach.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,uBAAkB;EAAlB,kBAAkB;EAClB,0BAA0B;EAC1B,cAAc;EACd,eAAe;EACf,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,qCAAqC;EACrC,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,uBAAkB;EAAlB,kBAAkB;EAClB,0BAA0B;EAC1B,QAAQ;EACR,cAAc;EACd,eAAe;EACf,mBAAmB;;EAEnB,uBAAuB;AACzB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".interest_card_rack_each_body {\n  box-shadow: 0px 1px 2px 0px #5258660d;\n  border: 1px solid #e2e4e9;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: fit-content;\n  padding: 10px 8px 10px 8px;\n  /* gap: 5px; */\n  cursor: pointer;\n  gap: 5px;\n  border-radius: 10px;\n}\n\n.interest_card_rack_each_body_active {\n  box-shadow: 0px 1px 2px 0px #5258660d;\n  border: 1px solid #e45911;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: fit-content;\n  padding: 10px 8px 10px 8px;\n  gap: 5px;\n  /* gap: 5px; */\n  cursor: pointer;\n  border-radius: 10px;\n\n  /* background: green; */\n}\n.interest_card_rack_each_body:hover {\n  background: #faf9f9;\n}\n.interest_card_rack_each_body_img {\n  width: 16px;\n  height: 16px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.interest_card_rack_each_body_text {\n  color: #525866;\n  font-size: 13px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
