import React from "react";
import "./eachMatchesCard.css";
import { luvhub_matchsample } from "../../images";
import { useDispatch, useSelector } from "react-redux";
import {
  handleEachMatchesModal,
  handleGetEachMatchModalData,
} from "../../../redux/userData";
import useProfileImage from "../../../utils/useProfileImage";

const EachMatchesCard = ({ data }) => {
  const dispatch = useDispatch();

  const imagePrefix = useProfileImage(
    data?.user?.firstName,
    data?.user?.lastName,
    "270px",
    "270px",
    "265px",
    "265px",
    "45px",
    "",
    "",
    "16px",
    "16px"
  );

  return (
    <div
      onClick={() => {
        dispatch(handleGetEachMatchModalData(data));
        // dispatch(handleEachMatchesModal(true));
      }}
      className="each_matches_card__body"
    >
      <div className="each_matches_card_image_topbody">
        {data?.publicImages[0] ? (
          <div className="each_matches_card_image_topbody_main">
            <img src={data?.publicImages[0]?.url} />
          </div>
        ) : (
          imagePrefix
        )}
        <div className="each_matches_card_image_topbody_card_down">
          <div className="each_matches_card_image_topbody_card_down_flexleft">
            <div className="each_matches_card_image_topbody_card_down_flexleft_name">
              {data?.user?.userName}
            </div>
            <div className="each_matches_card_image_topbody_card_down_flexleft_choices">
              {data?.interests?.slice(0, 5)?.map((interest, index) => (
                <div className="each_matches_card_image_topbody_card_down_flexleft_choicesEach">
                  {interest}
                </div>
              ))}
            </div>
          </div>

          {data?.interests?.length > 5 && (
            <div className="each_matches_card_image_topbody_card_down_flexright">
              +{data?.interests?.length - 5} more
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EachMatchesCard;
