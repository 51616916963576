import Home from "./screens/homeScreens/home/Home";
import TermsandCondition from "./screens/legal/termsandcondition/TermsandCondition";
import PrivacyPolicy from "./screens/legal/privacyPolicy/PrivacyPolicy";
import Login from "./screens/auth/login/Login";
import PasswordReset from "./screens/auth/passwordReset/PasswordReset";
import PersonalInfoRegister from "./screens/auth/personalInfoRegister/PersonalInfoRegister";
import Register from "./screens/auth/register/Register";
import VerifyRegister from "./screens/auth/verifyRegister/VerifyRegister";
import RegisterSummary from "./screens/auth/registerSummary/RegisterSummary";
import { ProtectedLayout } from "./components/layout";
import UserAccount from "./screens/homeScreens/userAccount/UserAccount";
import SecurityAndPrivacy from "./screens/homeScreens/securityAndPrivacy/SecurityAndPrivacy";
import NotificationPreferences from "./screens/homeScreens/notificationPreferences/NotificationPreferences";
import Interests from "./screens/homeScreens/interests/Interests";
import Bookmarks from "./screens/homeScreens/bookmarks/Bookmarks";
import ViewProfile from "./screens/homeScreens/viewProfile/ViewProfile";
import HelpSupport from "./screens/homeScreens/helpSupport/HelpSupport";
import PasswordInput from "./screens/auth/passwordInput/PasswordInput";
import PostGridViewer from "./components/postGridViewer/PostGridViewer";
import ChatHome from "./screens/chatSection/chatHome/ChatHome";
import SupaMallHome from "./screens/supaMall/supaMallHome/SupaMallHome";
import LuvhubHome from "./screens/luvhub/luvhubHome/LuvhubHome";
import SupaMallEachProduct from "./screens/supaMall/supaMallEachProduct/SupaMallEachProduct";
import SupermarketCategory from "./screens/supaMall/supaMallCategories/supermarketCategory/SupermarketCategory";
import RealEstateCategory from "./screens/supaMall/supaMallCategories/realEstateCategory/RealEstateCategory";
import PhonesTabletCategory from "./screens/supaMall/supaMallCategories/phonesTabletCategory/PhonesTabletCategory";
import HomeAppliancesCategory from "./screens/supaMall/supaMallCategories/homeAppliancesCategory/HomeAppliancesCategory";
import HealthCategory from "./screens/supaMall/supaMallCategories/healthCategory/HealthCategory";
import FurnitureCatgory from "./screens/supaMall/supaMallCategories/furnitureCategory/FurnitureCatgory";
import FashionCategory from "./screens/supaMall/supaMallCategories/fashionCategory/FashionCategory";
import ComputingCategory from "./screens/supaMall/supaMallCategories/computingCategory/ComputingCategory";
import AutomobilesCategory from "./screens/supaMall/supaMallCategories/automobilesCategory/AutomobilesCategory";
import StylistServices from "./screens/supaMall/supamallServices/stylistServices/StylistServices";
import UiUxJobs from "./screens/supaMall/supaMallJobs/uiUxJobs/UiUxJobs";
import PhotographyServices from "./screens/supaMall/supamallServices/photographyServices/PhotographyServices";
import SupamallCart from "./screens/supaMall/supamallCart/SupamallCart";
import SupaMallSellCategory from "./screens/supaMall/supaMallSellBody/supaMallSellCategory/SupaMallSellCategory";
import SupaMallJobsForm from "./screens/supaMall/supaMallSellBody/supaMallJobsForm/SupaMallJobsForm";
import SupaMallServicesForm from "./screens/supaMall/supaMallSellBody/supaMallServicesForm/SupaMallServicesForm";
import SupaMallProductForm from "./screens/supaMall/supaMallSellBody/supaMallProductForm/SupaMallProductForm";
import LuvhubSideBar from "./screens/luvhub/luvhubSideBar/LuvhubSideBar";
import ExplorePageCard from "./components/luvHubComp/explorePageCard/ExplorePageCard";
import DeleteAccount from "./screens/auth/deleteAccount/DeleteAccount";

export const HomePage = () => {
  return (
    <ProtectedLayout>
      <Home />
    </ProtectedLayout>
  );
};

export const ChatHomePage = () => {
  return (
    <ProtectedLayout>
      <ChatHome />
    </ProtectedLayout>
  );
};
export const UserAccountPage = () => {
  return (
    <ProtectedLayout>
      <UserAccount />
    </ProtectedLayout>
  );
};

export const SecurityAndPrivacyPage = () => {
  return (
    <ProtectedLayout>
      <SecurityAndPrivacy />
    </ProtectedLayout>
  );
};

export const InterestsPage = () => {
  return (
    <ProtectedLayout>
      <Interests />
    </ProtectedLayout>
  );
};

export const NotificationPreferencesPage = () => {
  return (
    <ProtectedLayout>
      <NotificationPreferences />
    </ProtectedLayout>
  );
};

export const BookmarksPage = () => {
  return (
    <ProtectedLayout>
      <Bookmarks />
    </ProtectedLayout>
  );
};
export const HelpSupportPage = () => {
  return (
    // <ProtectedLayout>
    <HelpSupport />
    // </ProtectedLayout>
  );
};

export const ViewProfilePage = () => {
  return (
    <ProtectedLayout>
      <ViewProfile />
    </ProtectedLayout>
  );
};

export const SupaMallHomePage = () => {
  return (
    <ProtectedLayout>
      <SupaMallHome />
    </ProtectedLayout>
  );
};

export const LuvhubHomePage = () => {
  return (
    <ProtectedLayout>
      <LuvhubHome />
    </ProtectedLayout>
  );
};

export const ExplorePageCardPage = () => {
  return (
    <ProtectedLayout>
      <ExplorePageCard />
    </ProtectedLayout>
  );
};
export const LuvhubSideBarPage = () => {
  return (
    <ProtectedLayout>
      <LuvhubSideBar />
    </ProtectedLayout>
  );
};

export const SupaMallEachProductPage = () => {
  return (
    <ProtectedLayout>
      <SupaMallEachProduct />
    </ProtectedLayout>
  );
};

export const SupermarketCategoryPage = () => {
  return (
    <ProtectedLayout>
      <SupermarketCategory />
    </ProtectedLayout>
  );
};

export const RealEstateCategoryPage = () => {
  return (
    <ProtectedLayout>
      <RealEstateCategory />
    </ProtectedLayout>
  );
};

export const PhonesTabletCategoryPage = () => {
  return (
    <ProtectedLayout>
      <PhonesTabletCategory />
    </ProtectedLayout>
  );
};

export const HomeAppliancesCategoryPage = () => {
  return (
    <ProtectedLayout>
      <HomeAppliancesCategory />
    </ProtectedLayout>
  );
};

export const HealthCategoryPage = () => {
  return (
    <ProtectedLayout>
      <HealthCategory />
    </ProtectedLayout>
  );
};

export const FurnitureCatgoryPage = () => {
  return (
    <ProtectedLayout>
      <FurnitureCatgory />
    </ProtectedLayout>
  );
};

export const FashionCategoryPage = () => {
  return (
    <ProtectedLayout>
      <FashionCategory />
    </ProtectedLayout>
  );
};

export const ComputingCategoryPage = () => {
  return (
    <ProtectedLayout>
      <ComputingCategory />
    </ProtectedLayout>
  );
};

export const AutomobilesCategoryPage = () => {
  return (
    <ProtectedLayout>
      <AutomobilesCategory />
    </ProtectedLayout>
  );
};

export const StylistServicesPage = () => {
  return (
    <ProtectedLayout>
      <StylistServices />
    </ProtectedLayout>
  );
};
export const PhotographyServicesPage = () => {
  return (
    <ProtectedLayout>
      <PhotographyServices />
    </ProtectedLayout>
  );
};

export const UiUxJobsPage = () => {
  return (
    <ProtectedLayout>
      <UiUxJobs />
    </ProtectedLayout>
  );
};

export const SupamallCartPage = () => {
  return (
    <ProtectedLayout>
      <SupamallCart />
    </ProtectedLayout>
  );
};

export const SupaMallSellCategoryPage = () => {
  return (
    <ProtectedLayout>
      <SupaMallSellCategory />
    </ProtectedLayout>
  );
};

export const SupaMallJobsFormPage = () => {
  return (
    <ProtectedLayout>
      <SupaMallJobsForm />
    </ProtectedLayout>
  );
};
export const SupaMallServicesFormPage = () => {
  return (
    <ProtectedLayout>
      <SupaMallServicesForm />
    </ProtectedLayout>
  );
};
export const SupaMallProductFormPage = () => {
  return (
    <ProtectedLayout>
      <SupaMallProductForm />
    </ProtectedLayout>
  );
};
export const TermsandConditionPage = () => {
  return <TermsandCondition />;
};

export const PrivacyPolicyPage = () => {
  return <PrivacyPolicy />;
};

export const LoginPage = () => {
  return <Login />;
};

export const PasswordResetPage = () => {
  return <PasswordReset />;
};

export const PersonalInfoRegisterPage = () => {
  return <PersonalInfoRegister />;
};

export const RegisterSummaryPage = () => {
  return <RegisterSummary />;
};
export const RegisterPage = () => {
  return <Register />;
};

export const VerifyRegisterPage = () => {
  return <VerifyRegister />;
};

export const PasswordInputPage = () => {
  return <PasswordInput />;
};
export const DeleteAccountPage = () => {
  return <DeleteAccount />;
};
