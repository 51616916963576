import React from "react";
import "./storyModalCard.css";
import InfoModal from "../../infoModal/InfoModal";
import { handleStoryModal } from "../../../redux/userData";
import { useDispatch } from "react-redux";
import { paperclip, stories_sampleShow } from "../../images";
import AuthInput from "../../defaultInput/authInput/AuthInput";
import AuthBtn from "../../button/authBtn/AuthBtn";

const StoryModalCard = () => {
  const dispatch = useDispatch();

  return (
    <InfoModal
      width="500px"
      handleCloseModal={() => {
        dispatch(handleStoryModal(false));
      }}
    >
      <div className="story_modal_username_">Kristin Watson</div>
      <div className="story_modal_user_date">12 mins ago</div>

      <div className="story_modal_user_img_sample">
        <img src={stories_sampleShow} />
      </div>

      <div className="story_modal_user_img_footer_gen">
        <div className="story_modal_user_img_footer_paper_icon">
          <img src={paperclip} />
        </div>
        <div className="story_modal_user_img_footer_paper_input">
          <input placeholder="Write something" />
        </div>

        <div className="story_modal_user_img_footer_paper_btn">
          <AuthBtn btnText="Send" width="100%" />
        </div>
      </div>
    </InfoModal>
  );
};

export default StoryModalCard;
