// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat_box_mobileModalBody {
  width: 70%;
}

.chat_box_mobileContent {
  width: 100%;
}

@media screen and (max-width: 850px) {
  .chat_box_mobileContent {
    position: relative;
    width: 100%;
    border-radius: 16px;
    background: #fff;
    padding: 20px;
    /* border-radius: 8px; */
    animation: slide-in 0.3s ease-in-out;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
  }
  .chat_box_mobileModalBody {
    position: fixed;
    top: 0;
    left: 0; /* Update to cover entire viewport */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9991;
    box-sizing: border-box;
  }

  @keyframes slide-in {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .info_modal_pointerr {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 330px; /* Adjust as needed */
    top: 32%; /* Adjust as needed */
    transform: translateY(-50%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/chatBoxMobile/chatBoxMobile.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,kBAAkB;IAClB,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,wBAAwB;IACxB,oCAAoC;IACpC,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;EACxB;EACA;IACE,eAAe;IACf,MAAM;IACN,OAAO,EAAE,oCAAoC;IAC7C,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oCAAoC;IACpC,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE;MACE,4BAA4B;MAC5B,UAAU;IACZ;IACA;MACE,wBAAwB;MACxB,UAAU;IACZ;EACF;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW,EAAE,qBAAqB;IAClC,QAAQ,EAAE,qBAAqB;IAC/B,2BAA2B;EAC7B;AACF","sourcesContent":[".chat_box_mobileModalBody {\n  width: 70%;\n}\n\n.chat_box_mobileContent {\n  width: 100%;\n}\n\n@media screen and (max-width: 850px) {\n  .chat_box_mobileContent {\n    position: relative;\n    width: 100%;\n    border-radius: 16px;\n    background: #fff;\n    padding: 20px;\n    /* border-radius: 8px; */\n    animation: slide-in 0.3s ease-in-out;\n    height: 100%;\n    overflow-y: auto;\n    box-sizing: border-box;\n  }\n  .chat_box_mobileModalBody {\n    position: fixed;\n    top: 0;\n    left: 0; /* Update to cover entire viewport */\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 9991;\n    box-sizing: border-box;\n  }\n\n  @keyframes slide-in {\n    from {\n      transform: translateY(-100%);\n      opacity: 0;\n    }\n    to {\n      transform: translateY(0);\n      opacity: 1;\n    }\n  }\n\n  .info_modal_pointerr {\n    position: absolute;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    left: 330px; /* Adjust as needed */\n    top: 32%; /* Adjust as needed */\n    transform: translateY(-50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
