import React from "react";
import "./createPostInnerCard.css";
import { chevron_right_post, copy_plus_post } from "../../images";

const CreatePostInnerCard = ({
  handleClickCard,
  innerIcon,
  innerHead,
  innerTextSub,
}) => {
  return (
    <div onClick={handleClickCard} className="create_post_innerCard_body">
      <div className="create_post_innerCard_right">
        <div className="create_post_innerCard_rightImg">
          <img src={innerIcon} />
        </div>
        <div className="create_post_innerCard_rightText">
          <div className="create_post_innerCard_rightText_head">
            {innerHead}
          </div>
          <div className="create_post_innerCard_rightText_sub">
            {innerTextSub}
          </div>
        </div>
      </div>
      <div className="create_post_innerCard_rightText_chev_icon">
        <img src={chevron_right_post} />
      </div>
    </div>
  );
};

export default CreatePostInnerCard;
