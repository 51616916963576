import React, { useState } from "react";
import "./deactivateAccountModal.css";
import InfoModal from "../infoModal/InfoModal";
import ModalHeaderIconCont from "../modalHeaderIconCont/ModalHeaderIconCont";
import AuthPasswordInput from "../defaultInput/authPasswordInput/AuthPasswordInput";
import { axiosCalls } from "../../_api";
import { CallCustomToast } from "../../utils/CallCustomToast";
import { handleDeactivateModal } from "../../redux/userData";
import { ban_deact } from "../images";
import AuthBtn from "../button/authBtn/AuthBtn";
import { useDispatch } from "react-redux";

const DeactivateAccountModal = () => {
  const [formValue, setFormValue] = useState({
    password: "",
  });

  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("userData"));

  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const handlePasswordInputSubmit = async (e) => {
    const data = {
      email: userDetails?.email,
      password: formValue?.password,
      status: false,
    };

    // showLoader();
    setIsLoading(true);
    const res = await axiosCalls("accounts/account-activation", "PATCH", data);

    if (res) {
      setIsLoading(false);
      // hideLoader();
      console.log(res, "llllllll");
      if (!res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });

        return;
      } else if (res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });

        dispatch(handleDeactivateModal(false));
      }
    }
  };

  return (
    <InfoModal
      handleCloseModal={() => {
        dispatch(handleDeactivateModal(false));
      }}
      width="600px"
    >
      <ModalHeaderIconCont
        modalHeaderIcon={ban_deact}
        modalHeaderName="Deactivate Account"
      />
      <div className="deactivate_acct_subHeaderTextt">
        Please enter your password to deactivate your account
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handlePasswordInputSubmit();
        }}
      >
        <AuthPasswordInput
          passwordLabel="Password"
          passwordValue={formValue?.password}
          passwordName="password"
          passwordPlaceHolder="*******"
          required={true}
          passwordOnchange={handleChange}
        />
        <AuthBtn
          isLoading={isLoading}
          btnDisabled={formValue.password === ""}
          width="100%"
          btnText="Deactivate"
          background="#C70920"
        />
      </form>
      ;
    </InfoModal>
  );
};

export default DeactivateAccountModal;
