import React from "react";
import {
  close_toast,
  error_toastIcon,
  success_toast,
} from "../components/images";
import "./customToast.css";
import { useSelector, useDispatch } from "react-redux";
import { handleShowSuccessToast } from "../redux/userData";

const CustomToast = () => {
  const dispatch = useDispatch();
  const { showSuccessToast } = useSelector(
    (state) => state.showSuccessToastBody.showSuccessToastBody
  );

  const { toastData } = useSelector(
    (state) => state.toastDataBody.toastDataBody
  );
  const { toastHeaderData } = useSelector(
    (state) => state.toastHeaderDataBody.toastHeaderDataBody
  );
  return (
    <div className="custom_toast_gen_body">
      <div className="custom_toast_gen_body_secondd">
        <div className="custom_toast_gen_body_img">
          <img
            src={
              showSuccessToast === "Success" ? success_toast : error_toastIcon
            }
          />
        </div>
        <div className="custom_toast_gen_body_text">
          <div className="custom_toast_gen_body_headd">{toastHeaderData}</div>
          <div className="custom_toast_gen_body_body">{toastData}</div>
        </div>
      </div>

      <div
        onClick={() => {
          dispatch(handleShowSuccessToast(null));
        }}
        className="custom_toast_gen_body_img_close"
      >
        <img src={close_toast} />
      </div>
    </div>
  );
};

export default CustomToast;
