import React from "react";
import "./eachFollowerCard.css";
import { more_vertical, sample_follower_img } from "../images";
import LazyLoadingHook from "../../utils/lazyLoadingHook";
import useProfileImage from "../../utils/useProfileImage";

const EachFollowerCard = ({ data }) => {
  const profileImageInitials = useProfileImage(data?.firstName, data?.lastName);

  return (
    <div className="each_follower_card_body">
      <div className="each_follower_card_body_firstFlex">
        {data?.profileImage ? (
          <div className="profile_info_pics">
            {/* <LazyLoadingHook
              imageSrc={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com/${data?.profileImage}`}
              effect="blur"
            /> */}
            <img
              src={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com/${data?.profileImage}`}
            />
          </div>
        ) : (
          profileImageInitials
        )}

        <div className="each_follower_card_body_firstFlex_text">
          {/* {data?.firstName} {data?.lastName} */}

          {data?.userName}
        </div>
      </div>

      <div className="each_follower_card_body_firstFlex_icon">
        <img src={more_vertical} />
      </div>
    </div>
  );
};

export default EachFollowerCard;
