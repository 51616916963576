import React from "react";
import "./authBtn.css";
import { FaSpinner } from "react-icons/fa";
import { css } from "@emotion/react"; // Import css function from react-spinners
import { ClipLoader } from "react-spinners"; // Import ClipLoader spinner from react-spinners library

const AuthBtn = ({
  handleClick,
  btnText,
  width,
  btnDisabled,
  isLoading,
  marginTop,
  height,
  fontSize,
  background,
}) => {
  return (
    <div
      onClick={handleClick}
      className="auth_btn_tag_container"
      style={{ width, marginTop }}
    >
      <div
        className={
          btnDisabled || isLoading
            ? "auth_btn_tag_parent auth_btn_tag_parent_disabled"
            : "auth_btn_tag_parent"
        }
        style={{ height }}
      >
        <input
          disabled={btnDisabled || isLoading}
          type="submit"
          value={isLoading ? "Processing..." : btnText}
          style={{ fontSize, background }}
        />
      </div>
    </div>
  );
};

const spinnerStyle = css`
  display: block;
  margin: auto;
`;

export default AuthBtn;
