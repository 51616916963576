import React from "react";
import "./senderChatCard.css";
import { check_check_message } from "../../../components/images";

const SenderChatCard = ({ messageTime, message }) => {
  return (
    <div className="sender_chat_card_genBody">
      <div className="sender_chat_card_genBody_inner">
        <div className="sender_chat_card_genBodyDate">{messageTime}</div>
        <div className="sender_chat_card_genBodyMessage">{message}</div>

        <div className="sender_chat_card_genBody_check">
          <div className="sender_chat_card_genBody_checkIcon">
            <img src={check_check_message} />
          </div>
          <div className="sender_chat_card_genBody_checkSeen">Seen</div>
        </div>
      </div>
    </div>
  );
};

export default SenderChatCard;
