import React, { useState } from "react";
import "./postCardEngage.css";
import {
  comment_circle,
  heart_liked,
  heartforlike,
  sampleLikedImg,
} from "../../images";
import HeartLiked from "../../images/files/heartLiked";
import {
  handlePreviewPostComment,
  handleShowAllPostComment,
  handleStatusPostComment,
} from "../../../redux/userData";
import { useDispatch } from "react-redux";
import { axiosCalls } from "../../../_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import LazyLoadingHook from "../../../utils/lazyLoadingHook";

const PostCardEngage = ({ sampleLikedImg, userPost }) => {
  const [likePost, setLikePost] = useState(false);
  const [isLoadingComment, setIsLoadingComment] = useState(false);
  const [isLoadingLike, setIsLoadingLike] = useState(false);

  const handleGetFeedComment = async (e) => {
    setIsLoadingComment(true);
    const res = await axiosCalls(
      `feeds/fetch-feed-comments?feedId=${userPost?._id}&page=1`,
      "Get"
    );

    if (res) {
      console.log(res);
      setIsLoadingComment(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        // onSuccessPostImages();
        // onSuccessMessagePost(res?.message);

        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        const resData = res?.data;
        dispatch(handleShowAllPostComment(resData));
        // console.log(postComment, "comments");
      }
    }
  };

  const handleLikeFeed = async (e) => {
    const data = {
      feedId: userPost?._id,
    };
    setIsLoadingLike(true);
    const res = await axiosCalls(`feeds/like-feed`, "PATCH", data);

    if (res) {
      console.log(res);
      setIsLoadingLike(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        // onSuccessPostImages();
        // onSuccessMessagePost(res?.message);

        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        setLikePost(true);
        // console.log(postComment, "comments");
      }
    }
  };

  const handleUnLikeFeed = async (e) => {
    const data = {
      feedId: userPost?._id,
    };
    setIsLoadingLike(true);
    const res = await axiosCalls(`feeds/unlike-feed`, "PATCH", data);

    if (res) {
      console.log(res);
      setIsLoadingLike(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        // onSuccessPostImages();
        // onSuccessMessagePost(res?.message);

        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        setLikePost(false);
        // console.log(postComment, "comments");
      }
    }
  };

  const toggleLikePost = (status) => {
    setLikePost(status);
  };
  const baseImageUrl =
    "https://talkstuff-store.fra1.cdn.digitaloceanspaces.com";

  const dispatch = useDispatch();

  // const { likes } = userPost;
  return (
    <div className="post_card_engage_body">
      <div
        style={{ width: "fit-content", display: "flex", alignItems: "center" }}
      >
        {userPost?.likesCount > 0 && (
          <div className="post_card_engage_left_img_body">
            {userPost?.lastNLikes?.likes?.map((data, index) => (
              <div className="post_card_engage_left_imgcontainer">
                <div className="post_card_engage_left_mainImg">
                  {/* <img src={`${baseImageUrl}${data?.profileImage}`} /> */}
                  {/* <img src={data?.profileImage} /> */}
                  <LazyLoadingHook imageSrc={data?.profileImage} />
                </div>
                <div className="post_card_engage_left_heartImg">
                  <img src={heartforlike} />
                </div>
              </div>
            ))}

            <div className="post_card_engage_left_imglike_num">
              {" "}
              {userPost?.likesCount}
            </div>
          </div>
        )}
      </div>

      <div className="post_card_engage_right_body">
        <div
          onClick={() => {
            {
              userPost?.iLiked ? handleUnLikeFeed() : handleLikeFeed();
            }
          }}
          className="post_card_engage_right_container"
        >
          <div className="post_card_engage_right_container_icon">
            {userPost?.iLiked ? (
              <HeartLiked fill="#FF5266" outline="none" />
            ) : (
              <HeartLiked
                fill={likePost ? "#FF5266" : "none"}
                outline={likePost ? "none" : "#525866"}
              />
            )}
          </div>
          <div className="post_card_engage_right_container_text">Like</div>
        </div>
        <div
          onClick={() => {
            dispatch(handlePreviewPostComment(userPost));
            // handleGetFeedComment();
            dispatch(handleStatusPostComment(true));
          }}
          className="post_card_engage_right_container"
        >
          <div className="user_post_comment_bodygen">{userPost?.comments}</div>
          <div className="post_card_engage_right_container_icon">
            <img src={comment_circle} />
          </div>{" "}
          <div className="post_card_engage_right_container_text">Comment</div>
        </div>
      </div>
    </div>
  );
};

export default PostCardEngage;
