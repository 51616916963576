// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_margin_body {
  margin-left: auto;
  margin-right: auto;
  margin-left: 310px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

@media screen and (max-width: 850px) {
  .home_margin_body {
    margin-left: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/homeMargin/homeMargin.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,8BAA8B;EAC9B,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".home_margin_body {\n  margin-left: auto;\n  margin-right: auto;\n  margin-left: 310px;\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  flex-direction: column;\n  gap: 20px;\n  margin-top: 30px;\n}\n\n@media screen and (max-width: 850px) {\n  .home_margin_body {\n    margin-left: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
