import React from "react";
import "./sellCategoryCard.css";
import { sell_categ_icon } from "../../images";
import WhiteBtn from "../../button/whiteBtn/WhiteBtn";

const SellCategoryCard = ({ headText, bodyText, handleBtn }) => {
  return (
    <div className="sell_category_card_bodyy">
      <div className="sell_category_card_icon__">
        <img src={sell_categ_icon} />
      </div>
      <div className="sell_category_card_main_textt">{headText}</div>
      <div className="sell_category_card_body_textt">{bodyText}</div>
      <WhiteBtn handleBtn={handleBtn} whiteBtnText="Select" width="80px" />
    </div>
  );
};

export default SellCategoryCard;
