// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_sidebar_body {
  width: 290px;
  /* height: 100%; */
  padding: 12px;
  border: 1px solid #e2e4e9;
  border-radius: 15px;
  position: fixed;
  box-sizing: border-box;
  margin-top: 30px;
  /* left: 0; */
}

.home_sidebar_bottom_border {
}

@media screen and (max-width: 850px) {
  .home_sidebar_body {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/homeSideBar/homeSideBar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;EACf,sBAAsB;EACtB,gBAAgB;EAChB,aAAa;AACf;;AAEA;AACA;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".home_sidebar_body {\n  width: 290px;\n  /* height: 100%; */\n  padding: 12px;\n  border: 1px solid #e2e4e9;\n  border-radius: 15px;\n  position: fixed;\n  box-sizing: border-box;\n  margin-top: 30px;\n  /* left: 0; */\n}\n\n.home_sidebar_bottom_border {\n}\n\n@media screen and (max-width: 850px) {\n  .home_sidebar_body {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
