// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post_card_body_option_dropdown {
  position: absolute;
  width: 280px;
  padding: 8px;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  flex-direction: column;
  right: 0;
  top: 60px;
  box-shadow: 0px 16px 40px -8px #585c5f29;
  background: #fff;
  z-index: 100;
}
`, "",{"version":3,"sources":["webpack://./src/components/postCardComponent/postCardComponent.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,QAAQ;EACR,sBAAsB;EACtB,QAAQ;EACR,SAAS;EACT,wCAAwC;EACxC,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".post_card_body_option_dropdown {\n  position: absolute;\n  width: 280px;\n  padding: 8px;\n  border-radius: 10px;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 5px;\n  flex-direction: column;\n  right: 0;\n  top: 60px;\n  box-shadow: 0px 16px 40px -8px #585c5f29;\n  background: #fff;\n  z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
