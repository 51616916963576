// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stories_bar_body {
  width: 290px;
}

.stories_bar_body_bottom_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box;
}
.stories_bar_body_bottom_footer_each {
  font-size: 12px;
  font-weight: 400;
  color: #525866;
  cursor: pointer;
}

.stories_bar_body_footer_btn_body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* gap: 5px; */
}

.stories_bar_body_footer_btn_body_each {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.stories_bar_body_footer_btn_body_each img {
  /* width: 100%; */
  object-fit: contain;
}

@media screen and (max-width: 1150px) {
  .stories_bar_body {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/storiesBar/storiesBar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,QAAQ;EACR,eAAe;EACf,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".stories_bar_body {\n  width: 290px;\n}\n\n.stories_bar_body_bottom_footer {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  gap: 5px;\n  flex-wrap: wrap;\n  padding: 20px;\n  box-sizing: border-box;\n}\n.stories_bar_body_bottom_footer_each {\n  font-size: 12px;\n  font-weight: 400;\n  color: #525866;\n  cursor: pointer;\n}\n\n.stories_bar_body_footer_btn_body {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  /* gap: 5px; */\n}\n\n.stories_bar_body_footer_btn_body_each {\n  width: 40%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.stories_bar_body_footer_btn_body_each img {\n  /* width: 100%; */\n  object-fit: contain;\n}\n\n@media screen and (max-width: 1150px) {\n  .stories_bar_body {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
