import React from "react";
import "./categoryCard.css";
import { arrow_up_supa, sample_category_image } from "../../images";

const CategoryCard = ({ image, catText }) => {
  return (
    <div className="category_supamall_card_body">
      <div className="categort_supamall_catImg">
        <img src={image} />
      </div>

      <div className="category_supamall_button">
        <img src={arrow_up_supa} />
      </div>

      <div className="category_supamall_cat_name">{catText} </div>
    </div>
  );
};

export default CategoryCard;
