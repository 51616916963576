import React, { useState, useEffect } from "react";
import "./postCardCaptionText.css";
import { sample_image } from "../../images";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCollectUserId,
  handleViewAnotherUserProfile,
} from "../../../redux/userData";
import { useNavigate } from "react-router-dom";
import useFormattedTime from "../../../utils/useFormattedTime";
import LazyLoadingHook from "../../../utils/lazyLoadingHook";

const PostCardCaptionText = ({ userPost, postUser, postCaption }) => {
  // const baseImgUrl = 'https://talkstuff-store.fra1.cdn.digitaloceanspaces.com'

  const [ownerDetails, setOwnerDetails] = useState(null);
  const getObjectFromLocalStorage = () => {
    const item = localStorage.getItem("userData");
    const itemized = JSON.parse(item);
    setOwnerDetails(itemized);
    console.log(ownerDetails, "oejen");
  };
  useEffect(() => {
    getObjectFromLocalStorage();
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formattedTime = useFormattedTime(userPost.createdAt);

  return (
    <div className="post_card_caption_text_body">
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          onClick={() => {
            {
              userPost?.userId?._id !== ownerDetails?._id
                ? dispatch(handleViewAnotherUserProfile(true))
                : dispatch(handleViewAnotherUserProfile(false));
            }
            dispatch(handleCollectUserId(userPost?.userId?._id));
            navigate("/user-profile");
          }}
          className="post_card_caption_text_info_head"
        >
          {userPost?.userId?.profileImage ? (
            <div className="post_card_caption_text_info_head_image_profile">
              <LazyLoadingHook imageSrc={`${userPost?.userId?.profileImage}`} />
            </div>
          ) : (
            <div className="profile_tab_body_details_image_body">
              <div className="profile_tab_body_details_image">
                {userPost?.userId?.firstName // if profile_pic is null, show only the first letters of the names
                  ?.split(" ")
                  ?.map((name) => name.charAt(0).toUpperCase())
                  .join("")}
                {userPost?.userId?.lastName // if profile_pic is null, show only the first letters of the names
                  ?.split(" ")
                  ?.map((name) => name.charAt(0).toUpperCase())
                  .join("")}
              </div>
            </div>
          )}

          <div className="post_card_caption_text_info_head_username">
            {/* {userPost?.userId?.firstName} {userPost?.userId?.lastName} */}
            {userPost?.userId?.userName}
          </div>
        </div>

        <div className="show_post_name_all_body_name_dateCounter">
          {formattedTime}
        </div>
      </div>

      <div className="post_card_caption_text_info_head_text">{postCaption}</div>
    </div>
  );
};

export default PostCardCaptionText;
