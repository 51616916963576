import React from "react";
import "./followersTab.css";
import EachFollowerCard from "../../eachFollowerCard/EachFollowerCard";

const FollowersTab = ({ userData }) => {
  return (
    <div className="profile_followers_tab_body">
      {userData?.followers?.map((data, index) => (
        <EachFollowerCard data={data} />
      ))}
    </div>
  );
};

export default FollowersTab;
