// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supamall_sell_category_gen_home {
  width: 100%;
  margin-top: 30px;
}

.supamall_sell_category_container {
  max-width: 1300px;
  border: 1px solid #e2e4e9;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  padding: 30px;
  box-sizing: border-box;
  margin-bottom: 100px;
}

.supamall_sell_category_card_body_flexxx {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.supamall_sell_category_goback_body {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.supamall_sell_categorization_text_head {
  color: #0a0d14;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 2px;
}
.supamall_sell_categorization_textttt {
  color: #525866;
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 20px;
}

@media screen and (max-width: 850px) {
  .supamall_sell_category_container {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/supaMall/supaMallSellBody/supaMallSellCategory/supaMallSellCategory.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,8BAA8B;EAC9B,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".supamall_sell_category_gen_home {\n  width: 100%;\n  margin-top: 30px;\n}\n\n.supamall_sell_category_container {\n  max-width: 1300px;\n  border: 1px solid #e2e4e9;\n  width: 100%;\n  margin-left: auto;\n  margin-right: auto;\n  border-radius: 20px;\n  padding: 30px;\n  box-sizing: border-box;\n  margin-bottom: 100px;\n}\n\n.supamall_sell_category_card_body_flexxx {\n  display: flex;\n  align-items: stretch;\n  justify-content: space-between;\n  width: 100%;\n  flex-wrap: wrap;\n}\n\n.supamall_sell_category_goback_body {\n  width: 100%;\n  display: flex;\n  align-items: flex-end;\n  justify-content: flex-end;\n}\n\n.supamall_sell_categorization_text_head {\n  color: #0a0d14;\n  font-weight: 600;\n  font-size: 16px;\n  margin-bottom: 2px;\n}\n.supamall_sell_categorization_textttt {\n  color: #525866;\n  font-size: 11px;\n  font-weight: 400;\n  margin-bottom: 20px;\n}\n\n@media screen and (max-width: 850px) {\n  .supamall_sell_category_container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
