// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post_upload_modal_headerBody {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  flex-direction: column;
}

.post_upload_modal_headerBody_img {
  width: 70px;
  height: 70px;

  border: 1px solid #e2e4e9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post_upload_modal_headerBody_head {
  color: #525866;
  font-size: 14px;
  font-weight: 600;
}

.post_upload_modal_headerBody_sub {
  color: #525866;
  font-size: 12px;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/createPost/postUploadModalHeader/postUploadModalHeader.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;EACR,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;;EAEZ,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".post_upload_modal_headerBody {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 3px;\n  flex-direction: column;\n}\n\n.post_upload_modal_headerBody_img {\n  width: 70px;\n  height: 70px;\n\n  border: 1px solid #e2e4e9;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.post_upload_modal_headerBody_head {\n  color: #525866;\n  font-size: 14px;\n  font-weight: 600;\n}\n\n.post_upload_modal_headerBody_sub {\n  color: #525866;\n  font-size: 12px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
