import React, { useState, useEffect } from "react";
import "./supaMallServicesForm.css";
import Footer from "../../../../components/supamallComp/footer/Footer";
import SelectInput from "../../../../components/defaultInput/selectInput/SelectInput";
import { info_supamall_product_add } from "../../../../components/images";
import DefaultTextArea from "../../../../components/defaultInput/defaultTextArea/DefaultTextArea";
import AuthInput from "../../../../components/defaultInput/authInput/AuthInput";
import AuthBtn from "../../../../components/button/authBtn/AuthBtn";
import Breadcrumbs from "../../../../components/supamallComp/breadCrumb/BreadCrumb";
import { productCategory } from "../../../../components/defaultInput/selectInput/SelectData";
import { axiosCalls } from "../../../../_api";
import { CallCustomToast } from "../../../../utils/CallCustomToast";
import { useDispatch } from "react-redux";

const SupaMallServicesForm = () => {
  const [uploadPost, setUploadPost] = useState(false);
  const [uploadStory, setUploadStory] = useState(false);

  const [selectedImages, setSelectedImages] = useState([]);
  const [displaySelectImages, setDisplaySelectedImages] = useState([]);
  const [productCategory, setProductCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorLoadig, setOnErrorLoading] = useState(false);
  const [productFormData, setProductFormData] = useState({
    category: "",
    location: "",
    type: "",
    quantity: "",
    name: "",
    description: "",
    price: "",
    phoneNumber: "",
    delivery: "",
    promoteAds: false,
  });

  const [productFormDataError, setProductFormDataError] = useState({
    category: "",
    location: "",
    type: "",
    quantity: "",
    name: "",
    description: "",
    price: "",
    phoneNumber: "",
    delivery: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetProductCategory();
  }, []);

  const handleGetProductCategory = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosCalls(
      `admin/supamall/category/fetch-categories`,
      "GET"
    );

    if (res) {
      console.log(res);
      setIsLoading(false);

      if (!res?.status) {
        setOnErrorLoading(true);

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        setProductCategory(res?.data[0]?.product);
        setOnErrorLoading(false);
        console.log(res?.data);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductFormData({
      ...productFormData,
      [name]: value,
    });

    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setProductFormDataError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "category":
          if (!value) {
            stateObj[name] = "Please enter select category";
          }
          break;
        case "location":
          if (!value) {
            stateObj[name] = "Please enter location";
          }
          break;
        case "type":
          if (!value) {
            stateObj[name] = "Please enter type";
          }
          break;
        case "quantity":
          if (!value) {
            stateObj[name] = "Please enter quantity";
          }
          break;
        case "name":
          if (!value) {
            stateObj[name] = "Please enter name";
          }
          break;
        case "description":
          if (!value) {
            stateObj[name] = "Please enter description";
          }
          break;
        case "price":
          if (!value) {
            stateObj[name] = "Please enter price";
          }
          break;
        case "phoneNumber":
          if (!value) {
            stateObj[name] = "Please enter phone number";
          }
          break;
        case "delivery":
          if (!value) {
            stateObj[name] = "Please select delivery";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);

    const totalFiles = selectedImages.length + files.length;

    if (totalFiles > 3) {
      alert("You can only upload a maximum of 3 files.");
      return;
    }

    if (files?.length > 0) {
      const newDisplayImages = [...displaySelectImages]; // Maintain existing displayed images
      const newSelectedImages = [...selectedImages]; // Maintain existing selected images

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.type === "video/mp4") {
          // Handle video file
          const reader = new FileReader();
          reader.onloadend = () => {
            // Generate a thumbnail from the video
            const video = document.createElement("video");
            video.src = reader.result;
            video.addEventListener("loadedmetadata", () => {
              // Create a canvas element to generate thumbnail
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");
              canvas.width = 200; // Set thumbnail width
              canvas.height =
                (canvas.width / video.videoWidth) * video.videoHeight;
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              // Convert thumbnail to data URL
              const thumbnail = canvas.toDataURL("image/jpeg");
              newDisplayImages.push(thumbnail); // Add thumbnail to displayed images
              setDisplaySelectedImages([...newDisplayImages]);
            });
          };
          reader.readAsDataURL(file);
        } else {
          // Handle image file
          const reader = new FileReader();
          reader.onloadend = () => {
            newDisplayImages.push(reader.result); // Add image to displayed images
            setDisplaySelectedImages([...newDisplayImages]);
          };
          reader.readAsDataURL(file);
        }

        newSelectedImages.push(file); // Add file to selected images
      }

      setSelectedImages(newSelectedImages);
    }

    console.log(selectedImages, "seletedimage");
  };

  const handleDeleteImagePost = (index) => {
    const updatedSelectedImages = [...selectedImages];
    updatedSelectedImages.splice(index, 1);
    setSelectedImages(updatedSelectedImages);

    const updatedDisplayImages = [...displaySelectImages];
    updatedDisplayImages.splice(index, 1);
    setDisplaySelectedImages(updatedDisplayImages);
  };

  const handleSubmitProductFormData = async () => {
    await console.log(productFormData, "product form data");
  };
  return (
    <div className="supamall_product_form_body_gen">
      <Breadcrumbs />
      <form className="supamall_product_form_body_form_gen">
        <div className="supamall_product_form_body_text_add__">Post an Ad</div>
        <SelectInput
          rolesData={productCategory}
          selectLabel="Category"
          width="100%"
          required={true}
          selectOnchange={handleChange}
          selectValue={productFormData?.category}
          selectName="category"
        />

        <SelectInput
          selectLabel="Location"
          selectOnchange={handleChange}
          selectValue={productFormData?.location}
          selectName="location"
        />

        <div className="supamall_product_form_addImages_gen">
          Add image{" "}
          <span
            style={{ color: "#F41010", fontSize: "13px", fontWeight: "600" }}
          >
            *{" "}
          </span>
        </div>
        <div className="supamall_product_form_addImages_top_head">
          At least add 1 or 2 images
        </div>
        <div className="supamall_product_form_addImages_flex_cards">
          <div className="supamall_product_form_addImages_card_add">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
            +
          </div>
          <div className="supamall_product_form_addImages_card_add">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
            +
          </div>
          <div className="supamall_product_form_addImages_card_add">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
            +
          </div>
        </div>

        {displaySelectImages?.length > 0 && (
          <div className="post_upload_uploaded_img_body">
            {displaySelectImages?.map((image, index) => (
              <div className="post_upload_uploaded_img_each" key={index}>
                {image?.name && /\.(mp4|webm|ogg)$/i.test(image?.name) ? (
                  <video controls className="uploaded-video">
                    <source
                      src={URL?.createObjectURL(image)}
                      type={image?.type || "video/mp4"}
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={image}
                    alt={`Uploaded ${index + 1}`}
                    className="uploaded-image"
                  />
                )}
                <div
                  onClick={() => {
                    handleDeleteImagePost(index);
                  }}
                  className="post_upload_cancel_icon"
                >
                  x
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="supamall_product_form_addImages_upload_card_body">
          <div className="supamall_product_form_addImages_upload_card_info">
            <img src={info_supamall_product_add} />
          </div>
          <div className="supamall_product_form_addImages_upload_card_text">
            Supported formats are .jpg, .gif and .png, 5MB max
          </div>
        </div>

        <div className="supamall_product_form_select_gen__flex">
          <SelectInput
            width="50%"
            selectLabel="Type"
            required={true}
            selectOnchange={handleChange}
            selectValue={productFormData?.type}
            selectName="type"
          />
          <SelectInput
            width="50%"
            selectLabel="Quantity"
            required={true}
            selectOnchange={handleChange}
            selectValue={productFormData?.quantity}
            selectName="quantity"
          />
        </div>
        <AuthInput
          width="100%"
          inputLabel="Name"
          required={true}
          inputPlaceHolder="Enter product name"
          inputName="name"
          inputOnchange={handleChange}
          inputType="text"
          inputValue={productFormData?.name}
          error={productFormDataError?.name}
          validateInput={validateInput}
        />
        <DefaultTextArea
          width="100%"
          inputPlaceholder="Enter your product description"
          required={true}
          inputLabel="Description"
          inputName="description"
          inputOnchange={handleChange}
          inputValue={productFormData?.description}
          error={productFormDataError?.description}
          validateInput={validateInput}
        />

        <div className="supamall_product_form_select_gen__flex">
          <AuthInput
            width="50%"
            inputLabel="Price"
            required={true}
            inputPlaceHolder="N"
            inputName="price"
            inputOnchange={handleChange}
            inputType="number"
            inputValue={productFormData?.price}
            error={productFormDataError?.price}
            validateInput={validateInput}
          />
          <AuthInput
            width="50%"
            inputLabel="Your phone number"
            required={true}
            inputPlaceHolder="Enter phone number"
            inputName="phoneNumber"
            inputOnchange={handleChange}
            inputType="number"
            inputValue={productFormData?.phoneNumber}
            error={productFormDataError?.phoneNumber}
            validateInput={validateInput}
          />
        </div>

        <SelectInput selectLabel="Delivery" width="100%" />
        <div className="supamall_product_form_select_promote_adssss">
          Promote Ads ?
        </div>

        <ul className="dropdown-menu">
          <li>
            <label>
              <input
                type="checkbox"
                // checked={isSelected(option)}
                // onChange={() => handleOptionChange(option)}
              />
              Yes
            </label>
          </li>
          <li>
            <label>
              <input
                type="checkbox"
                // checked={isSelected(option)}
                // onChange={() => handleOptionChange(option)}
              />
              No
            </label>
          </li>
        </ul>

        <div className="supamall_product_form_select_authBtn_body__">
          <AuthBtn
            handleClick={handleSubmitProductFormData}
            width="150px"
            btnText="Post Ad"
          />
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default SupaMallServicesForm;
