import React from "react";
import "./footer.css";
import FooterCardEssential from "../footerCardEssential/FooterCardEssential";
import {
  delivery_footer,
  payment_supamall_footer,
  support_footer_supamall,
} from "../../images";
import MainFooterSection from "../mainFooterSection/MainFooterSection";

const Footer = () => {
  return (
    <div style={{ width: "100%" }}>
      <div className="footer_supamall_bodyy">
        <div className="footer_supamall_bodyy__sub">
          <FooterCardEssential
            header="Pay on Delivery"
            para="And free returns"
            icon={delivery_footer}
          />
          <FooterCardEssential
            header="Safe Payment"
            para="Pay with the world’s most popular and safe payment methods"
            icon={payment_supamall_footer}
          />
          <FooterCardEssential
            icon={support_footer_supamall}
            header="24/7 Help center"
            para="Have a question? Call a specialist or chat online"
          />
          <FooterCardEssential
            icon={delivery_footer}
            header="Shop with confidence"
            para="Our Buyer’s protection covers your purchase from click to delivery"
            borderRight="none"
          />
        </div>
      </div>

      <MainFooterSection />
    </div>
  );
};

export default Footer;
