import React, { useState, useEffect } from "react";
import "./notificationPeferences.css";
import HomeMargin from "../../../components/homeMargin/HomeMargin";
import InnerAccordionHeader from "../../../components/innerAccordionHeader/InnerAccordionHeader";
import { bell_head } from "../../../components/images";
import InnerAccordionBorderBody from "../../../components/innerAccordionBorderBody/InnerAccordionBorderBody";
import Switch from "react-switch";
import WhiteBtn from "../../../components/button/whiteBtn/WhiteBtn";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";
import { axiosCalls } from "../../../_api";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import { useDispatch } from "react-redux";
import UseClipRoundLoader from "../../../utils/UseClipRoundLoader";

const NotificationPreferences = () => {
  const [tab, setTab] = useState(0);
  const [newsAndFeeds, setNewsAndFeeds] = useState(false);
  const [reminderAndEvents, setReminderAndEvents] = useState(false);

  const [promotionsAndOffers, setPromotionsAndOffers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInfo, setLoadingInfo] = useState(false);
  const handleSetTab = (status) => {
    setTab(status);
  };

  useEffect(() => {
    getUserAccountInfo();
  }, []);

  const dispatch = useDispatch();

  const handleNewsAndFeeds = () => {
    setNewsAndFeeds(!newsAndFeeds);
  };
  const handleReminderAndEvents = () => {
    setReminderAndEvents(!reminderAndEvents);
  };
  const handlePromotionsAndOffers = () => {
    setPromotionsAndOffers(!promotionsAndOffers);
  };

  const handleNotificationPreference = async (newSelectedRack) => {
    const data = {
      newsAndFeeds: newsAndFeeds,
      reminderAndEvents: reminderAndEvents,
      promotionAndOffers: promotionsAndOffers,
    };

    setIsLoading(true);
    const res = await axiosCalls(
      "accounts/account-notification-preference",
      "PATCH",
      data
    );

    if (res) {
      setIsLoading(false);
      if (!res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
      } else if (res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
      }
    }
  };

  const getUserAccountInfo = async () => {
    // Fetch user data from local storage
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    if (!storedUserData?._id) {
      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "User ID not found.",
      });
      return;
    }

    try {
      setLoadingInfo(true);
      const res = await axiosCalls(
        `accounts/account-info?userId=${storedUserData._id}`,
        "GET"
      );

      if (res) {
        setLoadingInfo(false);

        if (!res?.status) {
          CallCustomToast(dispatch, {
            messageHeading: "Error",
            toastStatus: "Error",
            messageBody: res?.er?.data?.message,
          });
        } else {
          if (res?.status) {
            // Process and preload any necessary data
            setNewsAndFeeds(res?.data?.notificationPreferences?.newsAndFeeds);
            setReminderAndEvents(
              res?.data?.notificationPreferences?.reminderAndEvents
            );
            setPromotionsAndOffers(
              res?.data?.notificationPreferences?.promotionAndOffers
            );
          }
        }
      }
    } catch (err) {
      console.log("Error getting account info", { err });

      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "Something went wrong",
      });
    }
  };
  return (
    <HomeMargin>
      <InnerAccordionHeader
        headerText="Notification"
        ringIcon={bell_head}
        subText="Select the notification preferences for updates related to your activities, interests, and recommendations"
      />
      <div className="user_account_home_body_tab_">
        <div
          onClick={() => {
            handleSetTab(0);
          }}
          className={
            tab === 0
              ? "user_account_home_body_tab_each_active"
              : "user_account_home_body_tab_each"
          }
        >
          Notification & Preferences
        </div>
      </div>

      {tab === 0 && (
        <InnerAccordionBorderBody
          borderMarginHead="Notification Preference"
          borderMarginHeadSub="Stay informed, stay in control. Customize your notification preferences for a tailored experience."
        >
          {isLoadingInfo ? (
            <div
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <UseClipRoundLoader color="#008dcb" />
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              <div className="notification_switch_selector_bodyyy">
                <div className="notification_switch_inner_body_textt">
                  <div className="notification_switch_inner_body_texttHead">
                    News & feeds
                  </div>
                  <div className="notification_switch_inner_body_texttSubb">
                    Stay informed, stay in control. Customize your notification
                    preferences for a tailored experience.
                  </div>
                </div>
                <div className="profile_option_each_body_sub_switch">
                  <Switch
                    name="isActive"
                    className="react-switcher"
                    onColor="#008dcb"
                    offColor="#e2e8f0"
                    // onHandleColor="#36a6a4"
                    handleDiameter={12}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    activeBoxShadow="0 0 1px 2px #FAF2FF"
                    height={24}
                    width={51}
                    borderRadius={30}
                    checked={newsAndFeeds}
                    onChange={handleNewsAndFeeds}
                  />
                </div>
              </div>
              <div className="notification_switch_selector_bodyyy">
                <div className="notification_switch_inner_body_textt">
                  <div className="notification_switch_inner_body_texttHead">
                    Reminder and Events
                  </div>
                  <div className="notification_switch_inner_body_texttSubb">
                    Tailor your alerts. Set Reminder and Events notifications
                    that suit your preferences.
                  </div>
                </div>
                <div className="profile_option_each_body_sub_switch">
                  <Switch
                    name="isActive"
                    className="react-switcher"
                    onColor="#008dcb"
                    offColor="#e2e8f0"
                    // onHandleColor="#36a6a4"
                    handleDiameter={12}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    activeBoxShadow="0 0 1px 2px #FAF2FF"
                    height={24}
                    width={51}
                    borderRadius={30}
                    checked={reminderAndEvents}
                    onChange={handleReminderAndEvents}
                  />
                </div>
              </div>
              <div className="notification_switch_selector_bodyyy">
                <div className="notification_switch_inner_body_textt">
                  <div className="notification_switch_inner_body_texttHead">
                    Promotion and offers{" "}
                  </div>
                  <div className="notification_switch_inner_body_texttSubb">
                    Receive promotion and offer alerts
                  </div>
                </div>
                <div className="profile_option_each_body_sub_switch">
                  <Switch
                    name="isActive"
                    className="react-switcher"
                    onColor="#008dcb"
                    offColor="#e2e8f0"
                    // onHandleColor="#36a6a4"
                    handleDiameter={12}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    activeBoxShadow="0 0 1px 2px #FAF2FF"
                    height={24}
                    width={51}
                    borderRadius={30}
                    checked={promotionsAndOffers}
                    onChange={handlePromotionsAndOffers}
                  />
                </div>
              </div>

              <div className="security_tagging_media_body_btn_flex">
                <WhiteBtn whiteBtnText="Discard" width="200px" />
                <AuthBtn
                  isLoading={isLoading}
                  handleClick={(e) => {
                    e.preventDefault();
                    handleNotificationPreference();
                  }}
                  btnText="Update Changes"
                  width="200px"
                />
              </div>
            </div>
          )}
        </InnerAccordionBorderBody>
      )}
    </HomeMargin>
  );
};

export default NotificationPreferences;
