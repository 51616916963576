import React from "react";
import "./homeSearchContainer.css";
import AuthInputIcon from "../../defaultInput/authInputnIcon/AuthInputIcon";
import { searchHelp, talkStuff_support_card } from "../../images";

const HomeSearchContainer = () => {
  return (
    <div className="supamall_support_search_body_gen">
      <div className="supamall_support_search_body_inputbody">
        <div className="supamall_support_search_body_InputHead">
          What are you looking for
        </div>
        <div className="supamall_support_search_body_input">
          <AuthInputIcon
            width="100%"
            inputPlaceHolder="Search items, brands, and categories."
            inputLeftIcon={searchHelp}
          />
        </div>
      </div>
      <div className="supamall_support_search_body_left_logo">
        <img src={talkStuff_support_card} />
      </div>
    </div>
  );
};

export default HomeSearchContainer;
