import React, { useEffect, useState } from "react";
import "./supaMallNotification.css";
import EachNotificationCard from "../eachNotificationCard/EachNotificationCard";
import { axiosNotificationCalls } from "../../../_api";
import UseClipRoundLoader from "../../../utils/UseClipRoundLoader";
import { useDispatch } from "react-redux";
import { CallCustomToast } from "../../../utils/CallCustomToast";

const SupaMallNotification = () => {
  const [userMallNotifications, setUserMallNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorLoading, setOnErrorLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    handleGetUserNotifications();
  }, []);

  const handleGetUserNotifications = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosNotificationCalls(
      `notifications/fetch-notifications?page=1&module=SOCIAL`,
      "GET"
    );

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        // console.log(res?.er.data?.message);
        setOnErrorLoading(true);

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });

        return;
      } else if (res?.status) {
        setUserMallNotifications(res?.data || []);
        setOnErrorLoading(false);
      }
    }
  };
  return (
    <div className="luv_hub_notification_body">
      {isLoading ? (
        <div className="clip_loader_boddyy">
          <UseClipRoundLoader color="#008dcb" size={50} isLoading={isLoading} />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          {userMallNotifications.length < 1 ? (
            <div className="no_notification_text_notify">No notifications</div>
          ) : (
            userMallNotifications?.map((data) => (
              <EachNotificationCard data={data} />
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SupaMallNotification;
