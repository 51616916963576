import React from "react";
import "./logoutModal.css";
import InfoModal from "../infoModal/InfoModal";
import {
  handleLogoutModal,
  handleToggleProfileOption,
} from "../../redux/userData";
import { useDispatch } from "react-redux";
import { log_out_img_icon, user_mo, x_lo_mo } from "../images";
import MediumModalHeader from "../mediumModalHeader/MediumModalHeader";
import AuthBtn from "../button/authBtn/AuthBtn";
import WhiteBtn from "../button/whiteBtn/WhiteBtn";
import { useNavigate } from "react-router";

const LogoutModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clearLocalStorageAndRedirect = () => {
    localStorage.clear();
    // Redirect to '/login'
    navigate("/login");
  };
  return (
    <InfoModal
      handleCloseModal={() => {
        dispatch(handleLogoutModal(false));
      }}
    >
      <MediumModalHeader
        mediumModalText="Logout"
        mediumModalIcon={log_out_img_icon}
        mediumModalSubText="Are you sure you want to logout ?"
        showXIcon={false}
      />
      <div className="logout_modal_btn_body">
        <WhiteBtn
          handleBtn={() => {
            dispatch(handleLogoutModal(false));
          }}
          width="120px"
          whiteBtnText="Close"
        />
        <AuthBtn
          handleClick={() => {
            dispatch(handleLogoutModal(false));
            dispatch(handleToggleProfileOption(false));
            clearLocalStorageAndRedirect();
          }}
          width="120px"
          btnText="Yes, Logout"
        />
      </div>
    </InfoModal>
  );
};

export default LogoutModal;
