import React from "react";

const BottomHome = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.35846 8.52405C10.0704 8.52405 11.4581 7.13628 11.4581 5.42438C11.4581 3.71248 10.0704 2.32471 8.35846 2.32471C6.64656 2.32471 5.25879 3.71248 5.25879 5.42438C5.25879 7.13628 6.64656 8.52405 8.35846 8.52405Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3174 13.4834C13.3174 12.1681 12.7949 10.9066 11.8648 9.97652C10.9347 9.04644 9.67325 8.52393 8.35791 8.52393C7.04258 8.52393 5.78112 9.04644 4.85103 9.97652C3.92095 10.9066 3.39844 12.1681 3.39844 13.4834"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BottomHome;
