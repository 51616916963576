import React, { useState } from "react";
import "./homeSideBar.css";
import SidebarOption from "../../sidebarOption/SidebarOption";
import InfoModal from "../../infoModal/InfoModal";
import { useNavigate } from "react-router-dom";
import {
  bell,
  bell_sidebar,
  bookmark,
  copy_plus,
  heart_sidebar,
  help_circle,
  lock_keyhole,
  settings,
  user_round_sidebar,
  user_settings_round,
} from "../../images";
import ProfileInfoSidebarOption from "../../profileInfoSidebarOption/ProfileInfoSidebarOption";
import Accordion from "../../sidebarAccordion/SidebarAccordion";
import { useDispatch, useSelector } from "react-redux";
import {
  handleToggleCreatePost,
  handleToggleNotification,
  handleToggleProfileOption,
  handleViewAnotherUserProfile,
} from "../../../redux/userData";
import NotificationsCard from "../../notificationsCard/NotificationsCard";

const HomeSideBar = () => {
  // const { notificationModal } = useSelector(
  //   (state) => state.notificationToggle.notificationToggle
  // );

  const dispatch = useDispatch();

  // const handleToggleNotification = (status) => {
  //   setNotificationModal(status);
  // };
  const navigate = useNavigate();
  return (
    <div className="home_sidebar_body">
      <SidebarOption
        path="/"
        icon={user_round_sidebar}
        text="Home"
        onClickFunc={() => {
          navigate("/");
        }}
        showChevronIcon={true}
      />

      <SidebarOption
        icon={bell}
        text="Notifications"
        noOfNotification="3"
        onClickFunc={() => {
          dispatch(handleToggleNotification(true));

          // handleToggleNotification(true);
        }}
        showChevronIcon={false}
      />
      <SidebarOption
        icon={copy_plus}
        text="Create New post"
        onClickFunc={() => {
          dispatch(handleToggleCreatePost(true));
        }}
        showChevronIcon={false}
      />
      <SidebarOption
        path="/bookmarks"
        icon={bookmark}
        text="Bookmarks"
        onClickFunc={() => {
          navigate("/bookmarks");
        }}
        showChevronIcon={true}
      />

      <Accordion
        accordionBodyIcon={settings}
        setOpenFunc={() => {
          console.log("");
        }}
        // path="/admin/product"
        // icon={<AdminProductsIcon />}
        title="Settings"
        content={[
          {
            section_name: "Your Account",
            section_route: "/account",
            icon: user_settings_round,
          },
          {
            section_name: "Security and Privacy",
            section_route: "/security-privacy",
            icon: lock_keyhole,
          },
          {
            section_name: "Notification Preferences",
            section_route: "/notification-preferences",
            icon: bell_sidebar,
          },
          {
            section_name: "Interests",
            section_route: "/interests",
            icon: heart_sidebar,
          },
        ]}
      />
      <SidebarOption
        path="/help-support"
        icon={help_circle}
        text="Support & Help"
        onClickFunc={() => {
          navigate("/help-support");
        }}
        showChevronIcon={true}
      />
      <div className="home_sidebar_bottom_border"></div>

      <ProfileInfoSidebarOption
        onClickFunc={() => {
          dispatch(handleToggleProfileOption(true));
          // dispatch(handleViewAnotherUserProfile(false));
        }}
        showChevron={true}
      />
    </div>
  );
};

export default HomeSideBar;
