import React from "react";
import "./luvHubLocationModal.css";
import InfoModal from "../../infoModal/InfoModal";
import { useDispatch } from "react-redux";
import { handleShowLuvHubLocation } from "../../../redux/userData";
import { searchHelp, x_cancelll } from "../../images";
import AuthInputIcon from "../../defaultInput/authInputnIcon/AuthInputIcon";
import AuthBtn from "../../button/authBtn/AuthBtn";

const LuvHubLocationModal = () => {
  const dispatch = useDispatch();
  const cities = [
    "Lagos",
    "Abuja",
    "Kano",
    "Ibadan",
    "Port Harcourt",
    "Benin City",
    "Jos",
    "Ilorin",
    "Enugu",
    "Zaria",
    "Aba",
    "Maiduguri",
    "Oyo",
    "Oshogbo",
    "Abeokuta",
    "Calabar",
    "Uyo",
    "Akure",
    "Makurdi",
    "Yola",
    "Awka",
    "Warri",
    "Onitsha",
    "Katsina",
    "Ado Ekiti",
    "Sokoto",
    "Gombe",
    "Owerri",
    "Bauchi",
    "Lokoja",
    "Minna",
    "Jalingo",
    "Umuahia",
    "Damaturu",
    "Gusau",
    "Birnin Kebbi",
  ];
  return (
    <InfoModal
      handleCloseModal={() => {
        dispatch(handleShowLuvHubLocation(false));
      }}
      width="600px"
    >
      <div className="luvhub_location_modal_body_gen">
        <div className="luvhub_location_modal_body_country">Nigeria</div>
        <div
          onClick={() => {
            dispatch(handleShowLuvHubLocation(false));
          }}
          className="luvhub_location_modal_body_cancel_close"
        >
          <img src={x_cancelll} />
        </div>
      </div>

      <AuthInputIcon
        width="100%"
        inputPlaceHolder="Search state or city"
        inputLeftIcon={searchHelp}
      />
      <div className="luvhub_location_modal_body_gen_citiesss">
        {cities?.map((data, index) => (
          <div className="luvhub_location_modal_country__each">{data}</div>
        ))}
      </div>

      <AuthBtn width="100%" btnText="Load More" />
    </InfoModal>
  );
};

export default LuvHubLocationModal;
