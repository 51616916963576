import React from "react";
import "./interestCardRack.css";
import InterestCardRackEach from "../interestCardRackEach/InterestCardRackEach";

const InterestCardRack = ({
  interestText,
  handleClick,
  selectedRack,
  rackHeadName,
}) => {
  return (
    <div className="interest_card_rack_heading__genBody">
      <div className="interest_card_rack_heading__gen">
        {rackHeadName} <span>* </span>{" "}
      </div>
      <div className="interest_card_rack_heading__flex">
        {interestText?.map((data, index) => (
          <InterestCardRackEach
            interestText={data}
            handleClick={() => {
              handleClick(data);
            }}
            selectedRack={selectedRack}
          />
        ))}
      </div>
    </div>
  );
};

export default InterestCardRack;
