import React, { useEffect, useState } from "react";
import "./storiesScrollBody.css";
import EachStoriesBar from "../layout/storiesBar/eachStoriesBar/EachStoriesBar";
import { film } from "../images";
import { useDispatch } from "react-redux";
import {
  handlePreviewStoriesMedia,
  handleStatusPreviewStoriesMedia,
} from "../../redux/userData";
import { axiosCalls } from "../../_api";
import { CallCustomToast } from "../../utils/CallCustomToast";

const StoriesScrollBody = () => {
  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorLoading, setOnErrorLoading] = useState(false);
  const [displayEachStories, setDisplayEachStories] = useState([]);
  const [displayEachMutedStories, setDisplayEachMutedStories] = useState([]);

  //   useEffect(() => {
  //     if (stories.length > 0) {
  //       setDisplayEachStories(
  //         extractAllAttachmentDetails(stories[0]?.userStories)
  //       );
  //       setDisplayEachMutedStories(
  //         extractAllAttachmentDetails(stories[0]?.userStories, "muted")
  //       );

  //       console.log(displayEachMutedStories, "333333");
  //       console.log(displayEachStories, "4444444");
  //     }
  //   }, [stories]);

  useEffect(() => {
    handleGetUserStories();
  }, []);

  const handleGetUserStories = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosCalls("stories/fetch-stories", "GET");

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        // console.log(res?.er.data?.message);
        setOnErrorLoading(true);

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        setOnErrorLoading(false);
        setStories(res?.data);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };
  const dispatch = useDispatch();

  return (
    <div className="stories_bar_body_scroolll">
      <div className="stories_top_header_body">
        <div className="stories_top_header_img">
          <img src={film} />
        </div>
        <div className="stories_top_header_img_text">Stories</div>
      </div>

      <div className="stories_bar_section_body_forall">
        {stories.map((data, index) => (
          <EachStoriesBar
            handleEachStories={() => {
              dispatch(handleStatusPreviewStoriesMedia(true));

              dispatch(
                handlePreviewStoriesMedia(data?.userStories?.primeStories)
              );
            }}
            eachStories={data}
          />
        ))}

        <div className="stories_bar_section_body_muteddd">Muted</div>
        {/* <EachStoriesBar
            handleEachStories={() => {
              dispatch(handleStoryModal(true));
            }}
          /> */}
      </div>
    </div>
  );
};

export default StoriesScrollBody;
