import React from "react";
import "./supamallCart.css";
import SupaMallSideBar from "../supaMallSideBar/SupaMallSideBar";
import SupamallCartCard from "../../../components/supamallComp/supamallCartCard/SupamallCartCard";

const SupamallCart = () => {
  return (
    <div
      style={{ marginTop: "30px" }}
      className=" supamall_home_body_gen_firstsection"
    >
      <SupaMallSideBar />

      <div className="supamall_home_body_gen_firstsection_row_cart">
        <div className="supamall_home_body_gen_firstsection_row_cart_top_flex">
          <div className="supamall_home_body_gen_firstsection_row_cart_top_flex_cartT">
            Cart
          </div>
          <div className="supamall_home_body_gen_firstsection_row_cart_top_flexcartN">
            2 <span>Clear all</span>
          </div>
        </div>

        <div className="bottom_border_supamall_cart_left"></div>

        <SupamallCartCard />
        <SupamallCartCard />
        <SupamallCartCard />
      </div>
    </div>
  );
};

export default SupamallCart;
