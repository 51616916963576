import React, { useState } from "react";
import "./profileOptionModal.css";
import InfoModal from "../infoModal/InfoModal";
import { useDispatch } from "react-redux";
import {
  handleLogoutModal,
  handleToggleProfileOption,
  handleViewAnotherUserProfile,
} from "../../redux/userData";
import {
  profile_option_eye,
  profile_option_logout,
  profile_option_plus,
  profile_pop_user,
} from "../images";
import ProfileOptionEach from "./profileOptionEach/ProfileOptionEach";
import { useNavigate } from "react-router-dom";

const ProfileOptionModal = () => {
  const [checked, setChecked] = useState(false);

  const navigate = useNavigate();

  const handleProfileOptionSwitch = () => {
    setChecked(!checked);
  };
  const dispatch = useDispatch();

  return (
    <InfoModal
      handleCloseModal={() => {
        dispatch(handleToggleProfileOption(false));
      }}
      justifyContent="flex-start"
      padding="20px"
      width="250px"
    >
      <ProfileOptionEach
        handleProfileOptionSwitch={handleProfileOptionSwitch}
        checked={checked}
        showSwitch={false}
        profileOptionText="View profile"
        profileOptionIcon={profile_pop_user}
        handleOptionClick={() => {
          dispatch(handleViewAnotherUserProfile(false));
          navigate("/user-profile");
          dispatch(handleToggleProfileOption(false));
        }}
        path="/user-profile"
      />
      <ProfileOptionEach
        handleProfileOptionSwitch={handleProfileOptionSwitch}
        checked={checked}
        showSwitch={true}
        profileOptionText="Profile visibility"
        profileOptionIcon={profile_option_eye}
      />
      <ProfileOptionEach
        handleProfileOptionSwitch={handleProfileOptionSwitch}
        checked={checked}
        showSwitch={false}
        profileOptionText="Add account"
        profileOptionIcon={profile_option_plus}
      />
      <ProfileOptionEach
        handleProfileOptionSwitch={handleProfileOptionSwitch}
        checked={checked}
        showSwitch={false}
        profileOptionText="Logout"
        profileOptionIcon={profile_option_logout}
        handleOptionClick={() => {
          dispatch(handleLogoutModal(true));
        }}
      />
    </InfoModal>
  );
};

export default ProfileOptionModal;
