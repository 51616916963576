import React from "react";
import "./helpSupportLinksCard.css";
import HelpSupportLinksCardRack from "../helpSupportLinksCardRack/HelpSupportLinksCardRack";

const HelpSupportLinksCard = () => {
  return (
    <div className="help_supportLinks_card_body__">
      <div className="help_supportLinks_card_body__head">
        Contact and Communication
      </div>

      <HelpSupportLinksCardRack />
      <HelpSupportLinksCardRack />
      <HelpSupportLinksCardRack />
      <div className="help_supportLinks_card_body__border"></div>
    </div>
  );
};

export default HelpSupportLinksCard;
