import "./explorePageImageSwiper.css";
import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
// import {
//   post_fourImage,
//   post_oneImage,
//   post_threeImage,
//   post_twoImage,
//   minigadget,
// } from "../images";
import {
  ellipse_luvhub_hh,
  post_fourImage,
  post_oneImage,
  post_threeImage,
  post_twoImage,
} from "../../images";
import { handleShowExplorePage } from "../../../redux/userData";
import useCalculateAge from "../../../utils/useCalculateAge";

const images = [
  {
    url: post_oneImage,
  },
  {
    url: post_fourImage,
  },
  {
    url: post_twoImage,
  },
  {
    url: post_threeImage,
  },
];

const ExplorePageImageSwiper = ({
  setShowPageReportTab,
  getEachExploreData,
}) => {
  const dispatch = useDispatch();
  const age = useCalculateAge(getEachExploreData?.user?.dob);

  const { previewPostMedia } = useSelector(
    (state) => state.previewPostMediaBody.previewPostMediaBody
  );

  const getFileComponent = (file, index) => {
    const fileExtension = getFileExtension(file);
    if (
      fileExtension === "jpg" ||
      fileExtension === "png" ||
      fileExtension === "jpeg" ||
      fileExtension === "webp"
    ) {
      return (
        <div className="explore_page_swiper_parent_gen">
          <div className="explore_page_swiper_parent_gen_img__">
            <img
              //   src={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com${file}`}
              src={file}
              alt={`Slide ${index}`}
              id={`slide-${index}`}
            />
          </div>
          <div className="explore_page_swiper_parent_gen_bottom_body">
            <div className="explore_page_swiper_parent_gen_bottom_body_first">
              <div className="explore_page_swiper_parent_gen_bottom_body_first_img">
                <img src={ellipse_luvhub_hh} />
              </div>
              <div className="explore_page_swiper_parent_gen_bottom_texttt">
                Recently active
              </div>
            </div>

            <div className="explore_page_swiper_parent_gen_bottom_body_second">
              <div className="explore_page_swiper_parent_gen_bottom_body_second_text">
                {getEachExploreData?.user?.userName} <span>{age}</span>
              </div>

              <div className="explore_page_swiper_parent_gen_bottom_body_second_text_more">
                More
              </div>
            </div>
          </div>
        </div>
      );
    } else if (fileExtension === "mp4") {
      return (
        // <video controls>
        //   <source src={file} type="video/mp4" />
        //   Your browser does not support the video tag.
        // </video>

        <div className="post_video_playerr_body" id={`slide-${index}`}>
          <ReactPlayer
            url={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com${file}`}
            playing={false}
            controls={true}
            width="100%"
            height="100%"
            // style={{ marginTop: "100px" }}
            // onPlay={() => handleVideoPlay(index)}
            // onPause={() => handleVideoPause(index)}
          />
        </div>
      );
    } else {
      return <div>No preview available</div>;
    }
  };

  const getFileExtension = (filename) => {
    return filename?.split(".").pop().toLowerCase();
  };

  return (
    <div className="explorepage_image_swiper_bodyyy">
      <Swiper
        className="mySwiper"
        // pagination={{
        //   dynamicBullets: true,
        //   clickable: true,
        // }}
        pagination={{ clickable: true, type: "bullets" }}
        navigation
        modules={[Pagination, Navigation, Scrollbar, A11y]}
      >
        {getEachExploreData?.publicImages?.map((image, index) => (
          <SwiperSlide
            onClick={() => {
              dispatch(handleShowExplorePage(true));
            }}
            key={index}
          >
            {getFileComponent(image?.url, index)}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ExplorePageImageSwiper;
