import React, { useEffect, useState } from "react";
import "./storiesBar.css";
import {
  apple_store_downloadBtn,
  film,
  google_playstore_btn,
} from "../../images";
import EachStoriesBar from "./eachStoriesBar/EachStoriesBar";
import { useDispatch, useSelector } from "react-redux";
import {
  handlePreviewStoriesMedia,
  handleStatusPreviewStoriesMedia,
  handleStoryModal,
} from "../../../redux/userData";
import { axiosCalls } from "../../../_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import extractAllAttachmentDetails from "../../../utils/ExtractAllAttachmentDetails";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import StoriesScrollBody from "../../storiesScrollBody/StoriesScrollBody";

const StoriesBar = () => {
  const dispatch = useDispatch();

  return (
    <div className="stories_bar_body">
      <StoriesScrollBody />

      <div className="stories_bar_body_bottom_footer">
        <div className="stories_bar_body_bottom_footer_each">
          Terms of service
        </div>
        <div className="stories_bar_body_bottom_footer_each">
          Privacy & Policy
        </div>
        <div className="stories_bar_body_bottom_footer_each"> About </div>
        <div className="stories_bar_body_bottom_footer_each">Help</div>
        <div className="stories_bar_body_bottom_footer_each">Language</div>
        <div className="stories_bar_body_bottom_footer_each">Guide</div>
        <div className="stories_bar_body_bottom_footer_each">
          Invite friends
        </div>
        <div className="stories_bar_body_bottom_footer_each">
          Download TalkStuff
        </div>
      </div>
      <div className="stories_bar_body_footer_btn_body">
        <div className="stories_bar_body_footer_btn_body_each">
          <img src={apple_store_downloadBtn} />
        </div>
        <div className="stories_bar_body_footer_btn_body_each">
          <img src={google_playstore_btn} />
        </div>
      </div>
    </div>
  );
};

export default StoriesBar;
