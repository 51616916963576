// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change_password_bodyyyyssbtn_flex {
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  gap: 10px;
  box-sizing: border-box;
  margin-top: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/changePasswordModal/changePasswordModal.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,SAAS;EACT,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":[".change_password_bodyyyyssbtn_flex {\n  width: 100%;\n  align-items: center;\n  justify-content: flex-end;\n  display: flex;\n  gap: 10px;\n  box-sizing: border-box;\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
