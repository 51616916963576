import React from "react";
import "./modalHeaderIconCont.css";
import { bell_noti, check_check } from "../images";

const ModalHeaderIconCont = ({
  modalHeaderName,
  modalHeaderIcon,
  showRead,
  modalHeaderParagraph,
}) => {
  return (
    <div className="modal_header_icon_cont_body">
      <div className="modal_header_icon_body_left">
        <div className="modal_header_icon_body_iconn">
          <img src={modalHeaderIcon} />
        </div>
        <div className="modal_header_icon_body_textt_bodyyy">
          <div className="modal_header_icon_body_textt">{modalHeaderName}</div>

          {modalHeaderParagraph && (
            <div className="modal_header_icon_body_textt_paragr">
              {modalHeaderParagraph}
            </div>
          )}
        </div>
      </div>

      {showRead && (
        <div className="modal_header_icon_body_rightt">
          <div className="modal_header_icon_body_left_check">
            <img src={check_check} />
          </div>
          <div className="modal_header_icon_body_rightText">
            Mark all as read
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalHeaderIconCont;
