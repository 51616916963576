// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_option_each_body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  padding: 10px;
  box-sizing: border-box;
  margin-top: 7px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
  border-radius: 10px;
  background: #fff;
}

.profile_option_each_body:hover {
  background: #faf9f9;
}

.profile_option_each_body_active {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  padding: 10px;
  box-sizing: border-box;
  margin-top: 7px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
  border-radius: 10px;
  background: #fff;
  background: #faf9f9;
}
.profile_option_each_body_sub {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile_option_each_body_sub_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_option_each_body_sub_textt {
  font-size: 13px;
  color: #525866;
  font-weight: 600;
}

.profile_option_each_body_sub_switch {
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/profileOptionModal/profileOptionEach/profileoptionEach.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,QAAQ;;EAER,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,eAAe;EACf,+BAA+B;EAC/B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,QAAQ;;EAER,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,eAAe;EACf,+BAA+B;EAC/B,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".profile_option_each_body {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 5px;\n\n  padding: 10px;\n  box-sizing: border-box;\n  margin-top: 7px;\n  cursor: pointer;\n  transition: 0.3s ease-in-out 0s;\n  border-radius: 10px;\n  background: #fff;\n}\n\n.profile_option_each_body:hover {\n  background: #faf9f9;\n}\n\n.profile_option_each_body_active {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 5px;\n\n  padding: 10px;\n  box-sizing: border-box;\n  margin-top: 7px;\n  cursor: pointer;\n  transition: 0.3s ease-in-out 0s;\n  border-radius: 10px;\n  background: #fff;\n  background: #faf9f9;\n}\n.profile_option_each_body_sub {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.profile_option_each_body_sub_icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.profile_option_each_body_sub_textt {\n  font-size: 13px;\n  color: #525866;\n  font-weight: 600;\n}\n\n.profile_option_each_body_sub_switch {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
