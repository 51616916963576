import React from "react";
import "./uploadBoxes.css";

const UploadBoxes = ({ handleImageChange }) => {
  return (
    <div className="post_upload_boxes_body">
      <input
        type="file"
        accept="image/*, video/mp4"
        multiple
        onChange={handleImageChange}
      />
      +
    </div>
  );
};

export default UploadBoxes;
