import React, { useState, useEffect } from "react";
import "./luvhubHome.css";
import EachMatchesCard from "../../../components/luvHubComp/eachMatchesCard/EachMatchesCard";
import GoBackBtn from "../../../components/button/gobackBtn/GoBackBtn";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { axiosCalls } from "../../../_api";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import UseClipRoundLoader from "../../../utils/UseClipRoundLoader";

const LuvhubHome = ({ isScreenWide }) => {
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [isErrorLoading, setIsErrorLoading] = useState(false);
  const [moreMatchesTabData, setMoreMatchesTabData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getMoreMatchesReqName } = useSelector(
    (state) => state.getMoreMatchesReqNameBody.getMoreMatchesReqNameBody
  );

  useEffect(() => {
    if (getMoreMatchesReqName) {
      getMoreMatchesCard();
    }
  }, [getMoreMatchesReqName]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 500
    ) {
      getMoreMatchesCard();
    }
  };

  const getMoreMatchesCard = async () => {
    try {
      setIsMoreLoading(true);
      const res = await axiosCalls(
        `luvhub/get-more-matches?page=${currentPage}&limit=20&q=${getMoreMatchesReqName}`,
        "GET"
      );

      if (res) {
        setIsMoreLoading(false);

        if (!res?.status) {
          CallCustomToast(dispatch, {
            messageHeading: "Error",
            toastStatus: "Error",
            messageBody: res?.er?.data?.message,
          });
          return;
        } else {
          if (res?.status) {
            console.log("matches res", res);
            setMoreMatchesTabData((prevPosts) => [
              ...prevPosts,
              ...res?.matches,
            ]);
            setCurrentPage((prevPage) => prevPage + 1);
          }
        }
      }
    } catch (err) {
      console.log("err getting product", { err });
      setIsMoreLoading(false);

      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "Something went wrong",
      });
    }
  };

  return (
    <div className="luv_hub_home_bodyyy__genf">
      {!isScreenWide && (
        <div className="luv_hub_home_bodyyy__genf_goback">
          <GoBackBtn
            handleGoBack={() => {
              navigate(-1);
            }}
          />
        </div>
      )}

      <div className="luv_hub_home_bodyyy__gentext">
        {getMoreMatchesReqName}
      </div>

      <div className="luv_hub_home_bodyyy">
        {isMoreLoading ? (
          <div className="luv_hub_home_bodyyy_load">
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
            <div style={{ width: "300px" }}>
              <Skeleton count={1} height={200} />
            </div>
          </div>
        ) : (
          <div className="luv_hub_home_bodyyyFleeee">
            {moreMatchesTabData?.map((data, index) => (
              <EachMatchesCard data={data} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LuvhubHome;
