// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification_switch_selector_bodyyy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 0.99px 1.98px 0px #5258660d;
  border: 0.99px solid #e2e4e9;
  background: #ffffff;
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
  gap: 10px;
}

.notification_switch_inner_body_textt {
  /* display: flex;
  align-items: flex-start;
  justify-content: flex-start; */
  width: calc(100%- 150px);
  gap: 10px;
  box-sizing: border-box;
  /* margin-top: 10px; */
}

.notification_switch_inner_body_texttHead {
  color: #0a0d14;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}

.notification_switch_inner_body_texttSubb {
  color: #525866;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/screens/homeScreens/notificationPreferences/notificationPeferences.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,sBAAsB;EACtB,2CAA2C;EAC3C,4BAA4B;EAC5B,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE;;gCAE8B;EAC9B,wBAAwB;EACxB,SAAS;EACT,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".notification_switch_selector_bodyyy {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  box-sizing: border-box;\n  box-shadow: 0px 0.99px 1.98px 0px #5258660d;\n  border: 0.99px solid #e2e4e9;\n  background: #ffffff;\n  margin-top: 20px;\n  padding: 10px;\n  border-radius: 8px;\n  gap: 10px;\n}\n\n.notification_switch_inner_body_textt {\n  /* display: flex;\n  align-items: flex-start;\n  justify-content: flex-start; */\n  width: calc(100%- 150px);\n  gap: 10px;\n  box-sizing: border-box;\n  /* margin-top: 10px; */\n}\n\n.notification_switch_inner_body_texttHead {\n  color: #0a0d14;\n  font-size: 12px;\n  font-weight: 600;\n  text-align: left;\n}\n\n.notification_switch_inner_body_texttSubb {\n  color: #525866;\n  font-size: 12px;\n  font-weight: 400;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
