import React, { useState, useEffect } from "react";
import "./luvhubSidebar.css";
import MatchesCardCont from "../../../components/luvHubComp/matchesCardCont/MatchesCardCont";
import LuvhubHome from "../luvhubHome/LuvhubHome";
import ExploreTabCard from "../../../components/luvHubComp/exploreTabCard/ExploreTabCard";
import ExplorePageCard from "../../../components/luvHubComp/explorePageCard/ExplorePageCard";
import Switch from "react-switch";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
  chevron_right_icon,
  empty_luvhubbb__,
  info__alert,
} from "../../../components/images";
import { useDispatch } from "react-redux";
import InterestCardRackEach from "../../../components/interestComp/interestCardRackEach/InterestCardRackEach";
import { handleShowLuvHubLocation } from "../../../redux/userData";
import { axiosCalls } from "../../../_api";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import Skeleton from "react-loading-skeleton";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";
import SelectInput from "../../../components/defaultInput/selectInput/SelectInput";
import UseClipRoundLoader from "../../../utils/UseClipRoundLoader";
const LuvhubSideBar = () => {
  const [tab, setTab] = useState(0);
  const [pageTab, setPageTab] = useState("");
  const [showPageReportTab, setShowPageReportTab] = useState(false);
  const [selectedRack, setSelectedRack] = useState([]);
  const [lookingForSelectedRack, setLookingForSelectedRack] = useState([]);
  const [showMeSelectedRack, setShowMeSelectedRack] = useState([]);
  const [interestData, setInterestData] = useState([]);
  const [discoveryChecked, setDiscoveryChecked] = useState(false);
  const [distanceChecked, setDistanceChecked] = useState(false);
  const [ageChecked, setAgeChecked] = useState(false);
  const [isScreenWide, setIsScreenWide] = useState(window.innerWidth >= 850);
  const [maxDistance, setMaxDistance] = useState(50); // default max distance

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isExploreLoading, setIsExploreLoading] = useState(false);
  const [isExploreError, setIsExploreError] = useState(false);
  const [exploreTabData, setExploreTabData] = useState([]);
  const [isMoreLoading, setIsMoreLoading] = useState(false);

  const [matchesTabData, setMatchesTabData] = useState([]);
  const [moreMatchesTabData, setMoreMatchesTabData] = useState([]);
  const [isMatchesError, setIsMatchesError] = useState(false);
  const [interestText, setInterestText] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [demoLocation, setDemoLocation] = useState("");
  const [userData, setUserData] = useState();
  const [isLoadingSettings, setIsLoadingSettings] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [accountInfo, setAccountInfo] = useState();
  const [agePreference, setAgePreference] = useState({
    minAge: 18,
    maxAge: 33,
  });
  const [loadingOptions, setLoadingOptions] = useState(false);

  const handleShowReportPageTab = (status) => {
    setShowPageReportTab(status);
  };

  const cities = [
    "Lagos",
    "Abuja",
    "Kano",
    "Ibadan",
    "Port Harcourt",
    "Benin City",
    "Jos",
    "Ilorin",
    "Enugu",
    "Zaria",
    "Aba",
    "Maiduguri",
    "Oyo",
    "Oshogbo",
    "Abeokuta",
    "Calabar",
    "Uyo",
    "Akure",
    "Makurdi",
    "Yola",
    "Awka",
    "Warri",
    "Onitsha",
    "Katsina",
    "Ado Ekiti",
    "Sokoto",
    "Gombe",
    "Owerri",
    "Bauchi",
    "Lokoja",
    "Minna",
    "Jalingo",
    "Umuahia",
    "Damaturu",
    "Gusau",
    "Birnin Kebbi",
  ];
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 500
    ) {
      getExploreData();
    }
  };

  const handleInterestsSubmit = async (newSelectedRack) => {
    const data = {
      interests: newSelectedRack,
      lookingFor: lookingForSelectedRack,
      showMe: showMeSelectedRack,
    };

    const res = await axiosCalls("accounts/save-interests", "POST", data);

    if (res) {
      if (!res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
      } else if (res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
      }
    }
  };

  const handleClick = (id) => {
    setSelectedRack((prevSelectedRack) => {
      const newSelectedRack = prevSelectedRack.includes(id)
        ? prevSelectedRack.filter((rackId) => rackId !== id)
        : [...prevSelectedRack, id];

      // Immediately call the submit function with the new state
      handleInterestsSubmit(newSelectedRack);

      return newSelectedRack;
    });
  };

  const handleLuvHUbTab = (status) => {
    setTab(status);
  };

  const handleLuvHubPageTab = (status) => {
    setPageTab(status);
  };

  const handleDiscoverySwitch = () => {
    setDiscoveryChecked(!discoveryChecked);
  };
  const handleDistanceSwitch = () => {
    setDistanceChecked(!distanceChecked);
  };
  const handleAgeSwitch = () => {
    setAgeChecked(!ageChecked);
  };

  const getAllInterestOptions = async () => {
    try {
      setLoadingOptions(true);
      const res = await axiosCalls(
        `accounts/account-get-interests-options`,
        "GET"
      );

      if (res) {
        setLoadingOptions(false);

        if (!res?.status) {
          CallCustomToast(dispatch, {
            messageHeading: "Error",
            toastStatus: "Error",
            messageBody: res?.er?.data?.message,
          });
        } else {
          if (res?.status) {
            setInterestText(res?.data?.interests);
          }
        }
      }
    } catch (err) {
      console.log("Error getting account info", { err });
      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "Something went wrong",
      });
    }
  };

  const getExploreData = async () => {
    try {
      //  showLoader();
      setIsExploreLoading(true);
      const res = await axiosCalls(
        `luvhub/explore-luvhub?page=${currentPage}&limit=15`,
        "GET"
      );

      if (res) {
        setIsExploreLoading(false);
        // hideLoader();

        if (!res?.status) {
          setIsExploreError(true);
          CallCustomToast(dispatch, {
            messageHeading: "Error",
            toastStatus: "Error",
            messageBody: res?.er?.data?.message,
          });
          return;
        } else {
          if (res?.status) {
            setIsExploreError(false);
            console.log("matches res", res);
            setIsExploreLoading(false);
            setExploreTabData((prevPosts) => [...prevPosts, ...res?.data]);
            setCurrentPage((prevPage) => prevPage + 1);
          }
        }
      }
    } catch (err) {
      console.log("err getting product", { err });

      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "Something went wrong",
      });
    }
  };

  const getUserAccountInfo = async () => {
    try {
      setLoadingInfo(true);
      const res = await axiosCalls(
        `accounts/account-info?userId=${userData?._id}`,
        "GET"
      );

      if (res) {
        setLoadingInfo(false);

        if (!res?.status) {
          // setIsExploreError(true);
          CallCustomToast(dispatch, {
            messageHeading: "Error",
            toastStatus: "Error",
            messageBody: res?.er?.data?.message,
          });
          return;
        } else {
          if (res?.status) {
            setAccountInfo(res?.data?.loveHubSettings);
            // Preload the settings fields
            setDiscoveryChecked(res?.data?.loveHubSettings?.discovery);
            setDemoLocation(res?.data?.loveHubSettings?.demographic?.[0] || "");
            setMaxDistance(res?.data?.loveHubSettings?.maxDistance);
            setDistanceChecked(
              res?.data?.loveHubSettings?.onlyShowLocationPreference
            );
            setAgePreference({
              minAge: res?.data?.loveHubSettings?.agePreference.minAge,
              maxAge: res?.data?.loveHubSettings?.agePreference.maxAge,
            });
            setAgeChecked(res?.data?.loveHubSettings?.onlyShowAgePreference);
            setSelectedRack(res?.data?.interests?.interests);
            setShowMeSelectedRack(res?.data?.interests?.showMe);
            setLookingForSelectedRack(res?.data?.interests?.lookingFor);
          }
        }
      }
    } catch (err) {
      console.log("err getting product", { err });

      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "Something went wrong",
      });
    }
  };
  const getDefaultMatches = async () => {
    try {
      //  showLoader();
      setIsLoading(true);
      const res = await axiosCalls("luvhub/get-default-matches", "GET");

      if (res) {
        setIsLoading(false);
        // hideLoader();

        if (!res?.status) {
          setIsMatchesError(true);
          CallCustomToast(dispatch, {
            messageHeading: "Error",
            toastStatus: "Error",
            messageBody: res?.er?.data?.message,
          });
          return;
        } else {
          if (res?.status) {
            setIsMatchesError(false);
            console.log("matches res", res);
            setIsLoading(false);
            setMatchesTabData(res?.data);
          }
        }
      }
    } catch (err) {
      console.log("err getting product", { err });

      CallCustomToast(dispatch, {
        messageHeading: "Error",
        toastStatus: "Error",
        messageBody: "Something went wrong",
      });
    }
  };
  const handleDistanceChange = (value) => {
    setMaxDistance(value);
    // Optionally send the data immediately
    // sendDataToEndpoint({ maxDistance: value });
  };
  const handleGetMatches = () => {
    getDefaultMatches();
  };

  useEffect(() => {
    getDefaultMatches();
    setUserData(JSON.parse(localStorage.getItem("userData")));
    // getExploreData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenWide(window.innerWidth >= 850);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handler for age preference slider
  const handleAgeChange = (value) => {
    setAgePreference({ minAge: value[0], maxAge: value[1] });
  };

  const handleChangesSubmit = async (e) => {
    const data = {
      user: userData?._id,
      discovery: discoveryChecked,
      demographic: [demoLocation],
      maxDistance,
      onlyShowLocationPreference: distanceChecked,
      agePreference,
      onlyShowAgePreference: ageChecked,
    };

    setIsLoadingSettings(true);
    const res = await axiosCalls("luvhub/profile-settings", "POST", data);

    if (res) {
      setIsLoadingSettings(false);
      console.log(res);
      if (!res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
      }
    }
  };

  const handleSettingPreInfo = async () => {
    await getUserAccountInfo();
    await getAllInterestOptions();
  };

  return (
    <div className="luvHub__layout__content">
      <div className="luvhub_home_sidebar_body">
        <div className="luvhub_home_sidebar_tab_body">
          <div
            onClick={() => {
              handleLuvHUbTab(0);
            }}
            className={
              tab === 0
                ? "luvhub_home_sidebar_tab_eachh_active"
                : "luvhub_home_sidebar_tab_eachh"
            }
          >
            Matches
          </div>
          <div
            onClick={() => {
              getExploreData();
              handleLuvHUbTab(1);
            }}
            className={
              tab === 1
                ? "luvhub_home_sidebar_tab_eachh_active"
                : "luvhub_home_sidebar_tab_eachh"
            }
          >
            Explore
          </div>
          <div
            onClick={() => {
              handleSettingPreInfo();
              handleLuvHUbTab(2);
            }}
            className={
              tab === 2
                ? "luvhub_home_sidebar_tab_eachh_active"
                : "luvhub_home_sidebar_tab_eachh"
            }
          >
            Settings
          </div>
          <div className="luv_hub_sidebar_bottom_border"></div>
        </div>

        {tab === 0 && (
          <div className="luv_hub_matches_section__bodygennn">
            {isLoading ? (
              <Skeleton count={5} height={300} />
            ) : (
              matchesTabData?.map((data, index) => (
                <MatchesCardCont
                  handleLuvHubPageTab={handleLuvHubPageTab}
                  isScreenWide={isScreenWide}
                  data={data}
                />
              ))
            )}

            {isMatchesError && (
              <div className="network_xerror_body_luvhub">
                Network error{" "}
                <AuthBtn
                  width="200px"
                  btnText="Reload"
                  handleClick={handleGetMatches}
                />
              </div>
            )}
          </div>
        )}

        {tab === 1 && (
          <div className="explore_tab_body_gennn_switch">
            <div className="explore_tab_body_top_textttt">
              Welcome to Explore
            </div>
            <div className="exploreTab_home_matches_section__bodygennn">
              {isExploreLoading ? (
                <div className="luv_hub_home_bodyyy_load">
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <Skeleton count={1} height={100} />
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <Skeleton count={1} height={100} />
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <Skeleton count={1} height={100} />
                  </div>
                </div>
              ) : (
                <div className="exploreTab_home_matches_section__bodygennn_vvv">
                  {" "}
                  {exploreTabData?.map((exploreData, index) => (
                    <ExploreTabCard
                      handleLuvHubPageTab={handleLuvHubPageTab}
                      isScreenWide={isScreenWide}
                      data={exploreData}
                    />
                  ))}
                </div>
              )}

              {isExploreError && (
                <div className="network_xerror_body_luvhub">
                  Network error{" "}
                  <AuthBtn
                    width="200px"
                    btnText="Reload"
                    handleClick={getExploreData}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {tab === 2 &&
          (loadingInfo ? (
            <div style={{ width: "100%", marginTop: "50px" }}>
              <Skeleton count={1} height={50} />
              <Skeleton count={1} height={50} />
              <Skeleton count={1} height={100} />
              <Skeleton count={1} height={50} />
              <Skeleton count={1} height={50} />
              <Skeleton count={1} height={70} />
              <Skeleton count={1} height={50} />
              <Skeleton count={1} height={50} />
              <Skeleton count={1} height={100} />
              <Skeleton count={1} height={50} />
              <Skeleton count={1} height={50} />
              <Skeleton count={1} height={70} />
              <Skeleton count={1} height={50} />
            </div>
          ) : (
            <div className="explore_tab_body_gennn_switch">
              <div className="explore_tab_body_top_textttt">
                Discovery Settings
              </div>
              <div className="explore_tab_body_top_textttt_discovery">
                Discovery
              </div>
              <div className="explore_tab_body_top_textt__bodyy_gen">
                <div className="explore_tab_body_top_textt__enable">
                  Enable discovery
                </div>
                <div className="profile_option_each_body_sub_switch">
                  <Switch
                    name="isActive"
                    className="react-switcher"
                    onColor="#EF6924"
                    offColor="#e2e8f0"
                    // onHandleColor="#36a6a4"
                    handleDiameter={12}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    activeBoxShadow="0 0 1px 2px #FAF2FF"
                    height={24}
                    width={51}
                    borderRadius={30}
                    checked={discoveryChecked}
                    onChange={handleDiscoverySwitch}
                  />
                </div>
              </div>

              <div className="lovehub_settings_info_body_caution">
                <div className="lovehub_settings_info_body_caution_icon">
                  <img src={info__alert} />
                </div>
                <div className="lovehub_settings_info_body_caution_text">
                  When disabled, your profile will be concealed from the card
                  stack, and the discovery feature will be disabled. Individuals
                  with whom you've previously interacted may still have the
                  ability to view and match with you.
                </div>
              </div>

              <div className="lovehub_settings_info_body_demo_head">
                Demographic
              </div>
              {/* <div
              className="lovehub_settings_info_body_location_gen"
              onClick={() => {
                dispatch(handleShowLuvHubLocation(true));
              }}
            >
              <div className="lovehub_settings_info_body_location_gen_sub">
                <div className="lovehub_settings_info_body_location_gen_headText">
                  Location
                </div>
                <div className="lovehub_settings_info_body_location_gen_country">
                  All Nigeria
                </div>
              </div>
              <div className="lovehub_settings_info_body_location_gen_country_icon">
                <img src={chevron_right_icon} />
              </div>
            </div> */}

              <SelectInput
                selectOnchange={(e) => {
                  setDemoLocation(e.target.value);
                }}
                selectValue={demoLocation}
                rolesData={cities}
                width="100%"
              />
              <div className="supamall_filter_card_body_rangeBody_gennn">
                <div className="supamall_filter_card_body_rangeBody">
                  Distance
                </div>
                <div className="luv_hub_settings__preferences_slider_text">
                  {maxDistance}Km
                </div>
                <Slider
                  className="custom-slider"
                  max={100}
                  defaultValue={maxDistance}
                  onAfterChange={handleDistanceChange}
                />
              </div>

              <div className="explore_tab_body_top_textt__bodyy_gen">
                <div className="explore_tab_body_top_textt__enable">
                  Only show people in this location
                </div>
                <div className="profile_option_each_body_sub_switch">
                  <Switch
                    name="isActive"
                    className="react-switcher"
                    onColor="#EF6924"
                    offColor="#e2e8f0"
                    // onHandleColor="#36a6a4"
                    handleDiameter={12}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    activeBoxShadow="0 0 1px 2px #FAF2FF"
                    height={24}
                    width={51}
                    borderRadius={30}
                    checked={distanceChecked}
                    onChange={handleDistanceSwitch}
                  />
                </div>
              </div>

              <div className="supamall_filter_card_body_rangeBody_gennn">
                <div className="supamall_filter_card_body_rangeBody">
                  <div className="luv_hub_settings__preferences_slider_text">
                    Age Preference:{" "}
                    {`${agePreference.minAge} - ${agePreference.maxAge}`}
                  </div>
                  <Slider
                    range
                    min={18}
                    max={55}
                    defaultValue={[18, 33]}
                    onChange={handleAgeChange}
                    className="custom-slider"
                  />
                </div>
              </div>
              <div className="explore_tab_body_top_textt__bodyy_gen">
                <div className="explore_tab_body_top_textt__enable">
                  Display only individuals within this range.
                </div>
                <div className="profile_option_each_body_sub_switch">
                  <Switch
                    name="isActive"
                    className="react-switcher"
                    onColor="#EF6924"
                    offColor="#e2e8f0"
                    // onHandleColor="#36a6a4"
                    handleDiameter={12}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    activeBoxShadow="0 0 1px 2px #FAF2FF"
                    height={24}
                    width={51}
                    borderRadius={30}
                    checked={ageChecked}
                    onChange={handleAgeSwitch}
                  />
                </div>
              </div>

              <div className="luvhub_settings_body_interest_gen">
                <div className="luvhub_settings_body_interest_gen_head">
                  Interests
                </div>
                <div className="luvhub_settings_body_interest_gen_headGen">
                  Choose your interests to personalize your experience
                </div>
                <div className="luvhub_settings_body_interest_gen_rackBody">
                  {interestText?.map((data, index) => (
                    <InterestCardRackEach
                      interestText={data}
                      handleClick={() => {
                        handleClick(data);
                      }}
                      selectedRack={selectedRack}
                    />
                  ))}
                </div>
              </div>

              <div className="lovehub_settings_info_body_caution">
                <div className="lovehub_settings_info_body_caution_icon">
                  <img src={info__alert} />
                </div>
                <div className="lovehub_settings_info_body_caution_text">
                  The categories where you will likely be placed are those that
                  closely align with your interests.
                </div>
              </div>
              <AuthBtn
                handleClick={() => {
                  handleChangesSubmit();
                }}
                width="100%"
                btnText="Save Preferences"
                isLoading={isLoadingSettings}
              />
            </div>
          ))}
      </div>

      {pageTab === "" && (
        <div className="luv_hub_home_bodyyy_empty__">
          <div className="luv_hub_home_bodyyy_empty__icon">
            <img src={empty_luvhubbb__} />
          </div>
          <div className="luv_hub_home_bodyyy_empty__iconText">It's Empty</div>
          <div className="luv_hub_home_bodyyy_empty__iconTextPara">
            Select one of the matches to view the matches or see list of all
            matches available.
          </div>
        </div>
      )}

      {pageTab === "matches" && isScreenWide && (
        <LuvhubHome isScreenWide={isScreenWide} />
      )}
      {pageTab === "explore" && isScreenWide && (
        <ExplorePageCard
          setShowPageReportTab={handleShowReportPageTab}
          showPageReportTab={showPageReportTab}
          isScreenWide={isScreenWide}
        />
      )}
    </div>
  );
};

export default LuvhubSideBar;
