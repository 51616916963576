import React, { useState, useEffect } from "react";
import "./userAccount.css";
import HomeMargin from "../../../components/homeMargin/HomeMargin";
import AuthTopIcon from "../../../components/authTopIcon/AuthTopIcon";
import {
  lock_keyhole,
  sample_profile_picss,
  user_round_cog,
} from "../../../components/images";
import InnerAccordionHeader from "../../../components/innerAccordionHeader/InnerAccordionHeader";
import InnerAccordionBorderBody from "../../../components/innerAccordionBorderBody/InnerAccordionBorderBody";
import HomeAccountInfoCard from "../../../components/homeAccountInfoCard/HomeAccountInfoCard";
import { getLocalStorageItem } from "../../../utils/getLocalStorageItem";
import { axiosCalls } from "../../../_api";
import {
  handleChangePasswordModal,
  handleDeactivateModal,
} from "../../../redux/userData";
import { useDispatch } from "react-redux";

const UserAccount = () => {
  const [tab, setTab] = useState(0);
  const handleSetTab = (status) => {
    setTab(status);
  };

  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [onErrorLoading, setOnErrorLoading] = useState(false);
  const [userRelation, setUserRelation] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const data = getLocalStorageItem("userData");
    setUserData(data);
    handleGetUserRelations();
  }, []);

  const handleGetUserRelations = async (e) => {
    setIsLoading(true);
    setOnErrorLoading(false);
    const res = await axiosCalls(
      "accounts/account-profile-relations?page=1&limit=50",
      "GET"
    );

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        // console.log(res?.er.data?.message);
        setOnErrorLoading(true);
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        return;
      } else if (res?.status) {
        setOnErrorLoading(false);
        setUserRelation(res?.data);
        console.log(userRelation, "user relation");
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
      }
    }
  };
  return (
    <HomeMargin>
      <InnerAccordionHeader
        headerText="Account Settings"
        ringIcon={user_round_cog}
        subText="  Tailor your experience and enhance security with personalized
            account settings"
      />

      <div className="user_account_home_body_tab_">
        <div
          onClick={() => {
            handleSetTab(0);
          }}
          className={
            tab === 0
              ? "user_account_home_body_tab_each_active"
              : "user_account_home_body_tab_each"
          }
        >
          Account Information
        </div>
        <div
          onClick={() => {
            handleSetTab(1);
          }}
          className={
            tab === 1
              ? "user_account_home_body_tab_each_active"
              : "user_account_home_body_tab_each"
          }
        >
          Change Password
        </div>
        <div
          onClick={() => {
            handleSetTab(2);
          }}
          className={
            tab === 2
              ? "user_account_home_body_tab_each_active"
              : "user_account_home_body_tab_each"
          }
        >
          Deactivate Account
        </div>
      </div>

      {tab === 0 && (
        <InnerAccordionBorderBody
          borderMarginHead="Account Information"
          borderMarginHeadSub="View your account details, including your phone number and email address."
        >
          <HomeAccountInfoCard
            username="Username"
            username_head={userData?.userName ?? "-"}
          />
          <HomeAccountInfoCard
            username="Phone "
            username_head={userData?.phone ?? "-"}
          />
          <HomeAccountInfoCard
            username="Email"
            username_head={userData?.email ?? "-"}
          />
          <HomeAccountInfoCard
            username="Country"
            username_head={userData?.country ?? "-"}
          />
          <HomeAccountInfoCard
            username="Languages"
            username_head={userData?.languages[0] ?? "-"}
          />
          <HomeAccountInfoCard
            username="Gender"
            username_head={userData?.gender ?? "-"}
          />
          <HomeAccountInfoCard
            username="Birth date"
            username_head={userData?.dob ?? "-"}
          />
        </InnerAccordionBorderBody>
      )}
      {tab === 1 && (
        <InnerAccordionBorderBody
          borderMarginHead="Security and privacy"
          borderMarginHeadSub="Your safety is our priority. Explore security and privacy settings to keep your account protected."
        >
          <div
            onClick={() => {
              dispatch(handleChangePasswordModal(true));
            }}
            className="security_privacy_btn_click"
          >
            <div className="security_privacy_btn_click_img">
              <img src={lock_keyhole} />
            </div>
            <div className="security_privacy_btn_click_text">
              Change Password
            </div>
          </div>
        </InnerAccordionBorderBody>
      )}
      {tab === 2 && (
        <InnerAccordionBorderBody
          borderMarginHead="Deactivate Account"
          borderMarginHeadSub={`You're about to start the process of deactivating your talkstuff account. Your display name, @${
            userData?.userName ?? "-"
          }, and public profile will no longer be viewable on talkstuff.com, talkstuff for iOS, or talkstuff for Android.`}
        >
          <div className="deactivate_account_all_body">
            <div className="deactivate_account_headerr">
              {userData?.firstName ?? "-"} {userData?.lastName ?? "-"}
            </div>
            <div className="deactivate_account_rolee">
              @{userData?.userName ?? "-"}
            </div>

            {userData?.profileImage ? (
              <div className="deactivate_account_profile_pics">
                <img
                  src={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com/${userData?.profileImage}`}
                />
              </div>
            ) : (
              <div className="profile_body_details_image_body">
                <div className="profile_top_card_image_profile_pics">
                  {userData?.firstName // if profile_pic is null, show only the first letters of the names
                    ?.split(" ")
                    ?.map((name) => name.charAt(0).toUpperCase())
                    .join("")}
                  {userData?.lastName // if profile_pic is null, show only the first letters of the names
                    ?.split(" ")
                    ?.map((name) => name.charAt(0).toUpperCase())
                    .join("")}
                </div>
              </div>
            )}
            <div className="deactivate_account_stats_body">
              <div className="deactivate_account_stats_each">
                <div className="deactivate_account_stats_each_num">
                  {" "}
                  {userRelation?.followingsPagination?.totalCount ?? 0}
                </div>
                <div className="deactivate_account_stats_each_tag">
                  Following
                </div>
              </div>

              <div className="deactivate_account_stats_each">
                <div className="deactivate_account_stats_each_num">
                  {" "}
                  {userRelation?.followersPagination?.totalCount ?? 0}
                </div>
                <div className="deactivate_account_stats_each_tag">
                  Followers
                </div>
              </div>

              <div className="deactivate_account_stats_each">
                <div className="deactivate_account_stats_each_num">
                  {" "}
                  {userRelation?.likesCount ?? 0}
                </div>
                <div className="deactivate_account_stats_each_tag">Likes</div>
              </div>
            </div>
          </div>

          <div className="deactivate_account_bottom_border"></div>
          <button
            onClick={() => {
              dispatch(handleDeactivateModal(true));
            }}
            className="deactivate_account_bottom_btn"
          >
            Deactivate
          </button>
        </InnerAccordionBorderBody>
      )}
    </HomeMargin>
  );
};

export default UserAccount;
