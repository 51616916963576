import React, { useState, useEffect } from "react";
import "./infoModal.css";
import { modal_pointer } from "../images";

const InfoModal = ({
  handleCloseModal,
  children,
  width,
  height,
  minHeight,
  modalPointer,
  justifyContent,
  padding,
  overflowY,
  maxHeight,
  background,
}) => {
  const handleChildClick = (event) => {
    // 👇️ stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();

    // console.log("child clicked");
  };
  return (
    <div
      style={{ justifyContent, padding }}
      className="Indexmodal-container"
      onClick={handleCloseModal}
    >
      {modalPointer && (
        <div className="info_modal_pointerr">
          <img src={modal_pointer} />
        </div>
      )}

      <div
        style={{
          width,
          maxHeight,
          height,
          minHeight,
          overflowY,
          padding,
          background,
        }}
        onClick={handleChildClick}
        className="Indexmodal-content"
      >
        {children}
      </div>
    </div>
  );
};

export default InfoModal;
