import React from "react";
import "./successregisterModal.css";
import NewModal from "../newModal/NewModal";
import AuthBtn from "../button/authBtn/AuthBtn";
import AuthBodyMargin from "../authBodyMargin/AuthBodyMargin";
import { check_circle, newSingleLogo, talk_stuff_authLogo } from "../images";
import AuthTopIcon from "../authTopIcon/AuthTopIcon";
import { useNavigate } from "react-router-dom";

const SuccessRegisterModal = ({
  handleTabTwo,
  successMessage,
  handleContinue,
}) => {
  const navigate = useNavigate();

  return (
    <NewModal>
      <AuthBodyMargin>
        <div className="termsandcondition_heading_logo">
          <img src={newSingleLogo} />
        </div>
        {/* </motion.div> */}
        <div className="success_register_input_body">
          <AuthTopIcon userDummyIcon={check_circle} />

          <div className="success_register_body_text">{successMessage}</div>

          <AuthBtn
            handleClick={handleContinue}
            btnText="Continue"
            width="100%"
          />
        </div>
      </AuthBodyMargin>
    </NewModal>
  );
};

export default SuccessRegisterModal;
