// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.go_backbtn_body {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid #e2e4e9;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.go_backbtn_body:hover {
  background: #f6f6fa;
}
.go_backbtn_body_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: -5px; */
}

.go_backbtn_body_text {
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  color: #525866;
}

.go_backbtn_body:hover .go_backbtn_body_btn {
  transition: 0.3s ease-in-out 0s;
  /* transform: ; */
  transform: translateX(-5px);
}
`, "",{"version":3,"sources":["webpack://./src/components/button/gobackBtn/goBackBtn.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;EACR,yBAAyB;EACzB,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;EAClB,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,+BAA+B;EAC/B,iBAAiB;EACjB,2BAA2B;AAC7B","sourcesContent":[".go_backbtn_body {\n  padding: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 5px;\n  border: 1px solid #e2e4e9;\n  border-radius: 10px;\n  width: fit-content;\n  cursor: pointer;\n  transition: 0.3s ease-in-out 0s;\n}\n\n.go_backbtn_body:hover {\n  background: #f6f6fa;\n}\n.go_backbtn_body_btn {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  /* margin-left: -5px; */\n}\n\n.go_backbtn_body_text {\n  font-size: 13px;\n  font-weight: 400;\n  font-style: normal;\n  color: #525866;\n}\n\n.go_backbtn_body:hover .go_backbtn_body_btn {\n  transition: 0.3s ease-in-out 0s;\n  /* transform: ; */\n  transform: translateX(-5px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
