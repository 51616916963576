// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.each_photo_card_body_gen {
  position: relative;

  width: calc(50% - 5px);
  height: 140px;
  border-radius: 10px;
}
.each_photo_card_image {
  width: 100%;
  height: 140px;
}
.each_photo_card_image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.each_photo_card_image_icon_opt {
  position: absolute;
  top: 10px;
  right: 10px;

  width: 30px;
  height: 30px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.99px solid #fff;
  cursor: pointer;
  box-shadow: 0px 0.9918952584266663px 1.9837905168533325px 0px #5258660d;
  border: 0.99px solid #e2e4e9;
}
`, "",{"version":3,"sources":["webpack://./src/components/profileTabs/photosTab/eachPhotoCard/eachPhotoCard.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;;EAElB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,aAAa;AACf;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;;EAEX,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,yBAAyB;EACzB,eAAe;EACf,uEAAuE;EACvE,4BAA4B;AAC9B","sourcesContent":[".each_photo_card_body_gen {\n  position: relative;\n\n  width: calc(50% - 5px);\n  height: 140px;\n  border-radius: 10px;\n}\n.each_photo_card_image {\n  width: 100%;\n  height: 140px;\n}\n.each_photo_card_image img {\n  width: 100%;\n  height: 100%;\n  border-radius: 10px;\n  object-fit: cover;\n}\n\n.each_photo_card_image_icon_opt {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n\n  width: 30px;\n  height: 30px;\n  border-radius: 10px;\n  background: #fff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  border: 0.99px solid #fff;\n  cursor: pointer;\n  box-shadow: 0px 0.9918952584266663px 1.9837905168533325px 0px #5258660d;\n  border: 0.99px solid #e2e4e9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
