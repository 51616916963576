import React, { useState, useEffect } from "react";
import "./matchesCard.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleGetMoreMatchesReqName } from "../../../redux/userData";
import { axiosCalls } from "../../../_api";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import useProfileImage from "../../../utils/useProfileImage";

const MatchesCard = ({ handleLuvHubPageTab, isScreenWide, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorLoading, setIsErrorLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = async () => {
    await dispatch(handleGetMoreMatchesReqName(data?.category));

    if (!isScreenWide) {
      navigate("/luvhub/matches");
    } else {
      handleLuvHubPageTab("matches");
    }
  };

  const imagePrefix = useProfileImage(
    data?.user?.firstName,
    data?.user?.lastName,
    "270px",
    "270px",
    "265px",
    "265px",
    "45px",
    "",
    "",
    "16px",
    "16px"
  );
  return (
    <div onClick={handleClick} className="matches_card__body">
      <div className="matches_card_image_topbody">
        {data?.matches[0]?.publicImages[0] ? (
          <div className="matches_card_image_topbody_main">
            <img src={data?.matches[0]?.publicImages[0]?.url} />
          </div>
        ) : (
          imagePrefix
        )}
        <div className="matches_card_image_topbody_card_down">
          <div className="matches_card_image_topbody_card_down_flexleft">
            <div className="matches_card_image_topbody_card_down_flexleft_name">
              {data?.matches[0]?.user?.userName}
            </div>
            <div className="matches_card_image_topbody_card_down_flexleft_choices">
              {data?.matches[0]?.interests
                ?.slice(0, 5)
                ?.map((interest, index) => (
                  <div
                    key={index}
                    className="matches_card_image_topbody_card_down_flexleft_choicesEach"
                  >
                    {interest}
                  </div>
                ))}
            </div>
          </div>

          {data?.matches[0]?.interests?.length > 5 && (
            <div className="matches_card_image_topbody_card_down_flexright">
              +{data?.matches[0]?.interests?.length - 5} more
            </div>
          )}
        </div>
      </div>

      {data?.matches[1]?.publicImages[0] ? (
        <div className="matches_card_image_topbody_bottom">
          <img src={data?.matches[1]?.publicImages[0]?.url} />
        </div>
      ) : (
        imagePrefix
      )}
    </div>
  );
};

export default MatchesCard;
