import React, { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { axiosAgoraCall, axiosAgoraNotificationCall } from "../../../_api";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import { useDispatch, useSelector } from "react-redux";
import { handleShowVideoCall } from "../../../redux/userData";
import InfoModal from "../../infoModal/InfoModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// const APP_ID = "99ee7677a8a745ed94b7f7f03fdab53e";
// const TOKEN =
//   "00699ee7677a8a745ed94b7f7f03fdab53eIAApoyMoTzk7vs7z6VZss24GSras+N37TeYdwlj7EDi9qSKBCQIAAAAAEACKRNzQfi6hYgEAAQCsMKFi";
// const CHANNEL = "wdj";

const client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});

const APP_ID = "31795023e0804e92aca50eb05f9e8a9f";

const CallScreen = () => {
  const [users, setUsers] = useState([]);
  const [localTracks, setLocalTracks] = useState([]);
  const [micEnabled, setMicEnabled] = useState(true);
  const [camEnabled, setCamEnabled] = useState(true);
  const [joined, setJoined] = useState(false);
  const dispatch = useDispatch();
  const [connectionData, setConnectionData] = useState({
    token: "",
    uid: "",
    CHANNEL: "",
    APP_ID: "",
  });

  const { showEachUserChat } = useSelector(
    (state) => state.showEachUserChatBody.showEachUserChatBody
  );
  const CHANNEL = showEachUserChat?._id;

  const getAgoraCallTokenSubscriber = async () => {
    const uid = Math.floor(Math.random() * 1000000) + 1;
    const data = {
      tokenType: "rtc",
      channel: CHANNEL,
      role: "publisher",
      uid: uid.toString(),
      expire: 3600,
    };

    try {
      const res = await axiosAgoraCall("getToken", "POST", data);

      if (res?.er) {
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res.er.data.message,
        });
        return null;
      } else if (res?.token) {
        return { token: res.token, uid };
      }
      return null;
    } catch (error) {
      console.error("Error fetching Agora token:", error);
      return null;
    }
  };

  const handleUserJoined = async (user, mediaType) => {
    await client.subscribe(user, mediaType);

    if (mediaType === "video") {
      setUsers((previousUsers) => [...previousUsers, user]);
    }

    if (mediaType === "audio") {
      user.audioTrack.play();
    }
  };

  const handleUserLeft = (user) => {
    setUsers((previousUsers) =>
      previousUsers.filter((u) => u.uid !== user.uid)
    );
  };

  const toggleMic = () => {
    localTracks[0].setEnabled(!micEnabled);
    setMicEnabled(!micEnabled);
  };

  const toggleCam = () => {
    localTracks[1].setEnabled(!camEnabled);
    setCamEnabled(!camEnabled);
  };

  const endCall = () => {
    if (joined) {
      client.unpublish(localTracks).then(() => client.leave());
    }
    for (let localTrack of localTracks) {
      localTrack.stop();
      localTrack.close();
    }
    setUsers([]);
    dispatch(handleShowVideoCall(false));
  };

  const handleSendingConnectionData = async (connectionData) => {
    const data = {
      token: connectionData?.token,
      data: {
        type: "call",
        appId: connectionData.APP_ID,
        rtcUid: connectionData?.uid,
      },
      notification: {
        title: "test",
        body: "Hello this is just a test",
      },
      android: {
        priority: "high",
      },
      apns: {
        payload: {
          aps: {
            "content-available": 1,
          },
        },
        headers: {
          "apns-priority": "10",
        },
      },
      webpush: {
        headers: {
          Urgency: "high",
        },
      },
    };

    try {
      const res = await axiosAgoraNotificationCall(
        "dev-send-notification",
        "POST",
        data
      );

      if (res?.er) {
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res.er.data.message,
        });
        return null;
      } else if (res) {
        console.log(res);
      }
      return null;
    } catch (error) {
      console.error("Error sending connection data", error);
      return null;
    }
  };
  useEffect(() => {
    const init = async () => {
      const tokenData = await getAgoraCallTokenSubscriber();

      if (!tokenData) {
        console.error("Failed to get Agora token. Video call cannot proceed.");
        return;
      }

      const { token, uid } = tokenData;

      const newConnectionData = {
        token,
        uid,
        CHANNEL,
        APP_ID,
      };

      setConnectionData(newConnectionData);

      console.log(token, uid, CHANNEL, APP_ID, "CONNECTION DATAAAAA");

      client.on("user-published", handleUserJoined);
      client.on("user-left", handleUserLeft);

      try {
        await client.join(APP_ID, CHANNEL, token, uid);
        setJoined(true);

        const tracks = await AgoraRTC.createMicrophoneAndCameraTracks();
        const [audioTrack, videoTrack] = tracks;
        setLocalTracks(tracks);

        setUsers((previousUsers) => [
          ...previousUsers,
          {
            uid,
            videoTrack,
            audioTrack,
          },
        ]);

        await client.publish(tracks);

        // After successfully joining, send the connection data
        handleSendingConnectionData(newConnectionData);
      } catch (error) {
        console.error("Error during Agora client setup:", error);
      }
    };

    init();
  }, []);

  return (
    <InfoModal
      handleCloseModal={endCall}
      width="80%"
      background="#202124"
      height="100vh"
    >
      {/* <ToastContainer /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          // height: "100vh",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: users.length > 1 ? "repeat(2, 200px)" : "1fr",
            gap: "10px",
          }}
        >
          {users.map((user) => (
            <div
              key={user.uid}
              id={`user-${user.uid}`}
              style={{
                width: "200px",
                height: "150px",
                backgroundColor: "#000",
              }}
            >
              <div
                ref={(el) => user.videoTrack && el && user.videoTrack.play(el)}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          ))}
        </div>

        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <button onClick={toggleMic}>
            {micEnabled ? "Mic Off" : "Mic On"}
          </button>
          <button onClick={toggleCam}>
            {camEnabled ? "Camera Off" : "Camera On"}
          </button>
          <button onClick={endCall} style={{ color: "red" }}>
            End Call
          </button>
        </div>
      </div>
    </InfoModal>
  );
};
export default CallScreen;
