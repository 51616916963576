import React from "react";
import "./aboutTabOverview.css";
import EditRegContent from "../../../editRegContent/EditRegContent";
import { phone, square_user, user_roundd } from "../../../images";

const AboutTabOverview = ({ ownerDetails, anotherUserProfile }) => {
  return (
    <div className="about_tab_overview_body">
      <div className="about_overview_content_reg_container">
        <EditRegContent
          anotherUserProfile={anotherUserProfile}
          sectionIcon={square_user}
          sectionHead="Full Name"
          sectionText={`${ownerDetails?.firstName ?? ""} ${
            ownerDetails?.lastName ?? ""
          }`}
        />

        <div className="about_overview_content_bottom_border"></div>
        <EditRegContent
          anotherUserProfile={anotherUserProfile}
          sectionIcon={user_roundd}
          sectionHead="Username"
          sectionText={ownerDetails?.userName ?? ""}
        />
        <div className="about_overview_content_bottom_border"></div>
        <EditRegContent
          anotherUserProfile={anotherUserProfile}
          sectionIcon={phone}
          sectionHead="Phone Number"
          sectionText={ownerDetails?.phone ?? ""}
        />
      </div>
    </div>
  );
};

export default AboutTabOverview;
