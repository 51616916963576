import React from "react";
import "./authForwardBtn.css";
import { chevron_right } from "../../images";

const AuthForwardBtn = ({ handleGoForward, forwardBtnText }) => {
  return (
    <div onClick={handleGoForward} className="forward_btn_body">
      <div className="forward_btn_body_text">{forwardBtnText}</div>
      <div className="forward_btn_body_btn">
        <img src={chevron_right} />
      </div>
    </div>
  );
};

export default AuthForwardBtn;
