import React from "react";
import "./iconAuthBtn.css";
import { pencil_white_profile } from "../../images";

const IconAuthBtn = ({ handleClick, btnText, width }) => {
  return (
    <div onClick={handleClick} className="icon_auth_btn_body" style={{ width }}>
      <div className="icon_auth_btn_body_icon">
        <img src={pencil_white_profile} />
      </div>
      <div className="icon_auth_btn_body_texttt">{btnText}</div>
    </div>
  );
};

export default IconAuthBtn;
