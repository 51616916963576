// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat_box_gen_body {
  width: 100%;
  height: 500px;
  border: none;

  padding: 15px;
  box-sizing: border-box;

  overflow-y: scroll;
  margin-top: 50px;
  position: relative;
  border-radius: 15px;

  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #999999 #f0f0f0; /* Scrollbar color and track color */
}

/* Hiding scrollbar for Chrome, Safari and Opera */
/* .chat_box_gen_body::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for Chrome, Safari, and Opera */
.chat_box_gen_body::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  background-color: #f0f0f0; /* Background color of the scrollbar */
}

.chat_box_gen_body::-webkit-scrollbar-thumb {
  background-color: #999999; /* Color of the scrollbar thumb */
}
.chat_box_gen_body_top {
  width: 100%;
  height: 590px;
  position: relative;
  border: 1px solid #e2e4e9;
  background: #fff;
  border-radius: 15px;
}

@media screen and (max-width: 850px) {
  .chat_box_gen_body_top {
    /* display: none; */
    width: 100%;
    height: 100%;
  }

  .chat_box_gen_body {
    height: calc(100% - 200px);
    margin-top: 140px;
    padding-bottom: 30px;
    box-sizing: border-box;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/chatSection/chatBox/chatBox.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,YAAY;;EAEZ,aAAa;EACb,sBAAsB;;EAEtB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;;EAEnB,qBAAqB,EAAE,gBAAgB;EACvC,gCAAgC,EAAE,oCAAoC;AACxE;;AAEA,kDAAkD;AAClD;;GAEG;;AAEH,iDAAiD;AACjD;EACE,UAAU,EAAE,2BAA2B;EACvC,yBAAyB,EAAE,sCAAsC;AACnE;;AAEA;EACE,yBAAyB,EAAE,iCAAiC;AAC9D;AACA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,mBAAmB;IACnB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,0BAA0B;IAC1B,iBAAiB;IACjB,oBAAoB;IACpB,sBAAsB;EACxB;AACF","sourcesContent":[".chat_box_gen_body {\n  width: 100%;\n  height: 500px;\n  border: none;\n\n  padding: 15px;\n  box-sizing: border-box;\n\n  overflow-y: scroll;\n  margin-top: 50px;\n  position: relative;\n  border-radius: 15px;\n\n  scrollbar-width: thin; /* For Firefox */\n  scrollbar-color: #999999 #f0f0f0; /* Scrollbar color and track color */\n}\n\n/* Hiding scrollbar for Chrome, Safari and Opera */\n/* .chat_box_gen_body::-webkit-scrollbar {\n  display: none;\n} */\n\n/* Hide scrollbar for Chrome, Safari, and Opera */\n.chat_box_gen_body::-webkit-scrollbar {\n  width: 8px; /* Width of the scrollbar */\n  background-color: #f0f0f0; /* Background color of the scrollbar */\n}\n\n.chat_box_gen_body::-webkit-scrollbar-thumb {\n  background-color: #999999; /* Color of the scrollbar thumb */\n}\n.chat_box_gen_body_top {\n  width: 100%;\n  height: 590px;\n  position: relative;\n  border: 1px solid #e2e4e9;\n  background: #fff;\n  border-radius: 15px;\n}\n\n@media screen and (max-width: 850px) {\n  .chat_box_gen_body_top {\n    /* display: none; */\n    width: 100%;\n    height: 100%;\n  }\n\n  .chat_box_gen_body {\n    height: calc(100% - 200px);\n    margin-top: 140px;\n    padding-bottom: 30px;\n    box-sizing: border-box;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
