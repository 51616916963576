// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post_card_caption_body {
  box-shadow: 0px 0px 10px 1px #00000014;
  width: 100%;
  padding: 20px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  box-sizing: border-box;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/postCard/postCardCaption/postCardCaption.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,sBAAsB;EACtB,sBAAsB;AACxB","sourcesContent":[".post_card_caption_body {\n  box-shadow: 0px 0px 10px 1px #00000014;\n  width: 100%;\n  padding: 20px;\n  border-radius: 13px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 30px;\n  box-sizing: border-box;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
