// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deactivate_acct_subHeaderTextt {
  color: #525866;
  font-size: 13px;
  font-weight: 400;
  margin-top: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/deactivateAccountModal/deactivateAccountModal.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".deactivate_acct_subHeaderTextt {\n  color: #525866;\n  font-size: 13px;\n  font-weight: 400;\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
