import React from "react";
import "./authInput.css";

const AuthInput = ({
  inputPlaceHolder,
  inputLabel,
  inputValue,
  inputName,
  inputOnchange,
  width,
  validateInput,
  error,
  inputLeftIcon,
  required,
  inputType,
  labelColor,
}) => {
  return (
    <div className="auth_dinput_tag_container" style={{ width }}>
      <div style={{ color: labelColor }} className="auth_dinput_tag_label">
        {" "}
        {inputLabel}{" "}
        {required && (
          <span
            style={{ color: "#F41010", fontSize: "13px", fontWeight: "600" }}
          >
            *
          </span>
        )}
      </div>

      <div className="auth_dinput_tag_parent_container">
        <input
          type={inputType}
          placeholder={inputPlaceHolder}
          value={inputValue}
          name={inputName}
          onChange={inputOnchange}
          onBlur={validateInput}
          error={error}
          required={required}
        />
      </div>

      {error && <div className="auth_dinput_error_message">{error}</div>}
    </div>
  );
};

export default AuthInput;
