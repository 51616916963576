import React from "react";
import "./footerCardEssential.css";
import { delivery_footer } from "../../images";

const FooterCardEssential = ({ borderRight, icon, header, para }) => {
  return (
    <div style={{ borderRight }} className="footer_card_essential_body">
      <div className="footer_card_essential_body_icon">
        <img src={icon} />
      </div>
      <div className="footer_card_essential_body_header">{header}</div>
      <div className="footer_card_essential_body_para">{para}</div>
    </div>
  );
};

export default FooterCardEssential;
