import React from "react";
import "./computingCategory.css";
import SupamallFilterCard from "../../../../components/supamallComp/supamallFilterCard/SupamallFilterCard";
import CategoryHeaderTop from "../../../../components/supamallComp/categoryHeaderTop/CategoryHeaderTop";
import HomeFifthSecion from "../../../../components/supamallComp/homeFifthSection/HomeFifthSecion";
import Footer from "../../../../components/supamallComp/footer/Footer";
import Breadcrumbs from "../../../../components/supamallComp/breadCrumb/BreadCrumb";

const ComputingCategory = () => {
  return (
    <div className="supermarket_category_bodyGennn_conta">
      <Breadcrumbs />
      <div className="supermarket_category_bodyGennn">
        <SupamallFilterCard />

        <CategoryHeaderTop name="Computing" />
      </div>

      <HomeFifthSecion />

      <Footer />
    </div>
  );
};

export default ComputingCategory;
