import React, { useEffect, useState } from "react";
import "./deleteAccount.css";
import {
  ellipse,
  login_carousel_circleOne,
  login_carousel_circleThree,
  login_carousel_circleTwo,
  login_carousel_imgOne,
  login_carousel_imgThree,
  login_carousel_imgTwo,
  mail_icon,
  newSingleLogo,
  talk_stuff_authLogo,
  user_login_dummy,
} from "../../../components/images";
import AuthTopIcon from "../../../components/authTopIcon/AuthTopIcon";
import AuthInputIcon from "../../../components/defaultInput/authInputnIcon/AuthInputIcon";
import AuthPasswordInput from "../../../components/defaultInput/authPasswordInput/AuthPasswordInput";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";
import AuthForwardBtn from "../../../components/button/authForwardBtn/AuthForwardBtn";
import LoginRightCarousel from "../../../components/loginRightCarousel/LoginRightCarousel";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { axiosCalls } from "../../../_api";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import DeleteConfirmModal from "./DeleteConfirmModal";

const COMPONENTS_COUNT = 3;
const DELAY_BETWEEN_COMPONENTS = 2000; // 3 seconds

const DeleteAccount = () => {
  const [currentComponent, setCurrentComponent] = useState(0);
  const [showSplash, setShowSplash] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();

  const handleShowDeleteModal = (status) => {
    setShowDeleteModal(status);
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const isFirstLoad = localStorage.getItem("isFirstLoad") === null;

    if (isFirstLoad) {
      // If it's the first load, hide the splash screen after a delay
      setTimeout(() => {
        setShowSplash(false);
        localStorage.setItem("isFirstLoad", "false");
      }, 4000); // Adjust the delay as needed
    } else {
      // If it's not the first load, hide the splash screen immediately
      setShowSplash(false);
    }
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentComponent((prevComponent) =>
        prevComponent === COMPONENTS_COUNT - 1 ? 0 : prevComponent + 1
      );
    }, DELAY_BETWEEN_COMPONENTS * COMPONENTS_COUNT);

    return () => clearInterval(interval);
  }, []);

  const handleGoForward = () => {
    navigate("/register");
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "email":
          if (!value) {
            stateObj[name] = "Please enter Email Address.";
          }
          break;
        case "password":
          if (!value) {
            stateObj[name] = "Please enter password";
          } else {
            // Check for password criteria
            if (value.length < 8) {
              stateObj[name] = "Password must be at least 8 characters long";
            } else if (!/\d/.test(value)) {
              // Check if value contains at least one number
              stateObj[name] = "Password must contain at least one number";
            } else if (!/[A-Z]/.test(value)) {
              // Check if value contains at least one uppercase letter
              stateObj[name] =
                "Password must contain at least one uppercase letter";
            }
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // if (name === "password" || name === "confirmPassword") {
    //   setPasswordMatch(formData.password === formData.confirmPassword);
    // }

    validateInput(e);
  };

  const handlePersonalInfoSubmit = async (e) => {
    // if (!passwordMatch) {
    //   setError(true);
    //   return;
    // }
    const data = {
      email: formData.email,
      password: formData.password,
    };

    // showLoader();
    setIsLoading(true);
    const res = await axiosCalls("authentication/login", "POST", data);

    if (res) {
      setIsLoading(false);
      // hideLoader();
      console.log(res);
      if (!res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });

        localStorage.setItem("token", res?.accessToken);
        localStorage.setItem("userData", JSON.stringify(res?.data));
        window.location.href = "/";
      }
    }
  };

  const handleResetPassword = () => {
    //    navigate("/password-reset");
  };

  return (
    <div className="delete_acc_login_body">
      <div className="delete_acc_login_left_body">
        <div className="delete_acc_login_left_inner_body_logo">
          <div className="delete_acc_login_left_inner_body_logooo">
            <img src={newSingleLogo} />
            {/* <img src={newSingleLogo} /> */}
          </div>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleShowDeleteModal(true);
          }}
          className="delete_acc_login_left_input_body"
        >
          <AuthTopIcon userDummyIcon={user_login_dummy} />
          <div className="delete_acc_login_left_input_heading">
            Delete your account
          </div>
          <div className="delete_acc_login_left_input_heading_sub">
            Enter your details to delete your account{" "}
          </div>
          {/* <div className="handle_alt_body_general">
                <AltLoginBtn
                  handleAltBtn={handleAppleBtn}
                  loginBtn={appleLogo}
                />
                <AltLoginBtn
                  handleAltBtn={handleGoogleBtn}
                  loginBtn={googleBtn}
                />
                <AltLoginBtn
                  handleAltBtn={handleTwitterBtn}
                  loginBtn={twitterLogo}
                />
              </div> */}

          <AuthInputIcon
            required={true}
            inputPlaceHolder="talkstuff@gmail.com"
            inputLabel="Email address"
            inputLeftIcon={mail_icon}
            width="100%"
            inputName="email"
            inputOnchange={handleChange}
            inputType="email"
            inputValue={formData.email}
            error={error.email}
            validateInput={validateInput}
          />

          <AuthPasswordInput
            passwordPlaceHolder="******"
            passwordLabel="Password"
            width="100%"
            passwordName="password"
            passwordOnchange={handleChange}
            passwordValue={formData.password}
            error={error.password}
            validateInput={validateInput}
            required={true}
          />
          {/* <div className="delete_acc_login_left_toggle_body">
            <div className="delete_acc_login_left_toggle_radio_body">
              <input
                type="checkbox"
                className="delete_acc_login_left_toggle_radio"
              />
              <label htmlFor="rememberMe">Remember Me</label>
            </div>

            <div
              onClick={handleResetPassword}
              className="delete_acc_login_left_forgot_passText"
            >
              Forgot password?
            </div>
          </div> */}

          <AuthBtn
            btnDisabled={
              formData.email === "" ||
              formData.password === "" ||
              error.email !== "" ||
              error.password !== ""
            }
            isLoading={isLoading}
            btnText="Delete Account"
            width="100%"
          />

          {/* <div className="delete_acc_login_bottom_register_body">
            <div className="delete_acc_login_bottom_register_body_text">
              Don’t have an account?
            </div>
            <AuthForwardBtn
              handleGoForward={handleGoForward}
              forwardBtnText="Register"
            />
          </div> */}
          <div className="delete_acc_login_invite_friends_btn_body">
            <div className="delete_acc_login_invite_friends_btn">
              Invite friends
            </div>
          </div>
        </form>
      </div>
      <div className="delete_acc_login_right_body">
        {currentComponent === 0 && (
          <LoginRightCarousel
            headerImg={login_carousel_imgOne}
            circleImg={login_carousel_circleOne}
            headerText=" Expand Your Circle Connect and Make New Friends!"
            headerParagraph="  Connect and make new friends based on shared interests."
            //   visible={currentComponent === 0}
          />
        )}
        {currentComponent === 1 && (
          <LoginRightCarousel
            headerImg={login_carousel_imgTwo}
            circleImg={login_carousel_circleTwo}
            headerText=" Socialize and Shop in Harmony."
            headerParagraph="  Social shopping: personalized, collaborative, shared."
            //   visible={currentComponent === 0}
          />
        )}
        {currentComponent === 2 && (
          <LoginRightCarousel
            headerImg={login_carousel_imgThree}
            circleImg={login_carousel_circleThree}
            headerText=" Expand Your Circle Connect and Make New Friends!"
            headerParagraph="  Connect and make new friends based on shared interests."
            //   visible={currentComponent === 0}
          />
        )}

        <div className="delete_acc_login_right_ellipse_abso">
          <div>
            <img src={ellipse} />
          </div>
          <div>
            <img src={ellipse} />
          </div>
          <div>
            <img src={ellipse} />
          </div>
          <div>
            <img src={ellipse} />
          </div>
          <div>
            <img src={ellipse} />
          </div>
          <div>
            <img src={ellipse} />
          </div>
          <div>
            <img src={ellipse} />
          </div>
          <div>
            <img src={ellipse} />
          </div>
          <div>
            <img src={ellipse} />
          </div>
        </div>
      </div>
      <ToastContainer />
      {showDeleteModal && (
        <DeleteConfirmModal handleShowDeleteModal={handleShowDeleteModal} />
      )}
    </div>
  );
};

export default DeleteAccount;
