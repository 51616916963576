import React from "react";
import "./eachStoriesBar.css";
import { stories_eye, stories_sample_image } from "../../../images";

const EachStoriesBar = ({ handleEachStories, eachStories }) => {
  const storiesLength = eachStories?.userStories?.primeStories?.length ?? 1;
  return (
    <div onClick={handleEachStories} className="each_stories_bar_body">
      <div className="each_stories_bar_body_left">
        <div className="each_stories_bar_body_img">
          {eachStories?.userStories?.primeStories[storiesLength - 1]?.attachment
            ?.url ? (
            <div className="post_card_caption_text_info_head_image">
              {eachStories?.userStories?.primeStories[storiesLength - 1]
                ?.attachment?.mimeType === "image" ? (
                <img
                  src={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com${
                    eachStories?.userStories?.primeStories[storiesLength - 1]
                      ?.attachment?.url
                  }`}
                />
              ) : (
                <img
                  src={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com${
                    eachStories?.userStories?.primeStories[storiesLength - 1]
                      ?.attachment?.thumbNail
                  }`}
                />
              )}
            </div>
          ) : (
            <div className="profile_tab_body_details_image_body">
              <div className="profile_tab_body_details_image">
                {eachStories?.userId?.firstName
                  ?.split(" ")
                  ?.map((name) => name.charAt(0).toUpperCase())
                  .join("")}
                {eachStories?.userId?.lastName
                  ?.split(" ")
                  ?.map((name) => name.charAt(0).toUpperCase())
                  .join("")}
              </div>
            </div>
          )}
        </div>
        <div className="each_stories_bar_body_name_text">
          {eachStories?.userId?.firstName} {eachStories?.userId?.lastName}
        </div>{" "}
      </div>

      <div className="each_stories_bar_body_eye_section">
        <div className="each_stories_bar_body_eye_img">
          <img src={stories_eye} />
        </div>
        <div className="each_stories_bar_body_eye_count">
          {eachStories?.userStories?.primeStories[0]?.viewers.length}
        </div>
      </div>
    </div>
  );
};

export default EachStoriesBar;
