import React from "react";
import "./allNotification.css";
import { default_notification_pics } from "../../images";
import EachNotificationCard from "../eachNotificationCard/EachNotificationCard";
import UseClipRoundLoader from "../../../utils/UseClipRoundLoader";

const AllNotification = ({ userNotifications, isLoading }) => {
  return (
    <div className="all_notification_body_section">
      {isLoading ? (
        <div className="clip_loader_boddyy">
          <UseClipRoundLoader color="#ef6924" size={50} isLoading={isLoading} />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          {userNotifications?.length < 1 ? (
            <div className="no_notification_text_notify">No notifications</div>
          ) : (
            userNotifications?.map((data) => (
              <EachNotificationCard data={data} />
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default AllNotification;
