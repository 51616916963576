import React, { useEffect, useState } from "react";
import "./chatBox.css";
import ChatBoxHeader from "../chatBoxHeader/ChatBoxHeader";
import ChatBoxSection from "../chatBoxSection/ChatBoxSection";
import {
  languages___,
  smileplus_icon,
  clip_text,
  mic_chat,
} from "../../../components/images";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import { useDispatch, useSelector } from "react-redux";
import { handleAgoraVideoCallToken } from "../../../redux/userData";
import { axiosAgoraCall, axiosChatCall } from "../../../_api";

const ChatBox = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingChatPreview, setIsLoadingChatPreview] = useState(false);
  const [previewChats, setPreviewChats] = useState(null);
  const [formValue, setFormValue] = useState({
    message: "",
  });
  const { serverChatData } = useSelector(
    (state) => state.serverChatDataBody.serverChatDataBody
  );

  useEffect(() => {
    if (serverChatData?.room) {
      handleGetChatPreview();
      console.log(serverChatData, "server chat data");
    }
  }, [serverChatData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const handleChatMessage = async (e) => {
    const data = {
      message: formValue?.message,
      room: serverChatData?.room,
      token: serverChatData?.token,
    };

    setIsLoading(true);
    const res = await axiosChatCall("chats/send-message", "POST", data);

    if (res) {
      console.log(res);
      setIsLoading(false);
      if (!res?.status) {
        console.log(res?.er.data?.message);
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res.er.data.message,
        });
        return;
      } else if (res?.status) {
        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });

        setFormValue({ message: "" });
      }
    }
  };

  const handleGetChatPreview = async (e) => {
    setIsLoadingChatPreview(true);
    // setOnErrorPhotosLoading(false);
    const res = await axiosChatCall(
      `chats/fetch-room-messages?roomId=${serverChatData?.room}`,
      "GET"
    );

    if (res) {
      console.log(res);
      setIsLoadingChatPreview(false);
      if (!res?.status) {
        // setOnErrorPhotosLoading(true);

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        console.log(res);
        // setPreviewChats(res?.data);
        // setOnErrorPhotosLoading(false);
      }
    }
  };
  return (
    <div className="chat_box_gen_body_top">
      <ChatBoxHeader />
      <div className="chat_box_gen_body">
        <ChatBoxSection handleGetChatPreview={handleGetChatPreview} />
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleChatMessage();
        }}
        className="chatBox_sending_messageBody_gen"
      >
        <div className="chatBox_sending_messageBody_inner">
          <div className="chatBox_sending_messageBody_inner_languages">
            <img src={languages___} />
          </div>
          <div className="chatBox_sending_messageBody_innerInner">
            <div className="chatBox_sending_messageBody_innerInner_emoji">
              <img src={smileplus_icon} />
            </div>
            <div className="chatBox_sending_messageBody_innerInner_text">
              <textarea
                onChange={handleChange}
                name="message"
                value={formValue?.message}
                placeholder="Write a message"
              />
            </div>
            <div className="chatBox_sending_messageBody_innerInner_clip">
              <img src={clip_text} />
            </div>
            <div className="chatBox_sending_messageBody_innerInner_mic">
              <img src={mic_chat} />
            </div>
            <div className="chatBox_sending_messageBody_innerInner_btn">
              <AuthBtn
                height="30px"
                marginTop="0px"
                width="90px"
                btnText="Send"
                fontSize="10px"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChatBox;
