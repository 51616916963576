import React, { useEffect, useState } from "react";
import "./termsandCondition.css";
import AuthBodyMargin from "../../../components/authBodyMargin/AuthBodyMargin";
import { talk_stuff_authLogo } from "../../../components/images";
import LegalBodyComponent from "../../../components/legalBodyComponent/LegalBodyComponent";
import { useLocation, useNavigate } from "react-router-dom";
import GoBackBtn from "../../../components/button/gobackBtn/GoBackBtn";
import { motion } from "framer-motion";

const TermsandCondition = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const termsandconditionData = [
    {
      heading: "Acceptance of Terms:",
      paragraph:
        "By signing up for TalkStuff, you acknowledge and agree to comply with the terms and conditions outlined herein. Users must carefully read and understand the terms before creating an account",
    },
    {
      heading: "User Responsibilities:",
      paragraph:
        "Users are obligated to provide accurate information during the sign-up process and maintain the security of their account and password. Users are responsible for any activity conducted through their account and must promptly report any unauthorized access",
    },
    {
      heading: "Data Usage:",
      paragraph:
        "TalkStuff may collect and utilize personal data as described in the Privacy Policy. Users consent to the collection, processing, and storage of their personal information for the purpose of using the app.",
    },
    {
      heading: "Content Guidelines:",
      paragraph:
        "Users are prohibited from posting offensive, illegal, or harmful content. TalkStuff reserves the right to remove any content that violates community guidelines, and users may be subject to account suspension or termination.",
    },
    {
      heading: "Intellectual Property:",
      paragraph:
        "While users retain ownership of their content, they grant TalkStuff a license to use, display, and distribute it on the platform. Users must not infringe on the intellectual property rights of others, and any such infringement may lead to account termination",
    },
  ];

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <AuthBodyMargin>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5 }}
        className="termsandcondition_heading_logo"
      >
        <img src={talk_stuff_authLogo} />
      </motion.div>

      {/* <GoBackBtn handleGoBack={handleGoBack} /> */}

      <div className="terms_condi_bodyyy">
        <div className="termsandcondition_heading">Terms and condition</div>

        {termsandconditionData.map((data, index) => (
          <LegalBodyComponent
            paragraphBody={data.paragraph}
            paragraphHeading={data.heading}
          />
        ))}
      </div>
    </AuthBodyMargin>
  );
};

export default TermsandCondition;
