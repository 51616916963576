import React, { useState } from "react";
import "./postUploadModal.css";
import InfoModal from "../../infoModal/InfoModal";
import PostUploadModalHeader from "../postUploadModalHeader/PostUploadModalHeader";
import { useDispatch } from "react-redux";
import UploadBoxes from "../uploadBoxes/UploadBoxes";
import AuthBtn from "../../button/authBtn/AuthBtn";
import { x_image } from "../../images";
import ScreenWidth from "../../../utils/ScreenWidth";

const PostUploadModal = ({
  handleCloseModal,
  handleImageChange,
  selectedImages,
  showAddCaption,
  handleAddImageCaption,
  postUploadHeading,
  postUploadIcon,
  postModal,
  storiesModal,
  handleDeleteImagePost,
  handleChange,
  formValue,
  handleCreatePostFeed,
  displaySelectImages,
  isLoading,
  handleShowWarningModal,
  handleCheckUploadPost,
}) => {
  return (
    <InfoModal
      handleCloseModal={handleCloseModal}
      height="fit-content"
      minHeight="0"
      width="600px"
      overflowY="hidden"
      maxHeight="800px"
    >
      <PostUploadModalHeader
        postUploadHeading={postUploadHeading}
        postUploadIcon={postUploadIcon}
      />

      <div className="post_upload_modal_body_gen">
        <UploadBoxes handleImageChange={handleImageChange} />
      </div>

      {/* {displaySelectImages?.length > 0 && (
        <div className="post_upload_uploaded_img_body">
          {displaySelectImages?.map((image, index) => (
            <div className="post_upload_uploaded_img_each" key={index}>
              <img
                src={image}
                alt={`Uploaded ${index + 1}`}
                // style={{
                //   maxWidth: "300px",
                //   maxHeight: "300px",
                //   marginBottom: "10px",
                // }}
              />
              <div
                onClick={() => {
                  handleDeleteImagePost(index);
                }}
                className="post_upload_cancel_icon"
              >
                x
              </div>
            </div>
          ))}
        </div>
      )} */}

      {displaySelectImages?.length > 0 && (
        <div className="post_upload_uploaded_img_body">
          {displaySelectImages?.map((image, index) => (
            <div className="post_upload_uploaded_img_each" key={index}>
              {/* Check file extension to determine if it's a video */}
              {image?.name && /\.(mp4|webm|ogg)$/i.test(image?.name) ? (
                // Render video element for video files
                <video controls className="uploaded-video">
                  <source
                    src={URL?.createObjectURL(image)}
                    type={image?.type || "video/mp4"} // Provide default type if not available
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                // Render img element for image files
                <img
                  src={image}
                  alt={`Uploaded ${index + 1}`}
                  className="uploaded-image"
                />
              )}
              <div
                onClick={() => {
                  handleDeleteImagePost(index);
                }}
                className="post_upload_cancel_icon"
              >
                x
              </div>
            </div>
          ))}
        </div>
      )}
      {postModal && (
        <>
          {showAddCaption && (
            <div className="post_upload_uploaded_add_captionBody">
              <div className="post_upload_uploaded_add_captionText">
                Add a caption
              </div>

              <div className="post_upload_uploaded_add_captionText_input">
                <textarea
                  onChange={handleChange}
                  name="captionText"
                  value={formValue?.captionText}
                  placeholder="Write something"
                />
              </div>
              <div className="post_upload_uploaded_add_captionText_btnn">
                <AuthBtn
                  handleClick={(e) => {
                    e.preventDefault();
                    handleCheckUploadPost();
                  }}
                  btnText="Upload"
                  width="120px"
                  isLoading={isLoading}
                />
              </div>
            </div>
          )}

          {!showAddCaption && (
            <div className="post_upload_modal_body_btn">
              <AuthBtn
                handleClick={() => {
                  handleAddImageCaption(true);
                }}
                btnText="Next"
                width="120px"
              />
            </div>
          )}
        </>
      )}

      {storiesModal && (
        <div className="post_upload_modal_body_btn">
          {/* {selectedImages.length > 0 && ( */}
          <AuthBtn
            handleClick={() => {
              handleCreatePostFeed();
            }}
            btnText="Upload"
            isLoading={isLoading}
            width="120px"
            btnDisabled={selectedImages.length < 1}
          />
          {/* )} */}
        </div>
      )}
    </InfoModal>
  );
};

export default PostUploadModal;
