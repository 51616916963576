import React, { useState, useEffect } from "react";
import "./registerSummary.css";
import RegInfoBodyMargin from "../../../components/regInfoBodyMargin/RegInfoBodyMargin";
import {
  check,
  pencil_line,
  phone,
  square_user,
  talk_stuff_authLogo,
  user_roundd,
} from "../../../components/images";
import RegIdentitySteps from "../../../components/regIdentitySteps/RegIdentitySteps";
import AuthTopIcon from "../../../components/authTopIcon/AuthTopIcon";
import EditRegContent from "../../../components/editRegContent/EditRegContent";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";
import { useNavigate } from "react-router-dom";

const RegisterSummary = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  useEffect(() => {
    // Get data from localStorage when component mounts
    const storedData = localStorage.getItem("userInfo");
    if (storedData) {
      setData(JSON.parse(storedData));
    }
  }, []);
  return (
    <RegInfoBodyMargin>
      <div className="summary_info_reg_body">
        <div className="termsandcondition_heading_logo">
          <img src={talk_stuff_authLogo} />
        </div>
        <RegIdentitySteps
          stepNum="1"
          stepStage="Personal"
          background="#EF6924"
          color="#fff"
        />
        <RegIdentitySteps
          stepNum="2"
          stepStage="Password"
          background="#EF6924"
          color="#fff"
        />
        <RegIdentitySteps
          stepNum="3"
          stepStage="Summary"
          background="#EF6924"
          color="#fff"
        />
      </div>

      <div className="summary_info_reg_tab_body">
        <AuthTopIcon userDummyIcon={check} />

        <div className="personal_info_input_body_headerr">
          Registration Summary
        </div>
        <div className="personal_info_input_body_head_textt">
          Here is a brief summary of your details
        </div>

        <div className="summary_content_reg_container">
          <EditRegContent
            sectionIcon={square_user}
            sectionHead="Full Name"
            sectionText={`${data?.data?.firstName}${" "}${
              data?.data?.lastName
            }`}
          />
          <div className="summary_content_bottom_border"></div>
          <EditRegContent
            sectionIcon={user_roundd}
            sectionHead="Username"
            sectionText={data?.data?.userName}
          />
          <div className="summary_content_bottom_border"></div>
          <EditRegContent
            sectionIcon={phone}
            sectionHead="Phone Number"
            sectionText={data?.data?.phone}
          />
        </div>
        <AuthBtn
          handleClick={() => {
            navigate("/login");
          }}
          btnText="Go to Login"
          width="100%"
        />
      </div>
    </RegInfoBodyMargin>
  );
};

export default RegisterSummary;
