// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about_tab_overview_body {
  width: 50%;
}

.about_overview_content_bottom_border {
  border-bottom: 1px solid #e2e4e9;
  width: 100%;
  margin-bottom: 10px;
}

.about_overview_content_reg_container {
  border: 1px solid #e7e7e8;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 20px;
  /* margin-top: 20px; */
  margin-bottom: 20px;
}

@media screen and (max-width: 850px) {
  .about_tab_overview_body {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/profileTabs/aboutTab/aboutTabOverview/aboutTabOverview.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,gCAAgC;EAChC,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".about_tab_overview_body {\n  width: 50%;\n}\n\n.about_overview_content_bottom_border {\n  border-bottom: 1px solid #e2e4e9;\n  width: 100%;\n  margin-bottom: 10px;\n}\n\n.about_overview_content_reg_container {\n  border: 1px solid #e7e7e8;\n  width: 100%;\n  padding: 15px;\n  box-sizing: border-box;\n  border-radius: 20px;\n  /* margin-top: 20px; */\n  margin-bottom: 20px;\n}\n\n@media screen and (max-width: 850px) {\n  .about_tab_overview_body {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
