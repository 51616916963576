import React from "react";
import "./homeFifthSection.css";
import MostPopularCard from "../mostPopularCard/MostPopularCard";
import { chevron_right_more } from "../../images";

const HomeFifthSecion = ({ categoryData }) => {
  return (
    <div className="supamall_homefifthbody_section_bbbb ">
      <div className="supamall_heading_btn_sectionName_flex">
        <div className="supamall_homefifthbody_section_bbbb_heading">
          {categoryData?.title}
        </div>
        <div className="supamall_heading_btn_sectionName_btn">
          <div className="supamall_heading_btn_sectionName_btntext">
            View all
          </div>
          <div className="supamall_heading_btn_sectionName_btnIcon">
            <img src={chevron_right_more} />
          </div>
        </div>
      </div>

      <div className="supamall_homefifthbody_section">
        <MostPopularCard
          title={categoryData?.title}
          categoryProducts={categoryData?.products}
        />
      </div>
    </div>
  );
};

export default HomeFifthSecion;
