import React, { useState, useEffect } from "react";
import "./register.css";
import AuthBodyMargin from "../../../components/authBodyMargin/AuthBodyMargin";
import { motion } from "framer-motion";
import {
  appleLogo,
  auth_key_reg,
  googleBtn,
  mail_icon,
  newSingleLogo,
  talk_stuff_authLogo,
  twitterLogo,
  userRegister_plus,
} from "../../../components/images";
import AuthTopIcon from "../../../components/authTopIcon/AuthTopIcon";
import AltLoginBtn from "../../../components/button/altLoginBtn/AltLoginBtn";
import AuthInputIcon from "../../../components/defaultInput/authInputnIcon/AuthInputIcon";
import { useNavigate } from "react-router-dom";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";
import AuthForwardBtn from "../../../components/button/authForwardBtn/AuthForwardBtn";
import GoBackBtn from "../../../components/button/gobackBtn/GoBackBtn";
import AuthInput from "../../../components/defaultInput/authInput/AuthInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosCalls, axiosFormData } from "../../../_api";
import { showLoader, hideLoader } from "../../../loader";
import SuccessRegisterModal from "../../../components/successRegisterModal/SuccessRegisterModal";
import { CallCustomToast } from "../../../utils/CallCustomToast";
import { useDispatch } from "react-redux";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAppleBtn = () => {};

  const handleGoogleBtn = () => {};

  const handleTwitterBtn = () => {};

  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [confirmId, setConfirmId] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    verificationCode: null,
  });

  const [error, setError] = useState({
    email: "",
    verificationCode: null,
  });
  const [onSecondCodeSent, setOnSecondCodeSent] = useState(false);
  const [countdown, setCountdown] = useState(6);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    let timer;
    if (onSecondCodeSent && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    } else if (countdown === 0) {
      // Change the text and reset countdown
      setOnSecondCodeSent(false);
      setCountdown(6);
      clearInterval(timer); // Stop the interval
    }
    return () => clearInterval(timer);
  }, [onSecondCodeSent, countdown]);

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "email":
          if (!value) {
            stateObj[name] = "Please enter Email address.";
          }
          break;
        case "verificationCode":
          if (!value) {
            stateObj[name] = "Please enter verification code.";
          } else if (value.length < 4) {
            stateObj[name] = "Verification code must be 4 characters minimum.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // if (name === "password" || name === "confirmPassword") {
    //   setPasswordMatch(formData.password === formData.confirmPassword);
    // }

    validateInput(e);
  };

  const handleTabToggle = (status) => {
    setTab(status);
  };

  const handleGoBack = () => {
    setTab(0);
  };

  const handleSubmitEmailVerify = async (e) => {
    // if (!passwordMatch) {
    //   setError(true);
    //   return;
    // }
    const data = {
      email: formData.email,
    };
    setIsLoading(true);
    // showLoader();
    const res = await axiosCalls("authentication/register", "POST", data);

    if (res) {
      setIsLoading(false);
      // hideLoader();
      console.log(res);
      if (!res?.status) {
        console.log(res?.er?.data?.message);
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        handleTabToggle(1);
        setConfirmId(res?.regId);
        // setOnSecondCodeSent(true);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
      }
    }
  };

  const handleSubmitEmailResendVerify = async (e) => {
    // if (!passwordMatch) {
    //   setError(true);
    //   return;
    // }
    const data = {
      email: formData.email,
    };

    showLoader();
    const res = await axiosCalls(
      "authentication/resend-email-verification-otp",
      "POST",
      data
    );

    if (res) {
      hideLoader();
      console.log(res);
      if (!res?.status) {
        console.log(res?.er?.data?.message);
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        setConfirmId(res?.data);
        // setOnSecondCodeSent(true);
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
      }
    }
  };

  const handleEmailCodeVerify = async (e) => {
    // if (!passwordMatch) {
    //   setError(true);
    //   return;
    // }
    const data = {
      otp: formData.verificationCode,
      email: formData.email,
    };

    // showLoader();
    setIsLoading(true);
    const res = await axiosCalls(
      "authentication/verify-registration-otp",
      "PATCH",
      data
    );

    if (res) {
      setIsLoading(false);
      // hideLoader();
      console.log(res);
      if (!res?.status) {
        // toast.error(res?.er?.data?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Error",
          toastStatus: "Error",
          messageBody: res?.er?.data?.message,
        });
        return;
      } else if (res?.status) {
        localStorage.setItem("regEmail", formData.email);
        // window.location.href = "/personal-data-register";
        // toast.success(res?.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });

        CallCustomToast(dispatch, {
          messageHeading: "Updated",
          toastStatus: "Success",
          messageBody: res?.message,
        });
        setSuccessMessage(res?.message);
        setShowSuccessModal(true);
      }

      // setTimeout(() => {
      //      handleTabToggle(1);
      // }, 2000);
    }
  };

  const handleContinue = () => {
    navigate("/personal-data-register");
  };
  return (
    <AuthBodyMargin>
      <ToastContainer />
      {/* <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5 }}
        className="termsandcondition_heading_logo"
      > */}
      <div className="termsandcondition_heading_logo">
        <img src={newSingleLogo} />
      </div>
      {/* </motion.div> */}
      {tab === 1 && <GoBackBtn handleGoBack={handleGoBack} />}

      {tab === 0 && (
        <div className="register_input_body">
          <AuthTopIcon userDummyIcon={userRegister_plus} />
          <div className="register_input_body_headerr">Join TalkStuff</div>
          <div className="register_input_body_head_textt">
            Get started by submitting your email address
          </div>

          {/* <div className="handle_alt_register_general">
          <AltLoginBtn handleAltBtn={handleAppleBtn} loginBtn={appleLogo} />
          <AltLoginBtn handleAltBtn={handleGoogleBtn} loginBtn={googleBtn} />
          <AltLoginBtn handleAltBtn={handleTwitterBtn} loginBtn={twitterLogo} />
        </div> */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitEmailVerify();
              // handleTabToggle(1);
            }}
          >
            <AuthInputIcon
              inputLabel="Email address"
              inputPlaceHolder="talkstuff@gmail.com"
              inputLeftIcon={mail_icon}
              width="100%"
              inputOnchange={handleChange}
              inputType="email"
              error={error.email}
              validateInput={validateInput}
              inputValue={formData.email}
              inputName="email"
              required={true}
            />

            <div className="register_toggle_radio_body">
              <div className="register_toggle_radio_body_checkBox">
                <input required={true} type="checkbox" />
              </div>
              <div className="register_toggle_radio_span">
                I agree to the{" "}
                <span
                  onClick={() => {
                    navigate("/terms-condition");
                  }}
                >
                  terms & condition{" "}
                </span>{" "}
                <span style={{ color: "#0A0D14", cursor: "default" }}>
                  and{" "}
                </span>
                <span
                  onClick={() => {
                    navigate("/privacy-policy");
                  }}
                >
                  privacy policy
                </span>
              </div>
            </div>

            <AuthBtn
              // handleClick={(e) => {
              //   e.preventDefault();
              //   handleSubmitEmailVerify();
              // }}
              isLoading={isLoading}
              btnText="Continue"
              width="100%"
            />
          </form>
          <div className="register_goback_login_body">
            <div className="register_goback_login_body_text">
              Already have an account?
            </div>
            <AuthForwardBtn
              handleGoForward={() => {
                navigate("/login");
              }}
              forwardBtnText="Login"
            />
          </div>
        </div>
      )}

      {tab === 1 && (
        <div className="register_verify_input_body">
          <AuthTopIcon userDummyIcon={auth_key_reg} />
          <div className="register_verify_input_body_headerr">
            Verify Your Email Address
          </div>
          <div className="register_verify_input_body_head_textt">
            We just sent a verification code to {formData.email}. Please enter
            the code below to confirm your identity.
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleEmailCodeVerify();
              // setShowSuccessModal(true);
            }}
          >
            <AuthInput
              required={true}
              inputOnchange={handleChange}
              inputType="text"
              inputPlaceHolder="Enter 4-digit code"
              inputLabel="Verification code"
              width="100%"
              error={error.verificationCode}
              validateInput={validateInput}
              inputValue={formData.verificationCode}
              inputName="verificationCode"
            />

            <div className="register_verify_resend_code_body">
              <div className="register_verify_resend_code_text">
                If you haven't received the code, you can{" "}
              </div>
              <div
                onClick={() => {
                  handleSubmitEmailResendVerify();
                  // console.log(confirmId, "regId");
                }}
                className="register_verify_resend_code_btn"
              >
                Resend code
              </div>
            </div>
            {/* {countdown === 0 ? (
              <div className="register_verify_resend_code_body">
                <div className="register_verify_resend_code_text">
                  If you haven't received the code, you can{" "}
                </div>
                <div
                  onClick={() => {
                    handleSubmitEmailResendVerify();
                    // console.log(confirmId, "regId");
                  }}
                  className="register_verify_resend_code_btn"
                >
                  Resend code
                </div>
              </div>
            ) : (
              <div className="register_verify_resend_code_body">
                <div className="register_verify_resend_code_text">
                  Link has been resent to your mail
                </div>
                {countdown === 0 ? (
                  <div
                    onClick={() => {
                      handleSubmitEmailResendVerify();
                      // console.log(confirmId, "regId");
                    }}
                    className="register_verify_resend_code_btn"
                  >
                    Resend code
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      handleSubmitEmailResendVerify();
                      // console.log(confirmId, "regId");
                    }}
                    className="register_verify_resend_code_btn"
                  >
                    Try in {countdown} seconds
                  </div>
                )}
              </div>
            )} */}

            <AuthBtn
              isLoading={isLoading}
              // handleClick={() => {
              //   navigate("/personal-data-register");
              // }}
              btnDisabled={error.verificationCode !== ""}
              btnText="Verify"
              width="100%"
            />
          </form>
        </div>
      )}

      {showSuccessModal && (
        <div>
          <SuccessRegisterModal
            handleContinue={handleContinue}
            successMessage="Congratulations! Your email address has been successfully verified."
          />
        </div>
      )}
    </AuthBodyMargin>
  );
};

export default Register;
