// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.each_follower_card_body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #e2e4e9;
  padding-top: 5px;
  padding-bottom: 10px;
}

.each_follower_card_body_firstFlex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.each_follower_card_body_firstFlex_img {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.each_follower_card_body_firstFlex_img img {
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.each_follower_card_body_firstFlex_text {
  font-size: 12px;
  color: #0a0d14;
  font-weight: 600;
}

.each_follower_card_body_firstFlex_icon {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.99px solid #fff;
  cursor: pointer;
}

.each_follower_card_body_firstFlex_icon:hover {
  box-shadow: 0px 0.9918952584266663px 1.9837905168533325px 0px #5258660d;
  border: 0.99px solid #e2e4e9;
}
`, "",{"version":3,"sources":["webpack://./src/components/eachFollowerCard/eachFollowerCard.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,sBAAsB;EACtB,gCAAgC;EAChC,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,uEAAuE;EACvE,4BAA4B;AAC9B","sourcesContent":[".each_follower_card_body {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 10px;\n  box-sizing: border-box;\n  border-bottom: 1px solid #e2e4e9;\n  padding-top: 5px;\n  padding-bottom: 10px;\n}\n\n.each_follower_card_body_firstFlex {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.each_follower_card_body_firstFlex_img {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  width: 40px;\n  height: 40px;\n}\n\n.each_follower_card_body_firstFlex_img img {\n  width: 100%;\n  border-radius: 50%;\n  object-fit: cover;\n}\n\n.each_follower_card_body_firstFlex_text {\n  font-size: 12px;\n  color: #0a0d14;\n  font-weight: 600;\n}\n\n.each_follower_card_body_firstFlex_icon {\n  width: 30px;\n  height: 30px;\n  border-radius: 10px;\n  background: #fff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  border: 0.99px solid #fff;\n  cursor: pointer;\n}\n\n.each_follower_card_body_firstFlex_icon:hover {\n  box-shadow: 0px 0.9918952584266663px 1.9837905168533325px 0px #5258660d;\n  border: 0.99px solid #e2e4e9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
