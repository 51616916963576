import React from "react";
import InfoModal from "../../../components/infoModal/InfoModal";
import WhiteBtn from "../../../components/button/whiteBtn/WhiteBtn";
import AuthBtn from "../../../components/button/authBtn/AuthBtn";
import { matches_info } from "../../../components/images";

const DeleteConfirmModal = ({ handleShowDeleteModal }) => {
  return (
    <InfoModal
      handleCloseModal={() => {
        handleShowDeleteModal(false);
      }}
      width="600px"
    >
      <div className="delete_each_matches_modal_subtext_caution_body">
        <div className="delete_each_matches_modal_subtext_caution_icon">
          <img src={matches_info} />
        </div>

        <div className="delete_each_matches_modal_subtext_caution_textt">
          This action will permanently delete your account and all associated
          data. This action is irreversible.
        </div>
      </div>

      <div className="delete_each_matches_modal_subtext_btn_conttt">
        <WhiteBtn
          handleBtn={() => {
            handleShowDeleteModal(false);
          }}
          whiteBtnText="Close"
          width="50%"
        />

        <AuthBtn btnText="Confirm Delete" width="50%" />
      </div>
    </InfoModal>
  );
};

export default DeleteConfirmModal;
