// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reg_identity_body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 110px;
  background: #fff;
}

.reg_identity_body_step_num {
  border: 1px solid #e2e4e9;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #525866;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
}

.reg_identity_body_step_stage {
  font-size: 12px;
  color: #0a0d14;
  font-weight: 600;
}

.reg_identity_body_step_chevron {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/regIdentitySteps/regIdentitySteps.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".reg_identity_body {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 110px;\n  background: #fff;\n}\n\n.reg_identity_body_step_num {\n  border: 1px solid #e2e4e9;\n  width: 28px;\n  height: 28px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #525866;\n  font-size: 12px;\n  font-weight: 600;\n  font-style: normal;\n}\n\n.reg_identity_body_step_stage {\n  font-size: 12px;\n  color: #0a0d14;\n  font-weight: 600;\n}\n\n.reg_identity_body_step_chevron {\n  width: 15px;\n  height: 15px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
