import React from "react";
import "./postUploadModalHeader.css";
import { book_image } from "../../images";

const PostUploadModalHeader = ({ postUploadHeading, postUploadIcon }) => {
  return (
    <div className="post_upload_modal_headerBody">
      <div className="post_upload_modal_headerBody_img">
        <img src={postUploadIcon} />
      </div>
      <div className="post_upload_modal_headerBody_head">
        {postUploadHeading}
      </div>
      <div className="post_upload_modal_headerBody_sub">
        Upload from computer or drag file to drop
      </div>
    </div>
  );
};

export default PostUploadModalHeader;
