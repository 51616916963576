import React, { useRef, useState, useEffect } from "react";

import "./postGridViewer.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import ReactPlayer from "react-player";
import InfoModal from "../infoModal/InfoModal";
import { useDispatch, useSelector } from "react-redux";
import { handleStatusPreviewPostMedia } from "../../redux/userData";
// SwiperCore.use([Navigation, Pagination]);

import {
  post_fourImage,
  post_oneImage,
  post_threeImage,
  post_twoImage,
  minigadget,
} from "../images";

const images = [
  {
    url: post_oneImage,
  },
  {
    url: post_fourImage,
  },
  {
    url: post_twoImage,
  },
  {
    url: post_threeImage,
  },
];

const PostGridViewer = () => {
  const [inView, setInView] = useState(Array(images?.length).fill(false));

  const dispatch = useDispatch();
  const { previewPostMedia } = useSelector(
    (state) => state.previewPostMediaBody.previewPostMediaBody
  );

  const getFileComponent = (file, index) => {
    const fileExtension = getFileExtension(file);
    if (
      fileExtension === "jpg" ||
      fileExtension === "png" ||
      fileExtension === "jpeg" ||
      fileExtension === "webp"
    ) {
      return (
        <img
          src={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com${file}`}
          alt={`Slide ${index}`}
          id={`slide-${index}`}
        />
      );
    } else if (fileExtension === "mp4") {
      return (
        // <video controls>
        //   <source src={file} type="video/mp4" />
        //   Your browser does not support the video tag.
        // </video>

        <div className="post_video_playerr_body" id={`slide-${index}`}>
          <ReactPlayer
            url={`https://talkstuff-store.fra1.cdn.digitaloceanspaces.com${file}`}
            playing={false}
            controls={true}
            width="100%"
            height="100%"
            // style={{ marginTop: "100px" }}
            onPlay={() => handleVideoPlay(index)}
            onPause={() => handleVideoPause(index)}
          />
        </div>
      );
    } else {
      return <div>No preview available</div>;
    }
  };

  const getFileExtension = (filename) => {
    return filename?.split(".").pop().toLowerCase();
  };

  const handleVideoPlay = (index) => {
    const newInView = [...inView];
    newInView[index] = true;
    setInView(newInView);
  };

  const handleVideoPause = (index) => {
    const newInView = [...inView];
    newInView[index] = false;
    setInView(newInView);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries?.forEach((entry, index) => {
          if (entry.isIntersecting) {
            handleVideoPlay(index);
          } else {
            handleVideoPause(index);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    images?.forEach((_, index) => {
      const element = document.getElementById(`slide-${index}`);
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <InfoModal
      handleCloseModal={() => {
        dispatch(handleStatusPreviewPostMedia(false));
      }}
      width="700px"
    >
      <Swiper
        className="mySwiper"
        // pagination={{
        //   dynamicBullets: true,
        //   clickable: true,
        // }}
        pagination={{ clickable: true, type: "bullets" }}
        navigation
        modules={[Pagination, Navigation, Scrollbar, A11y]}
      >
        {previewPostMedia?.map((image, index) => (
          <SwiperSlide key={index}>
            {getFileComponent(image?.url, index)}
          </SwiperSlide>
        ))}
      </Swiper>
    </InfoModal>
  );
};

export default PostGridViewer;
