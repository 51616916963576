import React from "react";
import "./goBackBtn.css";
import { chevronLeftlegal } from "../../images";

const GoBackBtn = ({ handleGoBack }) => {
  return (
    <div onClick={handleGoBack} className="go_backbtn_body">
      <div className="go_backbtn_body_btn">
        <img src={chevronLeftlegal} />
      </div>
      <div className="go_backbtn_body_text">Back</div>
    </div>
  );
};

export default GoBackBtn;
