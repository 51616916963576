// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help_support_links_card_rackBody {
  box-shadow: 0px 0.9918952584266663px 1.9837905168533325px 0px #5258660d;
  border: 0.99px solid #e2e4e9;
  background: #fff;
  border-radius: 8px;
  padding: 22px 12px 22px 12px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 10px;
}
.help_support_links_card_rackBody:hover {
  background: #faf9f9;
}

.help_support_links_card_rack_text {
  font-size: 12px;
  font-weight: 600;
  color: #0a0d14;
}

.help_support_links_card_rack_chevBod {
  display: flex;
  align-items: center;
  gap: 5px;
}

.help_support_links_card_rack_chevBodText {
  font-size: 12px;
  color: #525866;
  font-weight: 400;
}

.help_support_links_card_rack_chevBod_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/helpSupportComp/helpSupportLinksCardRack/helpSupportLinksCardRack.css"],"names":[],"mappings":"AAAA;EACE,uEAAuE;EACvE,4BAA4B;EAC5B,gBAAgB;EAChB,kBAAkB;EAClB,4BAA4B;EAC5B,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd","sourcesContent":[".help_support_links_card_rackBody {\n  box-shadow: 0px 0.9918952584266663px 1.9837905168533325px 0px #5258660d;\n  border: 0.99px solid #e2e4e9;\n  background: #fff;\n  border-radius: 8px;\n  padding: 22px 12px 22px 12px;\n  box-sizing: border-box;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  cursor: pointer;\n  margin-top: 10px;\n}\n.help_support_links_card_rackBody:hover {\n  background: #faf9f9;\n}\n\n.help_support_links_card_rack_text {\n  font-size: 12px;\n  font-weight: 600;\n  color: #0a0d14;\n}\n\n.help_support_links_card_rack_chevBod {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n}\n\n.help_support_links_card_rack_chevBodText {\n  font-size: 12px;\n  color: #525866;\n  font-weight: 400;\n}\n\n.help_support_links_card_rack_chevBod_icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 15px;\n  height: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
