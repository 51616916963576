import React, { useState } from "react";
import "./supamallCartCard.css";
import {
  homesecond_supamallleft,
  supamall_cart_clock,
  supamall_carteye,
  supamall_map_pin,
} from "../../images";
import { Rating } from "react-simple-star-rating";
import AuthBtn from "../../button/authBtn/AuthBtn";
import IconAuthBtn from "../../button/iconAuthBtn/IconAuthBtn";

const SupamallCartCard = () => {
  const [rating, setRating] = useState(0);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };
  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => {
    console.log(value, index);
  };
  return (
    <div className="supamall_cart_card_body_genn">
      <div className="supamall_cart_card_image_product">
        <img src={homesecond_supamallleft} />
      </div>

      <div className="supamall_cart_card_body_right_">
        <div className="supamall_cart_card_image_product_name">
          Nike sneakers shoe
        </div>

        <div className="supamall_cart_card_body_right_firstFlex">
          <div className="supamall_cart_card_body_right_firstFlex_cart_priceFlex">
            <div className="supamall_cart_card_body_right_firstFlex_price">
              <div className="supamall_cart_card_body_right_firstFlex_price_text">
                N28,000
              </div>
              <div className="supamall_cart_card_body_right_firstFlex_price___">
                Price
              </div>
            </div>

            <div className="supamall_cart_card_body_right_firstFlex_rating">
              <div className="supamall_cart_card_body_right_firstFlex_rating_star">
                {" "}
                <Rating
                  onClick={handleRating}
                  onPointerEnter={onPointerEnter}
                  onPointerLeave={onPointerLeave}
                  onPointerMove={onPointerMove}
                  size={15}
                />
              </div>
              <div className="supamall_cart_card_body_right_firstFlex_rating_num">
                32 ratings
              </div>
            </div>
          </div>

          <div className="supamall_cart_card_body_right_firstFlex_location">
            <div className="supamall_cart_card_body_right_firstFlex_locationGen">
              <div className="supamall_cart_card_body_right_firstFlex_locationGen_text">
                Location
              </div>
              <div className="supamall_cart_card_body_right_firstFlex_locationGen_bottom_sec">
                <div className="supamall_cart_card_body_right_firstFlex_locationGen_bottom_sec_iconB">
                  <div className="supamall_cart_card_body_right_firstFlex_locationGen_bottom_sec_icon">
                    <img src={supamall_cart_clock} />
                  </div>
                  <div className="supamall_cart_card_body_right_firstFlex_locationGen_bottom_sec_iconT">
                    Posted 1 hour ago
                  </div>
                </div>
                <div className="supamall_cart_card_body_right_firstFlex_locationGen_bottom_sec_iconB">
                  <div className="supamall_cart_card_body_right_firstFlex_locationGen_bottom_sec_icon">
                    <img src={supamall_map_pin} />
                  </div>
                  <div className="supamall_cart_card_body_right_firstFlex_locationGen_bottom_sec_iconT">
                    Efab metropolis{" "}
                  </div>
                </div>
                <div className="supamall_cart_card_body_right_firstFlex_locationGen_bottom_sec_iconB">
                  <div className="supamall_cart_card_body_right_firstFlex_locationGen_bottom_sec_icon">
                    <img src={supamall_carteye} />
                  </div>
                  <div className="supamall_cart_card_body_right_firstFlex_locationGen_bottom_sec_iconT">
                    77 views
                  </div>
                </div>
              </div>
            </div>

            <IconAuthBtn width="100px" btnText="Chat" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupamallCartCard;
