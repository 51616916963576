import React from "react";

const LogoutIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49835 13.8766H4.01862C3.68978 13.8766 3.37442 13.746 3.1419 13.5134C2.90938 13.2809 2.77875 12.9656 2.77875 12.6367V3.95764C2.77875 3.62881 2.90938 3.31344 3.1419 3.08092C3.37442 2.8484 3.68978 2.71777 4.01862 2.71777H6.49835"
        stroke="#cc182f"
        stroke-width="1.48784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8379 11.3969L13.9376 8.29718L10.8379 5.19751"
        stroke="#cc182f"
        stroke-width="1.48784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.9376 8.29712H6.49835"
        stroke="#cc182f"
        stroke-width="1.48784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
