import React from "react";
import "./supaMallSellCategory.css";
import Footer from "../../../../components/supamallComp/footer/Footer";
import SupaMallSideBar from "../../supaMallSideBar/SupaMallSideBar";
import SellCategoryCard from "../../../../components/supamallComp/sellCategoryCard/SellCategoryCard";
import WhiteBtn from "../../../../components/button/whiteBtn/WhiteBtn";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../../components/supamallComp/breadCrumb/BreadCrumb";

const SupaMallSellCategory = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{ marginTop: "30px" }}
      className="supamall_sell_category_gen_home"
    >
      <Breadcrumbs />
      <div className="supamall_sell_category_container">
        <div className="supamall_sell_categorization_text_head">
          Select categorization
        </div>
        <div className="supamall_sell_categorization_textttt">
          Select the category for the offerings you plan to provide to your
          customers.
        </div>
        <div className="supamall_sell_category_card_body_flexxx">
          <SellCategoryCard
            headText="Products"
            bodyText="Offer carefully curated products tailored to meet your customers' needs."
            handleBtn={() => {
              navigate("/supamall/sell-product");
            }}
          />
          <SellCategoryCard
            headText="Services"
            bodyText="Offer the specific services you aim to provide to your customers."
            handleBtn={() => {
              navigate("/supamall/sell-service");
            }}
          />
          <SellCategoryCard
            headText="Jobs"
            bodyText="Offer a range of job openings designed to match skills and aspirations of people"
            handleBtn={() => {
              navigate("/supamall/sell-job");
            }}
          />
        </div>

        <div className="supamall_sell_category_goback_body">
          <WhiteBtn width="120px" whiteBtnText="Go back" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SupaMallSellCategory;
