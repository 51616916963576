import React from "react";

const HeartLiked = ({ fill, outline }) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3299 7.77411C12.2536 6.86901 13.1897 5.78412 13.1897 4.36447C13.1897 3.46018 12.8304 2.59293 12.191 1.95349C11.5516 1.31406 10.6843 0.954834 9.78004 0.954834C8.68895 0.954834 7.92023 1.2648 6.99033 2.1947C6.06043 1.2648 5.29171 0.954834 4.20063 0.954834C3.29633 0.954834 2.42908 1.31406 1.78965 1.95349C1.15021 2.59293 0.790985 3.46018 0.790985 4.36447C0.790985 5.79032 1.72089 6.87521 2.65079 7.77411L6.99033 12.1137L11.3299 7.77411Z"
        stroke={outline}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HeartLiked;
