import React from "react";
import "./generalSearchModal.css";
import InfoModal from "../infoModal/InfoModal";
import { useDispatch, useSelector } from "react-redux";
import { handleShowGeneralSearch } from "../../redux/userData";
import AuthInputIcon from "../defaultInput/authInputnIcon/AuthInputIcon";
import { searchHelp } from "../images";

const GeneralSearchModal = () => {
  const dispatch = useDispatch();
  return (
    <InfoModal
      handleCloseModal={() => {
        dispatch(handleShowGeneralSearch(false));
      }}
      width="600px"
    >
      <div className="general_search_heading_text">Search</div>

      {/* <div className="help_support_search_body_input"> */}
      <AuthInputIcon
        marginTop="8px"
        width="100%"
        inputPlaceHolder="What are you looking for ?"
        inputLeftIcon={searchHelp}
      />

      {/* </div> */}
    </InfoModal>
  );
};

export default GeneralSearchModal;
