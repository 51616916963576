import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const UseClipRoundLoader = ({ isLoading, size, color }) => {
  return (
    <ClipLoader
      color={color}
      loading={isLoading}
      // cssOverride={override}
      size={size}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export default UseClipRoundLoader;
