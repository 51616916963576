import React from "react";
import "./interestCardRackEach.css";
import { plus_interest } from "../../images";

const InterestCardRackEach = ({ interestText, handleClick, selectedRack }) => {
  return (
    <div
      onClick={handleClick}
      className={
        selectedRack.includes(interestText)
          ? "interest_card_rack_each_body_active"
          : "interest_card_rack_each_body"
      }
    >
      <div className="interest_card_rack_each_body_img">
        <img src={plus_interest} />
      </div>
      <div className="interest_card_rack_each_body_text">{interestText}</div>
    </div>
  );
};

export default InterestCardRackEach;
