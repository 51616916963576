import React, { useState, useEffect } from "react";
import "./chatHome.css";
import AllUsersChat from "../allUsersChat/AllUsersChat";
import ChatBox from "../chatBox/ChatBox";
import ChatBoxMobile from "../../../components/chatBoxMobile/ChatBoxMobile";
import { useSelector } from "react-redux";

const ChatHome = () => {
  const [show, setShow] = useState(false);

  const { showChatBox } = useSelector(
    (state) => state.showChatBoxBody.showChatBoxBody
  );

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const controlNavbar = () => {
    if (window.scrollY > 70) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);
  return (
    <div className="chat_home_gen_body">
      <AllUsersChat />

      {showChatBox && <ChatBoxMobile />}
      {!showChatBox && <div className="chat_nochat_desktop_view"></div>}
      {/* {screenWidth > 850 && <ChatBox />} */}
    </div>
  );
};

export default ChatHome;
