import React, { useState, useEffect } from "react";

const useFormattedFullTime = (createdAt) => {
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    const calculateTimeDifference = () => {
      const now = new Date();
      const timeDifferenceMillis = now - new Date(createdAt);
      const timeDifferenceSeconds = Math.floor(timeDifferenceMillis / 1000);

      if (timeDifferenceSeconds < 60) {
        setFormattedTime(`${timeDifferenceSeconds} seconds ago`);
      } else if (timeDifferenceSeconds < 3600) {
        const minutesDifference = Math.floor(timeDifferenceSeconds / 60);
        setFormattedTime(`${minutesDifference} minutes ago`);
      } else if (timeDifferenceSeconds < 86400) {
        const hoursDifference = Math.floor(timeDifferenceSeconds / 3600);
        setFormattedTime(`${hoursDifference} hours ago`);
      } else if (timeDifferenceSeconds < 604800) {
        // 7 days (1 week)
        const daysDifference = Math.floor(timeDifferenceSeconds / 86400);
        setFormattedTime(`${daysDifference} days ago`);
      } else if (timeDifferenceSeconds < 2629746) {
        // 30.44 days (1 month)
        const weeksDifference = Math.floor(timeDifferenceSeconds / 604800);
        setFormattedTime(`${weeksDifference} weeks ago`);
      } else if (timeDifferenceSeconds < 31556952) {
        // 365.25 days (1 year)
        const monthsDifference = Math.floor(timeDifferenceSeconds / 2629746);
        setFormattedTime(`${monthsDifference} months ago`);
      } else {
        const yearsDifference = Math.floor(timeDifferenceSeconds / 31556952);
        setFormattedTime(`${yearsDifference} years ago`);
      }
    };

    calculateTimeDifference();
    const interval = setInterval(calculateTimeDifference, 60000); // Update every minute

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [createdAt]);

  return formattedTime;
};

export default useFormattedFullTime;
