// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_about_tab_sidebar_body {
  width: 50%;
  /* height: 100%; */
  padding: 12px;
  border: 1px solid #e2e4e9;
  border-radius: 15px;

  box-sizing: border-box;
  /* margin-top: 30px; */
}

@media screen and (max-width: 850px) {
  .profile_about_tab_sidebar_body {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/profileTabs/aboutTab/aboutTabSideBar/aboutTabSideBar.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;;EAEnB,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".profile_about_tab_sidebar_body {\n  width: 50%;\n  /* height: 100%; */\n  padding: 12px;\n  border: 1px solid #e2e4e9;\n  border-radius: 15px;\n\n  box-sizing: border-box;\n  /* margin-top: 30px; */\n}\n\n@media screen and (max-width: 850px) {\n  .profile_about_tab_sidebar_body {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
