import React, { useState, useEffect } from "react";
import "./photosTab.css";
import EachPhotoCard from "./eachPhotoCard/EachPhotoCard";
import { photo_library_sample, photo_sample_tab2 } from "../../images";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { axiosCalls } from "../../../_api";
import AuthBtn from "../../button/authBtn/AuthBtn";
import { MutatingDots } from "react-loader-spinner";

const PhotosTab = ({
  userPhotos,
  isLoading,
  onErrorLoading,
  handleGetUserPhotos,
}) => {
  // const [userPhotos, setUserPhotos] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  // const [onErrorLoading, setOnErrorLoading] = useState(false);
  const baseImageUrl =
    "https://talkstuff-store.fra1.cdn.digitaloceanspaces.com";

  useEffect(() => {
    handleGetUserPhotos();
  }, []);

  // const handleGetUserPhotos = async (e) => {
  //   setIsLoading(true);
  //   setOnErrorLoading(false);
  //   const res = await axiosCalls(
  //     "accounts/account-profile-userphotos?page=1&limit=50&flatten=true",
  //     "GET"
  //   );

  //   if (res) {
  //     console.log(res);
  //     setIsLoading(false);
  //     if (!res?.status) {
  //       console.log(res?.er.data?.message);
  //       setOnErrorLoading(true);
  //       toast.error(res?.er?.data?.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "dark",
  //       });
  //       return;
  //     } else if (res?.status) {
  //       setUserPhotos(res?.data);
  //       setOnErrorLoading(false);
  //       // toast.success(res?.message, {
  //       //   position: "top-right",
  //       //   autoClose: 5000,
  //       //   hideProgressBar: false,
  //       //   closeOnClick: true,
  //       //   pauseOnHover: true,
  //       //   draggable: true,
  //       //   progress: undefined,
  //       //   theme: "dark",
  //       // });
  //     }
  //   }
  // };

  return (
    <div className="photos_tab_body_gen">
      {isLoading ? (
        // Show skeleton while loading
        // <Skeleton count={5} height={500} />
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#e45911"
            secondaryColor="#e45911"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        // Show user posts when loaded
        userPhotos?.map((data, index) => <EachPhotoCard photo={data} />)
      )}

      {onErrorLoading && (
        <div className="network_xerror_body">
          Network error{" "}
          <AuthBtn
            width="200px"
            btnText="Reload"
            handleClick={handleGetUserPhotos}
          />
        </div>
      )}
    </div>
  );
};

export default PhotosTab;
