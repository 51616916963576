import React from "react";
import { appleLogo } from "../../images";
import "./altLoginBtn.css";

const AltLoginBtn = ({ handleAltBtn, loginBtn }) => {
  return (
    <div className="alt_login_btn" onClick={handleAltBtn}>
      <img src={loginBtn} />
    </div>
  );
};

export default AltLoginBtn;
