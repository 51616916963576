import React from "react";
import "./supamallEachSectionOne.css";
import {
  eac_sec_one,
  message_circle_message,
  sample_follower_img,
  supamall_messages_square,
  supamall_phone,
  supamall_star,
  supamall_user_icon,
} from "../../images";
import AuthBtn from "../../button/authBtn/AuthBtn";
import WhiteBtn from "../../button/whiteBtn/WhiteBtn";
import Breadcrumbs from "../breadCrumb/BreadCrumb";
import { imagePrefix } from "../../../utils/callImagePrefix";
import LazyLoadingHook from "../../../utils/lazyLoadingHook";
import Skeleton from "react-loading-skeleton";
import useFormattedFullTime from "../../../utils/useFormattedFullTime";

const SupaMallEachSectionOne = ({ productData, isProductLoading }) => {
  return (
    <div className="supamall_each_sectionOneBody">
      <Breadcrumbs />
      <div className="supamall_each_sectionOneBody__sub">
        {isProductLoading ? (
          <Skeleton height={500} />
        ) : (
          <div className="supamall_each_sectionOneBody__sub_image_containerrr">
            {productData?.product?.baseImages?.length === 1 && (
              <div className="supamall_each_sectionOneBody_left">
                <div className="supamall_each_sectionOneBody_left_img_main_one">
                  <LazyLoadingHook
                    effect="blur"
                    imageSrc={`${imagePrefix}${productData?.product?.baseImages[0]}`}
                  />
                </div>
              </div>
            )}

            {productData?.product?.baseImages?.length === 2 && (
              <div className="supamall_each_sectionOneBody_left">
                <div className="supamall_each_sectionOneBody_left_img_main">
                  <LazyLoadingHook
                    effect="blur"
                    imageSrc={`${imagePrefix}${productData?.product?.baseImages[0]}`}
                  />
                </div>

                <div className="supamall_each_sectionOneBody_left_img_flex_img_two">
                  <LazyLoadingHook
                    effect="blur"
                    imageSrc={`${imagePrefix}${productData?.product?.baseImages[1]}`}
                  />
                </div>
              </div>
            )}

            {productData?.product?.baseImages?.length === 3 && (
              <div className="supamall_each_sectionOneBody_left">
                <div className="supamall_each_sectionOneBody_left_img_main">
                  <LazyLoadingHook
                    effect="blur"
                    imageSrc={`${imagePrefix}${productData?.product?.baseImages[0]}`}
                  />
                </div>
                <div className="supamall_each_sectionOneBody_left_img_flex">
                  <div className="supamall_each_sectionOneBody_left_img_flex_img">
                    <LazyLoadingHook
                      effect="blur"
                      imageSrc={`${imagePrefix}${productData?.product?.baseImages[1]}`}
                    />
                  </div>
                  <div className="supamall_each_sectionOneBody_left_img_flex_img">
                    <LazyLoadingHook
                      effect="blur"
                      imageSrc={`${imagePrefix}${productData?.product?.baseImages[2]}`}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="supamall_each_sectionOneBody_right">
          <div className="supamall_each_sectionOneBody_right_storeName">
            {productData?.product?.userId?.firstName}{" "}
            {productData?.product?.userId?.lastName}
          </div>
          <div className="supamall_each_sectionOneBody_right_storeStatus">
            Verified
          </div>

          {productData?.product?.userId?.profileImage ? (
            <div className="supamall_each_sectionOneBody_right_storePics">
              <LazyLoadingHook
                imageSrc={`${productData?.product?.userId?.profileImage}`}
              />
            </div>
          ) : (
            <div className="supamall_each_sectionOneBody_right_storePics_details">
              <div className="supamall_each_sectionOneBody_right_storePics_details_image">
                {productData?.product?.userId?.firstName // if profile_pic is null, show only the first letters of the names
                  ?.split(" ")
                  ?.map((name) => name.charAt(0).toUpperCase())
                  .join("")}
                {productData?.product?.userId?.lastName // if profile_pic is null, show only the first letters of the names
                  ?.split(" ")
                  ?.map((name) => name.charAt(0).toUpperCase())
                  .join("")}
              </div>
            </div>
          )}

          <div className="supamall_each_sectionOneBody_right_storeInfo">
            <div className="supamall_each_sectionOneBody_right_storeInfoEach">
              <div className="supamall_each_sectionOneBody_right_storeInfoEachIcon">
                <img src={supamall_user_icon} />
              </div>
              <div className="supamall_each_sectionOneBody_right_storeInfoEachtext">
                {useFormattedFullTime(productData?.product?.userId?.createdAt)}
              </div>
            </div>
            <div className="supamall_each_sectionOneBody_right_storeInfoEach">
              <div className="supamall_each_sectionOneBody_right_storeInfoEachIcon">
                <img src={supamall_messages_square} />
              </div>
              <div className="supamall_each_sectionOneBody_right_storeInfoEachtext">
                Replies within minutes
              </div>
            </div>
            {/* <div className="supamall_each_sectionOneBody_right_storeInfoEach">
              <div className="supamall_each_sectionOneBody_right_storeInfoEachIcon">
                <img src={supamall_star} />
              </div>
              <div className="supamall_each_sectionOneBody_right_storeInfoEachtext">
                25 reviews
              </div>
            </div> */}
          </div>

          <div className="supamall_each_sectionOneBody_right_storeInfo_chatBtn">
            <div className="supamall_each_sectionOneBody_right_storeInfo_chatBtnIcon">
              <img src={message_circle_message} />
            </div>
            <div className="supamall_each_sectionOneBody_right_storeInfo_chatBtntext">
              Chat
            </div>
          </div>

          <div className="supamall_each_sectionOneBody_right_storeInfo_chatBtnW">
            <div className="supamall_each_sectionOneBody_right_storeInfo_chatBtnIconW">
              <img src={supamall_phone} />
            </div>
            <div className="supamall_each_sectionOneBody_right_storeInfo_chatBtntextW">
              Display Contact
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupaMallEachSectionOne;
